import React from 'react'

import ArrowDown from 'hoffelijk-react-components/components/icons/arrow-down'
import ArrowLeft from 'hoffelijk-react-components/components/icons/arrow-left'
import ArrowRight from 'hoffelijk-react-components/components/icons/arrow-right'
import ArrowUp from 'hoffelijk-react-components/components/icons/arrow-up'
import Bin from 'hoffelijk-react-components/components/icons/bin'
import CalendarCheck from 'hoffelijk-react-components/components/icons/calendar-check'
import CalendarClose from 'hoffelijk-react-components/components/icons/calendar-close'
import Camera from 'hoffelijk-react-components/components/icons/camera'
import Caret from 'hoffelijk-react-components/components/icons/caret'
import Check from 'hoffelijk-react-components/components/icons/check'
import Clock from 'hoffelijk-react-components/components/icons/clock'
import Cloud from 'hoffelijk-react-components/components/icons/cloud'
import ContentFilterEmpty from 'hoffelijk-react-components/components/icons/content-filter-empty'
import ContentFilter from 'hoffelijk-react-components/components/icons/content-filter'
import ControlSplit from 'hoffelijk-react-components/components/icons/control-split'
import CursorDial from 'hoffelijk-react-components/components/icons/cursor-dial'
import Download from 'hoffelijk-react-components/components/icons/download'
import EmailEnvelope from 'hoffelijk-react-components/components/icons/email-envelope'
import FileAdd from 'hoffelijk-react-components/components/icons/file-add'
import Flash from 'hoffelijk-react-components/components/icons/flash'
import GenderFemaleMale from 'hoffelijk-react-components/components/icons/gender-female-male'
import GenderFemale from 'hoffelijk-react-components/components/icons/gender-female'
import GenderMale from 'hoffelijk-react-components/components/icons/gender-male'
import Hide from 'hoffelijk-react-components/components/icons/hide'
import Hourglass from 'hoffelijk-react-components/components/icons/hourglass'
import InterfaceInformation from 'hoffelijk-react-components/components/icons/interface-information'
import Key from 'hoffelijk-react-components/components/icons/key'
import Magnify from 'hoffelijk-react-components/components/icons/magnify'
import Medal from 'hoffelijk-react-components/components/icons/medal'
import Pen from 'hoffelijk-react-components/components/icons/pen'
import PhoneOutgoing from 'hoffelijk-react-components/components/icons/phone-outgoing'
import PowerButton from 'hoffelijk-react-components/components/icons/power-button'
import QuestionMark from 'hoffelijk-react-components/components/icons/question-mark'
import Rabbit from 'hoffelijk-react-components/components/icons/rabbit'
import Refresh from 'hoffelijk-react-components/components/icons/refresh'
import Sections from 'hoffelijk-react-components/components/icons/sections'
import Show from 'hoffelijk-react-components/components/icons/show'
import Snail from 'hoffelijk-react-components/components/icons/snail'
import SwitchLeft from 'hoffelijk-react-components/components/icons/switch-left'
import SwitchRight from 'hoffelijk-react-components/components/icons/switch-right'
import TimeEdit from 'hoffelijk-react-components/components/icons/time-edit'
import Upload from 'hoffelijk-react-components/components/icons/upload'
import User from 'hoffelijk-react-components/components/icons/user'
import Verified from 'hoffelijk-react-components/components/icons/verified'
import ViewLock from 'hoffelijk-react-components/components/icons/view-lock'

const getIconComponent = (icon) => {
  switch (icon) {
    case 'arrow-down': return ArrowDown
    case 'arrow-left': return ArrowLeft
    case 'arrow-right': return ArrowRight
    case 'arrow-up': return ArrowUp
    case 'bin': return Bin
    case 'calendar-check': return CalendarCheck
    case 'calendar-close': return CalendarClose
    case 'camera': return Camera
    case 'caret': return Caret
    case 'check': return Check
    case 'clock': return Clock
    case 'cloud': return Cloud
    case 'content-filter-empty': return ContentFilterEmpty
    case 'content-filter': return ContentFilter
    case 'control-split': return ControlSplit
    case 'cursor-dial': return CursorDial
    case 'download': return Download
    case 'email-envelope': return EmailEnvelope
    case 'file-add': return FileAdd
    case 'flash': return Flash
    case 'gender-female-male': return GenderFemaleMale
    case 'gender-female': return GenderFemale
    case 'gender-male': return GenderMale
    case 'hide': return Hide
    case 'hourglass': return Hourglass
    case 'interface-information': return InterfaceInformation
    case 'key': return Key
    case 'magnify': return Magnify
    case 'medal': return Medal
    case 'pen': return Pen
    case 'phone-outgoing': return PhoneOutgoing
    case 'power-button': return PowerButton
    case 'question-mark': return QuestionMark
    case 'rabbit': return Rabbit
    case 'refresh': return Refresh
    case 'sections': return Sections
    case 'show': return Show
    case 'snail': return Snail
    case 'switch-left': return SwitchLeft
    case 'switch-right': return SwitchRight
    case 'time-edit': return TimeEdit
    case 'upload': return Upload
    case 'user': return User
    case 'verified': return Verified
    case 'view-lock': return ViewLock
    default: return null
  }
}

export const icons = {
  ArrowDown: 'arrow-down',
  ArrowLeft: 'arrow-left',
  ArrowRight: 'arrow-right',
  ArrowUp: 'arrow-up',
  Bin: 'bin',
  CalendarCheck: 'calendar-check',
  CalendarClose: 'calendar-close',
  Camera: 'camera',
  Caret: 'caret',
  Check: 'check',
  Clock: 'clock',
  Cloud: 'cloud',
  ContentFilterEmpty: 'content-filter-empty',
  ContentFilter: 'content-filter',
  ControlSplit: 'control-split',
  CursorDial: 'cursor-dial',
  Download: 'download',
  EmailEnvelope: 'email-envelope',
  FileAdd: 'file-add',
  Flash: 'flash',
  GenderFemaleMale: 'gender-female-male',
  GenderFemale: 'gender-female',
  GenderMale: 'gender-male',
  Hide: 'hide',
  Hourglass: 'hourglass',
  InterfaceInformation: 'interface-information',
  Key: 'key',
  Magnify: 'magnify',
  Medal: 'medal',
  Pen: 'pen',
  PhoneOutgoing: 'phone-outgoing',
  PowerButton: 'power-button',
  QuestionMark: 'question-mark',
  Rabbit: 'rabbit',
  Refresh: 'refresh',
  Sections: 'sections',
  Show: 'show',
  Snail: 'snail',
  SwitchLeft: 'switch-left',
  SwitchRight: 'switch-right',
  TimeEdit: 'time-edit',
  Upload: 'upload',
  User: 'user',
  Verified: 'verified',
  ViewLock: 'view-lock',
}

export default ({ icon, size, className, fill }) => {
  const IconComponent = getIconComponent(icon)
  if (IconComponent === null) { return null }

  if (fill === undefined) {
    fill = 'currentColor';
  }

  return <IconComponent fill={fill} width={size} height={size} className={className} />
}
