import React from 'react'
import styled, { css } from 'styled-components'
import { Button } from 'hoffelijk-react-components'

const PagerWrapper = styled.div`
  padding: 20px 29px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`


const PageList = styled.ul`
  width: 70%;  
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-right: 45px;
`

const PageListItem = styled.li`
  list-style: none;
  display: inline-block;
  font-weight: bold;
  
  width: 26px;
  height: 26px;
  
  text-align: center;
  line-height: 26px;
  
  margin-right: 2px;
  
  a {
    text-decoration: none;
  }
  
  ${props => props.active && css`
    background-color: ${props => props.theme.color.button};
    border: 1px solid ${props => props.theme.color.button};
    color: #FFF;
    border-radius: 100%;
  `}
`

const PageListItemLink = styled.a`
  outline: none;
  text-decoration: underline;
`

const ButtonWrapper = styled.div`
  width: 15%;
  min-width: 135px;
`

const Pager = ({ currentPage, numberOfPages, gotoPage }) => {
  const handlePrevNextClick = (event, newPage) => {
    event.preventDefault()

    gotoPage(newPage, numberOfPages);
  }

  if (numberOfPages === 1) {
    return (<></>)
  }

  return (<PagerWrapper>
    <ButtonWrapper>
      {currentPage > 0 &&
      <Button icon="arrow-left" onClick={(event) => handlePrevNextClick(event, currentPage - 1)}>Vorige</Button>}
    </ButtonWrapper>

    <PageList>
      {Array.from(Array(numberOfPages).keys()).map(pageIndex =>
        <PageListItem key={pageIndex} active={pageIndex === currentPage}>
          {pageIndex !== currentPage
            ? <PageListItemLink href="#" onClick={(event) => handlePrevNextClick(event, pageIndex)}>
                {pageIndex + 1}
              </PageListItemLink>
              : pageIndex + 1}
        </PageListItem>
      )}
    </PageList>

    <ButtonWrapper>
      {(currentPage + 1) < numberOfPages &&
      <Button onClick={(event) => handlePrevNextClick(event, currentPage + 1)}>Volgende</Button>}
    </ButtonWrapper>
  </PagerWrapper>)
}

export default Pager
