/**
 * @generated SignedSource<<6db808636e020daa7c6b6698f6aedddc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organisationId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "remindoOrganisationIds"
},
v2 = [
  {
    "kind": "Variable",
    "name": "organisationId",
    "variableName": "organisationId"
  },
  {
    "kind": "Variable",
    "name": "remindoOrganisationIds",
    "variableName": "remindoOrganisationIds"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Study",
  "kind": "LinkedField",
  "name": "study",
  "plural": false,
  "selections": [
    (v3/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ModuleManagementQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Module",
        "kind": "LinkedField",
        "name": "modules",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ModuleStudy",
            "kind": "LinkedField",
            "name": "module_study",
            "plural": false,
            "selections": [
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ModuleManagementQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Module",
        "kind": "LinkedField",
        "name": "modules",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ModuleStudy",
            "kind": "LinkedField",
            "name": "module_study",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "025ed604ed16a7dce3988c575a4abe93",
    "id": null,
    "metadata": {},
    "name": "ModuleManagementQuery",
    "operationKind": "query",
    "text": "query ModuleManagementQuery(\n  $remindoOrganisationIds: [ID!]\n  $organisationId: ID\n) {\n  modules(remindoOrganisationIds: $remindoOrganisationIds, organisationId: $organisationId) {\n    id\n    name\n    module_study {\n      study {\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

node.hash = "5594182f629260f20979312e86e5da2b";

module.exports = node;
