import React from 'react'
import graphql from 'babel-plugin-relay/macro'
import withQuery from 'containers/withQuery'
import { withMe } from 'containers/Me'
import { compose, withHandlers, withState } from 'recompose'
import withMutation from 'containers/withMutation'

import styled from 'styled-components'

import { Float, Paper, Table, Button, Styles, UserAvatar, WithHover } from 'hoffelijk-react-components'
import Actions from './Actions'

import { firstBy } from 'thenby'
import moment from 'moment'
import { parse } from "json2csv";
import { download } from 'utilities/functions'

const StyledAvatar = styled(UserAvatar)`
  margin: -4px;
`

const ActionLogButton = styled(Button)`
  margin-right: 15px;
`

const toCSV = data => {
  const csvData = data
    .map(log => ({
      id: log.id,
      tijdstip: moment(log.created_at).format('YYYY-MM-DD HH:mm:ss'),
      actie: log.action,
      details: log.message,
      'door gebruiker': log.user
        ? `${log.user.name} (${log.user.id}) <${log.user.email}>`
        : `verwijderd <${log.user_email}>`,
      'bij gebruiker': log.subject
        ? `${log.subject.name} (${log.subject.id}) <${log.subject.email}>`
        : log.subject_email
          ? `verwijderd <${log.subject_email}>`
          : '',
    }))

  return parse(csvData, {
    fields: Object.keys(csvData[0]),
    delimiter: ';',
  })
}

const getMutation = () => graphql`
  mutation AdminsOverviewActionLogsFetchMutation {
    fetchActionLogMessages {
      id
      created_at
      action
      message
      user {
        id
        name
        email
      }
      subject {
        id
        name
        email
      }
      user_email
      subject_email
    }
  }
`

export const AdminsOverview = ({ me, users, loading, error, retry, handleActionLogOnClick, actionLogLoading }) =>
  <Paper light expanding>
    <Paper.Section>
      <Float.Left>
        <Styles.NoMargin as="h2">Overzicht beheerders</Styles.NoMargin>
      </Float.Left>
      <Float.Right>
        <ActionLogButton
          icon="download"
          disabled={actionLogLoading}
          onClick={handleActionLogOnClick}
        >
          Download gebruikerslogs
        </ActionLogButton>
        <Button to="/user/add">Beheerder toevoegen</Button>
      </Float.Right>
    </Paper.Section>
    <Table
      defaultCompare={firstBy('name')}
      dense
      columns={[
        {
          label: '',
          content: user =>
            <StyledAvatar size={32} avatar={user.avatar} name={user.name} />,
          sortable: false,
        },
        {
          label: 'Naam',
          content: user => user.roles.includes('superadmin')
            ? <strong>{user.name}</strong> : user.name,
          comparableContent: user => user.name,
        },
        {
          label: 'E-mail',
          content: user => user.email
        },
        {
          label: 'Login',
          content: user =>
            <WithHover
               hover={`laatst: ${user.last_login_at ? moment(user.last_login_at).format('YYYY-MM-DD') : 'Nooit'})`}
            >
              {user.login_count} keer
            </WithHover>
        },
        {
          label: 'Status',
          content: user => user.active
            ? 'Actief' : 'Inactief'
        },
        {
          label: '',
          content: user => <Actions user={user} me={me} refetch={retry} />,
          sortable: false,
        }
      ]}
      data={users}
      loading={loading}
      error={error}
    />
  </Paper>

const getQuery = () => graphql`
  query AdminsOverviewQuery {
    users(role: "mainadmin") {
      id
      avatar
      name
      email
      login_count
      last_login_at
      roles
      active
      activation_sent_at
    }
  }
`

export default compose(
  withMe,
  withQuery({ getQuery }),
  withState('actionLogLoading', 'setActionLogLoading', false),
  withMutation({
    getMutation,
    onCompleted: ({ onCompleted, setActionLogLoading }) => res => {
      setActionLogLoading(false)
      download(`log_${moment().format('YYYY-MM-DD')}.csv`, toCSV(res.fetchActionLogMessages))
    },
  }),
  withHandlers({
    handleActionLogOnClick: ({ setActionLogLoading, commitMutation }) => () => {
      setActionLogLoading(true)
      commitMutation()
    }
  })
)(AdminsOverview)
