import React from 'react'

import { TableBody, TableRow, TableCell } from './Table'

export default ({ columns, component: Component }) =>
  <TableBody>
    <TableRow>
      <TableCell colSpan={columns}>
        {Component
          ? <Component />
          : 'Geen data weer te geven'}
      </TableCell>
    </TableRow>
  </TableBody>
