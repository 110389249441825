/**
 * @generated SignedSource<<9382d3a4cf5fd38cefea7abf3b28bcf0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organisationId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      },
      {
        "kind": "Variable",
        "name": "organisationId",
        "variableName": "organisationId"
      }
    ],
    "concreteType": "Organisation",
    "kind": "LinkedField",
    "name": "updateOrganisation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganisationManagementUpdateMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "OrganisationManagementUpdateMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "e8b75e02b94cc836efaa77ed867f68d6",
    "id": null,
    "metadata": {},
    "name": "OrganisationManagementUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation OrganisationManagementUpdateMutation(\n  $organisationId: ID!\n  $input: OrganisationInput!\n) {\n  updateOrganisation(organisationId: $organisationId, input: $input) {\n    id\n  }\n}\n"
  }
};
})();

node.hash = "518aadcc73806927148d81d7db6e8f58";

module.exports = node;
