/**
 * @generated SignedSource<<6a074da340d4704ea52347622a70f4c4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organisationModuleId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "recipeId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "teacherId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "organisationModuleId",
    "variableName": "organisationModuleId"
  },
  {
    "kind": "Variable",
    "name": "recipeId",
    "variableName": "recipeId"
  },
  {
    "kind": "Variable",
    "name": "teacherId",
    "variableName": "teacherId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "grade",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "score",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "answer",
  "storageKey": null
},
v8 = [
  (v4/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "OpenQuestion",
  "kind": "LinkedField",
  "name": "open_question",
  "plural": false,
  "selections": (v8/*: any*/),
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "RecipeSection",
  "kind": "LinkedField",
  "name": "section",
  "plural": false,
  "selections": (v8/*: any*/),
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "started_at",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "finished_at",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "Candidate",
  "kind": "LinkedField",
  "name": "candidate",
  "plural": false,
  "selections": (v8/*: any*/),
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "Recipe",
  "kind": "LinkedField",
  "name": "recipe",
  "plural": false,
  "selections": (v8/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "resultsRefreshMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Result",
        "kind": "LinkedField",
        "name": "refreshRecipe",
        "plural": true,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "OpenAnswer",
            "kind": "LinkedField",
            "name": "open_answers",
            "plural": true,
            "selections": [
              (v7/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SectionResult",
            "kind": "LinkedField",
            "name": "section_results",
            "plural": true,
            "selections": [
              (v10/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "resultsRefreshMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Result",
        "kind": "LinkedField",
        "name": "refreshRecipe",
        "plural": true,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "OpenAnswer",
            "kind": "LinkedField",
            "name": "open_answers",
            "plural": true,
            "selections": [
              (v7/*: any*/),
              (v9/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SectionResult",
            "kind": "LinkedField",
            "name": "section_results",
            "plural": true,
            "selections": [
              (v10/*: any*/),
              (v5/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d644e596dad9b7e96b99bc6460cd34e0",
    "id": null,
    "metadata": {},
    "name": "resultsRefreshMutation",
    "operationKind": "mutation",
    "text": "mutation resultsRefreshMutation(\n  $teacherId: ID!\n  $recipeId: ID!\n  $organisationModuleId: ID!\n) {\n  refreshRecipe(recipeId: $recipeId, teacherId: $teacherId, organisationModuleId: $organisationModuleId) {\n    id\n    grade\n    score\n    open_answers {\n      answer\n      open_question {\n        id\n      }\n      id\n    }\n    section_results {\n      section {\n        id\n      }\n      grade\n      id\n    }\n    started_at\n    finished_at\n    candidate {\n      id\n    }\n    recipe {\n      id\n    }\n  }\n}\n"
  }
};
})();

node.hash = "9fe9ef0a2bc9ddd1834582024bcf2b88";

module.exports = node;
