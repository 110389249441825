import React from 'react'
import styled from 'styled-components'
import { withOrganisation } from 'containers/Organisation'

import { Float, Paper, Table, Button, Styles, UserAvatar, WithHover, EmptyState } from 'hoffelijk-react-components'
import Actions from './Actions'
import AccessDetails from './AccessDetails'

import moment from 'moment'

import empty from 'hoffelijk-react-components/assets/module_empty_state.png'

const StyledAvatar = styled(UserAvatar)`
  margin: -4px;
`

export const TeachersOverview = ({ t, organisation, teachers, organisationModules, loading, error, retry }) =>
  <Paper light>
    <Paper.Section>
      <Float.Left>
        <Styles.NoMargin as="h2">Overzicht {t('teacher_plural')}</Styles.NoMargin>
      </Float.Left>
      <Float.Right>
        <Button to={`/${organisation.slug}/teacher/add`}>{t('teacher', ['capitalize'])} toevoegen</Button>
      </Float.Right>
    </Paper.Section>
    <Table
      dense
      columns={[
        {
          label: '',
          content: user =>
            <StyledAvatar size={32} avatar={user.avatar} name={user.name} />,
          sortable: false,
        },
        {
          label: 'Naam',
          content: teacher => <strong>{teacher.name}</strong>,
          comparableContent: teacher => teacher.name,
        },
        {
          label: 'E-mail',
          content: teacher => teacher.email
        },
        {
          label: 'Logins',
          content: teacher =>
            <WithHover
               hover={`laatst: ${teacher.last_login_at ? moment(teacher.last_login_at).format('YYYY-MM-DD') : 'Nooit'})`}
            >
              {teacher.login_count} keer
            </WithHover>
        },
        {
          label: 'Rechten',
          content: teacher =>
            <AccessDetails
              access={teacher.access}
              organisation={organisation}
              organisationModules={organisationModules}
            />,
          comparableContent: teacher => teacher.access.length,
        },
        {
          label: 'Status',
          content: teacher => teacher.active
            ? 'Actief' : 'Inactief'
        },
        {
          label: '',
          content: teacher => <Actions teacher={teacher} refetch={retry} />,
          sortable: false,
        }
      ]}
      data={teachers}
      loading={loading}
      error={error}
      fallback={() => <EmptyState
        image={empty}
        title={`Geen ${t('teacher_plural')} geregistreerd`}
        description={`Voeg ${t('teacher_plural')} toe om ze hier te zien`}
      />}
    />
  </Paper>

export default withOrganisation(TeachersOverview)
