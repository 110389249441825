import React from 'react'

export default ({ width, height, fill, className }) =>
	<svg height={height} width={width} className={className} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<g className="nc-icon-wrapper" fill={fill}>
			<path id="Frame_-_24px" d="M0 0h24v24H0z" fill="none"/>
			<g id="Line_Icons">
				<path d="M22.827 11.456c-.096-.148-1.522-2.282-4.101-3.979l3.97-3.971-1.414-1.414L1.811 21.564l1.414 1.414 5.146-5.146c1.093.341 2.298.554 3.618.554 6.804 0 10.667-5.585 10.828-5.824l.373-.548-.363-.558zm-10.839 4.93a9.873 9.873 0 0 1-1.968-.204l1.907-1.907c.252.069.515.111.79.111a3 3 0 0 0 3-3c0-.274-.042-.538-.111-.79l1.661-1.661a13.848 13.848 0 0 1 3.464 3.052c-1.087 1.29-4.182 4.399-8.743 4.399z" fill={fill}/>
				<path d="M4.604 15.942l1.453-1.453a13.823 13.823 0 0 1-2.812-2.503c1.07-1.336 4.154-4.6 8.744-4.6.378 0 .742.029 1.099.072l1.716-1.716a11.506 11.506 0 0 0-2.815-.356c-6.816 0-10.678 5.822-10.838 6.07l-.362.558.373.548c.086.129 1.278 1.845 3.442 3.38z" fill={fill}/>
			</g>
		</g>
	</svg>
