import React from 'react'
import graphql from 'babel-plugin-relay/macro'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import withQuery from 'containers/withQuery'
import { withNotifications } from 'containers/Notifications'

import { HeaderMod } from 'containers/Header'
import ModuleStudyManagement from 'components/admin/ModuleStudyManagement'

import Loader from 'components/Loader'

const UpdateModuleStudy = ({ history, notify, moduleStudy, ...rest }) =>
  <HeaderMod
    backLink="/#modules"
    title="Module bewerken"
  >
    <ModuleStudyManagement
      data={{
        moduleStudyId: moduleStudy.id
      }}
      defaultValues={{
        icon_url: moduleStudy.icon,
        name: moduleStudy.name,
        module_id: moduleStudy.module ? moduleStudy.module.id : '',
        study_id: moduleStudy.study.id,
        has_guarantee: moduleStudy.guarantee !== null,
        visible_recipes: moduleStudy.visible_recipes
          ? moduleStudy.visible_recipes.map(r => r.id)
          : null,
        guarantee: moduleStudy.guarantee
          ? {
              allowed_fails: moduleStudy.guarantee.allowed_fails,
              duration: moduleStudy.guarantee.duration,
              first_recipe_id: moduleStudy.guarantee.first_recipe.id,
              last_recipe_id: moduleStudy.guarantee.last_recipe.id,
              fails_apply_to: moduleStudy.guarantee.fails_apply_to
                  ? moduleStudy.guarantee.fails_apply_to.map(r => r.id)
                  : null,
              counting_recipes: moduleStudy.guarantee.counting_recipes
                ? moduleStudy.guarantee.counting_recipes
                : {}
            }
        : null,
        planning_recipes: moduleStudy.module_study_recipes_planning
          ? moduleStudy.module_study_recipes_planning
            .map(({ recipe, ...rest }) => ({ recipe_id: recipe.id, ...rest }))
          : null,
        connecting_field_planning_wft_module: moduleStudy.connecting_field_planning_wft_module,
        services: moduleStudy.services,
      }}
      onSubmit={() => {
        history.push('/#modules')
        notify({
          type: 'success',
          title: 'Module succesvol bijgewerkt',
        })
      }}
    />
  </HeaderMod>

const getQuery = () => graphql`
  query editModuleStudyQuery($moduleStudyId: ID!) {
    moduleStudy(id: $moduleStudyId) {
      id
      name
      icon
      module {
        id
      }
      study {
        id
      }

      visible_recipes {
        id
      }

      services {
          id
      }

      module_study_recipes_planning {
          name
          planning

          recipe {
              id
          }
      }

      connecting_field_planning_wft_module

      guarantee {
        duration
        allowed_fails

        first_recipe {
          id
        }
        last_recipe {
          id
        }

        fails_apply_to {
          id
        }
        counting_recipes {
          id
          allowed_tries
        }
      }
    }
  }
`

const getVariables = ({ match }) => ({
  moduleStudyId: match.params.moduleStudyId
})

export default compose(
  withRouter,
  withNotifications,
  withQuery({ getQuery, getVariables, loader: Loader }),
)(UpdateModuleStudy)
