import React from 'react'

import { TableRow, TableCell } from './Table'

const StandardRow = ({ columns, row, dense }) =>
  <TableRow>
    {columns.map((column, i) =>
      <TableCell dense={dense} key={i}>{column.content(row)}</TableCell>
    )}
  </TableRow>

export default StandardRow
