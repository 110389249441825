import React from 'react'
import styled from 'styled-components'

import { SimpleTable } from 'hoffelijk-react-components'
import { firstBy } from 'thenby'
import { formatParticipationResult, formatSubjectLabel, getSubjectWeightByLabel } from "../../../utilities/examHelpers";

const ExamSubjectsWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  margin-right: 140px;
`;

const ExamSubject = styled.div`
  width: 100%;
  max-width: 140px;
`;

const ExamSubjectTitle = styled.div``;

const ExamSubjectGradeBarBase = styled.div`
  border-radius: 4px;
  height: 8px;
`;

const ExamSubjectGradeBarBackground = styled(ExamSubjectGradeBarBase)`
  background-color: ${props => props.theme.color.gray40};

  margin: 5px 0;
`;

const ExamSubjectGradeBarForeground = styled(ExamSubjectGradeBarBase)`
  width: ${props => props.size};
  background-color: ${props => props.theme.color[props.color]};
`;

const ExamSubjects = ({ results }) => {
  const sortedResults = results.map(result => {
    return {
      ...result,
    }
  }).sort((a, b) => {
    return (getSubjectWeightByLabel(a.subject) < getSubjectWeightByLabel(b.subject))
      ? -1 : 1;
  })

  return (
    <ExamSubjectsWrapper>
      {sortedResults.map(
        (result, key) => {
          let color = 'error';

          if (result.score >= 68) {
            color = 'successLight';
          }

          return (
            <ExamSubject key={key}>
              <ExamSubjectTitle>{formatSubjectLabel(result.subject)}</ExamSubjectTitle>
              <ExamSubjectGradeBarBackground>
                <ExamSubjectGradeBarForeground color={color} size={result.score + '%'} title={result.score + '%'}/>
              </ExamSubjectGradeBarBackground>
            </ExamSubject>
          )
        }
      )}
    </ExamSubjectsWrapper>
    )
}

const ExamResults = ({ participations }) =>
  <SimpleTable
    defaultCompare={
      firstBy((a, b) => (a.exam_date && b.exam_date)
        ? a.exam_date.isAfter(b.exam_date) ? -1 : 1
        : -1,
        'desc')
        .thenBy('grade')
    }
    columns={[
      {
        label: 'Examendatum',
        content: participation => participation.exam_date ? participation.exam_date.format('DD-MM-YYYY HH:mm') : '-',
        comparableContent: participation => participation.exam_date,
      },
      {
        label: 'Resultaat',
        content: participation => formatParticipationResult(participation) || '-',
        comparableContent:  participation => participation.exam_result ,
      },
      {
        label: 'Competenties',
        content: participation => participation.exam_result
          ? <ExamSubjects results={participation.exam_result.exam_subject_results} />
          : '-'
      },
    ]}
  data={participations}
  />

export default ExamResults
