import React from 'react'
import styled, { css } from 'styled-components'

import PaperSectionTitle from './SectionTitle'

const PaperSection = styled.div`
  padding: 29px;

  ${props => props.noverticalpadding && css`
    padding-top: 0;
    padding-bottom: 0;
  `}

  ${props => props.accent && css`
    background-color: ${props.theme.color.mainnav};
    color: ${props.theme.color.white};

    && {
        h1, h2, h3, h4, h5, h6 {
        color: ${props.theme.color.white};
      }
    }
  `}

  &::after {
    content: '';
    display: block;
    clear: both;
  }

  & + & {
    border-top: 1px solid rgba(0, 0, 0, .1);
  }
`

export default ({ title, children, ...rest }) =>
  <PaperSection {...rest}>
    {title
      ? <PaperSectionTitle>{title}</PaperSectionTitle>
      : null}
    {children}
  </PaperSection>
