/**
 * @generated SignedSource<<a7d90f8772035f126df8ac18013087a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "role",
        "value": "mainadmin"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "users",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "avatar",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "login_count",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "last_login_at",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "roles",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "active",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "activation_sent_at",
        "storageKey": null
      }
    ],
    "storageKey": "users(role:\"mainadmin\")"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminsOverviewQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AdminsOverviewQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "4c4e2f207cc19428999c9d9a6a19e5d8",
    "id": null,
    "metadata": {},
    "name": "AdminsOverviewQuery",
    "operationKind": "query",
    "text": "query AdminsOverviewQuery {\n  users(role: \"mainadmin\") {\n    id\n    avatar\n    name\n    email\n    login_count\n    last_login_at\n    roles\n    active\n    activation_sent_at\n  }\n}\n"
  }
};
})();

node.hash = "d296710b610fb97c3ed40302b2c273b5";

module.exports = node;
