import React from 'react'
import { compose, withState, withHandlers, shouldUpdate, lifecycle } from 'recompose'

import {
  Label, InputWrapper, InputContainer, Input,
  InputError,
  LabelText, Info,
  BeforeIconContainer, BeforeIcon,
  AfterIcon, AfterIconContainer } from './Input'

import AvatarInput from './Avatar'
import ImageInput from './Image'
import InputGroup from './Group'
import Select from './Select'
import { TextArea } from './TextArea'

const isValue = value =>
  value === 0 || !!value

export const InputComponent = ({
                  dense, block, type, required,
                  label, info, error, beforeIcon,
                  toggleVisibility, onAnimationStart,
                  focussed, autofilled, visible,
                  value, checked, onChange, options, ...rest }) =>
  <InputWrapper block={block}>
    <Label
      required={required}
      error={error}
      focussed={focussed}
      type={type}
    >
      {(beforeIcon || type === `color`) &&
        <BeforeIconContainer dense={dense}>
          {type === `color`
            ? <Input
                type="color"
                onChange={event => onChange && onChange(event.target.value)}
                value={value && value.match(/#([a-f]|[0-9]){6}/g)
                  ? value : ''}
              />
            : <BeforeIcon size={20} icon={beforeIcon} />}
        </BeforeIconContainer>}

      <InputContainer hasAfterIcon={[`password`, `search`].includes(type)}>
        {type === 'select'
          ? <Select
              onAnimationStart={onAnimationStart}
              required={required}
              value={value}
              onChange={onChange}
              block={block}
              dense={dense}
              options={options}
              {...rest} />
          : type === 'textarea' ? <TextArea
                onAnimationStart={onAnimationStart}
                required={required}
                value={value === null || value === undefined ? '' : value}
                onChange={event => {
                    onChange(event.target.value === '' ? null : event.target.value)
                }}
                block={block}
                dense={dense}
                {...rest}
            /> : <Input
              onAnimationStart={onAnimationStart}
              required={required}
              value={value === null || value === undefined ? '' : value}
              checked={checked === undefined ? false : checked}
              onChange={event => {
                if (onChange) {
                  switch (type) {
                    case 'checkbox': return onChange(event.target.checked)
                    case 'number':
                      return onChange(isNaN(parseInt(event.target.value, 10))
                        ? null
                        : parseInt(event.target.value, 10))
                    case 'name':
                      return onChange(event.target.value === ''
                        ? null
                        : event.target.value
                            .replace(/^\w/, c => c.toUpperCase()))
                    default: return onChange(event.target.value === ''
                      ? null : event.target.value)
                  }
                }
              }}
              block={block}
              dense={dense}
              type={((type === `password` && visible) || type === `color`)
                ? `text`
                : type}
              {...rest}
            />}
        <LabelText up={isValue(value) || focussed || autofilled}>
          {label}
          {required && !isValue(value) && '*'}
          {info && <Info> ({info})</Info>}
        </LabelText>
      </InputContainer>
      {type === `password` &&
        <AfterIconContainer onClick={toggleVisibility} dense={dense}>
          <AfterIcon size={20} icon={visible ? 'hide' : 'show'} />
        </AfterIconContainer>}
      {type === `search` &&
        <AfterIconContainer dense={dense}>
          <AfterIcon size={20} icon="magnify" />
        </AfterIconContainer>}
    </Label>
    {error && error.map((err, i) =>
      <InputError key={i}>{err}</InputError>
    )}
  </InputWrapper>

const enhance = compose(
  shouldUpdate((props, nextProps) =>
    nextProps.visible !== props.visible ||
    nextProps.autofilled !== props.autofilled ||
    nextProps.focussed !== props.focussed ||
    nextProps.error !== props.error ||
    nextProps.value !== props.value ||
    nextProps.checked !== props.checked ||
    nextProps.options !== props.options
  ),
  withState('autofilled', 'setAutofilled', false),
  withState('visible', 'setVisible', false),
  withState('focussed', 'setFocussed', false),
  withHandlers({
    onAnimationStart: ({ setAutofilled }) => () => setAutofilled(true),
    onFocus: ({ setFocussed }) => () => setFocussed(true),
    onBlur: ({ setFocussed }) => () => setFocussed(false),
    onChange: ({ setAutofilled, onChange }) => value => {
      onChange && onChange(value)
      setAutofilled(false)
    },
    toggleVisibility: ({ visible, setVisible }) => () => setVisible(!visible)
  }),
  lifecycle({
    componentDidMount() {
      const { type, checked, onChange } = this.props

      if (type === 'checkbox' && checked === undefined) {
        onChange(false)
      }
    }
  }),
)

const EnhancedInput = enhance(InputComponent)
EnhancedInput.Avatar = AvatarInput
EnhancedInput.Image = ImageInput
EnhancedInput.Group = InputGroup

export default EnhancedInput
