import React from 'react'

import Table from './'

export default ({ columns, error }) =>
  <Table.Body>
    <Table.Row>
      <Table.Cell colSpan={columns.length}>
        {error}
      </Table.Cell>
    </Table.Row>
  </Table.Body>
