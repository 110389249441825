import moment from 'moment'

export default ({ target_date }) => ({
  target_date,
  allowed_tries: 2,
  name: "e-Leerlijn Wft Hypothecair Krediet",
  recipes: [
    {
      is_start: true,
      planning: 90,
      target_date: moment(target_date).subtract(90, 'day'),
      attempts: [
        { date: "2018-05-12" }
      ]
    },
    {
      is_start: false,
      planning: 60,
      target_date: moment(target_date).subtract(60, 'day'),
      name: "Hoofdstuk 1",
      attempts: [
        { date: "2018-06-14", grade: "5,8", duration: 2806000 }
      ]
    },
    {
      is_start: false,
      planning: 40,
      target_date: moment(target_date).subtract(40, 'day'),
      name: "Hoofdstuk 2",
      attempts: []
    },
    {
      is_start: false,
      planning: 10,
      target_date: moment(target_date).subtract(10, 'day'),
      name: "Hoofdstuk 3",
      attempts: []
    },
    {
      is_start: false,
      planning: 1,
      target_date: moment(target_date).subtract(1, 'day'),
      name: "Eindexamen",
      attempts: []
    }
  ]
})
