import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import styled, { css } from 'styled-components'
import { compose, withState, withHandlers } from 'recompose'

import Align from '../Align'
import Button from '../Button'

import PaperSection from './Section'
import PaperSectionTitle from './SectionTitle'

export const Paper = styled.div`
  position: relative;

  border-radius: ${props => props.theme.borderRadius}px;

  box-shadow: 0 1px 3px rgba(0, 0, 0, .1);

  &::before {
    pointer-events: none;
    content: '';

    position: absolute;
    left: 0; top: 0;
    right: 0; bottom: 0;

    border-radius: ${props => props.theme.borderRadius}px;
    box-shadow: inset 0 0 0 1px rgb(0, 0, 0, .1);
  }

  ${props => props.light && css`
    background-color: ${props.theme.color.white};
    color: ${props.theme.color.text};

    a:hover {
      color: ${props.theme.color.text_link};
    }

    h1, h2, h3, h4, h5, h6 {
      color: ${props.theme.color.title};

      & a:hover {
        color: ${props.theme.color.title_link};
      }
    }
  `}

  ${props => props.dark && css`
    background-color: ${props.theme.color.text};
    color: ${props.theme.color.white};
  `}

  ${props => props.marginBottom && css`
    margin-bottom: 30px;
  `}

  ${props => props.expanding && css`
    position: relative;
    overflow: hidden;
    padding-bottom: 20px;

    ${!props.expanded && css`
      max-height: 430px;

      &::after {
        content: '';

        pointer-events: none;

        position: absolute;
        left: 0;
        right: 0; bottom: 0;
        z-index: 1;

        height: 6em;

        background: linear-gradient(transparent, ${props.theme.color.white});
      }
    `}
  `}
`

const ExpandingPaper = ({ node, children, theme, light, toggleExpansion, expanding, expanded }) => {
  return (<div>
    <Paper
      expanding={expanding}
      expanded={expanded}
      marginBottom
      light={light}
      node={node}
      theme={theme}>
      {children}
    </Paper>
    {expanding
      ? <Align.Center>
          <Button
            under isHigh
            onClick={toggleExpansion}
            icon={expanded ? 'arrow-up' : 'arrow-down'}
          >
            {expanded ? 'Minder weergeven' : 'Alles weergeven'}
          </Button>
        </Align.Center>
      : null}
  </div>)}

const EnhancedExpandingPaper = compose(
  withState('expanded', 'setExpanded', false),
  withHandlers({
    toggleExpansion: ({ expanded, setExpanded }) => () =>
      setExpanded(!expanded),
  })
)(ExpandingPaper)

class PaperComponent extends PureComponent {
  state = {
    scrollHeight: 0,
    node: null
  }

  constructor(props) {
    super(props)
    this.paper = React.createRef()
  }

  componentDidMount() {
    const node = ReactDOM.findDOMNode(this.paper.current)

    this.setState({
      node,
      scrollHeight: node ? node.scrollHeight : 0
    })
  }

  componentDidUpdate() {
    const node = ReactDOM.findDOMNode(this.paper.current)

    if (node && node.scrollHeight !== this.state.scrollHeight) {
      this.setState({
        node,
        scrollHeight: node.scrollHeight
      })
    }
  }

  render() {
    const { expanding, ...rest } = this.props
    return expanding
      ? <EnhancedExpandingPaper
          expanding={this.state.scrollHeight > 430}
          ref={this.paper}
          {...rest}
        />
      : <Paper {...rest} />
  }
}

PaperComponent.Paper = Paper
PaperComponent.Section = PaperSection
PaperComponent.SectionTitle = PaperSectionTitle

export default PaperComponent
