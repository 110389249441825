import React from 'react'

import { withAuthentication } from 'containers/Authentication'

import AuthenticatedRouter from './Authenticated'
import UnauthenticatedRouter from './Unauthenticated'

const MainRouter = ({ authenticated }) => authenticated
  ? <AuthenticatedRouter />
  : <UnauthenticatedRouter />

export default withAuthentication(MainRouter)
