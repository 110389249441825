import React from 'react'

export default ({ width, height, fill, className }) =>
	<svg height={height} width={width} className={className} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<g fill={fill}>
			<path id="Frame_-_24px" d="M0 .001h24v24H0z" fill="none"/>
			<g id="Line_Icons">
				<path d="M16.24 10.444l2.709-8.127a.996.996 0 0 0-.139-.901.996.996 0 0 0-.81-.415H6a1 1 0 0 0-.95 1.316l2.709 8.127A6.987 6.987 0 0 0 5 16.001c0 3.86 3.141 7 7 7s7-3.14 7-7a6.985 6.985 0 0 0-2.76-5.557zM7.387 3.001h2.779l.834 5h2l.834-5h2.779l-2.152 6.455c-.768-.289-1.594-.455-2.461-.455s-1.693.166-2.461.455L7.387 3.001zm4.613 18c-2.757 0-5-2.242-5-5 0-2.757 2.243-5 5-5s5 2.243 5 5c0 2.758-2.243 5-5 5z" fill={fill}/>
				<path d="M13 14.501l-1-3-1 3H8l2 2-1 3 3-2 3 2-1-3 2-2z" fill={fill}/>
			</g>
		</g>
	</svg>
