import Notification from './Notification'
import NotificationContainer from './Container'

Notification.Container = NotificationContainer

export {
  Notification,
  NotificationContainer
}

export default Notification
