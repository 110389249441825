import React from 'react'

export default ({ width, height, fill, className }) =>
	<svg height={height} width={width} className={className} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<g className="nc-icon-wrapper" fill={fill}>
			<path id="Frame_-_24px" d="M0 0h24v24H0z" fill="none"/>
			<g id="Line_Icons">
				<path d="M8 4v5.535l3.446 2.297 1.109-1.664L10 8.465V4z" fill={fill}/>
				<path d="M2 9c0-3.86 3.14-7 7-7 3.859 0 7 3.14 7 7 0 .341-.033.673-.08 1h2.021c.037-.329.059-.662.059-1 0-4.962-4.037-9-9-9S0 4.038 0 9s4.038 9 9 9v-2c-3.859 0-7-3.141-7-7z" fill={fill}/>
				<path d="M23.707 14.293l-3-3a.999.999 0 0 0-1.414 0l-7 7a.997.997 0 0 0-.263.465l-1 4a1 1 0 0 0 1.213 1.213l4-1a1 1 0 0 0 .464-.264l7-7a.999.999 0 0 0 0-1.414zm-8.218 6.805l-2.114.528.528-2.114L17 16.414 18.586 18l-3.097 3.098zM20 16.586L18.414 15 20 13.414 21.586 15 20 16.586z" fill={fill}/>
			</g>
		</g>
	</svg>
