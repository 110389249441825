import React from 'react'

import Table from './'
import EmptyState from '../EmptyState'

import empty from 'hoffelijk-react-components/assets/module_empty_state.png'

export default ({ columns, component: Component }) =>
  <Table.Body>
    <Table.Row>
      <Table.Cell colSpan={columns.length}>
        {Component
          ? <Component />
          : <EmptyState
              image={empty}
              title="Geen data weer te geven"
              description=""
            />
        }
      </Table.Cell>
    </Table.Row>
  </Table.Body>
