import { mean, filterOutliers } from './statistics'
import { CATEGORIES, GRADES, PERCENTAGES } from './constants'

export const getBestAttempt = (attempts, allowed_tries=100) => {
  return attempts && attempts.length > 0
    ? attempts
      .filter(attempt => attempt.try < allowed_tries)
      .reduce(
        (best, attempt) => parseGrade(attempt.grade) > parseGrade(best.grade) ? attempt : best,
        attempts[0]
      )
    : null;
}

export const getAllowedTries = (guarantee, recipeId) => {
  if (guarantee) {
    const countingRecipe = guarantee.counting_recipes.find(countingRecipe => recipeId === countingRecipe.id)

    if (countingRecipe) {
      return countingRecipe.allowed_tries
    }
  }

  return 100
}

export const getFormat = grade => typeof grade === 'string'
  ? grade.includes('%')
    ? 'percentage'
    : grade.includes(' pt.')
      ? 'point'
      : grade.includes(',')
        ? 'comma'
        : null
  : null

export const fromFormat = format => grade => {
  if (grade === null) { return null }
  switch (format) {
    case 'percentage': return parseInt(grade.substr(0, grade.indexOf('%')), 10) / 10
    case 'point': return parseFloat(grade.replace(',', '.').replace(' pt.', ''))
    case 'comma': return parseFloat(grade.replace(',', '.'))
    default: return parseFloat(grade)
  }
}

export const toFormat = format => grade => {
  switch (format) {
    case 'percentage': return `${Math.round(grade * 10)}%`
    case 'point': return `${grade.toFixed(1).replace('.', ',')} pt.`
    case 'comma': return grade.toFixed(1).replace('.', ',')
    default: return grade.toFixed(1).replace('.', ',')
  }
}

export const getMeanGrade = (students, allowed_tries, doFormat=false) => {
  const allGrades = (students || [])
    .map(student => student.attempts)
    .map(attempts => getBestAttempt(attempts, allowed_tries))
    .filter(attempt => attempt !== null && attempt.grade !== null)
    .map(attempt => attempt.grade)

  const nonPercentageGrades = allGrades
    .filter(grade => getFormat(grade) !== 'percentage')

  const grades = nonPercentageGrades.length > 0
    ? nonPercentageGrades
    : allGrades

  if (!grades.length) { return null }

  const format = getFormat(grades[0])
  const grade = grades
    .map(fromFormat(format))
    .reduce((acc, x) => acc + x, 0) / grades.length

  return isNaN(grade)
    ? null
    : doFormat
      ? toFormat(format)(grade)
      : grade
}

export const getMaxGrade = (max_score, students) => {
  const student = students
    .find(student =>
      student.attempts.length &&
      student.attempts
        .find(attempt => attempt.grade !== null))

  if (!student) { return null }

  const attempt = student.attempts
      .find(attempt => attempt.grade !== null)

  const format = getFormat(attempt.grade)
  switch (format) {
    case 'percentage': return '100%'
    case 'point': return `${max_score} pt.`
    default: return '10,0'
  }
}

export const getDurations = (students, allowed_tries) => students
  .map(student => student.attempts)
  .map(attempts => getBestAttempt(attempts, allowed_tries))
  .filter(attempt => attempt !== null && attempt.duration !== null)
  .map(attempt => attempt.duration)

export const getMeanDuration = (students, allowed_tries) =>
  mean(getDurations(students))

export const getMeanDurationNormalized = (students, allowed_tries) =>
  mean(filterOutliers(getDurations(students)))

export const parseGrade = grade =>
  fromFormat(getFormat(grade))(grade)

export const getGrade = attempt => attempt === null
  ? null
  : parseGrade(attempt.grade)

export const getCategory = (grade, max_score) => {
  if (grade === null) { return CATEGORIES.NOT_PARTICIPATED }
  const format = getFormat(grade)
  const score = fromFormat(format)(grade)

  const category = score === null
    ? CATEGORIES.NOT_PARTICIPATED
    : format === 'point'
      ? score / max_score < PERCENTAGES.PASS
        ? CATEGORIES.FAILED
        : score / max_score < PERCENTAGES.EXCELLENT
          ? CATEGORIES.PASSED
          : CATEGORIES.EXCELLENT
      : score < GRADES.PASS
        ? CATEGORIES.FAILED
        : score < GRADES.EXCELLENT
          ? CATEGORIES.PASSED
          : CATEGORIES.EXCELLENT

  return category
}

export const getCategories = (allowed_tries, students) => students
  .map(student => student.attempts)
  .map(attempts => getBestAttempt(attempts, allowed_tries))
  .map(attempt => getCategory(attempt && attempt.grade, attempt && attempt.max_score))
  .reduce((categories, category) => ({
    ...categories,
    [category]: categories[category] + 1
  }), {
    [CATEGORIES.NOT_PARTICIPATED]: 0,
    [CATEGORIES.FAILED]: 0,
    [CATEGORIES.PASSED]: 0,
    [CATEGORIES.EXCELLENT]: 0,
  })
