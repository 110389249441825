import React from 'react'
import styled, { css } from 'styled-components'
import { noop } from 'utilities/functions'
import { onlyUpdateForKeys } from 'recompose'

import Icon from '../Icon'

export const SortLabel = styled.span`
  ${props => props.sortable && css`cursor: pointer;`}
`

const ActiveIcon = styled(Icon)`
  margin: 3px 0 0 3px;
  transform: scale(1, ${props => props.sorting});
`

export default onlyUpdateForKeys([
  'sorting'
])(({ children, sortable=true, onClick, sorting }) =>
  <SortLabel sortable={sortable} onClick={sortable ? onClick : noop}>
    {children}
    {sorting !== 0 && <ActiveIcon size={14} icon="caret" sorting={sorting} />}
  </SortLabel>)
