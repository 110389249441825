import React from 'react'
import styled from 'styled-components'
import { withHandlers } from 'recompose'
import { Paper, Input } from 'hoffelijk-react-components'

const fields = [
  { label: 'Contactpersoon', name: 'invoice_contact' },
  { label: 'Interne code', name: 'code' },
  { label: 'Factuurkenmerk', name: 'code_invoice' },
  { label: 'Debiteurenkenmerk', name: 'code_debtor' },
  { label: 'Contractnummer', name: 'code_contract' },
  { label: 'Projectkenmerk', name: 'code_project' },
  { label: 'Groep', name: 'groupname' },
  { label: 'Organisatie', name: 'org_name' },
]

const InputGroup = styled(Input.Group)`
  & > * { vertical-align: middle; }

  label[type="checkbox"] {
    width: calc(100% - 512px);
    min-width: 256px;
  }
`

const Wrapper = styled.div`
  display: inline-block;
  width: 100%;
  max-width: 512px;

  & > * { width: calc(50% - 20px); }
`

const VocabularyManagement = ({ values={}, errors={}, setValue }) =>
  <Paper.Section id="vocabulary">
    <h4>4. Woordgebruik</h4>
    <Input.Group>
      <Input
        label="Docent enkelvoud"
        value={values[`teacher_singular`]}
        errors={errors[`teacher_singular`]}
        onChange={v => setValue(`teacher_singular`, v)}
      />
      <Input
        label="Docent meervoud"
        value={values[`teacher_plural`]}
        errors={errors[`teacher_plural`]}
        onChange={v => setValue(`teacher_plural`, v)}
      />
    </Input.Group>
    <Input.Group>
      <Input
        label="Student enkelvoud"
        value={values[`student_singular`]}
        errors={errors[`student_singular`]}
        onChange={v => setValue(`student_singular`, v)}
      />
      <Input
        label="Student meervoud"
        value={values[`student_plural`]}
        errors={errors[`student_plural`]}
        onChange={v => setValue(`student_plural`, v)}
      />
    </Input.Group>
    <h4>4.1 Weergave aangepaste velden</h4>
    <p>Alle aangevinkte velden zichtbaar als filter</p>
    {fields.map(({ label, name }) =>
      <InputGroup key={name}>
        <Input
          type="checkbox"
          label={`${label} weergeven`}
          checked={values[`show_${name}`]}
          errors={errors[`show_${name}`]}
          onChange={v => setValue(`show_${name}`, v)}
        />
        <Wrapper>
          <Input
            label={`${label} enkelvoud`}
            value={values[`${name}_singular`]}
            errors={errors[`${name}_singular`]}
            onChange={v => setValue(`${name}_singular`, v)}
          />
          <Input
            label={`${label} meervoud`}
            value={values[`${name}_plural`]}
            errors={errors[`${name}_plural`]}
            onChange={v => setValue(`${name}_plural`, v)}
          />
        </Wrapper>
      </InputGroup>
    )}
  </Paper.Section>

export default withHandlers({
  setValue: ({ updateValue }) => (key, value) =>
    updateValue(values => ({
      ...values,
      [key]: value
    }))
})(VocabularyManagement)
