import React from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { withNotifications } from 'containers/Notifications'

import { HeaderMod } from 'containers/Header'
import OrganisationManagement from 'components/admin/OrganisationManagement'

const CreateOrganisation = ({ notify, history }) =>
  <HeaderMod
    backLink="/#organisations"
    title="Nieuwe organisatie"
    hashLinks={[
      { to: '#general',    label: '1. Algemeen' },
      { to: '#remindo',    label: '2. RemindoContent' },
      { to: '#theme',      label: '3. Thema' },
      { to: '#vocabulary', label: '4. Woordgebruik' },
    ]}
  >
    <OrganisationManagement
      onSubmit={() => {
        history.push('/#organisations')
        notify({
          type: 'success',
          title: 'Organisatie succesvol aangemaakt',
        })
      }}
    />
  </HeaderMod>

export default compose(
  withRouter,
  withNotifications,
)(CreateOrganisation)
