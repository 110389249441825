import React from 'react'
import graphql from 'babel-plugin-relay/macro'

import { compose } from 'recompose'
import { withOrganisation } from 'containers/Organisation'
import { withNotifications } from 'containers/Notifications'
import { withMask } from 'containers/Mask'
import { withRouter } from 'react-router-dom'

import { Table } from 'hoffelijk-react-components'
import TableAction from 'components/TableAction'

import moment from 'moment'

const DeactivateTeacher = ({ teacher, refetch }) =>
  <TableAction
    title="Deactiveer" icon="switch-right"
    variables={{ userId: teacher.id }}
    onCompleted={refetch}
    mutation={graphql`
      mutation ActionsDeactivateTeacherMutation($userId: ID!) {
        deactivateUser(userId: $userId) {
          id
        }
      }
    `}
  />

const ActivateTeacher = ({ teacher, refetch }) =>
  <TableAction
    title="Activeer" icon="switch-left"
    variables={{ userId: teacher.id }}
    onCompleted={refetch}
    mutation={graphql`
      mutation ActionsActivateTeacherMutation($userId: ID!) {
        activateUser(userId: $userId) {
          id
        }
      }
    `}
  />

const DeleteTeacher = ({ teacher, refetch }) =>
  <TableAction
    confirm={`Weet je zeker dat je ${teacher.name} wilt verwijderen?`}
    title="Verwijder" icon="bin"
    variables={{ userId: teacher.id }}
    onCompleted={refetch}
    mutation={graphql`
      mutation ActionsDeleteTeacherMutation($userId: ID!) {
        deleteUser(userId: $userId) {
          success
        }
      }
    `}
  />

const getActivationSentAt = teacher => teacher.activation_sent_at
  ? moment(teacher.activation_sent_at).format('YYYY-MM-DD')
  : 'Nooit'

const RefreshTeacher = withNotifications(({ teacher, refetch, notify }) =>
  <TableAction
    title={`Verzend activatie e-mail (laatst verzonden: ${getActivationSentAt(teacher)})`}
    icon="email-envelope"
    variables={{ userId: teacher.id }}
    onCompleted={() => {
      refetch()
      notify({
        duration: 5,
        type: 'success',
        title: 'Activatie e-mail gestuurd',
        content: 'De gebruiker heeft een e-mail ontvangen',
      })
    }}
    mutation={graphql`
      mutation ActionsSendTeacherActivationMailMutation($userId: ID!) {
        refreshUser(userId: $userId) {
          id
        }
      }
    `}
  />)

const EditTeacher = withOrganisation(({ organisation, teacher }) =>
  <Table.Action
    title="Bewerk" icon="pen"
    to={`/${organisation.slug}/teacher/${teacher.id}/edit`} />
)

const EditTeacherAccess = withOrganisation(({ organisation, teacher }) =>
  <Table.Action
    title="Beheer rechten" icon="key"
    to={`/${organisation.slug}/teacher/${teacher.id}/access`} />
)

const GoToTeacher = compose(
  withOrganisation,
  withRouter,
  withMask,
)(({ teacher, setMask, history, match, t }) =>
  <Table.Action
    title={`Inloggen als deze ${t('teacher_singular')}`}
    icon="arrow-right"
    onClick={() => {
      setMask(teacher)
      history.push(`${match.path}/dashboard`)
    }} />
)

export default ({ teacher, refetch }) =>
  <Table.Actions>
    {teacher.active && <DeactivateTeacher teacher={teacher} refetch={refetch} />}
    {!teacher.active && <ActivateTeacher teacher={teacher} refetch={refetch} />}
    <EditTeacher teacher={teacher} />
    <EditTeacherAccess teacher={teacher} />
    <RefreshTeacher teacher={teacher} refetch={refetch} />
    {teacher.active && <GoToTeacher teacher={teacher} />}
    {!teacher.active && <DeleteTeacher teacher={teacher} refetch={refetch} />}
  </Table.Actions>
