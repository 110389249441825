import React from 'react'
import withSorting from 'hoffelijk-react-components/containers/withSorting'

import ErrorBoundary from '../ErrorBoundary'

import {
  Table,
  TableHead,
  TableHeading,
  TableBody,
  TableRow,
  TableCell
} from './Table'

import LoadingState from './LoadingState'
import ErrorState from './ErrorState'
import EmptyState from './EmptyState'
import ActiveState from './ActiveState'
import SortLabel from './SortLabel'

const StructuredTable = ({
  columns, data, setData,
  loading, error, fallback,
  sorting, sortOnColumn, wideFirstColumn }) =>
  <ErrorBoundary>
    <Table>
      <TableHead>
        <TableRow wideFirstColumn={wideFirstColumn}>
          {columns.map(({ label, sortable, align }, i) =>
            <TableHeading align={align} key={i}>
              <SortLabel
                sortable={sortable}
                sorting={sorting.column === label ? sorting.direction : 0}
                onClick={() => sortOnColumn(label)}
              >
                {label}
              </SortLabel>
            </TableHeading>
          )}
        </TableRow>
      </TableHead>
      {loading
        ? <LoadingState columns={columns} />
        : error
          ? <ErrorState columns={columns} error={error} />
          : data && data.length
            ? <ActiveState columns={columns} data={data} />
            : <EmptyState columns={columns} component={fallback} />}
    </Table>
  </ErrorBoundary>

const EnhancedTable = withSorting(StructuredTable)

EnhancedTable.Table   = Table
EnhancedTable.Head    = TableHead
EnhancedTable.Heading = TableHeading
EnhancedTable.Body    = TableBody
EnhancedTable.Row     = TableRow
EnhancedTable.Cell    = TableCell

export default EnhancedTable
