import React, { Fragment } from 'react'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import { darken } from 'polished'

import LeagueSpartanOtf from 'hoffelijk-react-components/assets/LeagueSpartan-Bold.otf'
import LeagueSpartanWoff from 'hoffelijk-react-components/assets/LeagueSpartan-Bold.woff'
import background from 'hoffelijk-react-components/assets/banner.jpg'

import 'open-sans-fontface/open-sans.css';

let theme = {
  font: {
    heading: `'League Spartan Bold', 'Open Sans', sans-serif`,
    copy: `'Open Sans', sans-serif`,
  },
  color: {
    white:      '#feffff',
    gray10:     '#f6f6f6',
    gray15:     '#f0f0f0',
    gray20:     '#e5e5e5',
    gray40:     '#b9bdc1',
    gray50:     '#99a0a5',
    gray60:     '#79858b',
    accent:     '#adc0bd',
    primary:    '#5c6a73',
    secondary:  '#30c5ff',
    text:       '#5c6a73',
    error:      '#d34d4e',
    warning:    '#f7a600',
    success:    '#41ac57',
    successLight: '#b3cb78',
  },
  borderRadius: 4,
  column: {
    amount: 12,
    gap: 15,
  },
  background,
  gradient_disabled: false,
  hide_footer: false
}

theme.color = {
  ...theme.color,
  background: theme.color.gray15,
  mainnav: theme.color.primary,
  subnav: theme.color.accent,
  icon_mainnav: theme.color.accent,
  title: theme.color.text,
  title_link: theme.color.primary,
  text: theme.color.text,
  text_link: theme.color.primary,
  button: theme.color.accent,
  inside_button: theme.color.white,
  outside_button: theme.color.primary,
  button_hover: theme.color.primary,
  outside_button_hover: theme.color.white,
  inside_button_hover: theme.color.white,
  color_button_dark: theme.color.primary,
  color_button_dark_hover: darken(.2, theme.color.primary),
  footer: theme.color.white,
}

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: "League Spartan Bold";
    src: url(${LeagueSpartanOtf}) format('opentype'),
         url(${LeagueSpartanWoff}) format('woff');
  }

  *, *::before, *::after {
    vertical-align: top;
    box-sizing: border-box;
  }

  html, body, #root {
    height: 100%;
    margin: -1px 0;
    padding: 1px 0;
  }

  body {
    font-family: ${theme.font.copy};
    font-size: .875rem;

    color: ${theme.color.text};
    background-color: ${theme.color.gray15};
  }

  h1, h2, h3, h4, h5, h6, b, strong {
    font-family: ${theme.font.heading}
  }

  h1 { font-size: 3.25rem; margin: 2.25rem 0; }
  h2 { font-size: 2rem; }
  h3 { font-size: 1.375rem; }
  h4 { font-size: 1.125rem; }

  input, textarea, button {
    border: none;
    font: inherit;
    line-height: 1;
    background: transparent;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  p a:hover {
    text-decoration: underline;
    text-decoration-skip: none;
    text-decoration-skip-ink: none;
    text-decoration-color: ${theme.color.gray20};
  }
`

export const ThemeDecorator = ({ story }) =>
  <ThemeProvider theme={theme}>
    <Fragment>
      {story}
      <GlobalStyles />
    </Fragment>
  </ThemeProvider>

export default ({ children, ...rest }) =>
  <ThemeProvider theme={theme} {...rest}>
    <Fragment>
      {children}
      <GlobalStyles />
    </Fragment>
  </ThemeProvider>
