/**
 * @generated SignedSource<<9ef5da6194f2843b789830c7caf47a37>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organisationModuleId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "teacherId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organisationModuleId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Recipe",
  "kind": "LinkedField",
  "name": "visible_recipes",
  "plural": true,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "icon",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Study",
  "kind": "LinkedField",
  "name": "study",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allowed_fails",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "duration",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "Recipe",
  "kind": "LinkedField",
  "name": "first_recipe",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "Recipe",
  "kind": "LinkedField",
  "name": "last_recipe",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "Recipe",
  "kind": "LinkedField",
  "name": "counting_recipes",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowed_tries",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v14 = {
  "kind": "Variable",
  "name": "organisationModuleId",
  "variableName": "organisationModuleId"
},
v15 = {
  "alias": null,
  "args": [
    (v14/*: any*/)
  ],
  "concreteType": "Recipe",
  "kind": "LinkedField",
  "name": "recipes",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "order",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "category",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "category_order",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OpenQuestion",
      "kind": "LinkedField",
      "name": "open_questions",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "question_number",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RecipeSection",
      "kind": "LinkedField",
      "name": "sections",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        (v6/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v16 = [
  (v14/*: any*/),
  {
    "kind": "Variable",
    "name": "teacherId",
    "variableName": "teacherId"
  }
],
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "Candidate",
  "kind": "LinkedField",
  "name": "candidate",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": (v16/*: any*/),
  "concreteType": "Student",
  "kind": "LinkedField",
  "name": "students",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "first_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "middle_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "last_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "invoice_contact",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code_invoice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code_debtor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code_contract",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code_project",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "groupname",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "org_name",
      "storageKey": null
    },
    (v17/*: any*/)
  ],
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "grade",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "score",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "max_score",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "answer",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "concreteType": "OpenQuestion",
  "kind": "LinkedField",
  "name": "open_question",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "concreteType": "RecipeSection",
  "kind": "LinkedField",
  "name": "section",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "started_at",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "finished_at",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "concreteType": "Recipe",
  "kind": "LinkedField",
  "name": "recipe",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "resultsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "OrganisationModule",
        "kind": "LinkedField",
        "name": "organisationModule",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Module",
            "kind": "LinkedField",
            "name": "module",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ModuleStudy",
                "kind": "LinkedField",
                "name": "module_study",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Guarantee",
                    "kind": "LinkedField",
                    "name": "guarantee",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v15/*: any*/),
      (v18/*: any*/),
      {
        "alias": null,
        "args": (v16/*: any*/),
        "concreteType": "Result",
        "kind": "LinkedField",
        "name": "results",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "OpenAnswer",
            "kind": "LinkedField",
            "name": "open_answers",
            "plural": true,
            "selections": [
              (v22/*: any*/),
              (v23/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SectionResult",
            "kind": "LinkedField",
            "name": "section_results",
            "plural": true,
            "selections": [
              (v24/*: any*/),
              (v19/*: any*/)
            ],
            "storageKey": null
          },
          (v25/*: any*/),
          (v26/*: any*/),
          (v17/*: any*/),
          (v27/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "resultsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "OrganisationModule",
        "kind": "LinkedField",
        "name": "organisationModule",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Module",
            "kind": "LinkedField",
            "name": "module",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ModuleStudy",
                "kind": "LinkedField",
                "name": "module_study",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Guarantee",
                    "kind": "LinkedField",
                    "name": "guarantee",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v15/*: any*/),
      (v18/*: any*/),
      {
        "alias": null,
        "args": (v16/*: any*/),
        "concreteType": "Result",
        "kind": "LinkedField",
        "name": "results",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "OpenAnswer",
            "kind": "LinkedField",
            "name": "open_answers",
            "plural": true,
            "selections": [
              (v22/*: any*/),
              (v23/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SectionResult",
            "kind": "LinkedField",
            "name": "section_results",
            "plural": true,
            "selections": [
              (v24/*: any*/),
              (v19/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v25/*: any*/),
          (v26/*: any*/),
          (v17/*: any*/),
          (v27/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "89c68328b266d2ccab71fd0f64e18df8",
    "id": null,
    "metadata": {},
    "name": "resultsQuery",
    "operationKind": "query",
    "text": "query resultsQuery(\n  $organisationModuleId: ID!\n  $teacherId: ID!\n) {\n  organisationModule(id: $organisationModuleId) {\n    id\n    visible_recipes {\n      id\n    }\n    module {\n      id\n      module_study {\n        id\n        icon\n        name\n        slug\n        study {\n          id\n        }\n        guarantee {\n          allowed_fails\n          duration\n          first_recipe {\n            id\n          }\n          last_recipe {\n            id\n          }\n          counting_recipes {\n            id\n            allowed_tries\n          }\n          id\n        }\n      }\n    }\n  }\n  recipes(organisationModuleId: $organisationModuleId) {\n    id\n    name\n    order\n    category\n    category_order\n    open_questions {\n      id\n      name\n      question_number\n    }\n    sections {\n      id\n      name\n    }\n  }\n  students(teacherId: $teacherId, organisationModuleId: $organisationModuleId) {\n    id\n    name\n    first_name\n    middle_name\n    last_name\n    invoice_contact\n    code\n    code_invoice\n    code_debtor\n    code_contract\n    code_project\n    groupname\n    org_name\n    candidate {\n      id\n    }\n  }\n  results(organisationModuleId: $organisationModuleId, teacherId: $teacherId) {\n    id\n    grade\n    score\n    max_score\n    open_answers {\n      answer\n      open_question {\n        id\n      }\n      id\n    }\n    section_results {\n      section {\n        id\n      }\n      grade\n      id\n    }\n    started_at\n    finished_at\n    candidate {\n      id\n    }\n    recipe {\n      id\n    }\n  }\n}\n"
  }
};
})();

node.hash = "d014f302bd69cb3dd808c807828d9e1d";

module.exports = node;
