import React, { Component } from 'react'
import createRelayEnvironment from 'relayEnvironment'

const { Consumer, Provider } = React.createContext(null)

export class AuthenticationProvider extends Component {
  static displayName = 'AuthenticationProvider'

  constructor(props) {
    super(props)

    const accessToken = window.localStorage.getItem('accessToken')

    this.state = {
      accessToken,
      environment: createRelayEnvironment(accessToken)
    }
  }

  setAccessToken = accessToken => {
    this.setState({
      accessToken,
      environment: createRelayEnvironment(accessToken)
    })

    if (accessToken) {
      window.localStorage.setItem('accessToken', accessToken)
    } else {
      window.localStorage.removeItem('accessToken', accessToken)
    }
  }

  render = () =>
    <Provider
      value={{
        setAccessToken: this.setAccessToken,
        environment: this.state.environment,
        accessToken: this.state.accessToken,
      }}
      {...this.props}
    />
}

export const withAuthentication = WrappedComponent =>
  class extends Component {
    static displayName = `withAuthentication(${WrappedComponent.displayName})`

    render = () =>
      <Consumer>
        {({ accessToken }) =>
          <WrappedComponent {...this.props} authenticated={!!accessToken} />}
      </Consumer>
  }

export const withAccessToken = WrappedComponent =>
  class extends Component {
    static displayName = `withAccessToken(${WrappedComponent.displayName})`

    render = () =>
      <Consumer>
        {({ accessToken, setAccessToken }) =>
          <WrappedComponent
            {...this.props}
            accessToken={accessToken}
            setAccessToken={setAccessToken}
          />}
      </Consumer>
  }

export const withEnvironment = WrappedComponent =>
  class extends Component {
    static displayName = `withEnvironment(${WrappedComponent.displayName})`
    render = () =>
      <Consumer>
        {({ environment }) =>
          <WrappedComponent {...this.props} environment={environment} />}
      </Consumer>
  }

export default AuthenticationProvider
