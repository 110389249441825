import React from 'react'
import { compose } from 'recompose'

import { Route, Switch, Redirect } from 'react-router-dom'

import Inside from 'layouts/inside'

import { withMe } from 'containers/Me'
import { withOrganisation } from 'containers/Organisation'
import { withMask } from 'containers/Mask'

import Logout from 'pages/logout'
import NotFoundPage from 'pages/404'
import Account from 'pages/account'

import PrivateOrganisationRouter from './PrivateOrganisationRouter'
import TeacherRouter from './TeacherRouter'

import { isMainAdmin, isAdmin, isTeacher } from 'utilities/logic/authentication'

const OrganisationRouter = ({ me, organisation, mask, match }) =>
  <Inside>
    <Switch>
      <Route
        exact path={`${match.path}/logout`}
        component={Logout} />
      <Route
        exact path={`/${organisation.slug}/account`}
        component={Account} />
      {(isTeacher(me, organisation) || isTeacher(mask, organisation))
        ? <Route path={`${match.path}/dashboard`} component={TeacherRouter} />
        : <Route path={`${match.path}/dashboard`} render={() => <Redirect to={`${match.path}`} />} />}
      {isMainAdmin(me) || isAdmin(me, organisation)
        ? <Route path={match.path} component={PrivateOrganisationRouter} />
        : <Route exact path={match.path} render={() => <Redirect to={`${match.path}/dashboard`} />} />}
      <Route component={NotFoundPage} />
    </Switch>
  </Inside>

export default compose(
  withMe,
  withOrganisation,
  withMask,
)(OrganisationRouter)
