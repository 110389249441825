import React, { Component } from 'react'

const { Consumer, Provider } = React.createContext(null)

export class MaskProvider extends Component {
  state = { mask: null }

  setMask = mask =>
    this.setState({ mask })

  render = () =>
    <Provider value={{
      mask: this.state.mask,
      maskId: this.state.mask ? this.state.mask.id : null,
      setMask: this.setMask,
    }} {...this.props} />
}

export const withMask = WrappedComponent =>
class extends Component {
  static displayName = `withMask(${WrappedComponent.displayName})`

  render = () =>
    <Consumer>
      {({ mask, setMask }) =>
        <WrappedComponent
          {...this.props}
          mask={mask}
          maskId={mask ? mask.id : null}
          setMask={setMask}
        />
      }
    </Consumer>
}

export default {
  MaskProvider,
  withMask
}
