import React from 'react'
import styled, { css } from 'styled-components'

import { Plus, Cross, Check } from 'components/glyphs'
import { Icon, Paper } from 'hoffelijk-react-components'
import ProgressIllustration from 'components/teacher/progress/ProgressIllustration'

import moment from 'moment'
import sampleRow from './sampleRow'

const Accent = styled.span`
  display: inline-block;
  padding: 4px;
  margin: 4px;
  border-radius: 4px;

  vertical-align: initial;

  background-color: ${props => props.theme.color.gray20};
`

const StyledCross = styled(Cross)`
  color: ${props => props.theme.color.gray40};
`

const StyledCheck = styled(Check)`
  color: ${props => props.theme.color.gray40};
`

const StyledIcon = styled(Icon)`
  color: ${props => props.theme.color.gray40};
  ${props => props.error && css`color: ${props.theme.color.error};`}
  ${props => props.warning && css`color: ${props.theme.color.warning};`}
  ${props => props.success && css`color: ${props.theme.color.success};`}
`

const Large = styled.span`
  display: inline-block;
  width: 20px;

  line-height: 18px;
  text-align: center;
  font-size: 20px;
  font-weight: normal;

  color: ${props => props.theme.color.gray40};
`

const StyledTable = styled.table`
  float: right;
  margin: 32px 0 20px 14px;

  border-collapse: collapse;

  font-size: 12px;

  th, td {
    padding: .5em;

    border: 1px solid ${props => props.theme.color.gray40};

    vertical-align: middle;
  }

  td:first-child { text-align: center; }
  th:nth-child(n + 2) { text-align: left; }
`

const ClearFix = styled.div`
  clear: both;
`

const ScrollingPaper = styled(Paper)`
  overflow-y: auto;
  max-height: calc(100vh - 7em);
`

export default ({ t, days=20 }) =>
  <ScrollingPaper light>
    <Paper.Section accent>
      <h1>Handleiding progressie {t('student_plural')}</h1>
    </Paper.Section>
    <Paper.Section>
      <h2>Kolommen sorteren</h2>
      <p>Sorteer van hoog-laag en van A-Z (en visa versa) door op de kolomkoppen te klikken.</p>
    </Paper.Section>
    <Paper.Section>
      <h2>Afgeronde toetsen</h2>
      <p>Tijdens de opleidingen vinden meerdere toetsmomenten plaats. In deze kolom tref je de voorgang aan van de toetsmomenten: zowel in procenten als in absolute aantallen. Hierin wordt geen rekening gehouden met de lengte van de toets of van het hoofdstuk.</p>
    </Paper.Section>
    <Paper.Section>
      <h2>Streefdatum</h2>
      <p>Wanneer een {t('student_singular')} de opleiding vóór een bepaalde datum doorlopen moet hebben, kan de contactpersoon van je organisatie een streefdatum doorgeven via het ID-platform. De streefdatum kan bijvoorbeeld de examendatum zijn, maar ook de gewenste einddatum van het opleidingstraject.</p>
    </Paper.Section>
    <Paper.Section>
      <StyledTable>
        <thead>
          <tr>
            <th>Icoon</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><Large>?</Large></td>
            <td>Geen schema</td>
          </tr>
          <tr>
            <td><StyledIcon size={20} error icon="snail" /></td>
            <td>Achter op schema</td>
          </tr>
          <tr>
            <td><StyledIcon size={20} warning icon="calendar-check" /></td>
            <td>Op schema</td>
          </tr>
          <tr>
            <td><StyledIcon size={20} success icon="rabbit" /></td>
            <td>Voor op schema</td>
          </tr>
          <tr>
            <td><StyledIcon size={20} error icon="calendar-close" /></td>
            <td>Streefdatum gepasseerd</td>
          </tr>
          <tr>
            <td><StyledIcon size={20} success icon="medal" /></td>
            <td>Afgerond</td>
          </tr>
        </tbody>
      </StyledTable>
      <h2>Voortgang opleiding</h2>
      <p>Binnen jouw organisatie wordt aangegeven hoelang een {t('student_singular')} gemiddeld over een opleiding mag doen. Dit 'planningsschema' wordt teruggerekend vanaf de streefdatum.<br />Achter het voortgangsstatussymbool staat aangegeven hoeveel dagen de {t('student_singular')} voor- of achterloopt op het schema. Zo kun je in één oogopslag zien wie achterloopt of juist gemotiveerd moet worden om aan de slag te gaan.</p>
      <ClearFix />
    </Paper.Section>
    <Paper.Section>
      <h2>Progressiebalk</h2>
      <ProgressIllustration row={{
        ...sampleRow({ target_date: moment().add(days,'days') }),
      }} />
      <p>Sommige opleidingen zijn voorzien van een progressiebalk waarop de belangrijkste toetsen in een tijdlijn worden weergegeven. Je vindt de progressiebalk door op het plusje (<Accent><Plus size={20} /></Accent>) te klikken. De progressiebalk vertelt je waar een {t('student_singular')} zich bevindt ten opzichte van de planning. De planning wordt in kaart gebracht door middel van de streefdatum.<br />
      De beste poging (binnen de slagingsgarantie) wordt weergegeven binnen de cirkels. De kleuren groen, geel en oranje vertellen je niets over de score, maar over hoeveel de {t('student_singular')} voor- of achterloopt.<br />
      Een gestreepte progressiebalk geeft aan tot waar een {t('student_singular')} acher loopt.</p>
    </Paper.Section>
    <Paper.Section>
      <StyledTable>
        <thead>
          <tr>
            <th>Icoon</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><StyledCheck size={20} /></td>
            <td>Slagingsgarantie behaald /<br />
            Voldoende eindcijfer binnen aantal pogingen</td>
          </tr>
          <tr>
            <td><StyledCross size={20} /></td>
            <td>Geen recht op slagingsgarantie /<br />
            Onvoldoende eindcijfer binnen aantal pogingen</td>
          </tr>
          <tr>
            <td><StyledIcon size={20} icon="hourglass" /></td>
            <td>Slagingsgarantie/eindcijfer is nog niet bepaald</td>
          </tr>
        </tbody>
      </StyledTable>
      <h2>Slagingsgarantie</h2>
      <p>Wanneer een {t('student_singular')} een gelimiteerd aantal pogingen heeft voor het bepalen van het eindcijfer, dan kun je dit instellen. We noemen dit ook wel de slagingsgarantie. De slagingsgarantie wordt door de organisatieverantwoordelijke doorgegeven aan Hoffelijk en wij stellen deze voor je in. De symbolen geven feitelijk aan of iemand is geslaagd voor de opleiding, of er nog mee bezig is.</p>
      <ClearFix />
    </Paper.Section>
  </ScrollingPaper>
