import React from 'react'
import styled from 'styled-components'

import { Icon } from 'hoffelijk-react-components'
import { Check, Cross } from 'components/glyphs'
import { GUARANTEE_STATUS } from 'utilities/constants'

const LightIcon = styled(Icon)`color: ${props => props.theme.color.gray40};`
const LightCross = styled(Cross)`color: ${props => props.theme.color.gray40};`
const LightCheck = styled(Check)`color: ${props => props.theme.color.gray40};`

export default ({ guaranteed }) => {
  switch (guaranteed) {
    case GUARANTEE_STATUS.EXPIRED: return <LightCross size={20} />
    case GUARANTEE_STATUS.PENDING: return <LightIcon size={20} icon="hourglass" />
    case GUARANTEE_STATUS.GUARANTEED: return <LightCheck size={20} />
    default: return '-'
  }
}
