import React from 'react'

export default ({ width, height, fill, className }) =>
	<svg height={height} width={width} className={className} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<g className="nc-icon-wrapper" fill={fill}>
			<path id="Frame_-_24px" d="M0 0h24v24H0z" fill="none"/>
			<g id="Line_Icons">
				<path d="M15 5.589v2.223A5.999 5.999 0 0 1 18 13c0 3.309-2.691 6-6 6s-6-2.691-6-6a5.998 5.998 0 0 1 3-5.188V5.589C6.072 6.779 4 9.65 4 13c0 4.411 3.589 8 8 8s8-3.589 8-8c0-3.35-2.072-6.221-5-7.411z" fill={fill}/>
				<path d="M11 3h2v8h-2z" fill={fill}/>
			</g>
		</g>
	</svg>
