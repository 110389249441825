import React from 'react'
import { withRouter } from 'react-router-dom'
import { withNotifications } from 'containers/Notifications'
import { compose } from 'recompose'

import { Row, Column } from 'hoffelijk-react-components'
import { HeaderMod } from 'containers/Header'
import AccountManagement from 'components/AccountManagement'

const CreateAdmin = ({ history, notify }) =>
  <HeaderMod
    backLink="/"
    title="Nieuwe beheerder"
  >
    <Row>
      <Column size={8}>
        <AccountManagement
          editableRoles={['superadmin']}
          defaultValues={{
            is_main_admin: true
          }}
          onSubmit={() => {
            history.push('/#admins')
            notify({
              type: 'success',
              title: 'Beheerder succesvol aangemaakt',
            })
          }}
        />
      </Column>
    </Row>
  </HeaderMod>

export default compose(
  withNotifications,
  withRouter,
)(CreateAdmin)
