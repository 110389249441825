import React from 'react'
import styled from 'styled-components'

import { Icon, Paper } from 'hoffelijk-react-components'
import { Plus } from 'components/glyphs'

import { Failed, Passed, Excellent, NotParticipated } from 'components/teacher/results/Statistics'

const StatisticsContainer = styled.div`
  display: flex;
`

const Accent = styled.span`
  display: inline-block;
  padding: 4px;
  margin: 4px;
  border-radius: 4px;

  vertical-align: initial;

  background-color: ${props => props.theme.color.gray20};
`

const ScrollingPaper = styled(Paper)`
  overflow-y: auto;
  max-height: calc(100vh - 7em);
`

const Strong = styled.strong`
  display: inline-block;
  margin-top: -26px;
`

export default ({ t }) =>
  <ScrollingPaper light>
    <Paper.Section accent>
      <h1>Handleiding toetsresultaten</h1>
    </Paper.Section>
    <Paper.Section>
      <h2>Statistieken</h2>
      <StatisticsContainer>
        <Failed amount={5}>
          <Strong>&lt; 68%&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt; 5,5</Strong>
        </Failed>
        <Passed amount={5}>
          <Strong>68% - 80%&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5,5 - 7,5</Strong>
        </Passed>
        <Excellent amount={5}>
          <Strong>&ge; 80%&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&ge; 7,5</Strong>
        </Excellent>
        <NotParticipated amount={5}>
          <Strong>Niet gemaakt</Strong>
        </NotParticipated>
      </StatisticsContainer>
      <br />
      <p>In de grafiekbalk is in één keer oogopslag te zien hoe goed er wordt gescoord op een bepaalde toets.<br />Tip: Gebruik de grafiek als filter om te zien welke {t('student_plural')} goed of slecht scoren.</p>
    </Paper.Section>
    <Paper.Section>
      <h2>Open vragen</h2>
      <p>Klik op <Accent><Icon icon="file-add" size={20} /></Accent> om te bekijken welke antwoorden de {t('student_plural')} hebben gegeven op de open vragen. Als de toets bestaat uit meerdere vragen, kun je switchen tussen de vragen via de bovenbalk. De namen van de {t('student_plural')} zijn altijd verborgen, zodat je het ook in de groep kunt bespreken.</p>
    </Paper.Section>
    <Paper.Section>
      <h2>Gedifferentieerde feedback</h2>
      <p>Als een toets uit verschillende onderwerpen bestaat, kun je op <Accent><Icon icon="sections" size={20} /></Accent> klikken om te zien hoe {t('student_plural')} hebben gescoord op de verschillende onderdelen.</p>
    </Paper.Section>
    <Paper.Section>
      <h2>Toetsresultaten bekijken en sorteren</h2>
      <p>Wanneer je een toets heb opengeklapt door op de <Accent><Plus size={20} /></Accent> te klikken, kun je de testresultaten sorteren van hoog naar laag en van A naar Z (en visa versa) door op de kolomkoppen te klikken.</p>
    </Paper.Section>
  </ScrollingPaper>
