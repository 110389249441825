import React from 'react'
import styled from 'styled-components'
import graphql from 'babel-plugin-relay/macro'
import withQuery from 'containers/withQuery'

import { Float, Paper, Table, Button, Styles } from 'hoffelijk-react-components'
import Actions from './Actions'

import { firstBy } from 'thenby'
import defaultIcon from 'assets/images/default_module_icon.png'
import Icon from '../../../hoffelijk-react-components/components/Icon'

const WarningIcon = styled(Icon)`
  color: red;
`

const RowIcon = styled.img`
  height: 32px;
  margin: -4px 0;
`

export const ModulesOverview = ({ loading, moduleStudies, error, retry }) =>
  <Paper light expanding>
    <Paper.Section>
      <Float.Left>
        <Styles.NoMargin as="h2">Overzicht modules</Styles.NoMargin>
      </Float.Left>
      <Float.Right>
        <Button to="/module/add">Module toevoegen</Button>
      </Float.Right>
    </Paper.Section>
    <Table
      defaultCompare={firstBy('name')}
      dense
      columns={[
        {
          label: '',
          sortable: false,
          content: moduleStudy =>
            <RowIcon
              src={moduleStudy.icon ? moduleStudy.icon : defaultIcon}
              alt={moduleStudy.name}
            />
        },
        {
          label: 'Naam',
          content: moduleStudy => <>
            {moduleStudy.module
              ? ''
              : <WarningIcon size={20} icon="flash"/>
            }
            {moduleStudy.name}
          </>
        },
        // {
        //   label: 'Module',
        //   content: moduleStudy => moduleStudy.module.name
        // },
        // {
        //   label: 'Opleiding',
        //   content: moduleStudy => moduleStudy.study.name
        // },
        {
          label: 'Slagingsgarantie',
          content: moduleStudy => moduleStudy.guarantee
            ? 'Ingesteld' : 'Niet ingesteld'
        },
        {
          label: '',
          content: moduleStudy => <Actions moduleStudy={moduleStudy} refetch={retry} />,
          sortable: false,
        }
      ]}
      data={moduleStudies}
      loading={loading}
      error={error}
    />
  </Paper>

const getQuery = () => graphql`
  query ModulesOverviewQuery {
    moduleStudies {
      id
      icon
      name
      guarantee {
        id
      }
      module {
        id
        name
      }
      study {
        id
        name
      }
    }
  }
`

export default withQuery({ getQuery })(ModulesOverview)
