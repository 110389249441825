import React from 'react'
import styled from 'styled-components'

import Notification, { Notification as StyledNotification } from './Notification'

const NotificationContainer = styled.div`
  position: fixed;
  right: 30px; bottom: 30px;
  z-index: 10;

  width: calc(100vw - 60px);
  max-width: 560px;

  ${StyledNotification} {
    margin-top: 10px;
  }
`

export default ({ notifications, closeNotification }) =>
  <NotificationContainer>
    {notifications.map((notification, i) =>
      <Notification
        {...notification}
        onClose={() => closeNotification(notification)}
        key={i}
      />
    )}
  </NotificationContainer>
