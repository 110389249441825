import { ThemeProvider } from 'styled-components'
import { withProps } from 'recompose'
import deepmerge from 'deepmerge'

export const parseTheme = theme => Object.keys(theme)
  .reduce(
    (acc, key) => theme[key] === null
      ? acc
      : key.includes('color_')
        ? {
            ...acc,
            color: {
              ...acc.color,
              [key.replace('color_', '')]: theme[key]
            }
          }
        : {
            ...acc,
            [key]: theme[key]
          },
    { color: {} }
  )

const mergeThemes = newTheme => currentTheme =>
  deepmerge(currentTheme, newTheme)

const getTheme = ({ theme }) => ({
  theme: mergeThemes(parseTheme(theme || {}))
})

const OrganisationTheme = withProps(getTheme)(ThemeProvider)
OrganisationTheme.displayName = 'OrganisationTheme'
export default OrganisationTheme
