import React from 'react'
import graphql from 'babel-plugin-relay/macro'

import { withNotifications } from 'containers/Notifications'

import { Table } from 'hoffelijk-react-components'
import TableAction from 'components/TableAction'

import moment from 'moment'

const DeactivateUser = ({ user, refetch }) =>
  <TableAction
    title="Deactiveer" icon="switch-right"
    variables={{ userId: user.id }}
    onCompleted={refetch}
    mutation={graphql`
      mutation ActionsDeactivateUserMutation($userId: ID!) {
        deactivateUser(userId: $userId) {
          id
        }
      }
    `}
  />

const ActivateUser = ({ user, refetch }) =>
  <TableAction
    title="Activeer" icon="switch-left"
    variables={{ userId: user.id }}
    onCompleted={refetch}
    mutation={graphql`
      mutation ActionsActivateUserMutation($userId: ID!) {
        activateUser(userId: $userId) {
          id
        }
      }
    `}
  />

const DeleteUser = ({ user, refetch }) =>
  <TableAction
    confirm={`Weet je zeker dat je ${user.name} wilt verwijderen?`}
    title="Verwijder" icon="bin"
    variables={{ userId: user.id }}
    onCompleted={refetch}
    mutation={graphql`
      mutation ActionsDeleteUserMutation($userId: ID!) {
        deleteUser(userId: $userId) {
          success
        }
      }
    `}
  />

const getActivationSentAt = user => user.activation_sent_at
  ? moment(user.activation_sent_at).format('YYYY-MM-DD')
  : 'Nooit'

const RefreshUser = withNotifications(({ user, refetch, notify }) =>
  <TableAction
    title={`Verzend activatie e-mail (laatst verzonden: ${getActivationSentAt(user)})`}
    icon='email-envelope'
    variables={{ userId: user.id }}
    onCompleted={() => {
      refetch()
      notify({
        duration: 5,
        type: 'success',
        title: 'Activatie e-mail gestuurd',
        content: 'De gebruiker heeft een e-mail ontvangen',
      })
    }}
    mutation={graphql`
      mutation ActionsSendActivationMailMutation($userId: ID!) {
        refreshUser(userId: $userId) {
          id
        }
      }
    `}
  />)

const EditUser = ({ user }) =>
  <Table.Action
    title="Bewerk" icon="pen"
    to={`/user/${user.id}/edit`} />

const EditSelf = ({ user }) =>
  <Table.Action
    title="Bewerk eigen gegevens" icon="user"
    to={`/account`} />

export default ({ me, user, refetch }) =>
  <Table.Actions>
    {!user.active && <DeleteUser user={user} refetch={refetch} />}
    {user.active && <DeactivateUser user={user} refetch={refetch} />}
    {!user.active && <ActivateUser user={user} refetch={refetch} />}
    {me.id !== user.id && <EditUser user={user} />}
    {me.id !== user.id && <RefreshUser user={user} refetch={refetch} />}
    {me.id === user.id && <EditSelf />}
  </Table.Actions>
