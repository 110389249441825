import React from 'react'
import styled from 'styled-components'
import graphql from 'babel-plugin-relay/macro'

import withForm from 'containers/withForm'

import { Paper, Input, Button, Align } from 'hoffelijk-react-components'

const PaperSection = styled(Paper.Section)`
  padding-top: 0;
`

const PasswordManagement = ({ values, errors, disabled, updateValue, onSubmit }) =>
  <form>
    <Paper light>
      <PaperSection>
        <h4>Nieuw wachtwoord instellen</h4>
        <Input.Group>
          <Input
            label="Huidig wachtwoord"
            type="password"
            required
            value={values['current_password']}
            error={errors['current_password']}
            onChange={v => updateValue('current_password', v)}
            block
          />
          <Input
            label="Nieuw wachtwoord"
            type="password"
            required
            value={values['new_password']}
            error={errors['new_password']}
            onChange={v => updateValue('new_password', v)}
            block
          />
          <Input
            label="Herhaal wachtwoord"
            type="password"
            required
            value={values['confirm_password']}
            error={errors['confirm_password']}
            onChange={v => updateValue('confirm_password', v)}
            block
          />
        </Input.Group>
      </PaperSection>
    </Paper>
    <Align.Center>
      <Button under onClick={onSubmit} disabled={disabled}>Wijzig wachtwoord</Button>
    </Align.Center>
  </form>

export default withForm({
  getMutation: () => graphql`
    mutation PasswordManagementMutation($userId: ID! $input: UpdatePasswordInput!) {
      updatePassword(userId: $userId input: $input) {
        success
      }
    }
  `,
  getVariables: ({ userId }) => input => ({
    userId, input,
  }),
})(PasswordManagement)
