import React from 'react'
import { compose, withHandlers, withProps } from 'recompose'
import { Input } from 'hoffelijk-react-components'

const ServiceSelection = ({ values=[], errors=[], options, setExamServices }) =>
  <>
    <Input.Group>
      <Input
        required
        type="select"
        label="Examendiensten voor deze opleiding"
        value={values.map(v => v.id)}
        onChange={setExamServices}
        options={options}
        multi
        block
      />
    </Input.Group>
  </>

export default compose(
  withProps(({ values }) => ({ values: values || [] })),
  withHandlers({
    setExamServices: ({ values, updateValue }) => ids => updateValue(ids.map(id => ({ id }))),
  }),
)(ServiceSelection)
