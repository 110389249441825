/**
 * @generated SignedSource<<8c961d1b8d65cd8fff06572068fedbb1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organisationModuleId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organisationModuleId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "has_planning",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  (v2/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Recipe",
  "kind": "LinkedField",
  "name": "planning_recipes",
  "plural": true,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Recipe",
  "kind": "LinkedField",
  "name": "recipes",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Recipe",
  "kind": "LinkedField",
  "name": "visible_recipes",
  "plural": true,
  "selections": (v5/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "editOrganisationModuleQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "OrganisationModule",
        "kind": "LinkedField",
        "name": "organisationModule",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Module",
            "kind": "LinkedField",
            "name": "module",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ModuleStudy",
                "kind": "LinkedField",
                "name": "module_study",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Study",
                    "kind": "LinkedField",
                    "name": "study",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "editOrganisationModuleQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "OrganisationModule",
        "kind": "LinkedField",
        "name": "organisationModule",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Module",
            "kind": "LinkedField",
            "name": "module",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ModuleStudy",
                "kind": "LinkedField",
                "name": "module_study",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Study",
                    "kind": "LinkedField",
                    "name": "study",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "556fd7074c6f8b92574673a2edf8a7e0",
    "id": null,
    "metadata": {},
    "name": "editOrganisationModuleQuery",
    "operationKind": "query",
    "text": "query editOrganisationModuleQuery(\n  $organisationModuleId: ID!\n) {\n  organisationModule(id: $organisationModuleId) {\n    id\n    has_planning\n    module {\n      module_study {\n        name\n        planning_recipes {\n          id\n        }\n        study {\n          recipes {\n            id\n            name\n            code\n          }\n          id\n        }\n        id\n      }\n      id\n    }\n    visible_recipes {\n      id\n    }\n  }\n}\n"
  }
};
})();

node.hash = "99768347b7152c7648347deee99dd29a";

module.exports = node;
