import { compose, withState, withHandlers, withProps, onlyUpdateForKeys } from 'recompose'
import withWindow from 'containers/withWindow'

import { Filters } from 'hoffelijk-react-components'

export default compose(
  withState('expanded', 'setExpanded', false),
  withHandlers({
    toggleExpanded: ({ expanded, setExpanded }) => () => setExpanded(!expanded)
  }),
  withWindow,
  withProps(({ windowScroll }) => ({
    down: windowScroll.top > 30,
  })),
  onlyUpdateForKeys(['filters', 'filterState', 'expanded', 'down']),
)(Filters)
