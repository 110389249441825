import styled from 'styled-components'

export default styled.span`
  display: inline-block;
  padding: 0 .5rem;
  margin: -40px -.5rem 0 -.5rem;

  background-color: ${props => props.theme.color.white};
  color: ${props => props.theme.color.gray40};
`
