import React from 'react'
import PasswordResetForm from 'components/PasswordResetForm'
import graphql from 'babel-plugin-relay/macro'
import { compose, withHandlers } from 'recompose'
import withQuery from 'containers/withQuery'
import { withRouter } from 'react-router-dom'
import { withOrganisation } from 'containers/Organisation'
import { withAccessToken } from 'containers/Authentication'
import Loader from 'components/Loader'

const PasswordReset = ({ passwordReset, match, onSubmit }) =>
  <>
    <h1>Wachtwoord resetten</h1>
    <PasswordResetForm
      passwordReset={passwordReset}
      data={{ token: match.params.token }}
      onSubmit={onSubmit}
    />
  </>

const getQuery = () => graphql`
  query passwordResetQuery($token: String!) {
    passwordReset(token: $token) {
      expired
    }
  }
`

const getVariables = ({ match }) => ({
  token: match.params.token
})

export default compose(
  withRouter,
  withOrganisation,
  withAccessToken,
  withQuery({ getQuery, getVariables, loader: Loader }),
  withHandlers({
    onSubmit: ({ setAccessToken, history, organisation }) => ({ passwordReset }) => {
      setAccessToken(passwordReset.accessToken)
      history.push(organisation ? `/${organisation.slug}` : '/')
    }
  })
)(PasswordReset)
