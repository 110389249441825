import React from 'react'
import styled from 'styled-components'
import { compose, withHandlers, withProps } from 'recompose'
import { Input } from 'hoffelijk-react-components'

const PlanningInput = styled.div`
  margin-top: 20px;

  &::before, &::after {
    content: '';
    display: block;
    clear: both;
  }
`
const PlanningInputLabel = styled.p`
  float: left;
  width: 50%;
`

const InputGroup = styled(Input.Group)`
  display: flex;
  float: right;
  width: 50%;

  > div {
    width: 100%;
  }
`

const PlanningManagement = ({ values=[], errors=[], options, setRecipes, updateRecipe }) =>
  <>
    <Input.Group>
      <Input
        required
        type="select"
        label="Toetsmatrijzen met planning"
        value={values.map(value => value.recipe_id)}
        onChange={setRecipes}
        options={options}
        multi
        block
      />
    </Input.Group>
    {values.slice().sort((a, b) => a.planning < b.planning ? -1 : 1).map((value, i) =>
      <PlanningInput key={value.recipe_id}>
        <PlanningInputLabel>
          {(options.find(option => option.value === value.recipe_id) || {}).label}
        </PlanningInputLabel>
        <InputGroup>
          <Input
            label="Weergavenaam"
            value={value.name}
            error={errors[i] && errors[i].name}
            onChange={v => updateRecipe(value.recipe_id, { name: v })}
          />
          <Input
            required
            type="number"
            label="Percentage voor streefdatum"
            min={0}
            max={100}
            value={value.planning}
            error={errors[i] && errors[i].planning}
            onChange={v => updateRecipe(value.recipe_id, { planning: v })}
          />
        </InputGroup>
      </PlanningInput>
    )}
  </>

export default compose(
  withProps(({ values }) => ({ values: values || [] })),
  withHandlers({
    setRecipes: ({ values, updateValue }) => recipe_ids => updateValue(
      recipe_ids.map(recipe_id =>
        values.find(value => value.recipe_id === recipe_id) || { recipe_id, name: null, planning: null })
    ),
    updateRecipe: ({ values, updateValue }) => (recipe_id, o) => updateValue(
      values.map(value => value.recipe_id === recipe_id
        ? {...value, ...o}
        : value)
    ),
  }),
)(PlanningManagement)
