import styled from 'styled-components'

export const Table = styled.table`
  position: relative;

  width: 100%;

  border-collapse: collapse;
`

export const TableHead = styled.thead``

export const TableHeading = styled.th`
  text-align: ${props => props.align ?? 'left'};
  font-weight: bold;
`

export const TableBody = styled.tbody``

export const TableRow = styled.tr`
  ${props => props.wideFirstColumn ? `
    th:first-child:not([colspan]) {
      min-width: 50%;
    }` : ''}
`

export const TableCell = styled.td`
  padding: 8px 0;
  
  text-align: ${props => props.align ?? 'left'};
`
