import React from 'react'
import styled, { css } from 'styled-components'
import { darken } from 'polished'

import Wrapper from '../Wrapper'
import Paper from '../Paper'
import Icon from '../Icon'

const FilterWrapper = styled(Paper)`
  position: relative;

  box-shadow: 0 4px 12px rgba(0, 0, 0, .1);
  transform-origin: left;

  transition-property: transform, opacity;
  transition-duration: .4s;
  transition-timing-function: ease-in-out;

  ${props => !props.expanded && css`
    transform: translate(100%, 0) translate(46px, -3px) scale(0.045, .65);
    opacity: 0;
  `}
`

const RelativeWrapper = styled(Wrapper)`
  position: relative;
`

const FilterToggle = styled(Paper)`
  pointer-events: auto;
  cursor: pointer;

  position: absolute;
  left: calc(100% + 30px); top: 12px;

  opacity: 1;

  display: block;
  width: 53px;
  height: 53px;

  color: ${props => props.theme.color.gray40};

  transition: opacity .2s .2s ease-in-out;

  &::before,
  &::after {
    content: '';

    position: absolute;
    left: 0; top: 0;
    right: 0; bottom: 0;

    width: 24px;
    height: 3px;
    margin: auto;

    background-color: currentColor;

    transition: transform .2s ease-in-out;
  }

  &::before { transform: rotate(90deg); }

  ${props => props.expanded && css`
    &::before { transform: rotate(45deg); }
    &::after { transform: rotate(-45deg); }
  `}

  ${props => props.hidden && css`
    pointer-events: none;
    opacity: 0;
  `}
`

const FilterContainer = styled.div`
  ${props => props.down && !props.expanded && css`
    pointer-events: none;
  `}

  position: relative;
  z-index: 20;

  ${FilterWrapper} {
    margin-left: -15px;
    margin-right: -15px;
  }

  ${props => props.down && css`
    position: fixed;
    left: 0; top: 154px;
    right: 0;

    ${FilterWrapper} {
      box-shadow: 0 8px 24px rgba(0, 0, 0, .25);
    }

    ${props.isLow && css`top: 224px;`}
  `}
  ${props => !props.down && css`
    margin-bottom: -79px;
  `}

  & + ${Paper.Paper} {
    margin-top: 109px;
  }
`

const Filters = styled.div`
  display: inline-block;
  width: calc(100% - 78px);
`

const FiltersInner = styled.div`
  display: flex;
  flex-wrap: nowrap;
`

const ClearFilters = styled.div`
  cursor: pointer;

  position: relative;

  display: inline-block;
  width: 78px;
  height: 77px;

  padding: 26px;

  text-align: center;

  border-radius: 0 4px 4px 0;

  color: ${props => props.theme.color.button};

  ${props => props.selected && css`
    &::before, &::after {
      content: '';

      position: absolute;
      left: 20px; top: 20px;
      right: 0; bottom: 0;

      margin: auto;

      width: 10px;
      height: 2px;

      background: currentColor;
    }

    &::before { transform: rotate(45deg); }
    &::after { transform: rotate(-45deg); }
  `}

  &:hover {
    background-color: ${props => props.theme.color.button};
    box-shadow: 0 0 0 1px ${props => darken(.1, props.theme.color.button)};
    color: ${props => props.theme.color.white};
  }
`

const FilterIcon = styled(Icon)`fill: currentColor;`

export default ({ children, down, low, expanded, toggleExpanded, selected, clearFilters }) =>
  <FilterContainer down={down} isLow={low} expanded={expanded}>
    <RelativeWrapper>
      <FilterWrapper light expanded={!down || expanded}>
        <Filters>
          <FiltersInner>{children}</FiltersInner>
        </Filters>
        <ClearFilters onClick={clearFilters} selected={selected}>
          <FilterIcon icon={selected ? `content-filter` : `content-filter-empty`} size={24} />
        </ClearFilters>
      </FilterWrapper>
      <FilterToggle light hidden={!down} expanded={expanded} onClick={toggleExpanded} />
    </RelativeWrapper>
  </FilterContainer>
