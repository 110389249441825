import React from 'react'
import styled from 'styled-components'

import { Icon } from 'hoffelijk-react-components'

import { PROGRESS_STATUS } from 'utilities/constants'

const statusIcon = status => {
  switch (status) {
    case PROGRESS_STATUS.UNKNOWN: return "question-mark"
    case PROGRESS_STATUS.BEHIND: return "snail"
    case PROGRESS_STATUS.ON_SCHEME: return "calendar-check"
    case PROGRESS_STATUS.AHEAD: return "rabbit"
    case PROGRESS_STATUS.TARGET_PASSED: return "calendar-close"
    case PROGRESS_STATUS.FINISHED: return "medal"
    default: return null
  }
}

const withSign = n =>
  n === null
    ? ''
    : n > 0 ? `+${n}` : `${n}`

const LeftIcon = styled(Icon)`
  margin-right: 10px;
  fill: currentColor;
`

const ProgressStatus = styled.span`
  color: ${props => {
    switch(props.status) {
      case PROGRESS_STATUS.UNKNOWN:       return props.theme.color.gray40
      case PROGRESS_STATUS.BEHIND:        return props.theme.color.error
      case PROGRESS_STATUS.ON_SCHEME:     return props.theme.color.warning
      case PROGRESS_STATUS.AHEAD:         return props.theme.color.success
      case PROGRESS_STATUS.TARGET_PASSED: return props.theme.color.error
      case PROGRESS_STATUS.FINISHED:      return props.theme.color.success
      default: return props.theme.color.gray40
    }
  }};

  font-weight: bold;
`

const Large = styled.span`
  display: inline-block;
  width: 20px;

  text-align: center;
  font-size: 20px;
  font-weight: normal;
`

export default ({ status, days }) =>
  <ProgressStatus status={status}>
    {status === null
      ? '-'
      : status === 0
        ? <Large>?</Large>
        : <LeftIcon size={20} icon={statusIcon(status)} />}
      {![PROGRESS_STATUS.FINISHED, PROGRESS_STATUS.UNKNOWN].includes(status)
        && withSign(days)}
  </ProgressStatus>
