import React from 'react'

import { TableBody, TableRow, TableCell } from './Table'

export default ({ columns, error }) =>
  <TableBody>
    <TableRow>
      <TableCell colSpan={columns}>
        {error}
      </TableCell>
    </TableRow>
  </TableBody>
