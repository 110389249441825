import styled, {css} from 'styled-components';
import {noAnimKeyframes} from './Input';

export const noAnim = css`
  animation: ${noAnimKeyframes} 1s;
`

export const TextArea = styled.textarea`
    display: inline-block;
    ${props => props.block && css`width: 100%;`}
    padding: 1rem;
    ${props => props.dense && css`padding: 8.5px 1rem;`}

    color: ${props => props.theme.color.text};
    font-weight: bold;

    &:focus {
        outline: none;
    }

    &:-webkit-autofill {
        -webkit-box-shadow: inset 0 0 0px 9999px ${props => props.theme.color.white};

        ${noAnim};
    }

    ${props => props.disabled && css`
        background-color: rgba(229, 231, 233, 0.5);
    `}
`;
