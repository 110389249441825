import styled, { css, keyframes } from 'styled-components'

const backgroundRoll = keyframes`
  from { background-position-x: 0%; }
  to { background-position-x: -200%; }
`

const backgroundRollAnimation = css`
  animation: ${backgroundRoll} 3s ease-in-out infinite;
`

const Loading = styled.div`
  height: 1em;
  border-radius: .25em;
  background: linear-gradient(90deg,
    ${props => props.theme.color.gray20} 40%,
    ${props => props.theme.color.gray40},
    ${props => props.theme.color.gray20} 60%) repeat left 0% top 50% / 250%;

  ${backgroundRollAnimation}
`

export default Loading
