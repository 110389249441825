import styled, { css } from 'styled-components'

import { Filter } from './Filter'
import Paper from '../Paper'

const FilterContainer = styled(Paper)`
  pointer-events: none;

  position: absolute;
  left: 0; top: 100%;

  min-width: 320px;

  opacity: 0;

  box-shadow: 0 4px 12px rgba(0, 0, 0, .1);

  transition-property: opacity, top;
  transition-duration: .2s;
  transition-timing-function: ease-out;

  &::before {
    content: '';

    position: absolute;
    left: 30px; top: -6px;

    width: 12px;
    height: 12px;

    border: 1px solid transparent;
    border-left-color: ${props => props.theme.color.gray20};
    border-top-color: ${props => props.theme.color.gray20};
    border-top-left-radius: 4px;

    box-shadow:
      6px 6px 0 6px ${props => props.theme.color.white},
      3px 3px 12px rgba(0, 0, 0, .1);

    background-color: ${props => props.theme.color.white};

    transform: rotate(45deg);
  }

  &::after {
    content: '';

    position: absolute;
    left: 0; top: -13px;
    right: 0;
    z-index: -1;

    height: 79px;
  }

  ${props => props.last && css`
    left: auto;
    right: 0;

    &::before {
      left: auto;
      right: 30px;
    }
  `}

  ${Filter}:hover & {
    pointer-events: auto;

    top: calc(100% + 12px);

    opacity: 1;
  }
`
FilterContainer.displayName = 'FilterContainer'

export default FilterContainer
