import React from 'react'
import graphql from 'babel-plugin-relay/macro'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { withOrganisation } from 'containers/Organisation'
import { withNotifications } from 'containers/Notifications'
import withQuery from 'containers/withQuery'

import { HeaderMod } from 'containers/Header'
import Loader from 'components/Loader'
import OrganisationModuleManagement from 'components/admin/organisation/OrganisationModuleManagement'

const UpdateOrganisationModule = ({ notify, history, organisation,
  organisationModule: { id, module, first_recipe, ...organisationModule } }) =>
  <HeaderMod
    backLink={`/organisation/${organisation.slug}/modules/`}
    title="Module bewerken"
  >
    <OrganisationModuleManagement
      data={{
        organisationModuleId: id,
        module: module,
        moduleHasPlanning: module.module_study.planning_recipes.length > 0,
      }}
      defaultValues={{
        ...organisationModule,
        visible_recipes: organisationModule.visible_recipes
          ? organisationModule.visible_recipes.map(({ id }) => id)
          : null,
      }}
      onSubmit={() => {
        history.push(`/organisation/${organisation.slug}/modules/`)
        notify({
          type: 'success',
          title: 'Organisatie-module succesvol bijgewerkt',
        })
      }}
    />
  </HeaderMod>

const getQuery = () => graphql`
  query editOrganisationModuleQuery($organisationModuleId: ID!) {
    organisationModule(id: $organisationModuleId) {
      id

      has_planning

      module {
        module_study {
          name
          planning_recipes {
              id
          }
          study {
            recipes {
              id
              name
              code
            }
          }
        }
      }

      visible_recipes {
        id
      }
    }
  }
`

const getVariables = ({ match }) => ({
  organisationModuleId: match.params.organisationModuleId
})

export default compose(
  withRouter,
  withOrganisation,
  withNotifications,
  withQuery({ getQuery, getVariables, loader: Loader }),
)(UpdateOrganisationModule)
