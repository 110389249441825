import React from 'react'
import graphql from 'babel-plugin-relay/macro'

import { Table } from 'hoffelijk-react-components'
import TableAction from 'components/TableAction'

const DeactivateOrganisation = ({ organisation, refetch }) =>
  <TableAction
    title="Deactiveer" icon="switch-right"
    variables={{ organisationId: organisation.id }}
    onCompleted={refetch}
    mutation={graphql`
      mutation ActionsDeactivateOrganisationMutation($organisationId: ID!) {
        deactivateOrganisation(organisationId: $organisationId) {
          id
        }
      }
    `}
  />

const ActivateOrganisation = ({ organisation, refetch }) =>
  <TableAction
    title="Activeer" icon="switch-left"
    variables={{ organisationId: organisation.id }}
    onCompleted={refetch}
    mutation={graphql`
      mutation ActionsActivateOrganisationMutation($organisationId: ID!) {
        activateOrganisation(organisationId: $organisationId) {
          id
        }
      }
    `}
  />

const DeleteOrganisation = ({ organisation, refetch }) =>
  <TableAction
    confirm={`Weet je zeker dat je ${organisation.name} wilt verwijderen?`}
    title="Verwijder" icon="bin"
    variables={{ organisationId: organisation.id }}
    onCompleted={refetch}
    mutation={graphql`
      mutation ActionsDeleteOrganisationMutation($organisationId: ID!) {
        deleteOrganisation(organisationId: $organisationId) {
          success
        }
      }
    `}
  />

const ManageOrganisationAdmins = ({ organisation }) =>
  <Table.Action
    title="Beheer verantwoordelijken"
    to={`/organisation/${organisation.slug}/admins`}
    icon="user" />

const ManageOrganisationPlanning = ({ organisation }) =>
  <Table.Action
    title="Beheer modules"
    to={`/organisation/${organisation.slug}/modules`}
    icon="time-edit" />

const EditOrganisation = ({ organisation }) =>
  <Table.Action
    title="Bewerk"
    to={`/organisation/${organisation.slug}/edit`}
    icon="pen" />

const GoToOrganisation = ({ organisation }) =>
  <Table.Action
    title="Ga naar organisatie"
    to={`/${organisation.slug}`}
    icon="arrow-right" />

export default ({ organisation, refetch }) =>
  <Table.Actions>
    {!organisation.active && <DeleteOrganisation organisation={organisation} refetch={refetch} />}
    {organisation.active && <DeactivateOrganisation organisation={organisation} refetch={refetch} />}
    {!organisation.active && <ActivateOrganisation organisation={organisation} refetch={refetch} />}
    <ManageOrganisationAdmins organisation={organisation} />
    <ManageOrganisationPlanning organisation={organisation} />
    <EditOrganisation organisation={organisation} />
    <GoToOrganisation organisation={organisation} />
  </Table.Actions>
