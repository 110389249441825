import styled from 'styled-components'

export const NoPadding = styled.div`padding: 0;`
export const NoMargin = styled.div`margin: 0;`

export const NoVerticalPadding = styled.div`
  padding-top: 0;
  padding-bottom: 0;
`

export const NoVerticalMargin = styled.div`
  margin-top: 0;
  margin-bottom: 0;
`

export const NoMarginTop = styled.div`
  margin-top: 0;
`

export const AbsoluteCentered = styled.div`
  position: absolute;
  left: 0; top: 0;
  right: 0; bottom: 0;

  margin: auto;
`

const Styles = {
  NoPadding,
  NoVerticalPadding,
  NoMargin,
  NoVerticalMargin,
  NoMarginTop,
  AbsoluteCentered,
}

export default Styles
