import React from 'react'
import { compose, withProps, withHandlers } from 'recompose'
import { withModal } from 'containers/Modal'
import { Paper, Input, Button, Float } from 'hoffelijk-react-components'
import styled from 'styled-components'
import { parseTheme } from 'containers/OrganisationTheme'

import { LoginPreview, PagePreview } from './Preview'

const colors = [
  // { label: 'Accentkleur', name: 'accent' },
  // { label: 'Primaire kleur', name: 'primary' },
  { label: 'Achtergrond', name: 'background' },
  { label: 'Bovenbalk', name: 'mainnav' },
  { label: 'Tussenbalk', name: 'subnav' },
  { label: 'Actieknoppen tussenbalk', name: 'button_dark' },
  { label: 'Actieknoppen tussenbalk (hover)', name: 'button_dark_hover' },
  { label: 'Titels', name: 'title' },
  { label: 'Titels met link (hover)', name: 'title_link' },
  { label: 'Tekst', name: 'text' },
  { label: 'Tekst met link (hover)', name: 'text_link' },
  { label: 'Iconen bovenbalk', name: 'icon_mainnav' },
  { label: 'Knoppen', name: 'button' },
  { label: 'Knoppen (hover)', name: 'button_hover' },
  { label: 'Knoppen icoon', name: 'outside_button' },
  { label: 'Knoppen icoon (hover)', name: 'outside_button_hover' },
  { label: 'Knoppen tekst', name: 'inside_button' },
  { label: 'Knoppen tekst (hover)', name: 'inside_button_hover' },
  { label: 'Footer', name: 'footer' },
]

const ThemeTitle = styled.h4`
  line-height: 40px;
  margin: 0;
`

const ClearFix = styled.div`
  clear: both;
  height: 30px;
`

const ThemeManagement = ({ theme, popup, values={}, errors={}, setValue }) =>
  <Paper.Section id="theme">
    <Float.Left>
      <ThemeTitle>3. Thema</ThemeTitle>
    </Float.Left>
    <Float.Right>
      <Button
        onClick={event => {
          event.preventDefault()
          popup(<LoginPreview organisation={{
            theme: theme.background || values.background_url
              ? { copy: theme.copy, background: theme.background || values.background_url }
              : { copy: theme.copy }}}/>, theme && (theme.background || theme.background_url)
                ? { ...theme, background: theme.background || theme.background_url } : {})
        }}
      >
        Voorbeeld loginpagina
      </Button>
      &nbsp;&nbsp;&nbsp;
      <Button
        onClick={event => {
          event.preventDefault()
          popup(<PagePreview organisation={{
            theme: theme.logo || values.logo_url
              ? { hide_footer: theme.hide_footer, logo: theme.logo || values.logo_url }
              : { hide_footer: theme.hide_footer }}}/>, theme)
        }}
      >
        Voorbeeld docentenpagina
      </Button>
    </Float.Right>
    <ClearFix />
    <Input.Group>
      <Input.Image
        label="Bedrijfslogo"
        info="Max 100kb"
        defaultValue={values.logo_url}
        error={errors.logo}
        onChange={v => setValue('logo', v)}
      />
      {values.logo_url && !values.logo &&
        <Button
          onClick={event => {
            event.preventDefault()
            setValue('clear_logo', true)
            setValue('logo', null)
            setValue('logo_url', null)
          }}
        >
          Wis logo
        </Button>
      }
      <br />
      <Input.Image
        label="Achtergrondafbeelding"
        info="Voor het loginscherm, Max 1MB"
        defaultValue={values.background_url}
        error={errors.background}
        onChange={v => setValue('background', v)}
      />
      {values.background_url && !values.background &&
        <Button
          onClick={event => {
            event.preventDefault()
            setValue('clear_background', true)
            setValue('background', null)
            setValue('background_url', null)
          }}
        >
          Wis achtergrond
        </Button>
      }
      <br />
      <Input
        type="checkbox"
        label="Verberg footer"
        checked={values.hide_footer}
        error={errors.hide_footer}
        onChange={v => setValue('hide_footer', v)}
        block
      />
      <Input
        type="checkbox"
        label="Gradient uitschakelen"
        checked={values.gradient_disabled}
        error={errors.gradient_disabled}
        onChange={v => setValue('gradient_disabled', v)}
        block
      />
      <br />
      <Input
        label="Login tekst"
        value={values.copy}
        error={errors.copy}
        onChange={v => setValue('copy', v)}
        block
      />
      {colors.map(({ label, name }) =>
        <Input
          key={name}
          type="color"
          label={label}
          value={values[`color_${name}`]}
          error={errors[`color_${name}`]}
          onChange={v => setValue(`color_${name}`, (typeof v === 'string') ? v.toLowerCase().trim() : null)}
        />
      )}
    </Input.Group>
  </Paper.Section>

export default compose(
  withModal,
  withHandlers({
    setValue: ({ updateValue }) => (key, value) =>
      updateValue(values => ({
        ...values,
        [key]: value
      }))
  }),
  withProps(({ values }) => ({
    theme: {
      ...parseTheme(values || {}),
      ...(values.logo
        ? { logo: `data:${values.logo.mimetype};base64,${values.logo.data}` }
        : {}),
      ...(values.background
        ? { background: `data:${values.background.mimetype};base64,${values.background.data}` }
        : {}),
    }
  })),
)(ThemeManagement)
