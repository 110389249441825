import React from 'react'
import styled, { css } from 'styled-components'
import { darken } from 'polished'
import { onlyUpdateForKeys } from 'recompose'

import { Link } from 'react-router-dom'
import Icon from '../Icon'

const TableActionIcon = styled(Icon)`
  pointer-events: none;
  color: ${props => props.theme.color.button};
`

const TableAction = styled.span`
  position: relative;

  cursor: pointer;
  display: inline-block;
  margin-left: 10px;

  ${props => props.title && css`
    &::before, &::after {
      pointer-events: none;

      position: absolute;
      right: -20px;

      opacity: 0;

      transform: translate(0, 8px);

      transition:
        opacity .2s ease-out,
        transform .2s ease-out;
    }

    &::before {
      content: attr(title);

      bottom: calc(100% +  8px);

      padding: 4px 8px;
      border-radius: 4px;

      white-space: nowrap;

      background-color: ${props => props.theme.color.text};
      color: ${props => props.theme.color.white};
    }

    &::after {
      content: '';

      bottom: 100%;
      right: 6px;

      border: 4px solid transparent;
      border-top-color: ${props => props.theme.color.text};
    }

    &:hover {
      &::before, &::after {
        opacity: 1;
        transform: translate(0, 0);
      }
    }
  `}

  &:hover {
    ${TableActionIcon} {
      color: ${props => darken(.2, props.theme.color.button)};
    }
  }

  ${props => props.disabled && css`
    pointer-events: none;
    opacity: .5;
  `}
`

export default onlyUpdateForKeys(['disabled', 'onClick'])(({ title, icon, onClick, to, href, target, className, disabled }) => {
  if (to) {
    return <Link to={to} target={target}>
      <TableAction disabled={disabled} title={title} onClick={onClick}>
        <TableActionIcon className={className} icon={icon} size={20}/>
      </TableAction>
    </Link>;
  }

  if (href) {
    return <a href={href} target={target}>
      <TableAction disabled={disabled} title={title} onClick={onClick}>
          <TableActionIcon className={className} icon={icon} size={20}/>
      </TableAction>
    </a>;
  } else {
    return <TableAction disabled={disabled} title={title} onClick={onClick}>
      <TableActionIcon className={className} icon={icon} size={20}/>
    </TableAction>;
  }
})
