import React from 'react'
import styled, { css } from 'styled-components'
import graphql from 'babel-plugin-relay/macro'

import withForm from 'containers/withForm'

import { Paper, Input, Button, Align } from 'hoffelijk-react-components'

const Name = styled.h2`
  display: inline-block;
  width: calc(100% - 130px - 30px);
  margin: 45px 0;

  ${props => props.empty && css`
    &::after {
      content: 'Naam';
      opacity: ${props => props.theme.color.gray60};
    }
  `}
`

const Col = styled.div`
  float: left;
  width: ${props => 100 * props.size / 12}%;
`

const AccountManagement = ({ data, values, errors, disabled, onSubmit, updateValue, editableRoles=[] }) =>
  <form>
    <Paper light>
      <Paper.Section>
        <Input.Avatar
          defaultValue={values.avatar_url}
          placeholder={
            (values.first_name ? values.first_name.substr(0, 1) : '') +
            (values.last_name ? values.last_name.substr(0, 1) : '')}
          error={errors.avatar}
          onChange={v => updateValue('avatar', v)}
        />
        <Name
          empty={!(values.first_name || values.middle_name || values.last_name)}
        >{values.first_name} {values.middle_name} {values.last_name}</Name>
      </Paper.Section>
      <Paper.Section>
        <Input.Group>
          <Col size={4}>
            <Input
              required
              type="name"
              label="Voornaam"
              value={values.first_name}
              error={errors.first_name}
              onChange={v => updateValue('first_name', v)}
              block />
          </Col>
          <Col size={3}>
            <Input
              type="text"
              label="Tussenvoegsel"
              value={values.middle_name}
              error={errors.middle_name}
              onChange={v => updateValue('middle_name', v)}
              block />
          </Col>
          <Col size={5}>
            <Input
              required
              type="name"
              label="Achternaam"
              value={values.last_name}
              error={errors.last_name}
              onChange={v => updateValue('last_name', v)}
              block />
          </Col>
          <Col size={12}>
            <Input
              required
              type="email"
              label="E-mailadres"
              value={values.email}
              error={errors.email}
              onChange={v => updateValue('email', v)}
              block
            />
          </Col>
        </Input.Group>
      </Paper.Section>
      {(!data || !data.userId) &&
        <Paper.Section>
          <Input.Group>
            <Input
              type="checkbox"
              label="Account direct activeren"
              checked={values.activate}
              error={errors.activate}
              onChange={v => updateValue('activate', v)}
              block
            />
          </Input.Group>
        </Paper.Section>}
      {editableRoles.length > 0 &&
        <Paper.Section>
          {editableRoles.includes('admin') &&
            <Input
              type="checkbox"
              label="Organisatiebeheerder"
              checked={values.is_admin}
              error={errors.is_admin}
              onChange={v => updateValue('is_admin', v)}
              block
            />}
          {editableRoles.includes('mainadmin') &&
            <Input
              type="checkbox"
              label="Gebruiker is ook hoofdbeheerder"
              checked={values.is_main_admin}
              error={errors.is_main_admin}
              onChange={v => updateValue('is_main_admin', v)}
              block
            />}
          {editableRoles.includes('superadmin') &&
            <Input
              type="checkbox"
              label="Hoofdbeheerder: mag hoofdbeheer accounts aanmaken"
              checked={values.is_super_admin}
              error={errors.is_super_admin}
              onChange={v => updateValue('is_super_admin', v)}
              block
            />}
        </Paper.Section>}
    </Paper>
    <Align.Center>
      <Button under onClick={onSubmit} disabled={disabled}>Wijzigingen opslaan</Button>
    </Align.Center>
  </form>

const getMutation = ({ userId }) => userId
  ? graphql`
      mutation AccountManagementEditMutation($userId: ID!, $input: UserInput!) {
        updateUser(userId: $userId, input: $input) {
          id
        }
      }
    `
  : graphql`
      mutation AccountManagementAddMutation($organisationId: ID, $input: UserInput!) {
        createUser(organisationId: $organisationId, input: $input) {
          id
        }
      }
    `

export default withForm({
  getMutation,
  getVariables: ({ userId, organisationId }) => ({ avatar_url, ...input }) => ({
    userId, organisationId, input,
  }),
  // getVariables: ({ userId }) => ({ avatar, ...input }) => ({
  //   userId, input,
  // }),
  // getUploadables: () => ({ avatar }) => avatar
  //   ? { avatar } : {}
})(AccountManagement)
