import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import tables from './tables'

export const reducer = (history) => combineReducers({
  router: connectRouter(history),
  tables,
})

export default reducer
