import React from 'react'

export default ({ width, height, fill, className }) =>
	<svg height={height} width={width} className={className} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<g className="nc-icon-wrapper" fill={fill}>
			<path id="Frame_-_24px" d="M0 0h24v24H0z" fill="none"/>
			<g id="Line_Icons">
				<path d="M13.001 11c0-2.757-2.243-5-5-5s-5 2.243-5 5a5.007 5.007 0 0 0 4 4.898V19h-2v2h2v2h2v-2h2v-2h-2v-3.102a5.007 5.007 0 0 0 4-4.898zm-8 0c0-1.654 1.346-3 3-3s3 1.346 3 3-1.346 3-3 3-3-1.346-3-3z" fill={fill}/>
				<path d="M15.001 2v2h3.586l-2.833 2.833A4.974 4.974 0 0 0 13.001 6v2c1.654 0 3 1.346 3 3s-1.346 3-3 3v2c2.757 0 5-2.243 5-5a4.964 4.964 0 0 0-.833-2.753l2.833-2.833V9h2V2h-7z" fill={fill}/>
			</g>
		</g>
	</svg>
