import React from 'react'
import { compose } from 'recompose'
import graphql from 'babel-plugin-relay/macro'
import withForm from 'containers/withForm'
import withQuery from 'containers/withQuery'

import ModuleManagement from './ModuleManagement'
import ThemeManagement from './ThemeManagement'
import VocabularyManagement from './VocabularyManagement'

import Loader from 'components/Loader'
import { Align, Paper, Input, Button } from 'hoffelijk-react-components'

const OrganisationManagement = ({ values, errors, onSubmit, disabled, updateValue, setValues, remindoOrganisations }) =>
  <form>
    <Paper light>
      <Paper.Section id="general">
        <h4>1. Algemene gegevens</h4>
        <Input
          required
          label="Naam"
          value={values.name}
          error={errors.name}
          onChange={v => updateValue('name', v)}
          block
        />
        <br />
        <Input
          type="checkbox"
          label="Actieve studenten weergeven"
          checked={values.show_students_active}
          error={errors.show_students_active}
          onChange={v => updateValue('show_students_active', v)}
          block
        />
        <Input
          type="checkbox"
          label="Inactieve studenten weergeven"
          checked={values.show_students_inactive}
          error={errors.show_students_inactive}
          onChange={v => updateValue('show_students_inactive', v)}
          block
        />
        <Input
          type="checkbox"
          label="Demo studenten weergeven"
          checked={values.show_students_demo}
          error={errors.show_students_demo}
          onChange={v => updateValue('show_students_demo', v)}
          block
        />
        <Input
          type="checkbox"
          label="Gearchiveerde modules weergeven"
          checked={values.show_modules_archived}
          error={errors.show_modules_archived}
          onChange={v => updateValue('show_modules_archived', v)}
          block
        />
        <br />
        <Input.Group>
          <Input
            type="select"
            label="Koppelingsveld groep"
            required
            options={[
              { label: 'Contactpersoon', value: 'invoice_contact' },
              { label: 'Interne code', value: 'code' },
              { label: 'Factuurkenmerk', value: 'code_invoice' },
              { label: 'Debiteurenkenmerk', value: 'code_debtor' },
              { label: 'Contractnummer', value: 'code_contract' },
              { label: 'Projectkenmerk', value: 'code_project' },
              { label: 'Groep', value: 'groupname' },
            ]}
            value={values.connecting_field_group}
            error={errors.connecting_field_group}
            onChange={v => updateValue('connecting_field_group', v)}
            block
          />
        </Input.Group>
      </Paper.Section>
      <Paper.Section id="remindo">
        <h4>2. Remindo Content gegevens</h4>
        <Input
          type="checkbox"
          label="Omgeving is een module omgeving"
          checked={values.is_module_environment}
          error={errors.is_module_environment}
          onChange={v => {
            updateValue('is_module_environment', v)
            if (v) { updateValue('remindo_organisations', []) }
            else { updateValue('modules', []) }
          }}
          block
        />
        <br />
        {!values.is_module_environment &&
          <Input
            type="select"
            label="Remindo organisaties"
            multi
            options={remindoOrganisations.map(({ id, name }) => ({
              label: `${name} (${id})`,
              value: id,
            }))}
            value={values.remindo_organisations}
            error={errors.remindo_organisations}
            onChange={v => updateValue('remindo_organisations', v)}
            block
          />}
        <br />
        <ModuleManagement
          remindoOrganisationIds={values.is_module_environment
            ? remindoOrganisations.map(({ id }) => id)
            : values.remindo_organisations}
          value={values.modules}
          error={errors.modules}
          updateValue={v => updateValue('modules', v)}
        />
      </Paper.Section>
      <ThemeManagement
        values={values.theme ? values.theme : {}}
        errors={errors.theme ? errors.theme : {}}
        updateValue={v => updateValue('theme', v)}
      />
      <VocabularyManagement
        values={values.vocabulary ? values.vocabulary : {}}
        errors={errors.vocabulary ? errors.vocabulary : {}}
        updateValue={v => updateValue('vocabulary', v)}
      />
    </Paper>
    <Align.Center>
      <Button under onClick={onSubmit} disabled={disabled}>Wijzigingen opslaan</Button>
    </Align.Center>
  </form>

const getQuery = () => graphql`
  query OrganisationManagementQuery {
    remindoOrganisations {
      id
      name
    }
  }
`

const getMutation = ({ organisationId }) => organisationId
  ? graphql`
      mutation OrganisationManagementUpdateMutation($organisationId: ID!, $input: OrganisationInput!) {
        updateOrganisation(organisationId: $organisationId, input: $input) {
          id
        }
      }
    `
  : graphql`
      mutation OrganisationManagementCreateMutation($input: OrganisationInput!) {
        createOrganisation(input: $input) {
          id
        }
      }
    `

const clearPlaceholders = ({ background_url, logo_url, ...theme }) => theme

const getVariables = ({ organisationId }) => ({ theme, ...input }) => ({
  organisationId, input: {
    ...input,
    theme: theme ? clearPlaceholders(theme) : null,
  },
})

export default compose(
  withQuery({ getQuery, loader: Loader }),
  withForm({ getMutation, getVariables }),
)(OrganisationManagement)
