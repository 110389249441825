/**
 * @generated SignedSource<<5e43b6097926c88187f8896b27276d33>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "userId",
        "variableName": "userId"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "refreshUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ActionsSendActivationMailAdminMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ActionsSendActivationMailAdminMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7b69529a543ed60091ca956624b201a9",
    "id": null,
    "metadata": {},
    "name": "ActionsSendActivationMailAdminMutation",
    "operationKind": "mutation",
    "text": "mutation ActionsSendActivationMailAdminMutation(\n  $userId: ID!\n) {\n  refreshUser(userId: $userId) {\n    id\n  }\n}\n"
  }
};
})();

node.hash = "1596d9914219854af11d2c1e09a0759b";

module.exports = node;
