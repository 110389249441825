import React from 'react'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { onlyUpdateForKeys } from 'recompose'

import { Wrapper, Row, Column, Icon } from 'hoffelijk-react-components'

const Footer = styled.footer`
  padding: 30px 0;
  margin-top: 30px;

  background: ${props => props.theme.color.footer };

  color: ${props => transparentize(.4, props.theme.color.text)};

  a:hover {
    color: ${props => props.theme.color.text_link};
  }

  h1, h2, h3, h4, h5, h6 {
    color: ${props => transparentize(.5, props.theme.color.title)};

    & a:hover {
      color: ${props => props.theme.color.title_link};
    }
  }
`

const ContactLinkWrapper = styled.div`
  display: flex;
`

const ContactLink = styled.a`
  display: flex;
  align-items: center;
  margin-right: 1em;
  white-space: nowrap;
`

const ContactLinkText = styled.div`
  margin: auto 0;
`

const ExternalLink = styled.a`
  width: 198px;
  margin-bottom: 1em;
`

const ContactIcon = styled(Icon)`
  margin-right: 10px;
  fill: ${props => props.theme.color.button};
`

const MailIcon = styled(ContactIcon)`
  margin-right: 10px;
  margin-top: -2px;
  fill: ${props => props.theme.color.button};
`

const RightIcon = styled(Icon)`
  float: right;
  display: inline-block;
  margin-left: 10px;
  fill: ${props => props.theme.color.button};
`

const LinkRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 0;
`

const LinkColumn = styled.div`
  display: flex;
  flex-flow: column;
`

const linkCols = [
    [
        {href: 'https://hoffelijkgroep.nl/', label: 'Hoffelijk Groep'},
        {href: 'https://juridisch.hoffelijk.nl/', label: 'Hoffelijk Juridisch'},
        {href: 'https://www.hoffelijkgroep.nl/werken-bij/', label: 'Werken bij Hoffelijk'},
    ],
    [
        {href: 'https://financieel.hoffelijk.nl/', label: 'Hoffelijk Financieel'},
        {href: 'https://hoffelijk.nl/helpinghero/', label: 'Helping Hero'},
    ],
    [
        {href: 'https://financielezorg.hoffelijk.nl/', label: 'Financiële zorg'},
        {href: 'https://www.hoffelijkgroep.nl/nieuws/', label: 'Hoffelijk Nieuws'},
    ]
]

export default onlyUpdateForKeys([])(() =>
  <Footer>
    <Wrapper>
      <Row>
        <Column size={4} mSize={12}>
          <h3>Heb je vragen?</h3>
          <p>Je kunt ons bereiken van 8:00 tot 17:00 uur, neem contact op via:</p>
          <ContactLinkWrapper>
            <ContactLink href="tel:0107601100" target="_blank" rel="noopener noreferrer">
              <ContactIcon icon="phone-outgoing" size={18} />
              <ContactLinkText>010 - 760 11 00</ContactLinkText>
            </ContactLink>
            <ContactLink href="mailto:info@lindenhaeghe.nl" target="_blank" rel="noopener noreferrer">
              <MailIcon icon="email-envelope" size={24} />
              <ContactLinkText>info@lindenhaeghe.nl</ContactLinkText>
            </ContactLink>
          </ContactLinkWrapper>
        </Column>
        <Column size={8} mSize={12}>
          <h3>Andere websites van Lindenhaeghe</h3>
          <LinkRow>
            {linkCols.map((links, colIndex) =>
              <LinkColumn key={colIndex}>
                {links.map(({ href, label }, linkIndex) =>
                  <ExternalLink key={`${colIndex}:${linkIndex}`} href={href} target="_blank" rel="noopener noreferrer">
                    {label}
                    <RightIcon icon="arrow-right" size={20} />
                  </ExternalLink>
                )}
              </LinkColumn>
            )}
          </LinkRow>
        </Column>
      </Row>
    </Wrapper>
  </Footer>)
