import React from 'react'

export default ({ width, height, fill, className }) =>
	<svg height={height} width={width} className={className} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<g fill={fill}>
			<path id="Frame_-_24px" d="M0-.001h24v24H0z" fill="none"/>
			<g id="Line_Icons">
				<path d="M15.707 10.707l-1.414-1.414L12 11.586 9.707 9.293l-1.414 1.414L10.586 13l-2.293 2.293 1.414 1.414L12 14.414l2.293 2.293 1.414-1.414L13.414 13z" fill={fill}/>
				<path d="M21 3h-3V1h-2v6h2V5h3v15H3V5h2V3H3a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h18a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2z" fill={fill}/>
				<path d="M15 3H8V1H6v6h2V5h7z" fill={fill}/>
			</g>
		</g>
	</svg>
