import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import styled from 'styled-components';
import moment from 'moment';
import { compose } from 'recompose';

import withForm from 'containers/withForm';

import {Paper, Input, Styles, Align, Button, Icon} from 'hoffelijk-react-components';
import withQuery from '../../../containers/withQuery';
import ConfirmModal from '../../ConfirmModal';
import { withModal } from '../../../containers/Modal';
import { useMutation } from 'react-relay';

const NoteContainer = styled.div`
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 1em;
    
    position: relative;
    
    display: flex;
    flex-flow: row nowrap;
    gap: 7.5px;
`;

const Note = styled.p`
    flex-grow: 1;
    margin: 0;
`;

const CreatedAt = styled.small`
    font-style: italic;
`;

const StyledButton = styled(Button)`
    margin-top: 29px;
`

const DeleteIconWrapper = styled.a`
    display: block;
`

const CommentDialog = ({ onSubmit, openCommentDialog, popup, student, disabled, updateValue, values }) => {
    const [ commit ] = useMutation(
        graphql`
            mutation CommentDialogDeleteMutation($noteId: ID!) {
                deleteNote(noteId: $noteId) {
                    success
                }
            }
        `
    );

    const deleteNote = (id) => {
        commit({
            variables: {
                noteId: id,
            },
            // Our popup() function can't handle multiple levels of open popups, so we
            // need to re-open our comment dialog after the confirmation popup.
            onCompleted: openCommentDialog,
        });
    };

    const onDelete = (e, popup, id) => {
        e.preventDefault();

        popup(
            <ConfirmModal
                message={'Weet je zeker dat je deze notitie wil verwijderen?'}
                buttonMessage={'Verwijder notitie'}
                onSubmit={() => deleteNote(id)}
                onCancel={openCommentDialog}
            />
        );
    };

    return (
        <form>
            <Paper light>
                <Paper.Section>
                    <Styles.NoMargin as="h2">Notities voor {student.first_name} {student.middle_name} {student.last_name}</Styles.NoMargin>
                </Paper.Section>
                <Paper.Section>
                    <Input
                        type="textarea"
                        label="Notities"
                        block
                        onChange={v => updateValue('notes', v)}
                        value={values.notes} />
                    <Align.Right>
                        <StyledButton onClick={onSubmit} disabled={disabled}>Notities opslaan</StyledButton>
                    </Align.Right>
                </Paper.Section>
                {student.student_notes.length > 0 && <Paper.Section>
                    {student.student_notes.slice(0, 10).map((note, index) => {
                        return (<NoteContainer key={index.toString()}>
                            <Note>{note.note}</Note>
                            <CreatedAt>{moment(note.created_at * 1000).format('YYYY-MM-DD HH:mm:ss')}</CreatedAt>
                            <DeleteIconWrapper href={'#'} title={'Verwijderen'} onClick={(e) => onDelete(e, popup, note.id)}>
                                <Icon icon={'bin'} size={16} />
                            </DeleteIconWrapper>
                        </NoteContainer>);
                    })}
                </Paper.Section>}
            </Paper>
        </form>
    );
};

const getQuery = () => graphql`
query CommentDialogQuery($studentId: ID!) {
    student(studentId: $studentId) {
        first_name
        middle_name
        last_name
        student_notes {
            id
            note
            created_at
        }
    }
}
`;

const getVariables = ({student}) => ({studentId: student.id})

const getMutation = () => graphql`
mutation CommentDialogMutation($studentId: ID!, $input: AddNotesInput!) {
    addNotes(studentId: $studentId, input: $input) {
        id
    }
}
`;

export default compose(
    withModal,
    withForm(
        {
            getMutation,
            getVariables: ({ studentId }) => ({notes}) => ({studentId, input: {notes: notes}}),
        }
    ),
    withQuery({getQuery,  getVariables}),
)(CommentDialog);

