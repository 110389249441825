export const isSuperAdmin = user =>
  user &&
  user.roles.includes('superadmin')

export const isMainAdmin = user =>
  user &&
  user.roles.includes('mainadmin')

export const isAdmin = (user, organisation) =>
  user &&
  user.roles.includes('admin') &&
  organisation &&
  user.organisation.id === organisation.id

export const isTeacher = (user, organisation) =>
  user &&
  user.roles.includes('teacher') &&
  organisation &&
  user.organisation.id === organisation.id
