/**
 * @generated SignedSource<<75f4ba9b274326b52804da5cb244d286>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "moduleStudyId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      },
      {
        "kind": "Variable",
        "name": "moduleStudyId",
        "variableName": "moduleStudyId"
      }
    ],
    "concreteType": "ModuleStudy",
    "kind": "LinkedField",
    "name": "updateModuleStudy",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ModuleStudyManagementUpdateMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ModuleStudyManagementUpdateMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "d351a213c857f14666b5eb052bf63dac",
    "id": null,
    "metadata": {},
    "name": "ModuleStudyManagementUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation ModuleStudyManagementUpdateMutation(\n  $moduleStudyId: ID!\n  $input: ModuleStudyInput!\n) {\n  updateModuleStudy(moduleStudyId: $moduleStudyId, input: $input) {\n    id\n  }\n}\n"
  }
};
})();

node.hash = "3d2bd03f4514b83b4083e64699adc6c4";

module.exports = node;
