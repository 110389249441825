import React, { Component } from 'react'
import { pure } from 'recompose'

const { Consumer, Provider } = React.createContext(null)

export class ModalProvider extends Component {
  state = {
    open: false,
    theme: {},
    actions: [],
    content: null,
  }

  popup = (content, theme={}, actions=[]) =>
    this.setState({
      open: true,
      theme,
      actions,
      content,
    })

  close = () =>
    this.setState({
      open: false,
      content: null,
    })

  render = () =>
    <Provider
      value={{
        ...this.state,
        popup: this.popup,
        close: this.close,
      }}
      {...this.props}
    />
}

export const withModal = WrappedComponent => props => {
  const PureWrappedComponent = pure(WrappedComponent)

  return (
    <Consumer>
      {({ popup, close }) =>
        <PureWrappedComponent
          {...props}
          popup={popup}
          close={close}
        />}
    </Consumer>
  )
}

export const withModalState = WrappedComponent => props =>
  <Consumer>
    {modalProps =>
      <WrappedComponent
        {...props}
        {...modalProps}
      />}
  </Consumer>

export default {
  ModalProvider,
  withModal,
  withModalState,
}
