import React from 'react'

import { TableBody } from './Table'
import GenericRow from './GenericRow'

const ActiveState = ({
  columns, data,
  getRowVariables,
  expandable, expandedContent, onToggleExpanded, dense
}) =>
  <TableBody>
    {data.map((row, i) =>
      <GenericRow
        key={row.key}
        row={row}
        columns={columns}
        dense={dense}
        expandable={expandable}
        expandedContent={expandedContent}
        onToggleExpanded={onToggleExpanded}
        getVariables={getRowVariables}
      />
    )}
  </TableBody>

export default ActiveState
