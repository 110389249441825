import React from 'react'
import { QueryRenderer } from 'react-relay'
import { withEnvironment } from 'containers/Authentication'

const Renderer = ({
  environment,
  query,
  variables={},
  component: Component,
  loader: Loader,
  error: Error,
  ...rest
}) =>
  <QueryRenderer
    dataFrom="STORE_THEN_NETWORK"
    environment={environment}
    query={query}
    variables={variables}
    render={({ error, props, retry }) => error
      ? <Error {...rest} error={error} retry={retry} />
      : props
        ? <Component {...rest} {...props} retry={retry} />
        : <Loader {...rest} loading />}
  />

const RendererWithEnv = withEnvironment(Renderer)

export default ({
  getQuery=(() => null),
  getVariables=(() => ({})),
  loader,
  error,
}) => component => props =>
  <RendererWithEnv
    query={getQuery(props)}
    variables={getVariables(props)}
    loader={loader ? loader : component}
    error={error ? error : component}
    component={component}
    {...props}
  />
