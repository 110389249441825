import React, { Component } from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { withAccessToken } from 'containers/Authentication'
import { withOrganisation } from 'containers/Organisation'
import Outside from 'layouts/outside'

class Logout extends Component {
  componentDidMount() {
    const { setAccessToken, history, organisation } = this.props

    setAccessToken(null)
    history.push(organisation ? `/${organisation.slug}` : `/`)
  }

  render = () => (
    <Outside>
      <h1>Uitloggen...</h1>
    </Outside>
  )
}

export default compose(
  withRouter,
  withAccessToken,
  withOrganisation,
)(Logout)
