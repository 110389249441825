import styled from 'styled-components'

export default styled.div`
  margin-left: -${props => props.theme.column.gap}px;
  margin-right: -${props => props.theme.column.gap}px;

  &::after {
    content: '';

    display: block;
    clear: both;
  }
`
