import React from 'react'
import styled from 'styled-components'
import { repeat } from 'utilities/logic'

import { TableBody, TableRow, TableCell } from './Table'
import Loading from '../Loading'

const CellLoading = styled(Loading)`
  width: calc(100% - 1em);
  height: 1em;
`

export default ({ rows=3, columns }) =>
  <TableBody>
    {repeat(rows)(() =>
      <TableRow>
        {columns.map((_, i) =>
          <TableCell key={i}><CellLoading /></TableCell>
        )}
      </TableRow>
    )}
  </TableBody>
