import React from 'react'
import styled, { css, keyframes } from 'styled-components'

import Paper from '../Paper'

const fadeIn = keyframes`
  from { transform: translate(40px, 0); }
  to   { transform: translate(0, 0); }
`

const fadeInAnimation = css`
  animation: ${fadeIn} .2s ease-out;
`

export const Notification = styled(Paper)`
  position: relative;

  padding-right: 60px;

  box-shadow: 0 8px 24px rgba(0, 0, 0, .2);

  background-color: ${props => {
    switch (props.type) {
      case 'error': return props.theme.color.error
      case 'success': return props.theme.color.success
      default: return props.theme.color.text
    }
  }};
  color: ${props => props.theme.color.white};

  ${fadeInAnimation};
`

const Content = styled(Paper.Section)`
  padding-top: 10px;
  padding-bottom: 10px;
  word-break: break-word;
`

const Title = styled.h3`margin: 14px 0`

const Action = styled.div`
  cursor: pointer;

  position: absolute;
  top: 0;
  right: 0; bottom: 0;

  width: 60px;
  border-radius: 0 3px 3px 0;

  &:hover {
    background-color: rgba(0, 0, 0, .2);
  }

  &::before,
  &::after {
    content: '';

    position: absolute;
    left: 0; top: 0;
    right: 0; bottom: 0;

    width: 14px;
    height: 2px;
    margin: auto;

    background-color: currentColor;
  }

  &::before { transform: rotate(45deg); }
  &::after { transform: rotate(-45deg); }
`

const Text = styled.p`
  max-height: calc(100vh - 10em);
  overflow-y: auto;
`

export default ({ type, action, title, content, onClose }) =>
  <Notification type={type}>
    <Content>
      {!!title && <Title>{title}</Title>}
      {!!content && <Text>{content}</Text>}
    </Content>
    <Action action={action} onClick={onClose} />
  </Notification>
