import React from 'react'
import styled from 'styled-components'

const HoverContent = styled.span`
  pointer-events: none;

  position: absolute;
  left: 0; bottom: 2em;
  z-index: 2;

  padding: 14px;
  border-radius: 4px;

  white-space: nowrap;

  background-color: ${props => props.theme.color.text};
  color: ${props => props.theme.color.white};

  opacity: 0;
  transform: translate(0, 8px);

  transition:
    opacity .2s ease-out,
    transform .2s ease-out;

  &::before {
    content: '';

    position: absolute;
    left: 16px;
    bottom: -8px;

    border: 4px solid transparent;
    border-top-color: ${props => props.theme.color.text};
  }
`

const WithHover = styled.span`
  position: relative;
  &:hover {
    & ${HoverContent} {
      opacity: 1;
      transform: none;
    }
  }
`

export default ({ hover, children, rest}) =>
  <WithHover {...rest}>
    <HoverContent>{hover}</HoverContent>
    {children}
  </WithHover>
