/**
 * @generated SignedSource<<1814a055379aa379a0ff62f8f5e1c36f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Organisation",
    "kind": "LinkedField",
    "name": "createOrganisation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganisationManagementCreateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrganisationManagementCreateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "2fe69450744cbb748a78e02733f1d0c8",
    "id": null,
    "metadata": {},
    "name": "OrganisationManagementCreateMutation",
    "operationKind": "mutation",
    "text": "mutation OrganisationManagementCreateMutation(\n  $input: OrganisationInput!\n) {\n  createOrganisation(input: $input) {\n    id\n  }\n}\n"
  }
};
})();

node.hash = "41d0ffea1162893c789097a17a3423a0";

module.exports = node;
