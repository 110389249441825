import styled, { css, keyframes } from 'styled-components'

import Icon from '../Icon'

export const noAnimKeyframes = keyframes`
  from {}
  to {}
`

export const noAnim = css`
  animation: ${noAnimKeyframes} 1s;
`

export const InputWrapper = styled.div`
  display: ${props => props.block ? `block` : `inline-block`};
`

export const Label = styled.label`
  display: block;
  vertical-align: top;
  position: relative;

  border: 2px solid ${props => props.theme.color.gray20};
  border-radius: ${props => props.theme.borderRadius}px;

  white-space: nowrap;

  background-color: ${props => props.theme.color.white};

  transition: border-color .2s ease-out;

  ${props => props.type === 'checkbox' && css`
    border: none;

    ${InputContainer} {
      ${'' /* width: 24px; */}
    }

    ${LabelText} {
      position: static;
      transform: none;
    }
  `}

  ${props => props.error && css`
    border-color: ${props.theme.color.error};

    ${BeforeIconContainer} {
      border-right-color: ${props.theme.color.error};
    }

    ${LabelText} {
      color: ${props.theme.color.error};
    }
  `}

  ${props => props.focussed && css`
    border-color: ${props.theme.color.text};

    ${BeforeIconContainer} {
      border-right-color: ${props.theme.color.text};
    }

    ${LabelText} {
      color: ${props.theme.color.text};
    }
  `}

  ${'' /* ${props => props.required && css`
    &::before {
      content: '*';

      position: absolute;
      top: .3em;
      right: .5em;
      z-index: 1;

      font-weight: bold;
      font-size: 1.25em;

      color: ${props => props.theme.color.button};
    }
  `} */}
`

export const InputError = styled.span`
  display: inline-block;
  padding: .5em 1.15em;

  font-size: .8em;

  color: ${props => props.theme.color.error};
`

export const IconContainer = styled.div`
  display: inline-block;
  width: 3.875rem;
  height: 3.1875rem;
  padding: .96875rem 0;

  text-align: center;

  ${props => props.dense && css`
    height: 2.25rem;
    padding: .5rem 0;
  `}
`

export const BeforeIconContainer = styled(IconContainer)`
  border-right: 2px solid ${props => props.theme.color.gray20};

  background-color: ${props => props.theme.color.gray10};
`

export const BeforeIcon = styled(Icon)`
  color: ${props => props.theme.color.button};
`

export const InputContainer = styled.div`
  position: relative;

  display: inline-block;

  width: 100%;
  ${BeforeIconContainer} + & { width: calc(100% - 3.875rem); }
  ${props => props.hasAfterIcon && css`
    width: calc(100% - 3.875rem);
    ${BeforeIconContainer} + & { width: calc(100% - 7.75rem); }
  `}
`

export const AfterIconContainer = styled(IconContainer)`
  ${props => props.onClick && css`
    cursor: pointer;
  `}
`

export const AfterIcon = styled(Icon)`
  color: ${props => props.theme.color.gray40};
`

export const LabelText = styled.span`
  pointer-events: none;

  position: absolute;
  left: .5rem;
  top: 50%;

  ${props => props.up && css`
    top: 0;
  `}

  display: inline-block;
  padding: 0 .5rem;

  color: ${props => props.theme.color.gray40};
  background-color: ${props => props.theme.color.white};

  transition: top .2s ease-out;

  transform: translate(0, -50%);
`

export const Info = styled.span`
  font-size: .8em;
  vertical-align: .1em;
`

export const Input = styled.input`
  &[type="search"] { width: 256px; }

  display: inline-block;
  ${props => props.block && css`width: 100%;`}
  padding: 1rem;
  ${props => props.dense && css`padding: 8.5px 1rem;`}

  color: ${props => props.theme.color.text};
  font-weight: bold;

  &[type="color"] {
  	appearance: none;
  	border: none;
  	width: 32px;
  	height: 20px;
  	padding: 0;

    &::-webkit-color-swatch-wrapper {
    	padding: 0;
    }

    &::-webkit-color-swatch {
    	border: none;
    	border-radius: 4px;
    }
  }

  &[type="checkbox"] {
    appearance: none;

    display: inline-block;
    width: 16px;
    height: 16px;
    padding: 0;

    border: 2px solid;
    border-radius: 4px;

    font-size: 10px;
    text-align: center;
    line-height: 12px;

    color: #adc0bd;

    &::after {
      content: '✔';
      color: transparent;
    }

    &:checked::after {
      color: inherit;
    }

    ${props => props.disabled && css`
      opacity: .5;
      background-color: currentColor;
    `}
  }

  &:focus {
    outline: none;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: inset 0 0 0px 9999px ${props => props.theme.color.white};

    ${noAnim};
  }
`
