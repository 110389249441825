import React from 'react'
import graphql from 'babel-plugin-relay/macro'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { withOrganisation } from 'containers/Organisation'
import withQuery from 'containers/withQuery'
import { withNotifications } from 'containers/Notifications'
import { HeaderMod } from 'containers/Header'

import TeacherAccountCreation from 'components/organisation/TeacherAccountCreation'
import Loader from 'components/Loader'

const AddTeacher = ({ notify, t, organisation, groups, organisationModules, history }) =>
  <HeaderMod
    title="Nieuwe docent"
    backLink={`/${organisation.slug}/#teachers`}
  >
    <TeacherAccountCreation
      data={{
        groups,
        organisationModules,
        organisationId: organisation.id,
      }}
      defaultValues={{
        access: []
      }}
      onSubmit={() => {
        history.push(`/${organisation.slug}/#teachers`)
        notify({
          type: 'success',
          title: `${t('teacher_singular', ['capitalize'])} succesvol aangemaakt`,
        })
      }}
    />
  </HeaderMod>

const getQuery = () => graphql`
  query addTeacherQuery($organisationId: ID!) {
    groups(organisationId: $organisationId)
    organisationModules(organisationId: $organisationId) {
      id
      module {
        module_study {
          id
          name
        }
      }
    }
  }
`

const getVariables = ({ organisation }) => ({
  organisationId: organisation.id,
})

export default compose(
  withRouter,
  withOrganisation,
  withNotifications,
  withQuery({ getQuery, getVariables, loader: Loader })
)(AddTeacher)
