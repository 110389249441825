import React from 'react'
import styled from 'styled-components'
import { darken } from 'polished'
import { compose, withProps, withHandlers } from 'recompose'
import 'react-select/dist/react-select.css'
import 'react-virtualized-select/styles.css'
import Select from 'react-virtualized-select'
import Icon from '../Icon'

const ArrowIcon = styled(Icon)`
  color: ${props => props.theme.color.button}
`

const StyledSelect = styled(Select)`
  &.Select--multi {
    .Select-value-label,
    .Select-value-icon {
        background-color: ${props => props.theme.color.button};
        color: ${props => props.theme.color.white};
        border-color: ${props => darken(.1, props.theme.color.button)};
    }

    .Select-value-icon:hover {
        background-color: ${props => darken(.1, props.theme.color.button)};
        color: ${props => props.theme.color.white};
    }

    .Select-value {
        border-color: ${props => darken(.1, props.theme.color.button)};
    }

    .Select-multi-value-wrapper {
      white-space: normal;
    }
  }

  & .Select-control {
    height: 51px;
    padding-top: 9px;
    padding-bottom: 8px;
    border: none;
  }

  &&&.Select--single .Select-value,
  & .Select-placeholder {
    top: 9px;
    bottom: 8px;
  }

  & .Select-menu-outer {
    z-index: 2;
  }
`

export default compose(
  withProps(({ options, multi, value }) => ({
    arrowRenderer: () => <ArrowIcon size={20} icon="arrow-down" />,
    value: value
      ? multi
        ? options.filter(option => value.includes(option.value))
        : options.find(option => option.value === value)
      : '',
    closeOnSelect: !multi,
  })),
  withHandlers({
    onChange: ({ onChange, multi }) => option => {
      if (!onChange) { return }

      return multi
        ? onChange(option.map(o => o ? o.value : null))
        : onChange(option ? option.value : null)
    }
  })
)(StyledSelect)
