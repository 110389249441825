import React, { Fragment } from 'react'
import graphql from 'babel-plugin-relay/macro'
import styled, { css } from 'styled-components'

import { compose, withHandlers } from 'recompose'
import withForm from 'containers/withForm'
import { withOrganisation } from 'containers/Organisation'

import { Paper, Input, Button, Align, Float, Styles, Column } from 'hoffelijk-react-components'

const ModuleStudyName = styled.h4`
  line-height: 40px;
  margin: 0;
`

const ClearFix = styled.div`
  clear: both;
  height: 30px;
`

const Name = styled.h2`
  display: inline-block;
  width: calc(100% - 130px - 30px);
  margin: 45px 0;

  ${props => props.empty && css`
    &::after {
      content: 'Naam';
      opacity: ${props => props.theme.color.gray60};
    }
  `}
`

const TeacherAccessManagement = ({ data, values, errors, onSubmit, disabled, updateValue, setAccess, t }) =>
  <form>
    <Paper light>
      <Paper.Section>
        <Input.Avatar
          defaultValue={values.avatar_url}
          placeholder={
            (values.first_name ? values.first_name.substr(0, 1) : '') +
            (values.last_name ? values.last_name.substr(0, 1) : '')}
          error={errors.avatar}
          onChange={v => updateValue('avatar', v)}
        />
        <Name
          empty={!(values.first_name || values.middle_name || values.last_name)}
        >{values.first_name} {values.middle_name} {values.last_name}</Name>
      </Paper.Section>
      <Paper.Section>
        <Input.Group>
          <Column nopadding size={4}>
            <Input
              required
              type="name"
              label="Voornaam"
              value={values.first_name}
              error={errors.first_name}
              onChange={v => updateValue('first_name', v)}
              block />
          </Column>
          <Column nopadding size={3}>
            <Input
              type="text"
              label="Tussenvoegsel"
              value={values.middle_name}
              error={errors.middle_name}
              onChange={v => updateValue('middle_name', v)}
              block />
          </Column>
          <Column nopadding size={5}>
            <Input
              required
              type="name"
              label="Achternaam"
              value={values.last_name}
              error={errors.last_name}
              onChange={v => updateValue('last_name', v)}
              block />
          </Column>
          <Column nopadding size={12}>
            <Input
              required
              type="email"
              label="E-mailadres"
              value={values.email}
              error={errors.email}
              onChange={v => updateValue('email', v)}
              block
            />
          </Column>
          <Column nopadding size={12}>
            <Input
              type="checkbox"
              label="Account direct activeren"
              checked={values.activate}
              error={errors.activate}
              onChange={v => updateValue('activate', v)}
              block
            />
          </Column>
        </Input.Group>
      </Paper.Section>
      <Paper.Section>
        <Styles.NoMargin as="h2">Weergaverechten</Styles.NoMargin>
        {data.organisationModules.map(organisation_module =>
          <Fragment key={organisation_module.id}>
            <ClearFix />
            <Float.Left>
              <ModuleStudyName>
                {organisation_module.module.module_study.name}
              </ModuleStudyName>
            </Float.Left>
            <Float.Right>
              <Button
                onClick={event => {
                  event.preventDefault()
                  setAccess(organisation_module.id, data.groups)}
                }
              >
                Selecteer alle {t('connecting_field_group_plural')}
              </Button>
            </Float.Right>
            <ClearFix />
            <Input
              type="select"
              label={t('connecting_field_group_plural', ['capitalize'])}
              value={values.access
                .find(x => x.organisation_module_id === organisation_module.id)
                  ? values.access
                    .find(x => x.organisation_module_id === organisation_module.id).groups
                  : []
              }
              options={[
                {
                  label: `Alle ${t('connecting_field_group_plural')} (ook in de toekomst)`,
                  value: '$$ALL'
                },
                ...data.groups
                  .map(group => ({
                    label: group ? group : `Geen ${t('connecting_field_group')}`,
                    value: group ? group : '$$NONE',
                  }))
              ]}
              onChange={groups =>
                setAccess(organisation_module.id, groups.includes('$$ALL')
                  ? ['$$ALL']
                  : groups)}
              multi
              block
            />
          </Fragment>
        )}
      </Paper.Section>
    </Paper>
    <Align.Center>
      <Button under onClick={onSubmit} disabled={disabled}>Wijzigingen opslaan</Button>
    </Align.Center>
  </form>

const getMutation = () => graphql`
  mutation TeacherAccountCreationMutation($organisationId: ID!, $input: UserInput!) {
    createUser(organisationId: $organisationId, input: $input) {
      id
    }
  }
`

export default compose(
  withOrganisation,
  withForm({
    getMutation,
    getVariables: ({ organisationId }) => input => ({
      organisationId,
      input: {
        ...input,
        is_teacher: true,
      }
    }),
  }),
  withHandlers({
    setAccess: ({ values, updateValue }) => (organisation_module_id, groups) => updateValue('access',
      values.access.find(x => x.organisation_module_id === organisation_module_id)
        ? values.access.map(value => value.organisation_module_id === organisation_module_id
          ? { ...value, groups } : value)
        : [...values.access, { organisation_module_id, groups }]
    )
  }),
)(TeacherAccessManagement)
