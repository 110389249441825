import React from 'react'

import StandardRow from './StandardRow'
import ExpandingRow from './ExpandingRow'

const GenericRow = ({
  row, columns, dense,
  expandable, expandedContent, onToggleExpanded
}) => expandable
  ? <ExpandingRow
      columns={columns}
      dense={dense}
      row={row}
      expandedContent={expandedContent}
      onToggleExpanded={onToggleExpanded}
    />
  : <StandardRow
      dense={dense}
      columns={columns}
      row={row}
    />

export default GenericRow
