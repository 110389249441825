import React from 'react'

import { withOrganisation } from 'containers/Organisation'

import { Menu, Paper, EmptyState } from 'hoffelijk-react-components'
import { firstBy } from 'thenby'

import empty from 'hoffelijk-react-components/assets/module_empty_state.png'

const getCategories = (organisation, organisation_modules) => {
  const specificLinks = {
    title: 'Per opleiding',
    items: organisation_modules
      .map(organisation_module => ({
        icon: organisation_module.module.module_study.icon,
        label: organisation_module.module.module_study.name,
        to: `/${organisation.slug}/dashboard/progress/${organisation_module.module.module_study.slug}`,
      }))
      .sort(firstBy('label'))
  }

  // Instead of showing a table with the results of a single study, it
  // is possible to view a table with the combined results of all
  // studies. Since large tables currently perform very bad, we
  // disable this feature for now. Not removing the code since it's
  // tightly integrated with other functionality.
  const disallowShowAllStudies = [
    // allow the feature for all organisations!
  ];

  const showAllStudiesAllowed = disallowShowAllStudies.indexOf(organisation.slug) === -1;

  if (showAllStudiesAllowed && organisation_modules.length > 1) {
    return [
      {
        title: '',
        items: [
          { label: 'Alle opleidingen', to: `/${organisation.slug}/dashboard/progress/` }
        ]
      },
      specificLinks
    ]
  } else {
    return [{ ...specificLinks, title: '' }]
  }
}

const ProgressMenu = ({ organisation, t, organisation_modules=[], loading }) =>
  <Menu
    title={`Progressie ${t('student_plural')}`}
    loading={loading}
    empty={!loading && organisation_modules.length === 0}
    emptyState={() =>
      <Paper.Section>
        <EmptyState
          image={empty}
          title="Geen opleidingen weer te geven"
          description="Vraag jouw organisatiebeheerder om jou weergaverechten te geven"
        />
      </Paper.Section>}
    categories={getCategories(organisation, organisation_modules)}
  />

export default withOrganisation(ProgressMenu)
