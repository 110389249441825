import { compose, withState, withProps } from 'recompose'

const testRow = fragments => query => row =>
  fragments
    .map(f => f(row))
    .filter(fragment => typeof fragment === 'string')
    .map(fragment => fragment.toLowerCase())
    .some(fragment => fragment.includes(query))

const applySearch = fragments => data => query => query.length
  ? data.filter(testRow(fragments)(query))
  : data

export default fragments => compose(
  withState('query', 'setQuery', ''),
  withProps(({ query, data }) => ({
    data: applySearch(fragments)(data)(query)
  }))
)
