import React from 'react'

export default ({ width, height, fill, className }) =>
	<svg height={height} width={width} className={className} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<g className="nc-icon-wrapper" fill={fill}>
			<path id="Frame_-_24px" d="M0 0h24v24H0z" fill="none"/>
			<g id="Line_Icons">
				<path d="M5 20c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2V8H5v12zm2-10h10l.001 10H7V10z" fill={fill}/>
				<path d="M15 5V3H9v2H3v2h18V5z" fill={fill}/>
				<path d="M9 12h2v6H9z" fill={fill}/>
				<path d="M13 12h2v6h-2z" fill={fill}/>
			</g>
		</g>
	</svg>
