import React from 'react'
import styled from 'styled-components'
import { onlyUpdateForKeys } from 'recompose'

import { Link } from 'react-router-dom'
import Icon from '../Icon'
import UserAvatar from '../UserAvatar'

const User = styled.div`
  width: 320px;
`

const Info = styled.div`
  display: inline-block;
  margin-left: 20px;

  & h3 {
    max-width: 230px;
    margin: 12px 0 6px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & a {
    font-size: 12px;

    & + a { margin-left: 12px; }
  }
`

const LeftIcon = onlyUpdateForKeys([])(styled(Icon)`
  display: inline-block;
  margin-right: 10px;

  color: ${props => props.theme.color.icon_mainnav};
`)

export default onlyUpdateForKeys(['avatar', 'name', 'organisation', 'solo', 'down'])(
  ({ avatar, name='', organisation, solo, down }) =>
  <User solo={solo} down={down}>
    <UserAvatar size={70} avatar={avatar} name={name} />
    <Info>
      <h3>{name}</h3>
      <Link to={organisation ? `/${organisation.slug}/account` : `/account`}>
        <LeftIcon size={16} icon="user" />Mijn profiel
      </Link>
      <Link to={organisation ? `/${organisation.slug}/logout` : `/logout`}>
        <LeftIcon size={16} icon="power-button" />Uitloggen
      </Link>
    </Info>
  </User>
)
