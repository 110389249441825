import React from 'react'
import styled from 'styled-components';
import { withHandlers } from 'recompose'
import {Input, Column} from 'hoffelijk-react-components'

const DEFAULT_ALLOWED_TRIES = 2;

const CountingRecipeInputWrapper = styled.div`
  margin-top: 20px;
  
  &::before, &::after {
    content: '';
    display: block;
    clear: both;
  }
`;

const CountingRecipeLabel = styled.p`
  float: left;
  width: 50%;
`;

const CountingRecipeInputGroup = styled(Input.Group)`
  display: flex;
  float: right;
  width: 50%;
  
  > div {
    width: 100%;
    margin: 10px 0 !important;
  }
`;

const CountingRecipeInput = styled(Input)`
  width: 100%;
`;

const GuaranteeManagement = ({ values={}, errors={}, updateValue, recipes, setCountingRecipes, updateCountingRecipe }) =>
    <>
  <Input.Group>
    <Input
      type="select"
      multi
      label="Meetellende toetsmatrijzen"
      info="Laat leeg om alles te mee te laten tellen"
      value={values.counting_recipes && values.counting_recipes.map(value => value.id)}
      error={errors.counting_recipes}
      isDisabled={recipes.length === 0}
      isClearable
      options={recipes.map(({ name, id, code }) => ({
        label: `${name} (${code})`,
        value: id,
      }))}
      onChange={setCountingRecipes}
      block
    />
    <Column size={6} nopadding>
      <Input
        type="select"
        required
        label="Eerste toetsmatrijs"
        info="Start doorlooptijd"
        value={values.first_recipe_id}
        error={errors.first_recipe_id}
        isDisabled={recipes.length === 0}
        isClearable
        options={recipes.map(({ name, id, code }) => ({
          label: `${name} (${code})`,
          value: id,
        }))}
        onChange={v => updateValue('first_recipe_id', v)}
        block
      />
    </Column>
    <Column size={6} nopadding>
      <Input
        type="select"
        required
        label="Laatste toetsmatrijs"
        info="Einde doorlooptijd"
        value={values.last_recipe_id}
        error={errors.last_recipe_id}
        isDisabled={recipes.length === 0}
        isClearable
        options={recipes.map(({ name, id, code }) => ({
          label: `${name} (${code})`,
          value: id,
        }))}
        onChange={v => updateValue('last_recipe_id', v)}
        block
      />
    </Column>
    <Column size={6} nopadding>
      <Input
        required
        type="number"
        min={0}
        label="Aantal toetsen dat niet gehaald hoeft te worden"
        value={values.allowed_fails}
        error={errors.allowed_fails}
        onChange={v => updateValue('allowed_fails', v)}
        block
      />
    </Column>
    <Column size={6} nopadding>
      <Input
          type="select"
          multi
          label="Toetsmatrijzen die niet gehaald hoeven te worden"
          info=" "
          value={values.fails_apply_to}
          error={errors.fails_apply_to}
          isDisabled={recipes.length === 0}
          isClearable
          options={recipes.map(({ name, id, code }) => ({
            label: `${name} (${code})`,
            value: id,
          }))}
          onChange={v => updateValue('fails_apply_to', v)}
          block
      />
    </Column>
    <Column size={6} nopadding>
      <Input
        required
        type="number"
        label="Doorlooptijd slagingsgarantie"
        value={values.duration}
        error={errors.duration}
        onChange={v => updateValue('duration', v)}
        block
      />
    </Column>
  </Input.Group>
  {values.counting_recipes && values.counting_recipes.map((countedRecipe, i) =>
    <CountingRecipeInputWrapper key={i}>
      <CountingRecipeLabel>
        {(() => {
          const recipe = recipes.find(recipe => recipe.id === countedRecipe.id) || {};

          return `${recipe.name} (${recipe.code})`;
        })()}
      </CountingRecipeLabel>
      <CountingRecipeInputGroup>
        <CountingRecipeInput
          required
          type="number"
          label="Aantal pogingen per toets"
          min={0}
          errors={errors.counting_recipes && errors.counting_recipes[i] && errors.counting_recipes[i].allowed_tries}
          value={countedRecipe.allowed_tries ? countedRecipe.allowed_tries : 0}
          onChange={v => updateCountingRecipe(countedRecipe.id, {allowed_tries: v})}
        />
      </CountingRecipeInputGroup>
      </CountingRecipeInputWrapper>
  )}
</>

export default withHandlers({
  updateValue: ({values, updateValue}) => (key, value) => {
    updateValue(values => ({
      ...values,
      [key]: value
    }))
  },
  setCountingRecipes: ({values, updateValue}) => {
    return (recipe_ids) => {
      const updateCountingRecipes = (value) => {
        updateValue(values => ({
          ...values,
          'counting_recipes': value
        }));
      };

      return updateCountingRecipes(recipe_ids.map((recipe_id) => {
        return (values.counting_recipes || []).find((value) => {
          return value.id === recipe_id;
        }) || {id: recipe_id, allowed_tries: DEFAULT_ALLOWED_TRIES}
      }));
    }
  },
  updateCountingRecipe: ({values, updateValue}) => {
    return (recipe_id, o) => {
      const updateCountingRecipe = (value) => {
        updateValue(values => ({
          ...values,
          'counting_recipes': value
        }));
      }

      return updateCountingRecipe(values.counting_recipes.map((value) => {
        return value.id === recipe_id ? {...value, ...o} : value;
      }));
    }
  }
})(GuaranteeManagement)
