import React from 'react';
import { compose } from 'recompose';

import { Table } from 'hoffelijk-react-components';
import { withModal } from 'containers/Modal';
import CommentDialog from "./CommentDialog";

const OpenCommentDialog = ({onClick}) => {
    return (
        <Table.Action
            title="Opmerkingen"
            icon="file-add"
            onClick={onClick} />
    )
};

const SendEmail = ({email}) => {
    return (
        <Table.Action
            title="Stuur e-mail"
            icon="email-envelope"
            href={`mailto:${email}`}
            target={'_blank'}/>
    )
}

const Actions = ({popup, student, close, retry, loading}) => {
    if (loading) {
        return <Table.Actions />;
    }

    const openCommentDialog = () => {
        popup(
            <CommentDialog student={student}
                           data={{studentId: student.id}}
                           openCommentDialog={openCommentDialog}
                           onSubmit={() => {close();}} />
        );
    };

    return (
        <Table.Actions>
            <OpenCommentDialog onClick={openCommentDialog} />
            <SendEmail email={student.email} />
        </Table.Actions>
    );
};

export default compose(
    withModal,
)(Actions);
