import React, { Fragment } from 'react'
import { compose, withState, withHandlers } from 'recompose'
import styled, { css } from 'styled-components'

import { TableRow, TableCell } from './Table'

const ExpandButton = styled.span`
  cursor: pointer;

  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;

  &::before, &::after {
    content: '';

    position: absolute;
    left: 0; top: 0;
    right: 0; bottom: 0;

    margin: auto;
    width: 20px;
    height: 2px;

    background-color: ${props => props.theme.color.gray40};

    transition: transform .2s ease-in-out;
  }

  &::after { transform: rotate(90deg); }

  ${props => props.expanded && css`
    &::before { transform: rotate(-45deg); }
    &::after { transform: rotate(45deg); }
  `}
`

const ExpandingTableRow = styled(TableRow)`
  && { border-top: none; }
`

const ExpandingCell = styled(TableCell)`
  padding-top: 0;

  transition: padding-bottom .2s ease-out;

  ${props => !props.expanded && css`
    padding-bottom: 0;
  `}
`

const ExpandingCellContent = styled.div`
  position: relative;
  overflow: auto;

  max-height: 0;
  padding: 0 29px;
  border-radius: 4px;

  opacity: 0;

  background-color: ${props => props.theme.color.gray10};

  transition:
    padding .2s ease-out,
    opacity .2s ease-out;

  ${props => props.expanded && css`
    max-height: none;
    opacity: 1;
    padding: 14px 29px;
  `}
`

const ExpandingRow = ({
  columns, row, dense,
  expanded, toggleExpanded, expandedContent, onToggleExpanded,
  state, setState
}) =>
  <Fragment>
    <TableRow>
      {columns.map((column, i) =>
        <TableCell dense={dense} key={i}>{column.content(row, state, setState, toggleExpanded)}</TableCell>
      )}
      <TableCell dense={dense}>
        {expandedContent(row, state, setState) !== null && <ExpandButton
          onClick={onToggleExpanded
            ? x => {
                toggleExpanded()
                onToggleExpanded(!expanded, row, state, setState)
              }
            : toggleExpanded}
          expanded={expanded}
        />}
      </TableCell>
    </TableRow>
    <ExpandingTableRow>
      <ExpandingCell
        colSpan={columns.length + 1}
        expanded={expanded}
      >
        <ExpandingCellContent expanded={expanded}>
          {expanded && expandedContent(row, state, setState)}
        </ExpandingCellContent>
      </ExpandingCell>
    </ExpandingTableRow>
  </Fragment>

export default compose(
  withState('expanded', 'setExpanded', false),
  withState('state', 'setState', ({ state }) => state),
  withHandlers({
    toggleExpanded: ({ expanded, setExpanded }) => ex => setExpanded(ex === true || ex === false ? ex : !expanded)
  }),
)(ExpandingRow)
