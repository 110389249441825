import 'react-app-polyfill/ie11'
import values from 'object.values'
import includes from 'array-includes'
import 'core-js/es/string'
import 'core-js/es/array'
import 'core-js/es/map'
import 'core-js/es/set'
import 'core-js/es/promise'
import 'core-js/es/object'

import React from 'react'
import { hydrate, render } from 'react-dom'
import { Provider } from 'react-redux'
import nlLocale from 'moment/locale/nl'
import moment from 'moment'

import { ConnectedRouter } from 'connected-react-router'
import { unregister } from './registerServiceWorker'
import configureStore from './store'

import { MaskProvider } from 'containers/Mask'
import { AuthenticationProvider } from 'containers/Authentication'
import { ThemeProvider } from 'hoffelijk-react-components'
import { HeaderProvider } from 'containers/Header'
import { NotificationProvider } from 'containers/Notifications'
import { ModalProvider } from 'containers/Modal'
import App from 'containers/App'

const historyModule = require("history")

if (!Object.values) { values.shim() }
if (!Array.prototype.includes) { includes.shim() }

moment.updateLocale('nl', nlLocale)

const history = historyModule.createBrowserHistory({ basename: "/" })

const initialState = {}

const store = configureStore(initialState, history)

// if (process.env.NODE_ENV !== 'production') {
//   const { whyDidYouUpdate } = require('why-did-you-update')
//   whyDidYouUpdate(React)
// }

const Root = (
	<Provider store={store}>
		<AuthenticationProvider>
			<MaskProvider>
				<HeaderProvider>
					<NotificationProvider>
						<ThemeProvider>
							<ModalProvider>
								<ConnectedRouter history={history}>
									<App/>
								</ConnectedRouter>
							</ModalProvider>
						</ThemeProvider>
					</NotificationProvider>
				</HeaderProvider>
			</MaskProvider>
		</AuthenticationProvider>
	</Provider>
)
const root = document.getElementById('root')

if (root.hasChildNodes()) {
	hydrate(Root, root)
} else {
	render(Root, root)
}

unregister()
