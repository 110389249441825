import React from 'react'

import Organisation from 'pages/organisation'
import NotFoundPage from 'pages/404'

import AddTeacher from 'pages/organisation/teacher/add'
import EditTeacher from 'pages/organisation/teacher/edit'
import EditTeacherAccess from 'pages/organisation/teacher/access'

import { compose } from 'recompose'
import { withMe } from 'containers/Me'
import { withOrganisation } from 'containers/Organisation'
import { HeaderMod } from 'containers/Header'
import { Route, Switch } from 'react-router-dom'

import { isMainAdmin } from 'utilities/logic/authentication'

const PrivateOrganisationRouter = ({ me, organisation }) =>
  <HeaderMod
    upLinks={isMainAdmin(me)
      ? [{
          label: 'Terug naar hoofdbeheer',
          to: '/#organisations'
        }]
      : []}
  >
    <Switch>
      <Route
        exact path={`/${organisation.slug}`}
        component={Organisation} />
      <Route
        exact path={`/${organisation.slug}/teacher/add`}
        component={AddTeacher} />
      <Route
        path={`/${organisation.slug}/teacher/:teacherId/edit`}
        component={EditTeacher} />
      <Route
        path={`/${organisation.slug}/teacher/:teacherId/access`}
        component={EditTeacherAccess} />
      <Route component={NotFoundPage} />
    </Switch>
  </HeaderMod>

export default compose(
  withMe,
  withOrganisation
)(PrivateOrganisationRouter)
