import React, { Fragment } from 'react'

import Router from 'containers/Router'
import Modal from 'components/Modal'
import Notifications from 'components/Notifications'
import IdleTimer from 'components/IdleTimer'

const App = () =>
  <Fragment>
    <Router />
    <Modal />
    <Notifications />
    <IdleTimer />
  </Fragment>

export default App
