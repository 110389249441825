import React from 'react'
import styled from 'styled-components'
import { withModal } from 'containers/Modal'

import { Paper, Button, Styles, Align } from 'hoffelijk-react-components'

const StyledButton = styled(Button)`
  &&&& {
    &:hover {
      &, & svg {
        color: ${props => props.theme.color.text};
      }
    }
  }
`

const ConfirmModal = ({ message, buttonMessage, close, onCancel, onSubmit }) =>
  <Paper light>
    <Paper.Section>
      <Styles.NoMargin as="h4">{message}</Styles.NoMargin>
    </Paper.Section>
    <Align.Right as={Paper.Section}>
      <StyledButton
        light
        onClick={() => {
            if (close !== undefined) close();
            if (onCancel !== undefined) onCancel();
        }}
      >
        Annuleer
      </StyledButton>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <Button
        error
        onClick={event => {
          close()
          onSubmit(event)
        }}
      >
        {buttonMessage}
      </Button>
    </Align.Right>
  </Paper>

export default withModal(ConfirmModal)
