export const CATEGORIES = {
  NOT_PARTICIPATED: 0,
  FAILED: 1,
  PASSED: 2,
  EXCELLENT: 3,
}

export const GRADES = {
  PASS: 5.5,
  EXCELLENT: 7.5,
}

export const PERCENTAGES = {
  PASS: .68,
  EXCELLENT: .80,
}

export const GUARANTEE_STATUS = {
  NONE: null,
  PENDING: 0,
  EXPIRED: 1,
  GUARANTEED: 2,
}

export const PROGRESS_STATUS = {
  NONE:          null,
  UNKNOWN:       0,
  BEHIND:        1,
  ON_SCHEME:     2,
  AHEAD:         3,
  TARGET_PASSED: 4,
  FINISHED:      5,
}
