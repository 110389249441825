import React from 'react'
import { compose, withState, withHandlers } from 'recompose'
import withMutation from 'containers/withMutation'
import { withModal } from 'containers/Modal'

import { Table } from 'hoffelijk-react-components'
import ConfirmModal from 'components/ConfirmModal'

export default compose(
  withModal,
  withState('disabled', 'setDisabled', false),
  withMutation({
    getMutation: ({ mutation }) => mutation,
    getVariables: ({ variables }) => variables,
    onCompleted: ({ onCompleted, setDisabled }) => res => {
      setDisabled(false)
      onCompleted(res)
    },
  }),
  withHandlers({
    onClick: ({ popup, title, confirm, onSubmit, commitMutation, setDisabled }) => event => {
      if (confirm) {
        popup(<ConfirmModal
          message={confirm}
          buttonMessage={title}
          onSubmit={e => {
            if (onSubmit) {
              onSubmit();
            }
            setDisabled(true)
            commitMutation(e)
          }}
        />)
      } else {
        setDisabled(true)
        commitMutation(event)
      }
    }
  })
)(Table.Action)
