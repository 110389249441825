/**
 * @generated SignedSource<<24d1a5b59aabc98944be35a86cbb589d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "moduleStudyId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "moduleStudyId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "icon",
  "storageKey": null
},
v5 = [
  (v2/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Module",
  "kind": "LinkedField",
  "name": "module",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Study",
  "kind": "LinkedField",
  "name": "study",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Recipe",
  "kind": "LinkedField",
  "name": "visible_recipes",
  "plural": true,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Service",
  "kind": "LinkedField",
  "name": "services",
  "plural": true,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "planning",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "Recipe",
  "kind": "LinkedField",
  "name": "recipe",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "connecting_field_planning_wft_module",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "duration",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allowed_fails",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "Recipe",
  "kind": "LinkedField",
  "name": "first_recipe",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "Recipe",
  "kind": "LinkedField",
  "name": "last_recipe",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "Recipe",
  "kind": "LinkedField",
  "name": "fails_apply_to",
  "plural": true,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "Recipe",
  "kind": "LinkedField",
  "name": "counting_recipes",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowed_tries",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "editModuleStudyQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ModuleStudy",
        "kind": "LinkedField",
        "name": "moduleStudy",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ModuleStudyRecipePlanning",
            "kind": "LinkedField",
            "name": "module_study_recipes_planning",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/)
            ],
            "storageKey": null
          },
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Guarantee",
            "kind": "LinkedField",
            "name": "guarantee",
            "plural": false,
            "selections": [
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "editModuleStudyQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ModuleStudy",
        "kind": "LinkedField",
        "name": "moduleStudy",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ModuleStudyRecipePlanning",
            "kind": "LinkedField",
            "name": "module_study_recipes_planning",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Guarantee",
            "kind": "LinkedField",
            "name": "guarantee",
            "plural": false,
            "selections": [
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1c3aab9b5653fa74175ed8c8ca7c33b3",
    "id": null,
    "metadata": {},
    "name": "editModuleStudyQuery",
    "operationKind": "query",
    "text": "query editModuleStudyQuery(\n  $moduleStudyId: ID!\n) {\n  moduleStudy(id: $moduleStudyId) {\n    id\n    name\n    icon\n    module {\n      id\n    }\n    study {\n      id\n    }\n    visible_recipes {\n      id\n    }\n    services {\n      id\n    }\n    module_study_recipes_planning {\n      name\n      planning\n      recipe {\n        id\n      }\n      id\n    }\n    connecting_field_planning_wft_module\n    guarantee {\n      duration\n      allowed_fails\n      first_recipe {\n        id\n      }\n      last_recipe {\n        id\n      }\n      fails_apply_to {\n        id\n      }\n      counting_recipes {\n        id\n        allowed_tries\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

node.hash = "bbee7cd9a54e2a3f123c0af2864f13da";

module.exports = node;
