import React from 'react'
import styled from 'styled-components'
import { lighten } from 'polished'

import { compose, withProps } from 'recompose'

import { Icon } from 'hoffelijk-react-components'

import moment from 'moment'
import Moment from 'react-moment'
import { getBestAttempt } from 'utilities/attemptManipulation'

const IllustrationContainer = styled.div`
  position: relative;
  z-index: 1;

  white-space: nowrap;

  margin: 15px 0;

  height: 140px;
`

const getStatusColor = (props, fallback) => {
  switch (props.status) {
    case 'taken': return props.theme.color.success
    case 'upcomming': return props.theme.color.warning
    case 'expired': return props.theme.color.error
    default: return fallback
  }
}

const Container = styled.div`
  position: absolute;
  left: 40px; right: 40px;

  display: flex;
  margin: auto;
`

const Part = styled.div`
  position: relative;

  flex-grow: ${props => props.size};
  flex-basis: ${props => props.size === 0 ? `0` : `50px`};
`

const TimelineContainer = styled(Container)`
  top: 53px;
  height: 10px;
`

const BackgroundContainer = styled(TimelineContainer)`
  background-color: ${props => props.theme.color.gray20};
`

const TimelinePart = styled(Part)`
  height: 100%;

  background-size: 8px 8px;

  ${props => (props.status === 'upcomming' || props.status === 'expired')
  ? `background-image: linear-gradient(
      -45deg,
      ${getStatusColor(props, props.theme.color.gray40)} 25%,
      ${lighten(0.3, getStatusColor(props, props.theme.color.gray40))} 25%,
      ${lighten(0.3, getStatusColor(props, props.theme.color.gray40))} 50%,
      ${getStatusColor(props, props.theme.color.gray40)} 50%,
      ${getStatusColor(props, props.theme.color.gray40)} 75%,
      ${lighten(0.3, getStatusColor(props, props.theme.color.gray40))} 75%
    );`
  : `background-color: ${getStatusColor(props, 'transparent')}`
  }
`

const GradesContainer = styled(Container)`
  top: 0;
  height: 40px;
`

const GradeContainer = styled.div`
  position: absolute;
  right: -40px;

  width: 100px;

  text-align: center;
`

const Grade = styled.div`
  width: 40px;
  height: 40px;
  padding: 4px;
  margin: auto;
  border-radius: 100%;

  font-size: 20px;
  font-weight: bold;

  & sup {
    margin-left: -2px;

    font-size: 12px;
    line-height: 22px;
  }

  background-color: ${props => getStatusColor(props, props.theme.color.gray40)};
  color: ${props => props.theme.color.white};
`

const Description = styled.div`
  padding: 10px 5px;
  font-size: 0.9em;
  white-space: normal;

  display: flex;
  flex-flow: column;
  align-items: center;
`

const IconWrapper = styled.div`
  display: flex;
  
  svg {
    min-width: 20px;
  }
`

const GradeContent = ({ grade, target_date }) => grade
  ? grade.includes('%')
    ? <span>
        {grade.substr(0, grade.indexOf('%'))}
        <sup>%</sup>
      </span>
    : grade.includes(',')
      ? <span>{grade.substr(0, grade.indexOf(','))},
          <sup>{grade.substr(grade.indexOf(',') + 1)}</sup>
        </span>
      : grade
  : (target_date !== null && target_date.isBefore(moment()))
    ? <Icon size={28} icon="snail" />
    : null;

const Past = styled.div`
  position: absolute;
  left: 0; top: 0;
  bottom: 0;

  width: ${props => props.width}%;

  background-size: 8px 8px;
  background-image: linear-gradient(
    -45deg,
    ${props => props.theme.color.warning} 25%,
    ${props => lighten(0.3, props.theme.color.warning)} 25%,
    ${props => lighten(0.3, props.theme.color.warning)} 50%,
    ${props => props.theme.color.warning} 50%,
    ${props => props.theme.color.warning} 75%,
    ${props => lighten(0.3, props.theme.color.warning)} 75%
  );
`

const DaysBehindWarning = styled.span`
  color: ${props => props.theme.color.error};
  font-weight: bold;
  font-size: 12px;
  white-space: nowrap;
`

// The check svg is unlike other svg images, fixing it with margins until
// we have a proper icon
const OffsetBogusCheckSvg = styled.div`
  margin-left: -3px;
  margin-right: 4px;
`;

const ProgressIllustration = ({ theme, row, recipes }) =>
  <IllustrationContainer>
    <BackgroundContainer>
      {row.target_date !== null && recipes.slice(1).map(({ size, planning, target_date }, i) =>
        <Part key={planning} size={size}>
          {moment().isAfter(recipes[i].target_date) && moment().isBefore(target_date)
            ? <Past
                width={100 * (moment() - recipes[i].target_date) / (target_date - recipes[i].target_date)}
              />
            : null}
        </Part>
      )}
    </BackgroundContainer>
    <TimelineContainer>
      {recipes.map(({ size, status }, i) =>
        <TimelinePart
          key={i}
          size={size}
          status={status}
        />)}
    </TimelineContainer>
    <GradesContainer>
      {recipes.map(({ name, size, status, grade, date_taken, target_date, progress }, i) =>
        <Part key={i} size={size}>
          <GradeContainer>
            <Description>
              {name}
            </Description>
            <Grade status={status}>
              <GradeContent
                grade={grade}
                date_taken={date_taken}
                target_date={target_date}
              />
            </Grade>
            <Description>
              <IconWrapper>
                {target_date
                  ? <>
                    <Icon icon={"clock"} size={"22"} />
                    <Moment date={target_date} format="DD-MM-YYYY" />
                  </>
                  : '-'}
              </IconWrapper>
              {date_taken
                ? <IconWrapper>
                    <OffsetBogusCheckSvg>
                      <Icon icon={"check"} size={"12"} />
                    </OffsetBogusCheckSvg>
                    <Moment date={date_taken} format="DD-MM-YYYY" />
                  </IconWrapper>
                : <div>
                  {(target_date !== null && target_date.isBefore(moment()))
                    ? <DaysBehindWarning>{target_date.diff(moment(), 'days')} dagen te laat</DaysBehindWarning>
                    : '-'}
                  </div>}
            </Description>
          </GradeContainer>
        </Part>
      )}
    </GradesContainer>
    {row.target_date !== null &&
      <Container>
        {recipes.slice(1).map(({ size, planning, target_date }, i) =>
          <Part key={planning} size={size} />
        )}
      </Container>
    }
  </IllustrationContainer>

export default compose(
  withProps(({ row }) => ({
    recipes: row.recipes
      .filter(recipe => recipe.planning !== null)
      .map(recipe => {

        return ({
          ...recipe,
          bestAttempt: getBestAttempt(recipe.attempts, 100)
        });
      })
      .map(recipe => ({
        ...recipe,
        status: recipe.bestAttempt
          ? 'taken'
          : moment().subtract(12, 'hours').isAfter(recipe.target_date)
            ? 'expired'
            : moment().add(2, 'days').isAfter(recipe.target_date)
              ? 'upcomming'
              : null,
        date_taken: recipe.bestAttempt ? recipe.bestAttempt.date : null,
        grade: recipe.bestAttempt ? recipe.bestAttempt.grade : null,
      })),
  })),
  withProps(({ recipes }) => {
    recipes.sort((a, b) => a.planning_order - b.planning_order);

    const planningPercentages = recipes.map(recipe => recipe.planning)

    let differences = [0]

    for (let i = 1; i < recipes.length; i++) {
      differences[i] = planningPercentages[i] - planningPercentages[i - 1]
    }


    // The size of each time-part must not be smaller than 5, because the dates under the time-part
    // in the illustration will otherwise overlap.
    //
    // Find all parts smaller than 5 and set it to 5, remember the amount we increased the size: the toal
    // must be 100%.
    const minimumSize = 8;
    let sizeToDecrease = 0;
    for (let i = 1; i < differences.length; i++) {
      if (differences[i] < minimumSize) {
        sizeToDecrease += (minimumSize - differences[i]);
        differences[i] = minimumSize;
      }
    }

    // Snoop of the sizes in other parts, this makes the illustration less accurate, but at least the content fits!
    for (let i = 1; i < differences.length; i++) {
      if (differences[i] > minimumSize) {
        const decreaseWith = Math.min(differences[i] - minimumSize, sizeToDecrease);

        sizeToDecrease -= decreaseWith;

        differences[i] -= decreaseWith;
      }
    }

    return {
      recipes: recipes.map((recipe, i) => ({
        ...recipe,
        size: differences[i]
      }))
    }
  })
)(ProgressIllustration)
