import React from 'react'
import withSorting from 'hoffelijk-react-components/containers/withSorting'

import ErrorBoundary from '../ErrorBoundary'

import {
  ScrollArea,
  Table,
  TableHead,
  TableHeading,
  TableBody,
  TableRow,
  TableCell
} from './Table'

import LoadingState from './LoadingState'
import ErrorState from './ErrorState'
import EmptyState from './EmptyState'
import ActiveState from './ActiveState'

import SortLabel from './SortLabel'
import TableActions from './Actions'
import TableAction from './Action'
import { compose, withState, withHandlers } from "recompose";
import Pager from './Pager';

const ITEMS_PER_PAGE = 100;

const StructuredTable = ({
  columns, data, setData,
  loading, error, fallback,
  getRowVariables, dense,
  expandable, expandedContent, onToggleExpanded,
  currentPage, gotoPage, paginatedForNumberOfPages,
  sorting, sortOnColumn }) => {
  const numberOfPages = data !== undefined
    ? Math.ceil(data.length / ITEMS_PER_PAGE)
    : 0

  if (data && paginatedForNumberOfPages !== numberOfPages) {
    currentPage = 0
  }

  return (<ErrorBoundary>
    <ScrollArea>
      <Table dense={dense}>
        <TableHead>
          <TableRow>
            {columns.map(({label, sortable}, i) =>
              <TableHeading key={i}>
                <SortLabel
                  sortable={sortable}
                  sorting={sorting.column === label ? sorting.direction : 0}
                  onClick={() => sortOnColumn(label)}
                >
                  {label}
                </SortLabel>
              </TableHeading>
            )}
            {expandable && <TableHeading/>}
          </TableRow>
        </TableHead>
        {loading
          ? <LoadingState dense={dense} columns={columns}/>
          : error
            ? <ErrorState columns={columns} error={error}/>
            : data && data.length
              ? <ActiveState
                  columns={columns}
                  data={data.slice(ITEMS_PER_PAGE * currentPage, ITEMS_PER_PAGE * currentPage + ITEMS_PER_PAGE)}
                  setData={setData}
                  expandable={expandable}
                  expandedContent={expandedContent}
                  onToggleExpanded={onToggleExpanded}
                  dense={dense}
                  getRowVariables={getRowVariables}
                />
              : <EmptyState columns={columns} component={fallback}/>}
      </Table>
    </ScrollArea>
    {data && data.length > 0 &&
    <Pager currentPage={currentPage}
           numberOfPages={numberOfPages}
           gotoPage={gotoPage}
    />}
  </ErrorBoundary>)
};

const PagedTable = compose(
  withState('currentPage', 'setCurrentPage', 0),
  withState('paginatedForNumberOfPages', 'setPaginatedForNumberOfPages'),
  withHandlers({
    gotoPage: ({ currentPage, setCurrentPage, setPaginatedForNumberOfPages}) => (newPage, newNumberOfPages) => {
      setPaginatedForNumberOfPages(newNumberOfPages)
      setCurrentPage(newPage)
    },
  })
)(StructuredTable)

const EnhancedTable = withSorting(PagedTable)

EnhancedTable.Table   = Table
EnhancedTable.Head    = TableHead
EnhancedTable.Heading = TableHeading
EnhancedTable.Body    = TableBody
EnhancedTable.Row     = TableRow
EnhancedTable.Cell    = TableCell
EnhancedTable.Actions = TableActions
EnhancedTable.Action  = TableAction

export default EnhancedTable
