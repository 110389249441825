import React from 'react'
import styled, { withTheme } from 'styled-components'
import { onlyUpdateForKeys } from 'recompose'

import Header from 'components/Header'
import Footer from 'components/Footer'
import { Wrapper } from 'hoffelijk-react-components'

const Inside = styled.div`
  padding: 1px 0;
  margin: -1px 0;
  background-color: ${props => props.theme.color.background};
`

const PureWrapper = onlyUpdateForKeys(['children'])(Wrapper)

export default withTheme(({ theme, children, ...rest }) =>
  <Inside>
    <Header />
    <PureWrapper className="pagewrapper" children={children} />
    {!theme.hide_footer && <Footer />}
  </Inside>
)
