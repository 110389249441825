import React from 'react'
import styled from 'styled-components'
import { compose } from 'recompose'
import { withOrganisation } from 'containers/Organisation'
import Actions from './Actions';

import { Paper, Table, Input, Float, Styles } from 'hoffelijk-react-components'

import Moment from 'react-moment'
import GuaranteeStatus from './GuaranteeStatus'
import ProgressStatus from './ProgressStatus'
import RowDetails from './RowDetails'
import {examOfParticipationIsPassed, formatParticipationResult} from "../../../utilities/examHelpers";

const FloatLeft = styled(Float.Left)`
  max-width: calc(100% - 330px);

  h2 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

const Small = styled.span`
  font-size: .8em;
  line-height: 20px;
`

const Large = styled.span`
  font-size: 1em;
  font-weight: bold;
`

const ProgressOverview = ({
  teacher, organisation, t, organisationModules,
  hasExamParticipations, query, setQuery, data, loading, error
}) =>
  <Paper marginBottom light>
    <Paper.Section>
      <FloatLeft>
        <Styles.NoMargin as="h2">{organisationModules.length === 1
          ? organisationModules[0].module.module_study.name
          : `Progressie ${t('student_plural')}`}</Styles.NoMargin>
      </FloatLeft>
      <Float.Right>
        <Input
          type="search"
          label={`Zoek een ${t('student_singular')}`}
          dense
          value={query}
          onChange={value => setQuery(value ? value.toLowerCase() : '')}
        />
      </Float.Right>
    </Paper.Section>
    <Table
      dense
      getRowVariables={row => ({
        organisationModuleId: row.organisation_module.id,
        candidateId: row.student.candidate !== null ? row.student.candidate.id : null,
        teacherId: teacher.id,
      })}
      expandable
      expandedContent={row => <RowDetails row={row} />}
      columns={[
        {
          label: 'Naam',
          content: row => <strong>{row.student.last_name}, {row.student.first_name} {row.student.middle_name}</strong>,
          comparableContent: row => `${row.student.last_name}, ${row.student.first_name} ${row.student.middle_name}`,
        },
        ...(organisationModules.length === 1
          ? []
          : [{
              label: 'Opleiding',
              content: row => row.organisation_module.module.module_study.name,
            }]
        ),
        {
          label: 'Voortgang',
          content: row => {
            const total = row.recipes.length
            const done = row.recipes
              .filter(recipe => recipe.attempts && recipe.attempts.length > 0).length

            const p = Math.round(100 * done / total)
            return isNaN(p) ? '-' : <span>{p}% <Small>({done}/{total})</Small></span>
          },
          comparableContent: row => row.recipes
            .filter(recipe => recipe.attempts && recipe.attempts.length > 0).length /
              row.recipes.length,
        },
        ...(organisationModules
          .find(organisation_module => organisation_module.module.module_study.guarantee !== null)
            ? [{
                label: 'Slagingsgarantie',
                content: row =>
                  <GuaranteeStatus guaranteed={row.guarantee_status} />,
                comparableContent: row => row.guarantee_status,
              }]
            : []
        ),
        ...(hasExamParticipations
          ? [
              {
                label: 'Examen',
                content: row => {
                  if (row.exam_participations.length === 0) {
                    return '-';
                  }

                  if (examOfParticipationIsPassed(row.lastPastExamParticipation)) {
                    return <> <Large> {row.lastPastExamParticipation.exam_result.grade}</Large> &nbsp; <Small><Moment
                      date={row.lastPastExamParticipation.exam_date} format="DD-MM-YYYY"/></Small></>
                  }

                  if (row.firstFutureExamParticipation) {
                    const formattedResultOrState = formatParticipationResult(row.firstFutureExamParticipation)
                    return <> {formattedResultOrState
                      ? <>{formattedResultOrState} &nbsp;</>
                      : ''}
                      <Small><Moment date={row.firstFutureExamParticipation.exam_date}
                                     format="DD-MM-YYYY"/></Small></>
                  }

                  if (row.lastPastExamParticipation.exam_result) {
                    return <> <Large> {row.lastPastExamParticipation.exam_result.grade}</Large> &nbsp; <Small><Moment
                      date={row.lastPastExamParticipation.exam_date} format="DD-MM-YYYY"/></Small></>
                  }

                  const formattedResultOrState = formatParticipationResult(row.lastPastExamParticipation);
                  return <> {formattedResultOrState
                    ? <>{formattedResultOrState} &nbsp;</>
                    : ''}
                    <Small><Moment date={row.lastPastExamParticipation.exam_date} format="DD-MM-YYYY"/></Small></>
                },
                comparableContent: row => row.firstFutureExamParticipation
                  ? row.firstFutureExamParticipation.exam_date
                  : row.lastPastExamParticipation
                    ? row.lastPastExamParticipation.exam_date
                    : null
              },
            ]
          : []
        ),
        ...(organisationModules
          .find(organisation_module => organisation_module.has_planning)
            ? [
                {
                  label: 'Streefdatum',
                  content: row => row.target_date
                    ? <Moment date={row.target_date} format="DD-MM-YYYY" />
                    : '-',
                  comparableContent: row => row.target_date
                },
                {
                  label: 'Voortgang opleiding',
                  content: row =>
                    <ProgressStatus
                      status={row.progress.status}
                      days={row.progress.days}
                    />,
                  comparableContent: row => row.progress,
                  compare: (a, b) => a && b
                    ? a.status === b.status
                      ? a.days - b.days
                      : a.status - b.status
                    : 0
                },
              ]
            : []
        ),
          {
              label: '',
              content: row => <Actions student={row.student} />,
              sortable: false,
          },
      ]}
      data={data}
      loading={loading}
      error={error}
    />
  </Paper>

export default compose(
  withOrganisation,
)(ProgressOverview)
