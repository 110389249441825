import React from 'react'

import { OrganisationProvider } from 'containers/Organisation'
import AdminOrganisationRouter from './AdminOrganisationRouter'

import Inside from 'layouts/inside'

import IndexPage from 'pages/admin'
import Account from 'pages/account'
import NotFoundPage from 'pages/404'
import Logout from 'pages/logout'

import AddUser from 'pages/admin/user/add'
import EditUser from 'pages/admin/user/edit'
import AddModule from 'pages/admin/module/add'
import EditModule from 'pages/admin/module/edit'
import AddOrganisation from 'pages/admin/organisation/add'

import { Route, Switch } from 'react-router-dom'

const AdminRouter = ({ organisations }) =>
  <Inside>
    <Switch>
      <Route
        exact path="/"
        component={IndexPage}
      />
      <Route
        exact path="/account"
        component={Account}
      />
      <Route
        exact path="/user/add"
        component={AddUser}
      />
      <Route
        path="/user/:userId/edit"
        component={EditUser}
      />
      <Route
        exact path="/module/add"
        component={AddModule}
      />
      <Route
        path="/module/:moduleStudyId/edit"
        component={EditModule}
      />
      <Route
        exact path="/organisation/add"
        component={AddOrganisation}
      />
      {organisations.map((organisation, i) =>
        <Route
          key={i}
          path={`/organisation/${organisation.slug}`}
          render={props =>
            <OrganisationProvider organisation={organisation}>
              <AdminOrganisationRouter {...props} />
            </OrganisationProvider>
          }
        />
      )}
      <Route
        exact path="/logout"
        component={Logout} />
      <Route component={NotFoundPage} />
    </Switch>
  </Inside>

AdminRouter.displayName = 'AdminRouter'

export default AdminRouter
