import React, { Component } from 'react'

const { Consumer, Provider } = React.createContext(null)

export class NotificationProvider extends Component {
  state = {
    notifications: [],
  }

  notify = notification => {
    this.setState(({ notifications }) => ({
      notifications: [
        ...notifications,
        notification
      ]
    }), () => {
      const duration = notification.duration
        ? notification.duration
        : notification.type === 'success'
          ? 5
          : null

      if (duration) {
        setTimeout(
          this.closeNotification.bind(this, notification),
          duration * 1000
        )
      }
    })
  }

  clearNotifications = () =>
    this.setState({ notifications: [] })

  closeNotification = notification => {
    this.setState(({ notifications }) => ({
      notifications: notifications
        .filter(n => n !== notification)
    }))
  }

  render = () =>
    <Provider
      value={{
        notifications: this.state.notifications,
        notify: this.notify,
        clearNotifications: this.clearNotifications,
        closeNotification: this.closeNotification,
      }}
      {...this.props}
    />
}

export const withNotifications = WrappedComponent => props =>
  <Consumer>
    {({ ...notificationProps }) =>
      <WrappedComponent {...props} {...notificationProps} />
    }
  </Consumer>

export default {
  NotificationProvider,
  withNotifications,
}
