import React from 'react'
import graphql from 'babel-plugin-relay/macro'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { withOrganisation } from 'containers/Organisation'
import { withNotifications } from 'containers/Notifications'
import withQuery from 'containers/withQuery'

import { HeaderMod } from 'containers/Header'
import Loader from 'components/Loader'
import OrganisationManagement from 'components/admin/OrganisationManagement'

const makePlaceholder = ({ logo, background, ...rest }) => ({
  logo_url: logo,
  background_url: background,
  ...rest,
})

const UpdateOrganisation = ({ notify, history,
  organisation: { id, organisation_modules, remindo_organisations, theme, ...organisation } }) =>
  <HeaderMod
    backLink="/#organisations"
    title="Organisatie bewerken"
    hashLinks={[
      { to: '#general',    label: '1. Algemeen' },
      { to: '#remindo',    label: '2. RemindoContent' },
      { to: '#theme',      label: '3. Thema' },
      { to: '#vocabulary', label: '4. Woordgebruik' },
    ]}
  >
    <OrganisationManagement
      data={{
        organisationId: id
      }}
      defaultValues={{
        ...organisation,
        theme: theme
          ? makePlaceholder(theme)
          : null,
        modules: organisation_modules
          .map(organisation_module => organisation_module.module.id),
        remindo_organisations: remindo_organisations
          .map(remindo_organisation => remindo_organisation.id),
      }}
      onSubmit={() => {
        history.push('/#organisations')
        notify({
          type: 'success',
          title: 'Organisatie succesvol bijgewerkt',
        })
      }}
    />
  </HeaderMod>

const getQuery = () => graphql`
  query editOrganisationQuery($organisationId: ID!) {
    organisation(id: $organisationId) {
      id
      name

      show_students_active
      show_students_inactive
      show_students_demo
      show_modules_archived

      is_module_environment

      connecting_field_group

      organisation_modules {
        module {
          id
        }
      }
      remindo_organisations {
        id
      }
      theme {
        logo
        background
        copy

        color_accent
        color_primary
        color_background
        color_mainnav
        color_subnav
        color_icon_mainnav
        color_title
        color_title_link
        color_text
        color_text_link
        color_button
        color_button_hover
        color_outside_button
        color_outside_button_hover
        color_inside_button
        color_inside_button_hover
        color_button_dark
        color_button_dark_hover
        color_footer
        
        hide_footer
        gradient_disabled
      }
      vocabulary {
        teacher_singular
        teacher_plural

        student_singular
        student_plural

        show_invoice_contact
        invoice_contact_singular
        invoice_contact_plural

        show_code
        code_singular
        code_plural

        show_code_invoice
        code_invoice_singular
        code_invoice_plural

        show_code_debtor
        code_debtor_singular
        code_debtor_plural

        show_code_contract
        code_contract_singular
        code_contract_plural

        show_code_project
        code_project_singular
        code_project_plural

        show_groupname
        groupname_singular
        groupname_plural

        show_org_name
        org_name_singular
        org_name_plural
      }
    }
  }
`

const getVariables = ({ organisation }) => ({
  organisationId: organisation.id
})

export default compose(
  withRouter,
  withOrganisation,
  withNotifications,
  withQuery({ getQuery, getVariables, loader: Loader }),
)(UpdateOrganisation)
