import React from 'react'
import graphql from 'babel-plugin-relay/macro'
import withQuery from 'containers/withQuery'
import { withOrganisation } from 'containers/Organisation'
import { compose } from 'recompose'
import styled from 'styled-components'

import { Paper, Table, Styles } from 'hoffelijk-react-components'
import Actions from './Actions'

import defaultIcon from 'assets/images/default_module_icon.png'

const RowIcon = styled.img`
  height: 32px;
  margin: -4px 0;
`

export const ModulesOverview = ({ organisation, organisationModules, loading, error, retry }) =>
  <Paper light expanding>
    <Paper.Section>
      <Styles.NoMargin as="h2">Overzicht modules {organisation.name}</Styles.NoMargin>
    </Paper.Section>
    <Table
      dense
      columns={[
        {
          label: '',
          sortable: false,
          content: organisation_module =>
            <RowIcon
              src={organisation_module.module.module_study.icon
                ? organisation_module.module.module_study.icon : defaultIcon}
              alt={organisation_module.module.module_study.name}
            />
        },
        {
          label: 'Naam',
          content: organisation_module => organisation_module.module.module_study.name,
        },
        {
          label: 'Planning',
          content: organisation_module => organisation_module.has_planning
            ? 'Ja' : 'Nee',
        },
        {
          label: '',
          content: organisation_module =>
            <Actions
              organisation={organisation}
              organisation_module={organisation_module}
              refetch={retry}
            />,
          sortable: false,
        }
      ]}
      data={organisationModules ? organisationModules.filter(organisationModule => organisationModule.module.module_study !== null) : undefined}
      loading={loading}
      error={error}
    />
  </Paper>

const getQuery = () => graphql`
  query ModulesOverviewEditQuery($organisationId: ID!) {
    organisationModules(organisationId: $organisationId) {
      id
      has_planning
      module {
        module_study {
          id
          name
          icon
          slug
        }
      }
    }
  }
`

const getVariables = ({ organisation }) =>
  ({ organisationId: organisation.id })

export default compose(
  withOrganisation,
  withQuery({ getQuery, getVariables })
)(ModulesOverview)
