import React, { Component } from 'react'
import graphql from 'babel-plugin-relay/macro'
import styled, { css } from 'styled-components'
import { compose, withProps } from 'recompose'

import { withOrganisation } from 'containers/Organisation'
import withFilters from 'containers/withFilters'
import withSearch from 'containers/withSearch'
import withQuery from 'containers/withQuery'
import { withMe } from 'containers/Me'
import { withMask } from 'containers/Mask'
import { withModal } from 'containers/Modal'
import { HeaderMod } from 'containers/Header'
import { parseTheme } from 'containers/OrganisationTheme'

import Loader from 'components/Loader'
import Filters from 'components/Filters'
import ProgressOverview from 'components/teacher/progress'
import ProgressManual from 'components/teacher/manual/ProgressManual'
import { Check, Cross } from 'components/glyphs'

import { Icon } from 'hoffelijk-react-components'

import { unique, download } from 'utilities/functions'
import { getAllowedTries, getBestAttempt } from 'utilities/attemptManipulation'
import { GUARANTEE_STATUS, PROGRESS_STATUS } from 'utilities/constants'

import moment from 'moment'
import { parse } from 'json2csv'
import { transformRowData } from '../../components/teacher/progress/Row';
import {
  examOfParticipationIsPassed, formatParticipationResult,
  getFirstFutureExamParticipation,
  getLastPastExamParticipation,
  sortExamParticipationsByExamDate,
} from "../../utilities/examHelpers";

const progressFromStatus = status => {
  switch (status) {
    case PROGRESS_STATUS.NONE:          return 'Geen planning'
    case PROGRESS_STATUS.UNKNOWN:       return 'Geen schema'
    case PROGRESS_STATUS.BEHIND:        return 'Achter op schema'
    case PROGRESS_STATUS.ON_SCHEME:     return 'Op schema'
    case PROGRESS_STATUS.AHEAD:         return 'Voor op schema'
    case PROGRESS_STATUS.TARGET_PASSED: return 'Streefdatum gepasseerd'
    case PROGRESS_STATUS.FINISHED:      return 'Afgerond'
    default: return null
  }
}

const guaranteeFromStatus = status => {
  switch (status) {
    case GUARANTEE_STATUS.NONE:       return 'Geen slagingsgarantie'
    case GUARANTEE_STATUS.PENDING:    return 'In afwachting'
    case GUARANTEE_STATUS.EXPIRED:    return 'Vervallen'
    case GUARANTEE_STATUS.GUARANTEED: return 'Gehaald'
    default: return null
  }
}

const calculateProgress = (recipes) => {
    const total = recipes.length
    const done = recipes
        .filter(recipe => recipe.attempts && recipe.attempts.length > 0).length

    const p = Math.round(100 * done / total)
    return isNaN(p) ? '-' : `${p}%`
}

const toCSV = (data, organisation, organisationModules, t) => {
  const csvData = data
    .map(row => {
      let planningColumns = {};
      let examColumns = {
        examendatum: '',
        examenresultaat: '',
      };

      if (row.progress.status!== null) {
        planningColumns = {
          startdatum: row.start_date ? row.start_date.format('DD-MM-YYYY') : '',
          streefdatum: row.target_date ? row.target_date.format('DD-MM-YYYY') : '',
          voortgangsstatus: row.progress
            ? progressFromStatus(row.progress.status) : null,
          dagen_afwijkend_van_schema: row.progress
            ? row.progress.days : null,
        };
      }

      if (row.firstFutureExamParticipation || row.lastPastExamParticipation) {
        let participation;

        if (examOfParticipationIsPassed(row.lastPastExamParticipation)) {
          participation = row.lastPastExamParticipation;
        } else if (row.firstFutureExamParticipation) {
          participation = row.firstFutureExamParticipation;
        } else {
          participation = row.lastPastExamParticipation;
        }

        examColumns.examendatum = participation.exam_date.format('DD-MM-YYYY');
        examColumns.examenresultaat = formatParticipationResult(participation);
      }

      let csvRow = {
        deelnemer: row.student.name,
        email: row.student.email,
        ...customFields(organisation)
          .reduce((acc, field) => ({...acc, [t(field)]: row.student[field]}), {}),
        opleiding: row.organisation_module.module.module_study.name,
        toetsen_totaal: row.recipes.length,
        toetsen_gemaakt: row.recipes
          .filter(recipe => recipe.attempts.length > 0).length,
        voortgang: calculateProgress(row.recipes),
        slagingsgarantie: guaranteeFromStatus(row.guarantee_status),
        ...planningColumns,
        ...examColumns,
        notities: row.student.student_notes.length > 0 ? row.student.student_notes.slice(1, -1).reduce(
            (accumulator, note) => {
                return `${accumulator}

${moment(note.created_at * 1000).format('YYYY-MM-DD HH:mm:ss')}
${note.note}`;
            },
            `${moment(row.student.student_notes[0].created_at * 1000).format('YYYY-MM-DD HH:mm:ss')}
${row.student.student_notes[0].note}`
        ).replace('\n', '\r\n') : '',
      };

      const getBestAttemptDate = (recipe) => {
        const bestAttempt = getBestAttempt(recipe.attempts, getAllowedTries(row.counted_recipes, recipe.id));
        if (bestAttempt === null) {
          return '';
        }

        return bestAttempt.date.format('DD-MM-YYYY HH:mm');
      };

      // Only show recipes in the export when exporting a single
      // module. Exporting the list of all modules (Alle opleidingen)
      // should not include recipes because that list will be too
      // long.
      if (organisationModules.length === 1) {
        csvRow = {
          ...csvRow,
          ...row.recipes.reduce((acc, recipe) => {
            return ({
              ...acc,
              [recipe.name]: (getBestAttempt(recipe.attempts, getAllowedTries(row.counted_recipes, recipe.id)) || {}).grade,
              [recipe.name + " datum"]: getBestAttemptDate(recipe),
            })
          }, {})
        }
      }

      return csvRow;
    });

  return parse(csvData, {
    fields: Object.keys(csvData[0]),
    delimiter: ';',
    preserveNewLinesInValues: true,
  })
}

const RightCross = styled(Cross)`
  float: right;
  margin-left: 10px;
  color: ${props => props.theme.color.gray40};
`

const RightCheck = styled(Check)`
  float: right;
  margin-left: 10px;
  color: ${props => props.theme.color.gray40};
`

const RightIcon = styled(Icon)`
  float: right;
  margin-left: 10px;

  color: ${props => props.theme.color.gray40};
  ${props => props.error && css`color: ${props.theme.color.error}`}
  ${props => props.warning && css`color: ${props.theme.color.warning}`}
  ${props => props.success && css`color: ${props.theme.color.success}`}
`

const Large = styled.span`
  float: right;
  display: inline-block;
  width: 20px;
  margin-left: 10px;

  line-height: 18px;
  text-align: center;
  font-size: 20px;
  font-weight: normal;

  color: ${props => props.theme.color.gray40};
`

const isDownloadButtonEnabled = (data) => {
  return data.length > 0;
}

const ProgressPage = ({ me, mask, theme, popup, t, organisation, organisationModules, data, hasExamParticipations, loading, error, query, setQuery, ...rest }) =>
  <HeaderMod
    title={`Progressie ${t('student_plural')}`}
    backLink={`/${organisation.slug}/dashboard`}
    actions={[
      {
        label: 'Download Data',
        icon: 'download',
        disabled: !isDownloadButtonEnabled(data),
        onClick: () => {
          download(
            `progressie.csv`,
            toCSV(data, organisation, organisationModules, t)
          );
        }
      },
      {
        label: 'Handleiding',
        icon: 'question-mark',
        onClick: () => popup(<ProgressManual t={t} />, theme),
      },
    ]}>
    <Filters
      low
      {...rest}
    />
    <ProgressOverview
      data={data}
      hasExamParticipations={hasExamParticipations}
      loading={loading}
      error={error}
      query={query}
      setQuery={setQuery}
      teacher={mask ? mask : me}
      organisationModules={organisationModules}
    />
  </HeaderMod>

const getQuery = () => graphql`
  query progressQuery($organisationModuleId: ID! $teacherId: ID!) {
    organisationModule(
      id: $organisationModuleId
    ) {
      id
      has_planning
      module {
        id
        module_study {
          id
          icon
          name
          slug
          study {
            id
          }
          guarantee {
            allowed_fails
            duration

            first_recipe {
              id
            }
            last_recipe {
              id
            }
            fails_apply_to {
              id
            }  
            counting_recipes {
              id
              allowed_tries
            }
          }
          module_study_recipes_planning {
            planning
            name
            recipe {
              id
            }
          }

          connecting_field_planning_wft_module
        }
      }
    }

    students(
      teacherId: $teacherId
      organisationModuleId: $organisationModuleId
    ) {
      id
      name
      first_name
      middle_name
      last_name
      email
      student_notes {
        id
        created_at
        note
      }
      invoice_contact
      code
      code_invoice
      code_debtor
      code_contract
      code_project
      groupname
      org_name
      wft_planning
      contact_number

      candidate {
        id
      }
    }

    exam_participations(
        teacherId: $teacherId
        organisationModuleId: $organisationModuleId
    ) {
        exam_date
        contact_number
        result_state
        state
        no_show

        exam_result {
            exam {
                id
            }

            grade

            exam_subject_results {
                score
                subject 
            }
        }
    }

    recipes(
      organisationModuleId: $organisationModuleId
    ) {
      id
      name
      order
      category
      category_order
    }

    results(
      organisationModuleId: $organisationModuleId
      teacherId: $teacherId
    ) {
      id
      grade
      score
      max_score
      try
      started_at
      finished_at
      candidate {
        id
      }
      recipe {
        id
      }
    }
  }
`

const customFields = organisation => organisation.vocabulary
  ? Object.keys(organisation.vocabulary)
      .reduce((acc, key) => organisation.vocabulary[key] === true
        ? [...acc, key.replace('show_', '')]
        : acc, [])
  : []

const stringCompare = (a, b) =>
  (a.toLowerCase() < b.toLowerCase())
    ? -1
    : (a.toLowerCase() > b.toLowerCase())
      ? 1 : 0

const withDataProps = withProps(({
  organisationModule, students, recipes, results, exam_participations, hasExamParticipations,
  data=[], organisation, allStudents, organisationModules
}) => {
  const sortedPlanningRecipes = organisationModule.module.module_study.module_study_recipes_planning
    .slice().sort((a, b) => (Number(a.planning) < Number(b.planning)) ? -1 : 1);

  const planning = organisationModule.has_planning
    ? {
        planning_recipes: sortedPlanningRecipes,
        planning_recipe_ids: sortedPlanningRecipes.map(planning_recipe => planning_recipe.recipe.id),
      }
    : null

  const enhancedRecipes = recipes.map(recipe => {
    const planning_recipe = planning
      ? planning.planning_recipes
          .find(planning_recipe => planning_recipe.recipe.id === recipe.id)
      : null

    return {
      ...recipe,
      name: planning_recipe && planning_recipe.name
        ? planning_recipe.name
        : recipe.name,
      planning_order: planning ? planning.planning_recipe_ids.indexOf(recipe.id) : 0,
      planning: planning_recipe
        ? planning_recipe.planning
        : null
    }
  })

  return {
    organisationModules: [...organisationModules, organisationModule],
    allStudents: [...(allStudents || []), ...students],

    // Remember the 'hasExamparticipations' bool so it works when viewing all modules (Alle opleidingen).
    // This function is called for each module in the Alle opleidingen page.
    hasExamParticipations: hasExamParticipations || exam_participations.length > 0,
    data: data.concat(students.map(student => {
      function parseDate(value) {
        if (value === undefined) {
          return null;
        }

        const date = moment(value, 'YYYY-MM-DD');

        return date.isValid() ? date : null;
      }

      let startDate = null, targetDateOfModule = null;

      const wftModuleName = organisationModule.module.module_study.connecting_field_planning_wft_module;
      if (student.wft_planning && wftModuleName) {
        const planningSettings = JSON.parse(student.wft_planning);

        if (planningSettings[wftModuleName] !== undefined) {
          startDate = parseDate(planningSettings[wftModuleName]['date_start']);
          targetDateOfModule = parseDate(planningSettings[wftModuleName]['date_target']);
        }

        if (startDate === null || targetDateOfModule === null) {
          startDate = null;
          targetDateOfModule = null;
        }
      }

      function calculateRecipeTargetDate(percentage) {
        if (startDate === null || targetDateOfModule === null) {
          return null;
        }

        const startSeconds = Number(startDate.format('X'));
        const endSeconds = Number(targetDateOfModule.format('X'));

        const targetSeconds = startSeconds + (endSeconds - startSeconds) / 100 * percentage;

        return moment.unix(targetSeconds);
      }

      const sortedExamParticipations = sortExamParticipationsByExamDate(
        exam_participations.map(
          participation => ({
            ...participation,
            exam_date: moment.unix(participation.exam_date),
          })
        ).filter(
          participation => String(participation.contact_number) === student.contact_number
        )
      )

      return {
        student,
        organisation_module: organisationModule,
        recipes: enhancedRecipes.map(recipe => ({
          ...recipe,
          target_date: (organisationModule.has_planning && recipe.planning)
            ? calculateRecipeTargetDate(Number(recipe.planning))
            : null,
        })),
        start_date: startDate,
        target_date: targetDateOfModule,
        planning,
        guarantee: organisationModule.module.module_study.guarantee,
        results: results.filter(result => student.candidate !== null && result.candidate.id === student.candidate.id),
        exam_participations: sortedExamParticipations,
        lastPastExamParticipation: getLastPastExamParticipation(sortedExamParticipations),
        firstFutureExamParticipation: getFirstFutureExamParticipation(sortedExamParticipations),
      }
    })).map(transformRowData)
  }
})

const withFilterProps = withProps(({ organisation_modules, data, allStudents, organisation, t }) => ({
  filters: [
    ...customFields(organisation)
      .map(field => ({
        label: t(field, ['capitalize']),
        type: 'select',
        options: unique(allStudents.map(student => student[field])
          .map(option => option ? option : `Geen ${t(field)}`))
          .sort(stringCompare),
        fragment: row => row.student[field],
      })),
    {
      label: 'Opleiding',
      type: 'select',
      options: organisation_modules
        .map(organisation_module => organisation_module.module.module_study.name)
        .sort(stringCompare),
      fragment: row => row.organisation_module.module.module_study.name,
    },
    {
      label: 'Toetsdatum',
      type: 'daterange',
      min: moment().subtract(2, 'year'),
      max: moment(),
      filterFn: ({ from, to }) => data => data.map(row => row.recipes && (from || to)
        ? {
            ...row,
            recipes: row.recipes
              .filter(recipe => {
                const date = recipe.attempts && recipe.attempts.length > 0
                  ? getBestAttempt(recipe.attempts, getAllowedTries(row.guarantee, recipe.id)).date
                  : null

                return date &&
                  !(from && date.startOf('day').isBefore(from)) &&
                  !(to && date.endOf('day').isAfter(to))
              }),
          }
        : row)
    },
    ...(data
      .find(row => row.guarantee !== null)
        ? [{
            label: 'Slagingsgarantie',
            type: 'select',
            options: [
              { match: GUARANTEE_STATUS.EXPIRED,    label: <span>Vervallen <RightCross size={20} /></span> },
              { match: GUARANTEE_STATUS.PENDING,    label: <span>In afwachting <RightIcon size={20} icon="hourglass" /></span> },
              { match: GUARANTEE_STATUS.GUARANTEED, label: <span>Gehaald <RightCheck size={20} /></span> },
              { match: GUARANTEE_STATUS.NONE,       label: <span>Geen slagingsgarantie</span> },
            ],
            fragment: row => row.guarantee_status,
          }]
        : []
    ),
    ...(data
        .find(row => row.planning !== null)
        ? [
          {
            label: 'Streefdatum',
            type: 'daterange',
            min: moment.min(data.map(row => row.target_date).filter(moment.isMoment)),
            max: moment.max(data.map(row => row.target_date).filter(moment.isMoment)),
            fragment: row => row.target_date
              ? row.target_date
              : null,
          },
          {
            label: 'Voortgang opleiding',
            type: 'select',
            options: [
              { match: 0, label: <span>Geen schema<Large>?</Large></span> },
              { match: 1, label: <span>Achter op schema <RightIcon size={20} error icon="snail" /></span> },
              { match: 2, label: <span>Op schema <RightIcon size={20} warning icon="calendar-check" /></span> },
              { match: 3, label: <span>Voor op schema <RightIcon size={20} success icon="rabbit" /></span> },
              { match: 4, label: <span>Streefdatum gepasseerd <RightIcon size={20} error icon="calendar-close" /></span> },
              { match: 5, label: <span>Afgerond <RightIcon size={20} success icon="medal" /></span> },
              { match: null, label: <span>Geen planning</span> },
            ],
            fragment: row => row.progress
              ? row.progress.status
              : null
          },
        ]
        : []
    ),
    ...(data
        .find(row => row.exam_participations.length > 0)
        ? [
          {
            label: 'Examendatum',
            type: 'daterange',
            min: moment.min(data.map(row => (
              (row.lastPastExamParticipation)
                ? row.lastPastExamParticipation.exam_date
                : row.firstFutureExamParticipation
                  ? row.firstFutureExamParticipation.exam_date
                  : null
            )).filter(moment.isMoment)),
            max: moment.max(data.map(row => (
              (row.firstFutureExamParticipation)
                ? row.firstFutureExamParticipation.exam_date
                : row.lastPastExamParticipation
                ? row.lastPastExamParticipation.exam_date
                : null
            )).filter(moment.isMoment)),
            filterFn: ({ from, to }) => data => data.map(
              row => row.exam_participations.find(
                ep => (!from || from.startOf('day').isBefore(ep.exam_date)) &&
                    (!to || to.endOf('day').isAfter(ep.exam_date))
              ) ? row : null
            ).filter(row => row !== null)
          }
        ]
        : []
    )
  ]
}))

const branchOnOrganisationModules = WrappedComponent =>
  class extends Component {
    render() {
      const { me, mask, organisation_modules } = this.props
      const teacherId = mask ? mask.id : me.id

      const getVariables = organisation_module => () => ({
        teacherId,
        organisationModuleId: organisation_module.id
      })

      return compose(
        ...organisation_modules.map(organisation_module => [
          withQuery({
            getQuery,
            getVariables: getVariables(organisation_module),
            loader: Loader
          }),
          withDataProps,
        ]).reduce((acc, xs) => [...acc, ...xs], []),
        withFilterProps,
        withFilters,
        withSearch([
          row => row.student.name,
          row => row.organisation_module.module.module_study.name,
        ]),
      )(WrappedComponent)(this.props)
    }
  }

export default compose(
  withMe,
  withMask,
  withOrganisation,
  withModal,
  withProps(({ organisation }) => ({
    theme: parseTheme(organisation.theme || {}),
    organisationModules: [],
  })),
  branchOnOrganisationModules
)(ProgressPage)
