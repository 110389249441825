import React from 'react'

import { Table } from 'hoffelijk-react-components'

const EditOrganisationModule = ({ organisation, organisation_module }) =>
  <Table.Action
    title="Bewerk" icon="pen"
    to={`/organisation/${organisation.slug}/modules/${organisation_module.id}/edit`} />

export default ({ organisation, organisation_module }) =>
  <Table.Actions>
    <EditOrganisationModule organisation={organisation} organisation_module={organisation_module} />
  </Table.Actions>
