import React from 'react'
import styled from 'styled-components'

import Table from './'
import Loading from '../Loading'

const CellLoading = styled(Loading)`
  width: calc(100% - 1em);
  height: 1em;
`

export default ({ columns, dense }) =>
  <Table.Row>
    {columns.map((_, j) =>
      <Table.Cell dense={dense} key={j}><CellLoading /></Table.Cell>
    )}
  </Table.Row>
