import React from 'react'
import styled from 'styled-components'
import { darken } from 'polished'

import { Wrapper, Row, Column } from 'hoffelijk-react-components'

const Background = styled.div`
  position: absolute;
  right: 0; top: 0;
  bottom: 0;
  z-index: -1;

  width: calc(50% + 97px);

  background: url(${props => props.theme.background}) no-repeat center / cover;
`

const Overlay = styled.div`
  position: absolute;
  left: 0; top: 0;
  bottom: 0;
  z-index: -1;

  width: calc(50% - 97px);
  min-width: ${100*5/12}%;

  background: linear-gradient(
    ${props => darken(.2, props.theme.color.mainnav)},
    ${props => props.theme.color.mainnav}
  );
`

const Outside = styled.div`
  position: relative;
  color: ${props => props.theme.color.white};

  height: 1px;
  min-height: 100%;
  display: flex;
  align-items: center;
`

const OutsideWrapper = styled(Wrapper)`
  flex-shrink: 0;
  flex-grow: 1;
`

export default ({children, ...rest}) =>
  <Outside {...rest}>
    <Background />
    <Overlay />
    <OutsideWrapper>
      <Row>
        <Column size={5}>
          {children}
        </Column>
      </Row>
    </OutsideWrapper>
  </Outside>
