import React from 'react'
import styled from 'styled-components'

import { Paper, Styles, Row, Column } from 'hoffelijk-react-components'
import Statistics from './Statistics'

import { getCategories } from 'utilities/attemptManipulation'

const StyledColumn = styled(Column)`
  display: inline-block;
  vertical-align: bottom;
  margin-bottom: 2em;
  float: none;
`

export default ({ recipe_name, allowed_tries, sections, t }) =>
  <Paper light>
    <Styles.NoVerticalPadding as={Paper.Section}>
      <h4>{recipe_name}</h4>
      <Row>
        {sections.map(section =>
          <StyledColumn key={section.name} size={4}>
            {section.name}
            <Statistics categories={getCategories(allowed_tries, section.students)} t={t} />
          </StyledColumn>
        )}
      </Row>
    </Styles.NoVerticalPadding>
  </Paper>
