/**
 * @generated SignedSource<<f6cc2f788a05268bde33db95af0e8a73>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organisationId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "organisationId",
        "variableName": "organisationId"
      },
      {
        "kind": "Literal",
        "name": "role",
        "value": "admin"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "users",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "avatar",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "login_count",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "last_login_at",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "roles",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "active",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "activation_sent_at",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminsOverviewOrgQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AdminsOverviewOrgQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7609d920850f5c7d5fd98c2f57de86d0",
    "id": null,
    "metadata": {},
    "name": "AdminsOverviewOrgQuery",
    "operationKind": "query",
    "text": "query AdminsOverviewOrgQuery(\n  $organisationId: ID!\n) {\n  users(role: \"admin\", organisationId: $organisationId) {\n    id\n    avatar\n    name\n    email\n    login_count\n    last_login_at\n    roles\n    active\n    activation_sent_at\n  }\n}\n"
  }
};
})();

node.hash = "611bc2f6592bac85581774c686a74b37";

module.exports = node;
