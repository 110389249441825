import React from 'react'
import { withOrganisation } from 'containers/Organisation'
import { HeaderMod } from 'containers/Header'

import OrganisationAdminsOverview from 'components/admin/organisation/AdminsOverview'

const OrganisationAdminsOverviewWithOrganisation =
  withOrganisation(OrganisationAdminsOverview)

export default () =>
  <HeaderMod
    title={`Organisatiebeheerders`}
    backLink="/"
  >
    <OrganisationAdminsOverviewWithOrganisation />
  </HeaderMod>
