import React from 'react'
import styled from 'styled-components'
import { onlyUpdateForKeys } from 'recompose'

import { Link } from 'react-router-dom'
import { Wrapper, Float, Icon, Button } from 'hoffelijk-react-components'

const SubNav = styled.nav`
  display: block;
  height: 80px;
  padding: 18.5px 0;

  background-color: ${props => props.theme.color.subnav};
  color: ${props => props.theme.color.white};
`

const LeftIcon = styled(Icon)`
  display: inline-block;
  margin-right: 10px;

  fill: ${props => props.theme.color.white};
`

const HashLink = styled(Link)`
  display: inline-block;
  padding: .8125rem 1.125rem;
  margin-left: 10px;
  border-radius: 100em;

  vertical-align: top;
  line-height: 1;
  font-weight: bold;

  background-color: ${props => props.theme.color.white};
  color: ${props => props.theme.color.subnav};

  &:hover {
    color: ${props => props.theme.color.text};
  }
`
const Action = styled(Button)`margin-left: 10px;`

const BackLink = styled(Link)`
  display: inline-block;
  padding: 12px 0;

  font-weight: bold;
`

export default onlyUpdateForKeys([
  'backLink', 'hashLinks', 'actions'
])(({ backLink, hashLinks, actions }) =>
  <SubNav>
    <Wrapper>
      <Float.Left>
        {backLink
          ? <BackLink to={backLink}>
              <LeftIcon icon="arrow-left" size={20} />Terug
            </BackLink>
          : null}
      </Float.Left>
      <Float.Right>
        {hashLinks.map(({ label, ...rest }, i) =>
          <HashLink key={i} {...rest}>{label}</HashLink>)}
        {actions.map(({ label, ...rest }, i) =>
          <Action dark key={i} {...rest}>{label}</Action>)}
      </Float.Right>
    </Wrapper>
  </SubNav>
)
