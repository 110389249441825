import React from 'react'

const { Consumer, Provider } = React.createContext(null)

export const MeProvider = ({ me, ...rest }) =>
  <Provider value={me} {...rest} />

export const withMe = WrappedComponent => props =>
  <Consumer>
    {me =>
      <WrappedComponent {...props} me={me} />}
  </Consumer>

export default {
  MeProvider,
  withMe
}
