/**
 * @generated SignedSource<<a03e7734ca2ec86788ad20217a67b362>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "LoginUserPayload",
    "kind": "LinkedField",
    "name": "passwordReset",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "accessToken",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PasswordResetFormMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PasswordResetFormMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c9fc6f7307d989444e030557fbae6808",
    "id": null,
    "metadata": {},
    "name": "PasswordResetFormMutation",
    "operationKind": "mutation",
    "text": "mutation PasswordResetFormMutation(\n  $input: PasswordResetInput!\n) {\n  passwordReset(input: $input) {\n    accessToken\n  }\n}\n"
  }
};
})();

node.hash = "9055a6ed1a7d805d5d74a7c43f0e036b";

module.exports = node;
