import moment from 'moment'
import { PROGRESS_STATUS, GUARANTEE_STATUS, CATEGORIES } from 'utilities/constants'
import { getAllowedTries, getBestAttempt, getCategory } from 'utilities/attemptManipulation'

const calculateProgress = (row, recipes) => {
  if (row.planning === null) {
    return {
      status: PROGRESS_STATUS.NONE,
      days: null
    }
  }

  const planningRecipes = recipes
    .filter(recipe => recipe.planning !== null)

  if (planningRecipes.every(recipe => recipe.attempts.length > 0)) {
    return {
      status: PROGRESS_STATUS.FINISHED,
      days: null,
    }
  }

  const now = moment()

  if (!row.target_date) {
    return {
      status: PROGRESS_STATUS.UNKNOWN,
      days: null,
    }
  }

  if (now.isAfter(row.target_date)) {
    return {
      status: PROGRESS_STATUS.TARGET_PASSED,
      days: row.target_date.diff(now, 'days'),
    }
  }

  const daysBehind = planningRecipes
    .filter(test => test.attempts.length === 0)
    .filter(test => test.target_date.isBefore(now))
    .map(expiredRecipe => Math.abs(expiredRecipe.target_date.diff(now, 'days')))

  if (daysBehind.length > 0) {
    return {
      status: PROGRESS_STATUS.BEHIND,
      days: 0 - Math.max(...daysBehind),
    }
  }

  const upcommingRecipes = planningRecipes
    .filter(test => test.target_date.isAfter(now))

  if (upcommingRecipes.length === 0) {
    return {
      status: PROGRESS_STATUS.UNKNOWN,
      days: null,
    }
  }

  if (upcommingRecipes[0].attempts.length === 0) {
    return {
      status: PROGRESS_STATUS.ON_SCHEME,
      days: 0,
    }
  }

  const completedUpcommingRecipes = upcommingRecipes
    .filter(test => test.attempts.length > 0)

  return {
    status: PROGRESS_STATUS.AHEAD,
    days: completedUpcommingRecipes[completedUpcommingRecipes.length - 1]
      .target_date.diff(now, 'days')
  }
}

const calculateGuaranteeStatus = (row, recipes) => {
  const guarantee = row.guarantee

  if (guarantee === null) {
    return GUARANTEE_STATUS.NONE
  }

  const counting_recipe_ids = guarantee.counting_recipes
    ? guarantee.counting_recipes.map(recipe => recipe.id)
    : { includes: () => true }

  const countingRecipes = recipes
    .filter(recipe => counting_recipe_ids.includes(recipe.id))

  const takenRecipes = countingRecipes
    .filter(recipe => recipe.attempts.length > 0)

  // Find out of the recipes configured for "allowed to fail" do not exceed
  // the allowed_to_fail limit
  const recipesTakenAndAllowedToFail = takenRecipes.filter(recipe => {
    return guarantee.fails_apply_to.filter(recipeAllowedToFail => recipe.id === recipeAllowedToFail.id).length > 0;
  });

  const recipesPassedAndAllowedToFail = recipesTakenAndAllowedToFail.filter(recipe => {
    const bestAttempt = getBestAttempt(recipe.attempts, getAllowedTries(guarantee, recipe.id));

    return bestAttempt && [CATEGORIES.PASSED, CATEGORIES.EXCELLENT].includes(getCategory(bestAttempt.grade, bestAttempt.max_score));
  });

  if (recipesTakenAndAllowedToFail.length - recipesPassedAndAllowedToFail.length > guarantee.allowed_fails) {
    return GUARANTEE_STATUS.EXPIRED
  }

  // See if other any other recipes are failed, taking into account those allowed to fail
  const recipesTakenAndNotAllowedToFail = takenRecipes
    .filter(recipe => !recipesTakenAndAllowedToFail.includes(recipe))

  const recipesPassedOrPendingAndNotAllowedToFail = recipesTakenAndNotAllowedToFail
    .filter(recipe => {
      const allowedTries = getAllowedTries(guarantee, recipe.id);

      // Recipe is pending, might have failures, but still within configured max_attempts
      if (allowedTries > recipe.attempts.length) {
        return true;
      }

      const bestAttempt = getBestAttempt(recipe.attempts, allowedTries);

      // Not within max_attempts: best attempt a failure? no slagingsgarantie!
      return bestAttempt && [CATEGORIES.PASSED, CATEGORIES.EXCELLENT]
        .includes(getCategory(bestAttempt.grade, bestAttempt.max_score))
    })

  if (recipesTakenAndNotAllowedToFail.length - recipesPassedOrPendingAndNotAllowedToFail.length > 0) {
    return GUARANTEE_STATUS.EXPIRED
  }

  // Student did not fail the guarantee, but maybe he's behind schedule?
  const firstRecipe = takenRecipes.find(recipe => recipe.id === guarantee.first_recipe.id)

  if (firstRecipe) {
    const guarantee_expiry_date = firstRecipe.attempts
      .sort((a, b) => a.try - b.try)[0].date
      .add(guarantee.duration, 'day')

    const lastRecipe = takenRecipes.find(recipe => recipe.id === guarantee.last_recipe.id)
    const guarantee_finish_date = lastRecipe
      ? lastRecipe.attempts
          .sort((a, b) => b.try - a.try)[0].date
      : moment()

    if (guarantee_finish_date.isAfter(guarantee_expiry_date)) {
      return GUARANTEE_STATUS.EXPIRED
    }
  }

  // Not behind schedule: student either passed all, or some recipes are still pending
  return takenRecipes.length === countingRecipes.length
    ? GUARANTEE_STATUS.GUARANTEED
    : GUARANTEE_STATUS.PENDING
}

export const transformRowData = row => {
  const recipes = row.recipes.map(recipe => ({
    ...recipe,
    attempts: row.results
    .filter(res => res.recipe.id === recipe.id)
    .map(res => ({
        try: res.try,
        date: moment(res.started_at),
        grade: res.grade === null
          ? `${res.max_score === 0 ? 100 : Math.round(100 * res.score / res.max_score)}%`
          : res.grade,
        duration: res.finished_at && res.started_at
          ? moment.utc(moment(res.finished_at).diff(moment(res.started_at)))
          : null
      })),
  }))

  return {
    ...row,
    recipes,
    progress: calculateProgress(row, recipes),
    guarantee_status: calculateGuaranteeStatus(row, recipes),
    guarantee: row.guarantee,
  }
}
