import React, { Component } from 'react'
import styled from 'styled-components'
import { darken } from 'polished'

import Icon from '../Icon'
import { InputError } from './Input'

const AvatarContainer = styled.div`
  position: relative;

  display: inline-block;
  width: 130px;
  margin-right: 30px;
`

const AvatarPreview = styled.div`
  position: relative;
  overflow: hidden;

  width: 100%;
  padding-top: 100%;
  border-radius: 100%;

  text-align: center;
  text-transform: uppercase;
  line-height: 150px;
  font-size: 48px;
  font-weight: bold;
  font-family: ${props => props.theme.font.heading};

  background-color: ${props => props.theme.color.gray20};
  color: ${props => props.theme.color.gray40};
`

const Placeholder = styled.span`
  position: absolute;
  left: 0; top: 0;
  right: 0; bottom: 0;
`

const Avatar = styled.img`
  position: absolute;
  left: 50%; top: 50%;

  height: 100%;

  transform: translate(-50%, -50%);
`

const AvatarInput = styled.label`
  cursor: pointer;

  position: absolute;
  top: 6px;
  right: -10px;

  width: 40px;
  height: 40px;
  padding: 9px 10px 11px;
  border-radius: 100%;

  background-color: ${props => props.theme.color.button_hover};
  color: ${props => props.theme.color.white};

  &:hover {
    background-color: ${props => darken(.2, props.theme.color.button_hover)};
  }

  input {
    display: none;
  }
`

const InputIcon = styled(Icon)`
  fill: ${props => props.theme.color.white};
`

const Errors = styled.div`
  width: 256px;
  margin-left: -.5em;
`

export default class extends Component {
  state = {
    src: null
  }

  onChange = event => {
    if (event.target.files.length !== 1) { return }
    
    const { onChange } = this.props
    const value = event.target.files[0]

    const reader = new FileReader()
    reader.onload = e => {
      this.setState({ src: e.target.result })
      onChange && onChange({
        filename: value.name,
        mimetype: value.type,
        size: value.size,
        data: e.target.result.split(';base64,')[1],
      })
    }
    reader.readAsDataURL(value)
  }

  render = () => {
    const { error, placeholder, defaultValue } = this.props

    return (
      <AvatarContainer>
        <AvatarPreview>
          {this.state.src || defaultValue
            ? <Avatar src={this.state.src || defaultValue} />
            : <Placeholder>{placeholder}</Placeholder>}
        </AvatarPreview>
        {error
          ? <Errors>
              {error.map((err, i) =>
                <InputError>{err}</InputError>
              )}
            </Errors>
          : null}
        <AvatarInput>
          <InputIcon icon="camera" size={20} />
          <input
            type="file"
            accept=".png,.jpg,.jpeg,.svg,.webp,.bmp,.gif, image/*"
            onChange={this.onChange}
          />
        </AvatarInput>
      </AvatarContainer>
    )
  }
}
