import React, { Fragment } from 'react'
import styled, { css } from 'styled-components'
import { darken } from 'polished'
import { compose, onlyUpdateForKeys, withProps } from 'recompose'

import MainNav from './MainNav'
import SubNav from './SubNav'

const HeaderBackground = styled.div`
  position: fixed;
  left: 0; top: 0;
  right: 0;

  ${props => props.hasSubNav && css`
    z-index: 2;
  `}

  height: ${props => props.down
    ? 130
    : 230 - 50 * props.hasSubNav + 70 * props.hasLogo}px;

   ${props => props.theme.gradient_disabled ? css`background: ${props => props.theme.color.mainnav}`
    : css`background: linear-gradient(
    ${props => darken(.2, props.theme.color.mainnav)},
    ${props => props.theme.color.mainnav}
  );`
    }

  transition: height .2s ease-out;
`

const Header = styled.header`
  position: fixed;
  left: 0; top: 0; right: 0;

  z-index: 2;

  ${props => {
    const h = 180 + 70 * props.hasLogo + 109 * props.hasSubNav
    return css`
      & + .pagewrapper {
        position: relative;
        z-index: 1;

        min-height: calc(100vh - ${h}px - ${props.theme.hide_footer ? 0 : 298}px);
        margin-top: ${h}px;
      }
    `
    }
  }
`

const HeaderComponent = ({ down, title, me, logo, organisation, hasSubNav, upLinks, backLink, hashLinks, actions }) =>
  <Fragment>
    <HeaderBackground hasLogo={!!logo} hasSubNav={hasSubNav} down={down} />
    <Header hasSubNav={hasSubNav} hasLogo={!!logo} down={down}>
      <MainNav
        logo={logo}
        down={down}
        hasSubNav={hasSubNav}
        title={title}
        me={me}
        organisation={organisation}
        upLinks={upLinks}
      />
      {hasSubNav
        ? <SubNav
            backLink={backLink}
            hashLinks={hashLinks}
            actions={actions}
          />
        : null}
    </Header>
  </Fragment>

const mapPropsToProps = ({ organisation, backLink, hashLinks=[], actions=[] }) => ({
  hasSubNav: !!backLink || hashLinks.length > 0 || actions.length > 0,
  logo: organisation && organisation.theme && organisation.theme.logo
    ? organisation.theme.logo : null
})

export default compose(
  onlyUpdateForKeys([
    'down', 'title', 'me', 'organisation',
    'upLinks', 'backLink', 'hashLinks', 'actions'
  ]),
  withProps(mapPropsToProps),
)(HeaderComponent)
