import React from 'react'
import { pure, shouldUpdate } from 'recompose'

import { TableBody, TableRow, TableCell } from './Table'

const PureTableCell = pure(TableCell)

const Row = shouldUpdate(
  (props, nextProps) => Object.keys(nextProps.row)
    .some(key => nextProps.row[key] !== props.row[key])
)(({ row, columns }) =>
  <TableRow>
    {columns.map((column, i) =>
      <PureTableCell align={column.align} key={i}>{column.content(row)}</PureTableCell>
    )}
  </TableRow>
)

const ActiveState = ({ columns, data }) =>
  <TableBody>
    {data.map(row => {
      let key;

      if (columns[0].comparableContent) {
        key = columns[0].comparableContent(row);
      } else if (row.key !== undefined) {
        key = row.key;
      } else {
        key = columns[0].content(row)
      }

      return (<Row
          key={key}
          row={row}
          columns={columns}
        />);
      }
    )}
  </TableBody>

export default ActiveState
