import React, { Component } from 'react'
import { onlyUpdateForKeys } from 'recompose'
import deepEqual from 'deep-equal'

const { Consumer, Provider } = React.createContext(null)

class HeaderProvider extends Component {
  state = { mods: [] }

  applyMod = (hash, mod) => {
    this.setState(state => ({
      mods: [ ...state.mods, { hash, mod } ]
    }))
  }

  updateMod = (newHash, newMod) => {
    this.setState(state => ({
      mods: state.mods.map(({ hash, mod }) => ({
        hash,
        mod: hash === newHash ? newMod : mod
      }))
    }))
  }

  removeMod = (hash) => {
    this.setState(state => ({
      mods: state.mods.filter(mod => mod.hash !== hash)
    }))
  }

  getValueFromMods = () => {
    const { mods } = this.state

    const mergeValues = (current, { title, backLink, hashLinks=[], upLinks=[], actions=[] }) => ({
      title: title ? title : current.title,
      backLink: backLink ? backLink : current.backLink,
      hashLinks: current.hashLinks.concat(hashLinks),
      upLinks: current.upLinks.concat(upLinks),
      actions: current.actions.concat(actions),
    })

    return mods.reduce(
      (acc, mod) => mergeValues(acc, mod.mod),
      { title: 'Dynamisch dashboard', backLink: null, hashLinks: [], upLinks: [], actions: [] }
    )
  }

  render () {
    return (
      <Provider
        value={{
          nav: this.getValueFromMods(),
          applyMod: this.applyMod,
          updateMod: this.updateMod,
          removeMod: this.removeMod,
        }}
        {...this.props}
      />
    )
  }
}

class Mod extends Component {
  state = {
    hash: null
  }

  componentDidMount() {
    const { title, backLink, hashLinks, upLinks, actions, applyMod } = this.props
    const mod = { title, backLink, upLinks, hashLinks, actions }
    const hash = `${10000 * Math.random()}`
    applyMod(hash, mod)
    this.setState({ hash })
  }

  shouldComponentUpdate(nextProps) {
    const { title, backLink, hashLinks, upLinks, actions } = this.props
    return (
      !deepEqual(nextProps.title, title) ||
      !deepEqual(nextProps.backLink, backLink) ||
      !deepEqual(nextProps.hashLinks, hashLinks) ||
      !deepEqual(nextProps.upLinks, upLinks) ||
      !deepEqual(nextProps.actions, actions)
    )
  }

  componentDidUpdate() {
    const { title, backLink, hashLinks, upLinks, actions, updateMod } = this.props
    updateMod(this.state.hash, { title, backLink, hashLinks, upLinks, actions })
  }

  componentWillUnmount() {
    const { removeMod } = this.props
    removeMod(this.state.hash)
  }

  render() {
    return this.props.children || null
  }
}

const HeaderMod = onlyUpdateForKeys([
  'title', 'backLink', 'hashLinks', 'upLinks', 'actions'
])(({ children, ...newMods }) =>
  <Consumer>
    {({ applyMod, updateMod, removeMod }) =>
      <Mod
        {...newMods}
        applyMod={applyMod}
        updateMod={updateMod}
        removeMod={removeMod}
        children={children}
      />
    }
  </Consumer>
)

const withHeader = WrappedComponent => props =>
  <Consumer>
    {({ nav, ...rest }) =>
      <WrappedComponent {...props} {...nav} />
    }
  </Consumer>

export {
  HeaderProvider,
  HeaderMod,
  withHeader,
}
