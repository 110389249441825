import React from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { withNotifications } from 'containers/Notifications'

import { HeaderMod } from 'containers/Header'
import ModuleStudyManagement from 'components/admin/ModuleStudyManagement'

const CreateModuleStudy = ({ notify, history }) =>
  <HeaderMod
    backLink="/#modules"
    title="Nieuwe module"
  >
    <ModuleStudyManagement
      onSubmit={() => {
        notify({
          type: 'success',
          title: 'Module succesvol aangemaakt',
        })
        history.push('/#modules')
      }}
    />
  </HeaderMod>

export default compose(
  withNotifications,
  withRouter,
)(CreateModuleStudy)
