/**
 * @generated SignedSource<<ad4e2ff2b92fb27fd0a3a74e3becb37b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Module",
    "kind": "LinkedField",
    "name": "modules",
    "plural": true,
    "selections": (v2/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Study",
    "kind": "LinkedField",
    "name": "studies",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Recipe",
        "kind": "LinkedField",
        "name": "recipes",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Service",
    "kind": "LinkedField",
    "name": "services",
    "plural": true,
    "selections": (v2/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ModuleStudyManagementQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ModuleStudyManagementQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "e90470051bd5aeb9a6b995f27a394432",
    "id": null,
    "metadata": {},
    "name": "ModuleStudyManagementQuery",
    "operationKind": "query",
    "text": "query ModuleStudyManagementQuery {\n  modules {\n    id\n    name\n  }\n  studies {\n    id\n    name\n    recipes {\n      id\n      name\n      code\n    }\n  }\n  services {\n    id\n    name\n  }\n}\n"
  }
};
})();

node.hash = "17a8d2d18637abe5c984f8a82b1a1004";

module.exports = node;
