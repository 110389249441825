import React from 'react'
import { repeat } from 'utilities/logic'

import Table from './'
import LoadingRow from './LoadingRow'

export default ({ rows=3, columns, dense }) =>
  <Table.Body>
    {repeat(rows)(() =>
      <LoadingRow dense={dense} columns={columns} />
    )}
  </Table.Body>
