import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import { Paper } from 'hoffelijk-react-components'

import basis from 'assets/loader/basis.png'
import conskred from 'assets/loader/conskred.png'
import hflk from 'assets/loader/hflk.png'
import hypkred from 'assets/loader/hypkred.png'
import inkom from 'assets/loader/inkom.png'
import pensioen from 'assets/loader/pensioen.png'
import schadepart from 'assets/loader/schadepart.png'
import schadez from 'assets/loader/schadez.png'
import vermogen from 'assets/loader/vermogen.png'
import zorg from 'assets/loader/zorg.png'

const defaultIcons = [
  { icon: basis, color: '#f0eae5' },
  { icon: conskred, color: '#eaece9' },
  { icon: hflk, color: '#dfe1e3' },
  { icon: hypkred, color: '#e3f2f0' },
  { icon: inkom, color: '#e3f0e5' },
  { icon: pensioen, color: '#def0f5' },
  { icon: schadepart, color: '#f9e2e2' },
  { icon: schadez, color: '#e9e2ed' },
  { icon: vermogen, color: '#fdecdb' },
  { icon: zorg, color: '#edf3e0' },
]

const spin = keyframes`
   10% { transform: rotate( 180deg); }
   20% { transform: rotate( 360deg); }
   30% { transform: rotate( 540deg); }
   40% { transform: rotate( 720deg); }
   50% { transform: rotate( 900deg); }
   60% { transform: rotate(1080deg); }
   70% { transform: rotate(1260deg); }
   80% { transform: rotate(1440deg); }
   90% { transform: rotate(1620deg); }
  100% { transform: rotate(1800deg); }
`

const bounce = keyframes`
  0% { transform: scale(0); }
  10% { transform: scale(1); }
  20% { transform: scale(0); }
`

const bounceMirrored = keyframes`
  0% { transform: scale(0); }
  10% { transform: scale(-1); }
  20% { transform: scale(0); }
`

const LoaderContainer = styled.div`
  width: 95px;
  margin: 75px auto;

  text-align: center;
`

const LoaderIcons = styled.div`
  position: relative;

  width: 75px;
  height: 75px;
  margin: 10px;

  animation: ${spin} 10s infinite ease-in-out;
`

const LoaderIcon = styled.div`
  position: absolute;
  left: 20%;

  width: 60%;
  height: 60%;
  border-radius: 100%;

  background: ${props => props.color || props.theme.color.gray10} url(${props => props.icon}) no-repeat center / 70%;

  ${props => props.i % 2
    ? css`
        bottom: 0;
        transform: scale(0);
        animation: ${bounce} 10s infinite ease-in-out;
      `
    : css`
        top: 0;
        transform: scale(0);
        animation: ${bounceMirrored} 10s infinite ease-in-out;
      `
  }
  animation-delay: ${props => props.i}s;
`

const FullHeight = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;

  text-align: center;
`

export const Loader = ({ icons=defaultIcons }) =>
  <LoaderContainer>
    <LoaderIcons>
      {icons.map(({ icon, color }, i) =>
        <LoaderIcon
          key={i}
          icon={icon}
          color={color}
          i={i}
          n={icons.length}
        />
      )}
    </LoaderIcons>
    <strong>Laden...</strong>
  </LoaderContainer>

export default ({ fullHeight }) =>
  <Paper light>
    {fullHeight
      ? <FullHeight as={Paper.Section}>
          <Loader />
        </FullHeight>
      : <Paper.Section>
          <Loader />
        </Paper.Section>}
  </Paper>
