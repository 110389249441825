import React from 'react'
import graphql from 'babel-plugin-relay/macro'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { withOrganisation } from 'containers/Organisation'
import { withNotifications } from 'containers/Notifications'
import withQuery from 'containers/withQuery'

import { Row, Column } from 'hoffelijk-react-components'
import { HeaderMod } from 'containers/Header'
import AccountManagement from 'components/AccountManagement'
import Loader from 'components/Loader'

const EditUser = ({ notify, organisation, t, history, user }) =>
  <HeaderMod
    backLink={`/${organisation.slug}/#teachers`}
    title={`${t('teacher_singular', ['capitalize'])} bewerken`}
  >
    <Row>
      <Column size={8}>
        <AccountManagement
          data={{
            userId: user.id
          }}
          defaultValues={{
            avatar_url: user.avatar,
            first_name: user.first_name,
            middle_name: user.middle_name,
            last_name: user.last_name,
            email: user.email,
            is_admin: user.roles.includes('admin'),
          }}
          onSubmit={() => {
            history.push(`/${organisation.slug}/#teachers`)
            notify({
              type: 'success',
              title: `${t('teacher_singular', ['capitalize'])} succesvol bijgewerkt`,
            })
          }}
        />
      </Column>
    </Row>
  </HeaderMod>

const getQuery = () => graphql`
  query editTeacherQuery($userId: ID!) {
    user(id: $userId) {
      id
      avatar
      first_name
      middle_name
      last_name
      email
      roles
    }
  }
`

const getVariables = ({ match }) => ({
  userId: match.params.teacherId
})

export default compose(
  withRouter,
  withOrganisation,
  withNotifications,
  withQuery({ getQuery, getVariables, loader: Loader }),
)(EditUser)
