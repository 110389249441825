import React from 'react'
import styled, { css } from 'styled-components'
import { darken } from 'polished'
import { compose, onlyUpdateForKeys } from 'recompose'

import { Link } from 'react-router-dom'
import { Icon, Float, Wrapper } from 'hoffelijk-react-components'
import User from './User'

const OrganisationLogo = styled.img`
  display: block;
  height: 70px;
  margin-bottom: 50px;

  transition:
    height .2s ease-out,
    margin-bottom .2s ease-out;

  ${props => !props.visible && css`
    height: 0;
    margin-bottom: 0;
  `}
`

const LeftFloat = styled(Float.Left)`
  width: calc(100% - 320px);
  padding-right: 30px;
`

const FloatRight = styled(Float.Right)`
  width: 320px;
  position: relative;
`

const Title = styled.h1`
  width: 100%;
  margin: 0;
  font-size: 60px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  transition:
    font-size .2s ease-out,
    padding .2s ease-out,
    margin .2s ease-out;

  ${props => props.small && css`font-size: 32px;`}
  ${props => props.small && props.solo && css`padding-top: 15px;`}
  ${props => !props.solo && css`margin-top: -10px;`}
`

const QuickLink = styled(Link)`
  white-space: nowrap;
  margin-right: 30px;
`

const QuickLinks = styled.p`
  margin-top: 25px;

  transition: opacity .2s ease-out;

  ${props => !props.visible && css`opacity: 0;`}

  ${props => props.right && css`
    text-align: right;

    ${QuickLink} {
      white-space: nowrap;
      display: block;
      margin-top: 10px;
    }
  `}
`

const RightIcon = onlyUpdateForKeys(['icon'])(styled(Icon)`
  display: inline-block;
  margin-left: 10px;

  color: ${props => props.theme.color.icon_mainnav};
`)

const MainNav = styled.div`
  position: relative;
  overflow: hidden;

  height: ${props => props.down
    ? 130
    : 230 - 50 * props.hasSubNav + 70 * props.hasLogo}px;

  padding: ${props => props.down || props.hasLogo ? 30 : 55}px 0;

  transition:
    padding .2s ease-out,
    height .2s ease-out;

  color: ${props => props.theme.color.white};

  &::before {
    content: '';

    position: absolute;
    left: 0; top: 0;
    right: 0; bottom: 0;
    z-index: -1;

    opacity: ${props => props.down ? 1 : 0};

    background: linear-gradient(
      ${props => darken(.2, props.theme.color.mainnav)},
      ${props => props.theme.color.mainnav}
    );

    transition: opacity .2s ease-out;
  }
`

const UpLinks = ({ upLinks, ...rest }) =>
  <QuickLinks {...rest}>
    {upLinks.map(({to, label}, i) =>
      <QuickLink to={to} key={i}>
        {label}<RightIcon size={20} icon="arrow-up" />
      </QuickLink>
    )}
  </QuickLinks>

const PureTitle = onlyUpdateForKeys(['solo', 'children', 'small'])(Title)

const MainNavComponent = ({ title, me={}, logo, organisation=null, upLinks=[], down, hasSubNav }) =>
  <MainNav
    hasLogo={!!logo}
    hasSubNav={hasSubNav}
    down={down}
  >
    <Wrapper>
      <LeftFloat>
        {logo && <OrganisationLogo src={logo} visible={!down} />}
        <PureTitle solo={upLinks.length === 0} small={down}>
          {title || `Dynamisch dashboard`}
        </PureTitle>
        <UpLinks upLinks={upLinks} visible={upLinks.length > 0  && (!logo || down)}/>
      </LeftFloat>
      <FloatRight>
        <User
          solo={logo && upLinks.length === 0}
          down={down}
          organisation={organisation}
          {...me}
        />
        <UpLinks upLinks={upLinks} right visible={upLinks.length > 0 && logo && !down} />
      </FloatRight>
    </Wrapper>
  </MainNav>

export default compose(
  onlyUpdateForKeys(['logo', 'title', 'me', 'organisation', 'upLinks', 'down']),
)(MainNavComponent)
