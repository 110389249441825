import styled, { css } from 'styled-components'
import { media } from 'utilities/styles'

const Column = styled.div`
  display: block;
  float: left;
  ${props => !props.nopadding && css`
    padding-left: ${props.theme.column.gap}px;
    padding-right: ${props.theme.column.gap}px;
  `}
  width: ${props => 100 * props.size / props.theme.column.amount}%;
  min-height: 1px;
  ${props => props.lSize && media.large`width: ${100 * props.lSize / props.theme.column.amount}%;`}
  ${props => props.mSize && media.medium`width: ${100 * props.mSize / props.theme.column.amount}%;`}
  ${props => props.sSize && media.small`width: ${100 * props.sSize / props.theme.column.amount}%;`}
`

export default Column
