import styled from 'styled-components'

export default styled.span`
  position: relative;

  display: inline-block;

  width: ${props => props.size}px;
  height: ${props => .9 * props.size}px;

  &::before, &::after {
    content: '';

    position: absolute;
    left: 0; top: 0;
    right: 0; bottom: 0;

    width: ${props => Math.floor(.2 * props.size)}px;
    height: ${props => props.size}px;
    margin: auto;

    background-color: currentColor;
  }

  &::after { transform: rotate(90deg); }
`
