import React from 'react'
import styled from 'styled-components'
import { lighten } from 'polished'

const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 480px;
  margin: 40px auto;

  text-align: center;
`

const Img = styled.img`
  max-width: 160px;
  margin-bottom: 20px;
`

const Title = styled.strong`
  font-size: 1.5em;
  color: ${props => lighten(.2, props.theme.color.text)};
`

const Description = styled.p`
  margin-top: 10px;
  color: ${props => props.theme.color.gray40};
`

export default ({ image, title, description }) =>
  <EmptyState>
    <Img src={image} alt={title} />
    <Title>{title}</Title>
    <Description>{description}</Description>
  </EmptyState>
