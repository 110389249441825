import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import graphql from 'babel-plugin-relay/macro'

import { compose, withState, lifecycle } from 'recompose'
import withMutation from 'containers/withMutation'
import { withOrganisation } from 'containers/Organisation'

import { Paper, Table, Input, Float, Styles, Icon } from 'hoffelijk-react-components'

import RowDetails from './RowDetails'
import Statistics from './Statistics'
import SectionDetails from './SectionDetails'
import OpenQuestionDetails from './OpenQuestionDetails'

import moment from 'moment'

import {
  getAllowedTries,
  getMeanGrade,
  getMeanDurationNormalized,
  getCategories,
} from 'utilities/attemptManipulation'

const FloatLeft = styled(Float.Left)`
  max-width: calc(100% - 352px);

  h2 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const StyledAction = styled(Table.Action)`
  color: ${props => props.theme.color.gray40};

  &&& {
    &:hover {
      color: ${props => props.theme.color.gray50};
    }
  }
`

const rotate = keyframes`
  to { transform: rotate(360px); }
`

const RotatingIcon = styled(Icon)`
  ${props => props.rotating && css`
    animation: ${rotate} 2s infinite linear;
  `}
`

const ActionButton = lifecycle({
  componentDidUpdate() {
    const { onClick, dirty, setDirty, questions } = this.props

    if (dirty) {
      onClick(null, null, null, questions)
      setDirty(false)
    }
  }
})(StyledAction)

const ResultsOverview = ({
  organisation_module, teacher, guarantee,
  query, setQuery, data, loading, error,
  latestRecipeId, setLatestRecipeId, commitMutation,
  popupDirty, setPopupDirty,
  close, popup, t
}) =>
  <Paper marginBottom light>
    <Paper.Section>
      <FloatLeft>
        <Styles.NoMargin as="h2">{organisation_module.module.module_study.name}</Styles.NoMargin>
      </FloatLeft>
      <Float.Right>
        <Input
          type="search"
          label="Zoek een toets"
          dense
          value={query}
          onChange={value => setQuery(value ? value.toLowerCase() : '')}
        />
      </Float.Right>
    </Paper.Section>
    <Table
      dense
      getRowVariables={row => ({
        organisationModuleId: organisation_module.id,
        recipeId: row.id,
        teacherId: teacher.id,
      })}
      expandable
      expandedContent={(row, state) => row.category
        ? null
        : <RowDetails
            row={row}
            allowed_tries={getAllowedTries(guarantee, row.id)}
            max_score={row.max_score}
            selectedCategory={state}
            t={t}
          />}
      onToggleExpanded={(expanded, row, state, setState) => !expanded && setState(null)}
      columns={[
        {
          label: 'Naam',
          sortable: false,
          content: row => row.category
            ? <strong>{row.category}</strong>
            : row.name,
          comparableContent: row => row.name,
        },
        {
          label: 'Gem. cijfer / percentage',
          sortable: false,
          content: row => row.category
            ? null : getMeanGrade(row.students, getAllowedTries(guarantee, row.id), true) || '-',
          comparableContent: row => row.category
            ? null : getMeanGrade(row.students, getAllowedTries(guarantee, row.id)),
        },
        // {
        //   label: 'Max. cijfer',
        //   sortable: false,
        //   content: row => row.category
        //     ? null : getMaxGrade(row.max_score, row.students, true),
        //   comparableContent: row => row.category
        //     ? null : getMaxGrade(row.max_score, row.students),
        // },
        {
          label: 'Tijdsduur',
          sortable: false,
          content: row => {
            if (row.category) { return null }
            const duration = getMeanDurationNormalized(row.students, getAllowedTries(guarantee, row.id))

            return duration
              ? moment.utc(duration).format('HH:mm:ss')
              : '-'
          },
          comparableContent: row => getMeanDurationNormalized(row.students, getAllowedTries(guarantee, row.id))
        },
        {
          label: 'Deelnamestatistieken',
          state: null,
          content: (row, state, setState, toggleExpanded) => row.category
            ? null
            : <Statistics
                categories={getCategories(getAllowedTries(guarantee, row.id), row.students)}
                onClick={category => {
                  setState(state === category ? null : category)
                  toggleExpanded(state !== category)
                }}
                selectedCategory={state}
                t={t}
              />,
          sortable: false,
        },
        {
          label: '',
          sortable: false,
          version: row => row.students.length,
          content: row => (
            !row.category &&
            row.sections.length > 1 &&
            row.sections.find(section =>
              section.students.find(student =>
                student.attempts && student.attempts.length > 0))
          ) &&
            <ActionButton
              onClick={() => popup(
                <SectionDetails
                  recipe_name={row.name}
                  sections={row.sections}
                  allowed_tries={getAllowedTries(guarantee, row.id)}
                  t={t}
                />
              )}
              icon="sections"
              title="Gedifferentieerde feedback"
            />
        },
        {
          label: '',
          sortable: false,
          version: row => `${Math.random()}`,
          content: row => {
            return (!row.category && row.open_questions.length > 0)
              ? <ActionButton
                  dirty={popupDirty}
                  setDirty={setPopupDirty}
                  questions={row.open_questions}
                  onClick={(a, b, c, questions) => popup(
                    <OpenQuestionDetails questions={questions || row.open_questions} />,
                    {},
                    [{
                      label: <span>
                        Ververs resultaten
                        <RotatingIcon
                          rotating={latestRecipeId !== null}
                          icon="refresh"
                          size={20}
                        />
                      </span>,
                      disabled: latestRecipeId !== null,
                      onClick: event => {
                        setLatestRecipeId(row.id, () => {
                          setPopupDirty(true)
                          commitMutation(event)
                        })
                      }
                    }]
                  )}
                  icon="file-add"
                  title="Open vragen"
                />
              : null
          }
        },
      ]}
      data={data}
      loading={loading}
      error={error}
    />
  </Paper>

const getMutation = () => graphql`
  mutation resultsRefreshMutation($teacherId: ID!, $recipeId: ID!, $organisationModuleId: ID!) {
    refreshRecipe(
      recipeId: $recipeId
      teacherId: $teacherId
      organisationModuleId: $organisationModuleId
    ) {
      id
      grade
      score
      open_answers {
        answer
        open_question {
          id
        }
      }
      section_results {
        section {
          id
        }
        grade
      }
      started_at
      finished_at
      candidate {
        id
      }
      recipe {
        id
      }
    }
  }
`

const getVariables = ({ teacher, latestRecipeId, organisation_module }) => ({
  recipeId: latestRecipeId,
  teacherId: teacher.id,
  organisationModuleId: organisation_module.id
})

const onCompleted = ({ data, setPopupDirty, setLatestRecipeId }) => ({ refreshRecipe: results }) => {
  setLatestRecipeId(null)
  setPopupDirty(true)
}

export default compose(
  withState('latestRecipeId', 'setLatestRecipeId', null),
  withState('popupDirty', 'setPopupDirty', false),
  withMutation({
    getMutation,
    getVariables,
    onCompleted
  }),
  withOrganisation
)(ResultsOverview)
