import React from 'react'
import styled, { css } from 'styled-components'
import { darken } from 'polished'

import { Link } from 'react-router-dom'
import Icon from './Icon'

const ButtonIcon = styled(Icon)`
  margin: -3px 0 -3px 10px;

  ${props => props.align === 'left' && css`
    margin-right: 10px;
    margin-left: 0;
  `}

  color: ${props => props.theme.color.button_hover};
`

export const Button = styled.button`
  position: relative;
  
  display: inline-block;
  vertical-align: top;
  padding: .8125rem 1.125rem;
  border-radius: 100em;

  font-weight: bold;
  line-height: 1;

  ${props => props.large && css`
    display: block;
    width: 100%;
    padding: 1.25rem 1.4rem;
  `}

  ${props => props.under && css`
    position: relative;
    z-index: 1;
    margin-top: -${props.isHigh ? 50 : 20}px;
  `}

  background-color: ${props => props.bgColor
    ? props.bgColor
    : props.theme.color.button};
  color: ${props => props.theme.color.inside_button};

  & ${ButtonIcon} {
    color: ${props => props.theme.color.outside_button};
  }

  ${props => props.disabled && css`
    opacity: .5;
  `}

  ${props => !props.disabled && css`
    &:hover {
      cursor: pointer;

      background-color: ${props => props.bgColor
        ? darken(.2, props.bgColor)
        : props.theme.color.button_hover};
      && {
        &, a {
          color: ${props => props.theme.color.inside_button_hover};
        }
      }

      & ${ButtonIcon} {
        color: ${props => props.theme.color.outside_button_hover};
      }
    }
  `}

  ${props => props.light && css`
    background-color: ${props.theme.color.white};
    color: ${props.theme.color.button};

    ${props => !props.disabled && css`
      &:hover {
        background-color: ${props.theme.color.white};
        color: ${props.theme.color.text};
      }
    `}
  `}

  ${props => props.dark && css`
    background-color: ${props.theme.color.button_dark};
    & ${ButtonIcon} { color: ${props.theme.color.white}; }

    ${props => !props.disabled && css`
      &:hover {
        background-color: ${props.theme.color.button_dark_hover};
        &&&, & ${ButtonIcon} { color: ${props.theme.color.white}; }
      }
    `}
  `}

  ${props => props.error && css`
    background-color: ${props.theme.color.error};
    & ${ButtonIcon} { color: ${props.theme.color.white}; }

    ${props => !props.disabled && css`
      &:hover {
        background-color: ${darken(.2, props.theme.color.error)};
      }
    `}
  `}

  &:focus {
    outline: none;
  }
`

export default ({ to, children, icon="arrow-right", ...rest }) => {
  let content;

  if (icon === 'arrow-left') {
    content = (
      <>
        <ButtonIcon align="left" icon={icon} size={20}/>
        {children}
      </>
    );
  } else {
    content = (
      <>
        {children}
        <ButtonIcon align="right" icon={icon} size={20}/>
      </>
    );
  }

  if (to) {
    return (
      <Button as={Link} to={to} {...rest}>
        {content}
      </Button>
    );
  } else {
    return (
      <Button {...rest}>
        {content}
      </Button>
    );
  }
}
