import styled, { css } from 'styled-components'

export const ScrollArea = styled.div`
  overflow-x: auto;
  width: 100%;
  padding-top: 30px;
  margin-top: -30px;
`

export const Table = styled.table`
  position: relative;

  width: 100%;

  border-collapse: collapse;
`

export const TableHead = styled.thead`
  border-top: 1px solid ${props => props.theme.color.gray20};

  line-height: 0;
`

export const TableHeading = styled.th`
  text-align: left;
  font-weight: normal;

  color: ${props => props.theme.color.gray40};

  &:first-child { padding-left: 25px; }
  &:last-child { padding-right: 25px; }
`

export const TableBody = styled.tbody`

`

export const TableRow = styled.tr`
  &:nth-child(n + 2) {
    border-top: 1px solid ${props => props.theme.color.gray20};
  }
`

export const TableCell = styled.td`
  padding: 29px 4px;

  ${props => props.dense && css`
    padding-top: 14px;
    padding-bottom: 14px;
  `}

  vertical-align: middle;

  &:first-child { padding-left: 29px; }
  &:last-child { padding-right: 29px; }
`
