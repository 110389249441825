import React from 'react'
import styled, { css } from 'styled-components'
import { transparentize } from 'polished'
import { withProps, onlyUpdateForKeys } from 'recompose'

import { noop } from 'utilities/functions'
import { CATEGORIES } from 'utilities/constants'

const Container = styled.div`
  position: relative;

  display: flex;
`

const Part = styled.div`
  cursor: pointer;

  position: relative;
  z-index: 1;

  flex-grow: ${props => props.amount};

  text-align: center;

  padding-top: 8px;
  margin: 8px 0 -8px;

  color: ${props => props.theme.color[props.textColor || props.color]};

  &::before {
    content: '';

    position: absolute;
    left: 2px; top: 0;
    right: 2px;
    z-index: -1;

    height: 6px;
    border-radius: 3px;

    box-shadow: 0 14px 0 ${props => transparentize(1, props.theme.color.white)};

    background-color: ${props => props.theme.color[props.color]};
  }

  ${props => props.amount === 0 && css`
    display: none;
  `}

  ${props => props.selected && css`
    position: absolute;
    left: 0; top: -7px;
    right: 0;
    z-index: 2;

    padding-top: 0;

    color: ${props.theme.color.white};

    &::before {
      height: 20px;
      border-radius: 10px;

      box-shadow: 0 14px 0 ${props.theme.color.white};
    }

    &::after {
      content: ' ${props.amount > 1 ? props.t('student_plural') : props.t('student_singular')} tonen';
    }
  `}
`

const withColor = (color, textColor) => withProps(() => ({ color, textColor }))
export const Failed = withColor('error')(Part)
export const Passed = withColor('warning')(Part)
export const Excellent = withColor('success')(Part)
export const NotParticipated = withColor('gray40', 'text')(Part)

export default onlyUpdateForKeys([
  'categories', 'selectedCategory'
])(({ categories, selectedCategory, t, onClick=noop }) =>
  <Container>
    <Failed
      amount={categories[CATEGORIES.FAILED]}
      selected={selectedCategory === CATEGORIES.FAILED}
      onClick={() => onClick(CATEGORIES.FAILED)}
      t={t}
    >
      {categories[CATEGORIES.FAILED]}
    </Failed>
    <Passed
      amount={categories[CATEGORIES.PASSED]}
      selected={selectedCategory === CATEGORIES.PASSED}
      onClick={() => onClick(CATEGORIES.PASSED)}
      t={t}
    >
      {categories[CATEGORIES.PASSED]}
    </Passed>
    <Excellent
      amount={categories[CATEGORIES.EXCELLENT]}
      selected={selectedCategory === CATEGORIES.EXCELLENT}
      onClick={() => onClick(CATEGORIES.EXCELLENT)}
      t={t}
    >
      {categories[CATEGORIES.EXCELLENT]}
    </Excellent>
    <NotParticipated
      amount={categories[CATEGORIES.NOT_PARTICIPATED]}
      selected={selectedCategory === CATEGORIES.NOT_PARTICIPATED}
      onClick={() => onClick(CATEGORIES.NOT_PARTICIPATED)}
      t={t}
    >
      {categories[CATEGORIES.NOT_PARTICIPATED]}
    </NotParticipated>
  </Container>
)
