import React from 'react'
import styled from 'styled-components'

import { Paper, SimpleTable } from 'hoffelijk-react-components'
import { Cross, Check } from 'components/glyphs'

const GreenCheck = styled(Check)`color: ${props => props.theme.color.success}`
const RedCross = styled(Cross)`color: ${props => props.theme.color.error}`

const PaperSection = styled(Paper.Section)`
  padding-top: 0;
`

export default ({ moduleName, allowed_tries, has_guarantee, attempts, loading, error }) =>
  <Paper light>
    <PaperSection>
      <h4>{moduleName}</h4>
      <SimpleTable
        columns={[
          {
            label: 'Poging',
            content: row => `#${row.try + 1} (${row.date.format('YYYY-MM-DD')})`,
            comparableContent: row => row.try,
          },
          {
            label: 'Resultaat',
            content: row => row.grade,
          },
          {
            label: 'Tijdsduur',
            content: row => row.duration.format('HH:mm:ss')
          },
          ...(has_guarantee
            ? [{
                label: 'Telt mee voor slagingsgarantie',
                content: row => row.attempt < allowed_tries
                  ? <GreenCheck size={20} />
                  : <RedCross size={20} />,
                comparableContent: row => row.attempt
              }]
            : [])
        ]}
        data={attempts}
        loading={loading}
        error={error}
      />
    </PaperSection>
  </Paper>
