import React from 'react'
import graphql from 'babel-plugin-relay/macro'
import withQuery from 'containers/withQuery'
import { withOrganisation } from 'containers/Organisation'
import { withMe } from 'containers/Me'
import { compose } from 'recompose'
import styled from 'styled-components'

import { Float, Paper, Table, Button, Styles, UserAvatar, WithHover } from 'hoffelijk-react-components'
import Actions from './Actions'

import moment from 'moment'

const StyledAvatar = styled(UserAvatar)`
  margin: -4px;
`

export const AdminsOverview = ({ organisation, me, users, loading, error, retry }) =>
  <Paper light expanding>
    <Paper.Section>
      <Float.Left>
        <Styles.NoMargin as="h2">Overzicht organisatiebeheerders</Styles.NoMargin>
      </Float.Left>
      <Float.Right>
        <Button to={`/organisation/${organisation.slug}/admins/add`}>Organisatiebeheerder toevoegen</Button>
      </Float.Right>
    </Paper.Section>
    <Table
      dense
      columns={[
        {
          label: '',
          content: user =>
            <StyledAvatar size={32} avatar={user.avatar} name={user.name} />,
          sortable: false,
        },
        {
          label: 'Naam',
          content: user => user.name
        },
        {
          label: 'E-mail',
          content: user => user.email
        },
        {
          label: 'Login',
          content: user =>
            <WithHover
               hover={`laatst: ${user.last_login_at ? moment(user.last_login_at).format('YYYY-MM-DD') : 'Nooit'})`}
            >
              {user.login_count} keer
            </WithHover>
        },
        {
          label: 'Status',
          content: user => user.active
            ? 'Actief' : 'Inactief'
        },
        {
          label: '',
          content: user =>
            <Actions
              organisation={organisation}
              user={user}
              me={me}
              refetch={retry}
            />,
          sortable: false,
        }
      ]}
      data={users}
      loading={loading}
      error={error}
    />
  </Paper>

const getQuery = () => graphql`
  query AdminsOverviewOrgQuery($organisationId: ID!) {
    users(role: "admin" organisationId: $organisationId) {
      id
      avatar
      name
      email
      login_count
      last_login_at
      roles
      active
      activation_sent_at
    }
  }
`

const getVariables = ({ organisation }) =>
  ({ organisationId: organisation.id })

export default compose(
  withMe,
  withOrganisation,
  withQuery({ getQuery, getVariables })
)(AdminsOverview)
