import React from 'react'
import graphql from 'babel-plugin-relay/macro'

import { Table } from 'hoffelijk-react-components'
import TableAction from 'components/TableAction'

const DeleteModuleStudy = ({ moduleStudy, refetch }) =>
  <TableAction
    confirm={`Weet je zeker dat je ${moduleStudy.name} wilt verwijderen?`}
    title="Verwijder" icon="bin"
    variables={{ moduleStudyId: moduleStudy.id }}
    onCompleted={refetch}
    mutation={graphql`
      mutation ActionsDeleteModuleStudyMutation($moduleStudyId: ID!) {
        deleteModuleStudy(moduleStudyId: $moduleStudyId) {
          success
        }
      }
    `}
  />


const EditModuleStudy = ({ moduleStudy }) =>
  <Table.Action
    title="Bewerk" icon="pen"
    to={`/module/${moduleStudy.id}/edit`} />

export default ({ moduleStudy, refetch }) =>
  <Table.Actions>
    <DeleteModuleStudy moduleStudy={moduleStudy} refetch={refetch} />
    <EditModuleStudy moduleStudy={moduleStudy} refetch={refetch} />
  </Table.Actions>
