import React from 'react'

const { Consumer, Provider } = React.createContext(null)

const defaultVocabulary = {
  teacher_singular: 'Docent',
  teacher_plural: 'Docenten',

  student_singular: 'Deelnemer',
  student_plural: 'Deelnemers',

  invoice_contact_singular: 'Contactpersoon',
  invoice_contact_plural: 'Contactpersonen',

  code_singular: 'Interne code',
  code_plural: 'Interne codes',

  code_invoice_singular: 'Factuurkenmerk',
  code_invoice_plural: 'Factuurkenmerken',

  code_debtor_singular: 'Debiteurenkenmerk',
  code_debtor_plural: 'Debiteurenkenmerken',

  code_contract_singular: 'Contractnummer',
  code_contract_plural: 'Contractnummers',

  groupname_singular: 'Groep',
  groupname_plural: 'Groepen',

  org_name_singular: 'Organisatie',
  org_name_plural: 'Organisaties',

  code_project_singular: 'Projectkenmerk',
  code_project_plural: 'Projectkenmerken',
}

const translate = organisation => {
  const vocabulary = Object
    .keys(organisation && organisation.vocabulary
      ? organisation.vocabulary : {})
    .filter(phrase => typeof organisation.vocabulary[phrase] === 'string')
    .reduce((acc, phrase) => ({
      ...acc,
      [phrase]: organisation.vocabulary[phrase]
    }), defaultVocabulary)

  return phrase => {
    switch (phrase) {
      case 'connecting_field_group':
      case 'connecting_field_group_singular':
        return vocabulary[`${organisation.connecting_field_group}_singular`]
      case 'connecting_field_group_plural':
        return vocabulary[`${organisation.connecting_field_group}_plural`]
      default:
        if (vocabulary[phrase]) { return vocabulary[phrase] }
        if (vocabulary[`${phrase}_singular`]) { return vocabulary[`${phrase}_singular`] }
        return phrase
    }
  }
}

const applyOption = (phrase, option) => {
  switch (option) {
    case 'capitalize':
      return phrase.substr(0, 1).toUpperCase() + phrase.substr(1)
    default:
      return phrase.substr(0, 1).toLowerCase() + phrase.substr(1)
  }
}

const t = organisation => (phrase, options=['lowercase']) =>
  options.reduce(applyOption, translate(organisation)(phrase))

export const OrganisationProvider = ({ organisation, ...rest }) =>
  <Provider value={organisation} {...rest} />

export const withOrganisation = WrappedComponent => props =>
  <Consumer>
    {organisation =>
      <WrappedComponent
        {...props}
        organisation={organisation}
        t={t(organisation)}
      />}
  </Consumer>

export default {
  OrganisationProvider,
  withOrganisation
}
