import { compose, withHandlers } from 'recompose'
import { withEnvironment } from 'containers/Authentication'
import { withNotifications } from 'containers/Notifications'
import { commitMutation } from 'react-relay'

const withMutation = ({
  getMutation=(() => null),
  getVariables=(() => ({})),
  getUploadables=(() => () => ({})),
  onCompleted=(() => () => {}),
}) => compose(
  withEnvironment,
  withNotifications,
  withHandlers({
    commitMutation: props => event => {
      event && event.preventDefault && event.preventDefault()

      const { environment, notify } = props

      commitMutation(environment, {
        mutation: getMutation(props),
        variables: getVariables(props),
        uploadables: {}, // getUploadables(props)(values),
        onCompleted: (results, errors) => {
          if (errors) {
            errors.forEach(error => {
              notify({
                type: 'error',
                title: 'Er is iets mis gegaan',
                content: error.message,
              })
            })
          } else {
            onCompleted(props)(results)
          }
        },
        onError: err => {
          notify({
            type: 'error',
            title: 'Er is iets mis gegaan',
            content: err.message
          })
        },
      })
    }
  })
)

export default withMutation
