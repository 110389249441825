import React from 'react'
import styled from 'styled-components'
import { compose, defaultProps, withState, withHandlers, shouldUpdate } from 'recompose'

import {
  InputWrapper, Label, InputContainer,
  Info,
  InputError } from './Input'

import Icon from '../Icon'

const UploadIcon = styled(Icon)`
  margin: 8px;
  color: ${props => props.theme.color.white};
`

export const ClickableInputWrapper = styled(InputWrapper)`
  cursor: pointer;
`

const Preview = styled.div`
  cursor: pointer;
  position: relative;

  display: inline-block;
  width: 43px;
  height: 43px;
  margin: 4px 0 4px 4px;
  border-radius: 4px;

  background-color: ${props => props.theme.color.gray20};
  color: ${props => props.theme.color.button};
`

const Image = styled.img`
  position: absolute;
  left: 0; top: 0;
  right: 0; bottom: 0;

  max-width: 100%;
  max-height: 100%;
  margin: auto;
`

const LabelText = styled.span`
  cursor: pointer;

  color: ${props => props.theme.color.gray40};
`

const Input = styled.div`
  display: inline-block;
  padding: 16px 10px;

  input {
    display: none;
  }
`

const ImageInput = ({ src, onChange, error, label, info, placeholder, defaultValue, block }) =>
  <ClickableInputWrapper block={block}>
    <Label>
      <InputContainer>
        <Preview>
          {src || defaultValue
            ? <Image src={src || defaultValue} />
            : placeholder}
        </Preview>
        <Input>
          <LabelText>
            {label}
            {info && <Info> ({info})</Info>}
          </LabelText>
          <input
            type="file"
            accept=".png,.jpg,.jpeg,.svg,.webp,.bmp,.gif, image/*"
            onChange={onChange}
          />
        </Input>
      </InputContainer>
    </Label>
    {error && error.map((err, i) =>
      <InputError key={i}>{err}</InputError>
    )}
  </ClickableInputWrapper>

export default compose(
  shouldUpdate((props, nextProps) =>
    nextProps.src !== props.src ||
    nextProps.defaultValue !== props.defaultValue ||
    nextProps.error !== props.error),
  defaultProps({ placeholder: <UploadIcon icon="cloud" size={27} /> }),
  withState('src', 'setSrc', null),
  withHandlers({
    onChange: ({ onChange, setSrc }) => event => {
      if (event.target.files.length !== 1) { return }

      const value = event.target.files[0]

      const reader = new FileReader()
      reader.onload = e => {
        setSrc(e.target.result)

        onChange && onChange({
          filename: value.name,
          mimetype: value.type,
          size: value.size,
          data: e.target.result.split(';base64,')[1],
        })
      }
      reader.readAsDataURL(value)
    }
  }),
)(ImageInput)
