import styled from 'styled-components'

export const None = styled.div`float: none;`
export const Left = styled.div`float: left;`
export const Right = styled.div`float: right;`

export const Float = {
  None, Left, Right
}

export default Float
