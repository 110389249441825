/**
 * @generated SignedSource<<33e413b8807bb3b4a9e28d08c9fc5db4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "email",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ActionLogMessage",
    "kind": "LinkedField",
    "name": "fetchActionLogMessages",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "created_at",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "action",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "subject",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "user_email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "subject_email",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminsOverviewActionLogsFetchMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AdminsOverviewActionLogsFetchMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "133ebe1b89dab451cfbae1aaa8dc5161",
    "id": null,
    "metadata": {},
    "name": "AdminsOverviewActionLogsFetchMutation",
    "operationKind": "mutation",
    "text": "mutation AdminsOverviewActionLogsFetchMutation {\n  fetchActionLogMessages {\n    id\n    created_at\n    action\n    message\n    user {\n      id\n      name\n      email\n    }\n    subject {\n      id\n      name\n      email\n    }\n    user_email\n    subject_email\n  }\n}\n"
  }
};
})();

node.hash = "c9cd2575fcd8f51f0e2d80ad7d0feeab";

module.exports = node;
