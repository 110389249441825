import React from 'react'
import { compose, withProps, withHandlers } from 'recompose'
import graphql from 'babel-plugin-relay/macro'

import { withRouter } from 'react-router-dom'

import withQuery from 'containers/withQuery'

import { HeaderMod } from 'containers/Header'
import { Row, Column } from 'hoffelijk-react-components'

import AccountManagement from 'components/AccountManagement'
import PasswordManagement from 'components/PasswordManagement'
import Loader from 'components/Loader'

const AccountPage = ({ me, backLink, goBack }) =>
  <Row>
    <HeaderMod
      title="Profiel bewerken"
      backLink={backLink}
    />
    <Column size={8}>
      {me
        ? <AccountManagement
            data={{
              userId: me.id
            }}
            defaultValues={{
              avatar_url: me.avatar,
              first_name: me.first_name,
              middle_name: me.middle_name,
              last_name: me.last_name,
              email: me.email,
            }}
            onSubmit={goBack}
          />
        : null}
    </Column>
    <Column size={4}>
      {me
        ? <PasswordManagement
            data={{
              userId: me.id
            }}
            onSubmit={goBack}
          />
        : null}
    </Column>
  </Row>

const getQuery = () => graphql`
  query accountQuery {
    me {
      id
      avatar
      first_name
      middle_name
      last_name
      email
    }
  }
`

export default compose(
  withQuery({ getQuery, loader: Loader }),
  withRouter,
  withProps(({ match }) => ({
    backLink: match.path.split('/account')[0] || '/'
  })),
  withHandlers({
    goBack: ({ backLink, history }) => () => history.push(backLink)
  }),
)(AccountPage)
