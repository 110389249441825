import React from 'react'
import styled from 'styled-components'
import graphql from 'babel-plugin-relay/macro'

import { compose, withState, withHandlers } from 'recompose'
import withForm from 'containers/withForm'

import { Check } from 'components/glyphs'
import { Paper, Input, Button, Float, Styles } from 'hoffelijk-react-components'

const LoginPaper = styled(Paper)`
  margin-right: -90px;
  margin-bottom: 30px;
`

const LoginInfo = styled.div`
  padding-top: 20px;

  a {
    display: inline-block;
    margin-top: 10px;
  }
`

const StyledCheck = styled(Check)`
  float: left;
  margin: 10px 10px 10px 0;
  color: ${props => props.theme.color.button};
`

const PasswordForgotForm = ({ recaptcha=false, values, errors, disabled, updateValue, onSubmit, submitted }) =>
  <LoginPaper light>
    <Paper.Section>
      { submitted
        ? <>
            <Styles.NoMargin as="h4">Reset link verzonden</Styles.NoMargin>
            <StyledCheck size={40} />
            <p>Indien je e-mailadres bij ons bekend is, hebben we zojuist een mail verstuurd waarmee je jouw wachtwoord kan herstellen.</p>
          </>
        : <form>
            <h4>Vraag een nieuw wachtwoord aan</h4>
            <Input.Group>
              <Input
                label="E-mailadres"
                type="email"
                value={values.email}
                error={errors.email}
                onChange={v => updateValue('email', v)}
                block
              />
            </Input.Group>
            <LoginInfo>
              <Float.Right>
                <Button type="submit" onClick={onSubmit} disabled={disabled}>Verstuur</Button>
              </Float.Right>
            </LoginInfo>
          </form>}
    </Paper.Section>
  </LoginPaper>

export default compose(
  withState('submitted', 'setSubmitted', false),
  withHandlers({
    onSubmit: ({ setSubmitted }) => () => setSubmitted(true)
  }),
  withForm({
    getMutation: () => graphql`
      mutation PasswordForgotFormMutation($input: PasswordForgotInput!) {
        passwordForgot(input: $input) {
          success
        }
      }
    `,
    getVariables: () => input => ({
      input
    }),
  })
)(PasswordForgotForm)
