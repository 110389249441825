import { compose, withState, withHandlers, withProps } from 'recompose'

const rangeFilter = (data, fragment, { from, to }) =>
  data.filter(row => fragment(row) !== null &&
            (from === null || from.startOf('day').isBefore(fragment(row))) &&
            (to === null || to.endOf('day').isAfter(fragment(row))))

const oneOfFilter = (data, fragment, options) => options.length
  ? data.filter(row => options.includes(fragment(row)))
  : data

const applyFilter = (data, filter, filterState) => {
  if (!filterState) { return data }

  if (filter.filterFn) {
    return filter.filterFn(filterState)(data)
  }

  switch(filter.type) {
    case 'daterange':
      return rangeFilter(data, filter.fragment, filterState)
    default:
      return oneOfFilter(data, filter.fragment, filterState)
  }
}

const applyFilters = (data, filters, filterState) =>
  filters.reduce((remainingData, filter, i) =>
    applyFilter(remainingData, filter, filterState[filter.label]),
    data
  )

export default compose(
  withState('filterState', 'setFilterState', {}),
  withHandlers({
    toggleOption: ({ setFilterState }) => cat => opt => () => {
      setFilterState(filterState => ({
        ...filterState,
        [cat]: filterState[cat]
          ? filterState[cat].includes(opt)
            ? filterState[cat].filter(o => o !== opt)
            : [...filterState[cat], opt]
          : [opt]
      }))
    },
    setRange: ({ setFilterState }) => cat => ({ from, to }) => {
      setFilterState(filterState => ({
        ...filterState,
        [cat]: {
          from: from ? from.startOf('day') : null,
          to: to ? to.endOf('day') : null,
        }
      }))
    },
    clearFilters: ({ filters, setFilterState }) => () =>
      setFilterState({})
  }),
  withProps(({ data, filters, filterState }) => ({
    data: applyFilters(data, filters, filterState)
  }))
)
