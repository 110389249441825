import React from 'react'
import graphql from 'babel-plugin-relay/macro'
import { compose, withProps } from 'recompose'
import withForm from 'containers/withForm'

import { Paper, Align, Input, Button, Styles } from 'hoffelijk-react-components'

const OrganisationModuleManagement = ({ options, data, values, errors, onSubmit, disabled, updateValue }) =>
  <form>
    <Paper light>
      <Paper.Section>
        <Styles.NoMargin as="h2">{data.module.module_study.name}</Styles.NoMargin>
      </Paper.Section>
      <Paper.Section>
        <Input.Group>
          <Input
            type="select"
            label="Zichtbare toetsmatrijzen"
            info="Laat leeg om alles te weergeven"
            value={values.visible_recipes}
            error={errors.visible_recipes}
            isClearable
            multi
            options={options}
            onChange={v => updateValue('visible_recipes', v)}
            block
          />
          <Align.Right>
            <Button
              onClick={event => {
                event.preventDefault()
                updateValue(
                  'visible_recipes',
                  options.map(option => option.value)
                )
              }}
            >
              Alle toetsmatrijzen selecteren
            </Button>
          </Align.Right>
        </Input.Group>
      </Paper.Section>
      {data.moduleHasPlanning &&
        <Paper.Section>
          <Input.Group>
            <Input
              type="checkbox"
              label="Deze module heeft een planning"
              checked={values.has_planning}
              error={errors.has_planning}
              onChange={v => updateValue('has_planning', v)}
              block
            />
          </Input.Group>
        </Paper.Section>}
    </Paper>
    <Align.Center>
      <Button under onClick={onSubmit} disabled={disabled}>Wijzigingen opslaan</Button>
    </Align.Center>
  </form>

const getMutation = () =>
  graphql`
    mutation OrganisationModuleManagementUpdateMutation($organisationModuleId: ID!, $input: OrganisationModuleInput!) {
      updateOrganisationModule(organisationModuleId: $organisationModuleId, input: $input) {
        id
      }
    }
  `

const getVariables = ({ organisationModuleId }) => input => ({
  organisationModuleId,
  input,
})

export default compose(
  withForm({ getMutation, getVariables }),
  withProps(({ data }) => ({
    options: data.module.module_study.study.recipes
      .map(({ name, id, code }) => ({
        label: `${name} (${code})`,
        value: id,
      })),
  }))
)(OrganisationModuleManagement)
