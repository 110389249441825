import React from 'react'

import { HeaderMod } from 'containers/Header'
import ModulesOverview from 'components/admin/organisation/ModulesOverview'

export default () =>
  <HeaderMod
    title="Beheer modules"
    backLink="/#organisations"
  >
    <ModulesOverview />
  </HeaderMod>
