import React from 'react'

import { Row, Column } from 'hoffelijk-react-components'

import TestResultsMenu from 'components/teacher/TestResultsMenu'
import ProgressMenu from 'components/teacher/ProgressMenu'

export default ({ organisation_modules }) =>
  <Row>
    <Column size={6} mSize={12}>
      <TestResultsMenu organisation_modules={organisation_modules} />
    </Column>
    <Column size={6} mSize={12}>
      <ProgressMenu organisation_modules={organisation_modules} />
    </Column>
  </Row>
