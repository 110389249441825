import React from 'react'
import graphql from 'babel-plugin-relay/macro'
import withQuery from 'containers/withQuery'

import { MeProvider } from 'containers/Me'
import { OrganisationProvider } from 'containers/Organisation'
import OrganisationTheme from 'containers/OrganisationTheme'

import OrganisationRouter from './OrganisationRouter'
import AdminRouter from './AdminRouter'

import { Route, Switch, Redirect } from 'react-router-dom'
import Loader from 'components/Loader'

import { isMainAdmin } from 'utilities/logic/authentication'

const MainRouter = ({ me, organisations }) =>
  <MeProvider me={me}>
    <Switch>
      {organisations.map((organisation, i) =>
        <Route
          key={i}
          path={`/${organisation.slug}`}
          render={props =>
            <OrganisationProvider organisation={organisation}>
              <OrganisationTheme theme={organisation.theme}>
                <OrganisationRouter {...props} />
              </OrganisationTheme>
            </OrganisationProvider>}
        />
      )}
      {isMainAdmin(me)
        ? <Route path='/' render={() => <AdminRouter organisations={organisations} />} />
        : <Route path='/' render={() => <Redirect to={`/${me.organisation.slug}`} />} />}
    </Switch>
  </MeProvider>

const getQuery = () => graphql`
  query AuthenticatedRouterQuery {
    organisations {
      id
      name
      slug
      show_students_active
      show_students_inactive
      show_students_demo
      show_modules_archived
      is_module_environment

      connecting_field_group

      remindo_organisations {
        id
      }
      theme {
        logo
        background
        copy
        hide_footer
        gradient_disabled

        color_accent
        color_primary
        color_background
        color_mainnav
        color_subnav
        color_icon_mainnav
        color_title
        color_title_link
        color_text
        color_text_link
        color_button
        color_button_hover
        color_outside_button
        color_outside_button_hover
        color_inside_button
        color_inside_button_hover
        color_button_dark
        color_button_dark_hover
        color_footer
      }
      vocabulary {

        teacher_singular
        teacher_plural

        student_singular
        student_plural

        show_invoice_contact
        invoice_contact_singular
        invoice_contact_plural

        show_code
        code_singular
        code_plural

        show_code_invoice
        code_invoice_singular
        code_invoice_plural

        show_code_debtor
        code_debtor_singular
        code_debtor_plural

        show_code_contract
        code_contract_singular
        code_contract_plural

        show_code_project
        code_project_singular
        code_project_plural

        show_groupname
        groupname_singular
        groupname_plural

        show_org_name
        org_name_singular
        org_name_plural
      }
    }
    me {
      id
      avatar
      email
      name
      first_name
      middle_name
      last_name
      roles
      organisation {
        id
        slug
      }
    }
  }
`

export default withQuery({ getQuery, loader: () => <Loader fullHeight /> })(MainRouter)
