import React from 'react'
import graphql from 'babel-plugin-relay/macro'
import withQuery from 'containers/withQuery'
import { withOrganisation } from 'containers/Organisation'
import { compose, defaultProps, withHandlers, shouldUpdate } from 'recompose'
import styled from 'styled-components'

import { SimpleTable, Input } from 'hoffelijk-react-components'

const Whitespace = styled.span`
  display: inline-block;
  width: 102px;
  height: 1px;
`

const ModuleManagement = ({ error, value=[], toggleModule, modules, ...rest }) =>
  <SimpleTable
    sortByDefault={false}
    columns={[
      {
        label: 'Module',
        content: row => row.name,
      },
      {
        label: 'Beschikbaarheid',
        content: row => row.module_study
          ? 'Beschikbaar' : 'Niet Beschikbaar',
      },
      {
        label: 'Status',
        content: row => {
          const active = row.module_study && row.active;
          return <Input
            type="checkbox"
            disabled={!row.module_study}
            label={active ? "Geselecteerd" : <Whitespace />}
            checked={active}
            onChange={checked => toggleModule(row.id, checked)}
          />
        },
        comparableContent: row => row.active,
      }
    ]}
    data={modules
      .map(module => ({
        ...module,
        active: value.find(m => m === module.id) !== undefined,
      }))
    }
    {...rest} />


const getQuery = () => graphql`
  query ModuleManagementQuery($remindoOrganisationIds: [ID!], $organisationId: ID) {
    modules(remindoOrganisationIds: $remindoOrganisationIds, organisationId: $organisationId) {
      id
      name
      module_study {
        study {
          id
        }
      }
    }
  }
`

const getVariables = ({ organisation, remindoOrganisationIds }) => organisation
  ? { organisationId: organisation.id, remindoOrganisationIds }
  : { remindoOrganisationIds }

export default compose(
  withOrganisation,
  shouldUpdate((props, nextProps) =>
    nextProps.remindoOrganisationIds !== props.remindoOrganisationIds ||
    nextProps.value !== props.value ||
    nextProps.error !== props.error
  ),
  defaultProps({
    remindoOrganisationIds: [],
    modules: [],
  }),
  withQuery({ getQuery, getVariables }),
  withHandlers({
    toggleModule: ({ value=[], updateValue }) => (id, active) =>
      updateValue(
        active
          ? value.includes(id)
            ? value
            : [...value, id]
          : value.filter(m => m !== id)
      )
  }),
)(ModuleManagement)
