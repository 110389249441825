import { compose, withProps, setDisplayName } from 'recompose'

import withWindow from 'containers/withWindow'
import { withMe } from 'containers/Me'
import { withOrganisation } from 'containers/Organisation'
import { withHeader } from 'containers/Header'

import { Header } from 'hoffelijk-react-components'

export default compose(
  setDisplayName('EnhancedHeader'),
  withMe,
  withOrganisation,
  withWindow,
  withHeader,
  withProps(({ windowScroll }) => ({
    down: windowScroll.top > 30
  })),
)(Header)
