import React, { Component } from 'react'

const getScroll = () => ({
  left: window.pageXOffset || document.body.scrollLeft || document.documentElement.scrollLeft,
  top: window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop
})

const getSize = () => ({
  width: window.innerWidth,
  height: window.innerHeight
})

const withWindow = WrappedComponent =>
  class extends Component {
    state = {
      windowSize: getSize(),
      windowScroll: getScroll(),
    }

    componentDidMount() {
      window.addEventListener('resize', this.onResize)
      window.addEventListener('scroll', this.onScroll)
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.onResize)
      window.removeEventListener('scroll', this.onScroll)
    }

    onResize = () => {
      this.setState({ windowSize: getSize() })
    }

    onScroll = () => {
      this.setState({ windowScroll: getScroll() })
    }

    render() {
      return (
        <WrappedComponent
          {...this.state}
          {...this.props}
        />
      )
    }
  }

export default withWindow
