import React from 'react'
import styled from 'styled-components'
import { darken, lighten } from 'polished'

import { withState, onlyUpdateForKeys } from 'recompose'
import 'react-dates/lib/css/_datepicker.css'

import { DateRangePicker } from 'react-dates'
import FilterContainer from './FilterContainer'

const DateOptionContainer = styled(FilterContainer)`
  .DateInput {
    display: inline-block;
    width: calc(50% - 12px);
    position: relative;
  }

  .DateRangePickerInput {
    border: none;
  }

  .DateInput_input {
    font-size: 14px;
    color: ${props => props.theme.color.text};
    padding: 14px 14px 12px;
  }

  .CalendarDay__selected_span {
    border: 1px solid ${props => darken(.1, props.theme.color.button)};
    background: ${props => props.theme.color.button};
    color: ${props => props.theme.color.white};
  }

  .CalendarDay__selected_span:hover {
    border: 1px solid ${props => darken(.2, props.theme.color.button)};
    background: ${props => darken(.1, props.theme.color.button)};
  }

  .CalendarDay__selected {
    border: 1px solid ${props => darken(.2, props.theme.color.button)};
    background: ${props => darken(.1, props.theme.color.button)};
    color: ${props => props.theme.color.white};
  }

  .CalendarDay__selected:hover {
    border: 1px solid ${props => darken(.3, props.theme.color.button)};
    background: ${props => darken(.2, props.theme.color.button)};
    color: ${props => props.theme.color.white};
  }

  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span {
    border: 1px solid ${props => props.theme.color.button};
    background: ${props => lighten(.1, props.theme.color.button)};
    color: ${props => props.theme.color.white};
  }

  .DayPickerKeyboardShortcuts_show {
    display: none;
  }
`

const DateFilter = onlyUpdateForKeys([
  'min', 'max', 'dateState', 'focus', 'label', 'last'
])(({
  min, max, dateState={ from: null, to: null }, onChange,
  focus, setFocus,
  label, last
}) =>
  <DateOptionContainer light last={last}>
    <DateRangePicker
      startDate={dateState.from}
      startDateId={`${label}_from`}
      endDate={dateState.to}
      endDateId={`${label}_to`}
      minimumNights={0}
      onDatesChange={({ startDate, endDate }) => onChange({ from: startDate, to: endDate })}
      focusedInput={focus}
      onFocusChange={setFocus}
      firstDayOfWeek={1}
      numberOfMonths={1}
      isOutsideRange={day => day.endOf('day').isBefore(min) || day.startOf('day').isAfter(max)}
      displayFormat={() => 'DD-MM-YYYY'}
      startDatePlaceholderText="Eerste datum"
      endDatePlaceholderText="Laatste datum"
    />
  </DateOptionContainer>
)

export default withState('focus', 'setFocus', null)(DateFilter)
