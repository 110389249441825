import React from 'react'
import Idle from 'react-idle'

import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { withAccessToken } from 'containers/Authentication'

const IdleTimer = ({ location, history, setAccessToken }) =>
  <Idle
    timeout={30 * 60 * 1000}
    onChange={({ idle }) => {
      if (idle) {
        setAccessToken(null)
        const rootPath = location.pathname.split('/')[1]
        history.push(['module', 'organisation', 'user'].includes(rootPath)
          ? '/' : `/${rootPath}`)
      }
    }}
  />

export default compose(
  withRouter,
  withAccessToken,
)(IdleTimer)
