import React from 'react'
import styled from 'styled-components'

import { withProps } from 'recompose'
import { Link } from 'react-router-dom'
import Paper from './Paper'
import Styles from './Styles'
import Loading from './Loading'

import defaultIcon from 'assets/images/default_module_icon.png'

const Menu = withProps(() => ({
  light: true,
  expanding: true,
}))(Paper)

const MenuTitle = ({ children }) =>
  <Paper.Section>
    <Styles.NoMargin as="h2">{children}</Styles.NoMargin>
  </Paper.Section>

const MenuCategory = Paper.Section

const MenuItem = styled(Link)`
  position: relative;
  z-index: 1;

  display: block;
  padding: .5em 0;

  ${Paper.SectionTitle} + & {
    margin-top: -2em;
  }

  &:hover::after {
    content: '';
    position: absolute;
    left: -29px; top: 0;
    right: -29px; bottom: 0;
    z-index: -1;

    background-color: ${props => props.theme.color.gray10};
  }
`

const MenuItemIcon = styled.div`
  display: inline-block;
  width: 48px;
  height: 48px;
  background: url(${props => props.icon || defaultIcon}) no-repeat center / contain;
`
const MenuItemContent = styled.span`
  display: inline-block;
  max-width: calc(100% - 64px);
  margin: 17px 0 17px 16px;
`

const Item = ({ icon, label, to }) =>
  <MenuItem to={to}>
    <MenuItemIcon icon={icon} />
    <MenuItemContent>{label}</MenuItemContent>
  </MenuItem>

const Category = ({ title, items, loading }) => items.length && !loading
  ? <MenuCategory title={title}>
      {loading
        ? <MenuItem>
            <MenuItemIcon />
            <MenuItemContent><Loading /></MenuItemContent>
          </MenuItem>
        : items.map((item, i) => <Item key={i} {...item} />)}
    </MenuCategory>
  : null

const MenuComponent = ({ title, categories, loading, empty, emptyState: EmptyState }) =>
  <Menu>
    <MenuTitle>{title}</MenuTitle>
    {empty
      ? <EmptyState />
      : categories.map((category, i) =>
          <Category key={i} {...category} loading={loading} />
        )}
  </Menu>

MenuComponent.Menu = Menu
MenuComponent.Title = MenuTitle
MenuComponent.category = MenuCategory
MenuComponent.Item = MenuItem
MenuComponent.Icon = MenuItemIcon
MenuComponent.Content = MenuItemContent

export default MenuComponent
