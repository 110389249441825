import React, { Fragment } from 'react'
import { withProps } from 'recompose'
import styled from 'styled-components'

import { ErrorBoundary } from 'react-error-boundary'

const FallBack = styled.div`
  padding: 30px;
  border-radius: 4px;

  background-color: ${props => props.theme.color.error};
  color: ${props => props.theme.color.white};
`

// if (process.env.NODE_ENV === 'production') {
//   const { whyDidYouUpdate } = require('why-did-you-update')
//   whyDidYouUpdate(React)
// }

const FallbackComponent = ({ componentStack, error }) =>
  <FallBack>
    <p><strong>Er is iets mis gegaan.</strong></p>
    <p>We weten het volgende</p>
    {process.env.NODE_ENV !== 'production' &&
    <Fragment>
      <p><strong>Error:</strong> {error.toString()}</p>
      <p><strong>Stacktrace:</strong> {componentStack}</p>
    </Fragment>}
  </FallBack>

export default withProps(() => ({ FallbackComponent }))(ErrorBoundary)
