import { median, sampleStandardDeviation } from 'simple-statistics'

export const filterOutliers = xs => {
  if (xs.length < 4) { return xs }

  const med = median(xs)
  const stdDev = sampleStandardDeviation(xs)

  const minValue = med - 2*stdDev
  const maxValue = med + 2*stdDev
  return xs.filter(x => (x >= minValue) && (x <= maxValue))
}

export const mean = xs => xs.length
  ? xs.reduce((acc, x) => acc + x, 0) / xs.length
  : null
