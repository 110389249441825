import React from 'react'
import styled from 'styled-components'
import { onlyUpdateForKeys } from 'recompose'

const Avatar = styled.div`
  position: relative;
  overflow: hidden;

  display: inline-block;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: 100%;

  text-align: center;
  font-size: ${props => .4*props.size}px;

  background-color: ${props => props.theme.color.gray20};
  color: ${props => props.theme.color.gray40};
`

const AvatarImage = styled.img`
  position: absolute;
  left: 50%; top: 50%;

  transform: translate(-50%, -50%);

  height: 100%;
`

const AvatarPlaceholder = styled.span`
  display: inline-block;
  margin: .7em 0 .5em;
  font-family: ${props => props.theme.font.heading};
`

const getInitials = name => {
  const initials = name
    .split(' ')
    .map(part => part.substr(0, 1))

  return (initials[0] + initials[initials.length - 1])
    .toUpperCase()
}

export default onlyUpdateForKeys(['avatar', 'name', 'size'])(({ avatar, name, size, className }) =>
  <Avatar size={size} className={className}>
    {avatar
      ? <AvatarImage src={avatar} />
      : <AvatarPlaceholder>{getInitials(name)}</AvatarPlaceholder>}
  </Avatar>
)
