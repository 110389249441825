import styled from 'styled-components'

import { Button } from '../Button'
import { InputWrapper } from './Input'
import { ClickableInputWrapper } from './Image'

export default styled.div`
  margin: -10px;

  ${InputWrapper},
  ${ClickableInputWrapper} {
    margin: 10px;
  }

  ${Button} {
    margin: 17px 10px;
  }
`
