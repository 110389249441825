import moment from 'moment'
import { firstBy } from 'thenby'

export const transformRowData = row => ({
  ...row,
  sections: row.sections.map(section => ({
    ...section,
    students: row.students
      .map(student => ({
        ...student,
        attempts: row.results
          .filter(result =>
            result.recipe.id === row.id &&
            result.candidate !== null &&
            result.candidate.id === student.candidate.id
          )
          .map(result => {
            const attempt = result.section_results
              .find(section_result => section_result.section.id === section.id)
            return {
              grade: attempt ? attempt.grade : null
            }
          })
      }))
  })),
  open_questions: row.open_questions
    ? row.open_questions
        .sort(firstBy('question_number'))
        .map(open_question => ({
          ...open_question,
          answers: row.results
            .map(result =>
              result.open_answers
                ? result.open_answers
                    .filter(answer => answer.open_question.id === open_question.id)
                    .map(answer => ({
                      answer: (answer.answer || '').trim(),
                      studentName: (row.students
                        .filter(student => student.candidate !== null)
                        .find(student => student.candidate.id === result.candidate.id) || { name: null })
                        .name,
                    }))
                : []
            )
            .reduce((acc, xs) => [...acc, ...xs], [])
            .reduce((answers, answer) => {
              const index = answers.findIndex(a => a.answer === answer.answer)
              if (index === -1) {
                return [
                  ...answers,
                  { answer: answer.answer, studentNames: [answer.studentName], }
                ]
              } else {
                return [
                  ...answers.slice(0, index),
                  {
                    ...answers[index],
                    studentNames: [...answers[index].studentNames, answer.studentName],
                  },
                  ...answers.slice(index + 1)
                ]
              }
            }, [])
            .sort(
              firstBy(a => a.studentNames.length, -1)
                .thenBy('answer', { ignoreCase: true })
            )
        }))
    : [],
  students: row.students
    .map(student => ({
      ...student,
      attempts: row.results
        .filter(result => student.candidate !== null && result.candidate.id === student.candidate.id)
        .map(result => ({
          date: moment(result.started_at),
          grade: result.grade || `${Math.round(result.max_score === 0 ? 100 : 100 * result.score / result.max_score)}%`,
          score: result.score,
          duration: result.finished_at && result.started_at
            ? moment(result.finished_at)
                .diff(moment(result.started_at))
            : null
        })),
    })),
})
