import React from 'react'
import graphql from 'babel-plugin-relay/macro'
import withQuery from 'containers/withQuery'
import { OrganisationProvider } from 'containers/Organisation'
import OrganisationTheme from 'containers/OrganisationTheme'

import Login from 'pages/login'
import PasswordReset from 'pages/passwordReset'
import PasswordForgot from 'pages/passwordForgot'
import NotFoundPage from 'pages/404'

import Outside from 'layouts/outside'

import { Switch, Route } from 'react-router-dom'
import Loader from 'components/Loader'

const UnauthenticatedOrganisationRouter = ({ organisation }) =>
  <OrganisationProvider organisation={organisation}>
    <OrganisationTheme theme={organisation.theme}>
      <Outside>
        <Switch>
          <Route
            exact path={`/${organisation.slug}`}
            component={Login} />
          <Route
            exact path={`/${organisation.slug}/password-forgot`}
            component={PasswordForgot} />
          <Route
            exact path={`/${organisation.slug}/password-reset/:token`}
            component={PasswordReset} />
          <Route path="*" component={Login} />
        </Switch>
      </Outside>
    </OrganisationTheme>
  </OrganisationProvider>

const OutsideLogin = props =>
  <Outside>
    <Login {...props} />
  </Outside>

const OutsidePasswordForgot = props =>
  <Outside>
    <PasswordForgot {...props} />
  </Outside>

const OutsidePasswordReset = props =>
  <Outside>
    <PasswordReset {...props} />
  </Outside>

const UnauthenticatedRouter = ({ organisations }) =>
  <Switch>
    {organisations
      .filter(organisation => organisation.active)
      .map((organisation, i) =>
      <Route
        key={i}
        path={`/${organisation.slug}`}
        component={() =>
          <UnauthenticatedOrganisationRouter organisation={organisation} />
        }
      />
    )}
    <Route
      exact path="/"
      component={OutsideLogin} />
    <Route
      exact path="/password-forgot"
      component={OutsidePasswordForgot} />
    <Route
      exact path="/password-reset/:token"
      component={OutsidePasswordReset} />
    <Route component={NotFoundPage} />
  </Switch>

const getQuery = () => graphql`
  query UnauthenticatedRouterQuery {
    organisations {
      id
      active
      name
      slug
      theme {
        logo
        background
        copy
        hide_footer
        gradient_disabled

        color_accent
        color_primary
        color_background
        color_mainnav
        color_subnav
        color_icon_mainnav
        color_title
        color_title_link
        color_text
        color_text_link
        color_button
        color_button_hover
        color_outside_button
        color_outside_button_hover
        color_inside_button
        color_inside_button_hover
        color_button_dark
        color_button_dark_hover
        color_footer
      }
      vocabulary {

        teacher_singular
        teacher_plural

        student_singular
        student_plural

        show_invoice_contact
        invoice_contact_singular
        invoice_contact_plural

        show_code
        code_singular
        code_plural

        show_code_invoice
        code_invoice_singular
        code_invoice_plural

        show_code_debtor
        code_debtor_singular
        code_debtor_plural

        show_code_contract
        code_contract_singular
        code_contract_plural

        show_code_project
        code_project_singular
        code_project_plural

        show_groupname
        groupname_singular
        groupname_plural

        show_org_name
        org_name_singular
        org_name_plural
      }
    }
  }
`

const OutsideLoader = props =>
  <Outside>
    <Loader {...props} />
  </Outside>

const ErrorComp = props =>
  <Outside>
    {JSON.stringify(props)}
  </Outside>

export default withQuery({ getQuery, loader: OutsideLoader, error: ErrorComp })(UnauthenticatedRouter)
