import React from 'react'

import { withRouter } from 'react-router-dom'
import { Paper, Button } from 'hoffelijk-react-components'

const NotFoundPage = ({ location, history }) =>
  <Paper light location={location}>
    <Paper.Section>
      <h2>Er is geen data om weer te geven.</h2>
      <p>Ga terug en probeer het opnieuw.</p>
      <Button onClick={history.goBack} icon="arrow-left">Ga terug</Button>
    </Paper.Section>
  </Paper>

export default withRouter(NotFoundPage)
