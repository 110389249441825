import React from 'react'
import styled, { css } from 'styled-components'
import { darken } from 'polished'
import { onlyUpdateForKeys } from 'recompose'

import Icon from '../Icon'

const RightIcon = styled(Icon)`
  margin-left: 10px;

  fill: ${props => props.theme.color.gray40};
`

const Label = styled.div`
  display: block;

  text-align: center;
  font-weight: bold;

  color: ${props => props.theme.color.gray40};
`
Label.displayName = 'FilterLabel'

const LabelContent = styled.div`
  ${props => props.selected && css`
    color: ${props.theme.color.text};
    margin-top: -10px;
  `}

  transition:
    color .2s ease-out,
    margin-top .2s ease-out;
`

const FilterState = styled.div`
  margin-top: 4px;

  opacity: ${props => props.selected ? 1 : 0};

  transition: opacity .2s ease-out;
`

export const Filter = styled.div`
  flex-grow: 1;

  position: relative;
  z-index: 1;

  display: inline-block;
  height: 77px;
  padding: 29px 0;

  border-right: 1px solid rgba(0, 0, 0, .1);

  &:first-child { border-radius: 3px 0 0 3px; }

  &:hover {
    box-shadow: 0 0 0 1px ${props => darken(.1, props.theme.color.button)};
    border-right: none;

    background-color: ${props => props.theme.color.button};

    ${Label} {
      color: ${props => props.theme.color.white};
    }

    ${RightIcon} {
      fill: ${props => props.theme.color.text};
    }
  }
`
Filter.displayName = 'Filter'

const DateState = ({ from , to }) => from && to
  ? `${from.format('DD-MM-YYYY')} - ${to.format('DD-MM-YYYY')}`
  : from
    ? `Vanaf ${from.format('DD-MM-YYYY')}`
    : to
      ? `Tot en met ${to.format('DD-MM-YYYY')}`
      : null

const SelectionState = ({ options }) => options && options.length > 0
  ? `${options.length} geselecteerd`
  : null

const EnhancedLabel = onlyUpdateForKeys(
  ['label', 'selected', 'state']
)(({ selected, label, type, state }) =>
  <Label>
    <LabelContent selected={selected}>
      {label}
      <RightIcon size={20} icon="arrow-down" />
    </LabelContent>
    <FilterState selected={selected}>
    {type === 'daterange'
      ? <DateState {...state} />
      : <SelectionState options={state} />}
    </FilterState>
  </Label>
)

export default ({ label, selected, type, state, children }) =>
  <Filter>
    <EnhancedLabel
      selected={selected}
      label={label}
      type={type}
      state={state}
    />
    {children}
  </Filter>
