import React from 'react'
import styled, { css, ThemeProvider } from 'styled-components'
import { transparentize } from 'polished'
import { compose, onlyUpdateForKeys } from 'recompose'
import { withModalState } from 'containers/Modal'
import deepmerge from 'deepmerge'

import { Wrapper, Icon } from 'hoffelijk-react-components'

const ModalBackground = styled.div`
  position: fixed;
  left: 0; top: 0;
  right: 0; bottom: 0;
  z-index: 10;

  display: flex;
  justify-content: center;

  background-color: ${props => transparentize(.25, props.theme.color.text)};

  transition: opacity .4s ease-out;

  ${props => !props.open && css`
    pointer-events: none;
    opacity: 0;
  `}
`

const ModalWrapper = styled(Wrapper)`
  width: 100%;
  max-height: calc(100vh - 4em);
  flex-grow: 1;
`

const ModalActions = styled.div`
  padding: 10px 0;

  text-align: right;

  color: ${props => props.theme.color.white};
`

const ModalAction = styled.div`
  cursor: pointer;
  display: inline-block;
  margin-left: 30px;

  line-height: 20px;

  svg {
    margin-left: 10px;
  }

  opacity: ${props => props.disabled ? .5 : 1};
`

const CloseAction = styled(ModalAction)`
  position: relative;

  padding-right: 30px;

  &::before, &::after {
    content: '';

    position: absolute;
    top: 0;
    right: 10px;

    width: 2px;
    height: 20px;

    background-color: currentColor;
  }

  &::before { transform: rotate(45deg); }
  &::after { transform: rotate(-45deg); }
`

const ModalContainer = styled.div`
  position: relative;
  overflow: auto;

  max-height: calc(100vh - 7em);
  border-radius: 4px;
`

const Modal = ({ theme, open, content, close, actions }) =>
  <ModalBackground open={open}>
    <ThemeProvider theme={currentTheme => deepmerge(currentTheme, theme)}>
      <ModalWrapper medium>
        <ModalActions>
          {actions.map(({ label, icon, onClick, disabled }, i) =>
            <ModalAction
              key={i}
              onClick={event => !disabled && onClick(event)}
              disabled={disabled}
            >
              {label}
              <Icon size={20} icon={icon} />
            </ModalAction>
          )}
          <CloseAction onClick={close}>Sluit dit venster</CloseAction>
        </ModalActions>
        <ModalContainer>
          {content}
        </ModalContainer>
      </ModalWrapper>
    </ThemeProvider>
  </ModalBackground>

export default compose(
  withModalState,
  onlyUpdateForKeys(['open', 'content', 'version']),
)(Modal)
