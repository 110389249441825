import React from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { withOrganisation } from 'containers/Organisation'
import { withNotifications } from 'containers/Notifications'
import { HeaderMod } from 'containers/Header'
import { Row, Column } from 'hoffelijk-react-components'

import AccountManagement from 'components/AccountManagement'

const AddOrganisationAdmin = ({ notify, organisation, history }) =>
  <HeaderMod
    title="Nieuwe organisatiebeheerder"
    backLink={`/organisation/${organisation.slug}/admins`}
  >
    <Row>
      <Column size={8}>
        <AccountManagement
          data={{
            organisationId: organisation.id,
          }}
          defaultValues={{
            is_admin: true
          }}
          onSubmit={() => {
            history.push(`/organisation/${organisation.slug}/admins`)
            notify({
              type: 'success',
              title: 'Organisatiebeherder succesvol aangemaakt',
            })
          }}
        />
      </Column>
    </Row>
  </HeaderMod>

export default compose(
  withRouter,
  withNotifications,
  withOrganisation
)(AddOrganisationAdmin)
