import { compose, withState, withHandlers, withProps } from 'recompose'
import { cmp } from 'utilities/functions'
import moment from 'moment'

const compare = (a, b) =>
  typeof a === typeof b && typeof a === 'string'
    ? cmp(a.toLowerCase(), b.toLowerCase())
      : moment.isMoment(a)
       ? a.isBefore(b) ? -1 : 1
       : cmp(a, b)

const DIRECTION = {
  DOWN: 1,
  UP: -1,
  NONE: 0,
}

const initialState = { column: null, direction: DIRECTION.NONE }

const withSorting = compose(
  withProps(({ data, getKey }) => ({
    data: data && data.map((row, key) => ({
      ...row,
      key: getKey ? getKey(row) : key
    }))
  })),
  withState('sorting', 'setSorting', initialState),
  withHandlers({
    sortOnColumn: ({ setSorting, sorting }) => column =>
      column === sorting.column
        ? sorting.direction === DIRECTION.DOWN
          ? setSorting({ column, direction: DIRECTION.UP })
          : setSorting(initialState)
        : setSorting({ column, direction: DIRECTION.DOWN }),
    resetState: ({ setSorting }) => () => setSorting(initialState),
  }),
  withProps(({ data, columns, sorting, defaultCompare, sortByDefault=true }) => {
    if (!sorting.column) {
      if (defaultCompare) {
        return {
          data: data && data.slice().sort(defaultCompare)
        }
      }
      if (!sortByDefault) {
        return {}
      }
    }

    const { column, direction } = sorting.column
      ? {
          column: columns.find(column => column.label === sorting.column),
          direction: sorting.direction
        }
      : {
          column: columns.find(column => column.sortable !== false),
          direction: DIRECTION.DOWN,
        }

    if (column && direction) {
      const comparator = column.compare || compare
      const comparableContent = column.comparableContent ? column.comparableContent : column.content
      return {
        data: data && data.slice().sort((a, b) =>
          direction * comparator(comparableContent(a), comparableContent(b)))
      }
    } else {
      return {}
    }
  }),
)

export default withSorting
