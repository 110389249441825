/**
 * @generated SignedSource<<5fcb4981eb6bd98270ec027ef360d292>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Organisation",
    "kind": "LinkedField",
    "name": "organisations",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "slug",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "active",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganisationsOverviewQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "OrganisationsOverviewQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "eb7362c668815261bdcc8e212bf0f895",
    "id": null,
    "metadata": {},
    "name": "OrganisationsOverviewQuery",
    "operationKind": "query",
    "text": "query OrganisationsOverviewQuery {\n  organisations {\n    id\n    name\n    slug\n    active\n  }\n}\n"
  }
};
})();

node.hash = "b267e3d0d30a602a67109eb0d1bc1699";

module.exports = node;
