import React from 'react'

import { withOrganisation } from 'containers/Organisation'

import EditOrganisation from 'pages/admin/organisation/edit'
import ManageOrganisationAdmins from 'pages/admin/organisation/admin'
import AddOrganisationAdmin from 'pages/admin/organisation/admin/add'
import EditOrganisationAdmin from 'pages/admin/organisation/admin/edit'
import ManageOrganisationModules from 'pages/admin/organisation/module'
import EditOrganisationModule from 'pages/admin/organisation/module/edit'

import NotFoundPage from 'pages/404'

import { Switch, Route } from 'react-router-dom'

const AdminOrganisationRouter = ({ organisation }) =>
  <Switch>
    <Route
      exact path={`/organisation/${organisation.slug}/edit`}
      component={EditOrganisation}
    />
    <Route
      exact path={`/organisation/${organisation.slug}/admins`}
      component={ManageOrganisationAdmins}
    />
    <Route
      exact path={`/organisation/${organisation.slug}/admins/add`}
      component={AddOrganisationAdmin}
    />
    <Route
      path={`/organisation/${organisation.slug}/admins/:userId/edit`}
      component={EditOrganisationAdmin}
    />
    <Route
      exact path={`/organisation/${organisation.slug}/modules`}
      component={ManageOrganisationModules}
    />
    <Route
      exact path={`/organisation/${organisation.slug}/modules/:organisationModuleId/edit`}
      component={EditOrganisationModule}
    />
    <Route component={NotFoundPage} />
  </Switch>

export default withOrganisation(AdminOrganisationRouter)
