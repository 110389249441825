export const noop = () => {}

export const identity = x => x

export const hashString = s => s
  .split('')
  .map(x => x.charCodeAt(0))
  .reduce((hash, char) =>
    (((hash << 5) - hash) + char) | 0, 0)

export const uniqueBy = f => xs => xs
  .reduce(
    (acc, x) => acc.find(a => f(a) === f(x))
      ? acc
      : [...acc, x],
    []
  )

export const uniqueByKey = key => uniqueBy(o => o[key])

export const unique = uniqueBy(identity)

export const cmp = (a, b) => (a > b) ? 1 : ((a < b) ? -1 : 0)

export const download = (filename, text) => {
  const charset = 'text/csv;charset=utf-8'
  const bom = '\uFEFF'

  // Prevent IE11 error "The data area passed to a system call is too small"
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(new Blob([bom + text], { type: charset + ';' }), filename)
    return
  }

  var element = document.createElement('a')
  element.setAttribute('href', 'data:text/csv;charset=utf-8,' + bom + encodeURIComponent(text))
  element.setAttribute('download', filename)

  element.style.display = 'none'

  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}
