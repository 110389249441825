import { Environment, Network, RecordSource, Store } from 'relay-runtime'
import RelayQueryResponseCache from 'relay-runtime/lib/network/RelayQueryResponseCache';

const cache = new RelayQueryResponseCache({ size: 250, ttl: 60 * 5 * 1000 });

const createHeaders = (token, uploadables) => {
  let headers = {}
  if (token) { headers['Authorization'] = `Bearer ${token}` }
  if (!uploadables) { headers['Content-Type'] = 'application/json' }
  return headers
}

const createBody = (query, variables, uploadables) => {
  if (uploadables) {
    if (!window.FormData) {
      throw new Error('Uploading files without `FormData` not supported.')
    }

    const formData = new FormData()
    formData.append('query', query)
    formData.append('variables', JSON.stringify(variables))
    Object.keys(uploadables).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(uploadables, key) &&
          uploadables[key] !== undefined) {
        formData.append(key, uploadables[key])
      }
    })

    return formData
  } else {
    return JSON.stringify({ query, variables })
  }
}

const fetchQuery = token =>
  async function fetchQuery(
    operation,
    variables,
    cacheConfig,
    uploadables
  ) {
    const queryID = operation.text;
    const isMutation = operation.operationKind === 'mutation';
    const isQuery = operation.operationKind === 'query';
    const forceFetch = cacheConfig && cacheConfig.force;

    // Try to get data from cache on queries
    const fromCache = cache.get(queryID, variables);
    if (
      isQuery &&
      fromCache !== null &&
      !forceFetch
    ) {
      return fromCache;
    }

    const endpoint = token
      ? process.env.REACT_APP_GRAPHQL_AUTHENTICATED_ENDPOINT
      : process.env.REACT_APP_GRAPHQL_ENDPOINT

    const response = await fetch(endpoint, {
      method: 'POST',
      headers: createHeaders(token, uploadables),
      body: createBody(operation.text, variables, uploadables)
    })

    // Hackish way to handle authentication errors, happens when accessToken
    // in local storage is invalid/expired. The API returns a redirect to /login
    // in those situations, we catch the redirect, clear the token and redirect
    // to the homepage.
    if (response.url.match(/\/login$/)) {
      window.localStorage.removeItem('accessToken');
      window.location = '/';

      return;
    }

    const json = await response.json()
    if (isQuery && json) {
      cache.set(queryID, variables, json);
    }
    // Clear cache on mutations
    if (isMutation) {
      cache.clear();
    }

    return json
  }

export default (token) => {
  const handlerProvider = null
  const network = Network.create(fetchQuery(token))
  const source = new RecordSource()
  const store = new Store(source)

  return new Environment({
    handlerProvider,
    network,
    store,
  })
}
