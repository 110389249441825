import React from 'react'
import graphql from 'babel-plugin-relay/macro'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import withQuery from 'containers/withQuery'
import { withNotifications } from 'containers/Notifications'

import { Row, Column } from 'hoffelijk-react-components'
import { HeaderMod } from 'containers/Header'
import AccountManagement from 'components/AccountManagement'
import Loader from 'components/Loader'

const UpdateAdmin = ({ history, notify, user }) =>
  <HeaderMod
    backLink="/#admins"
    title="Beheerder bewerken"
  >
    <Row>
      <Column size={8}>
        <AccountManagement
          editableRoles={['superadmin']}
          data={{
            userId: user.id
          }}
          defaultValues={{
            avatar_url: user.avatar,
            first_name: user.first_name,
            middle_name: user.middle_name,
            last_name: user.last_name,
            email: user.email,
            is_main_admin: user.roles.includes('mainadmin'),
            is_super_admin: user.roles.includes('superadmin'),
          }}
          onSubmit={() => {
            history.push('/#admins')
            notify({
              type: 'success',
              title: 'Beheerder succesvol bijgewerkt',
            })
          }}
        />
      </Column>
    </Row>
  </HeaderMod>

const getQuery = () => graphql`
  query editAdminQuery($userId: ID!) {
    user(id: $userId) {
      id
      avatar
      first_name
      middle_name
      last_name
      email
      roles
    }
  }
`

const getVariables = ({ match }) => ({
  userId: match.params.userId
})

export default compose(
  withRouter,
  withNotifications,
  withQuery({ getQuery, getVariables, loader: Loader }),
)(UpdateAdmin)
