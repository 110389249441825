import moment from "moment";

/**
 * Sort participations new to old
 */
export const sortExamParticipationsByExamDate = (participations) =>
  participations.sort((a, b) =>
    (a.exam_date.isBefore(b.exam_date)) ? 1 : -1)

export const getLastPastExamParticipation = (sortedParticipations) =>
  sortedParticipations.find(participation => participation.exam_date.isBefore(moment()))

const participationisFailed = (participation) =>
  participation.state !== 'booked' || participation.result_state === 'failed'

export const getFirstFutureExamParticipation = (sortedParticipations) => {
  const oldToNewParticipations = sortedParticipations
    .reverse()
    .filter(participation => participation.exam_date.isAfter(moment()));

  const nonFailedParticipations = oldToNewParticipations.filter(participation => !participationisFailed(participation));
  const failedParticipations = oldToNewParticipations.filter(participationisFailed);

  if (nonFailedParticipations.length > 0) {
    return nonFailedParticipations[0];
  }


  if (failedParticipations.length > 0) {
    return failedParticipations[0];
  }

  return null
}

export const formatSubjectLabel = (label) => {
  if (label.match(/kennis en begrip/i)) {
    return 'Kennis & begrip'
  }

  if (label.match(/vaardigheden/i)) {
    return 'Vaardigheden'
  }

  if (label.match(/professioneel gedrag/i)) {
    return 'Professioneel gedrag'
  }

  return label
}

/**
 * Get weight of subject by label
 *
 * Used to sort known subjects by specific order
 *
 * @param label
 * @returns {number}
 */
export const getSubjectWeightByLabel = (label) => {
  if (label === 'Kennis & begrip') {
    return 0;
  }

  if (label === 'Vaardigheden') {
    return 1;
  }

  if (label === 'Professioneel gedrag') {
    return 2;
  }

  return 3
}

/**
 * Return formatted string representing participation resul state:
 *
 *  - grade if result is received
 *  - string indicating status when failed
 *  - null if result is not there, but not yet failed
 */
export const formatParticipationResult = (participation) => {
  if (participation.exam_result) {
    return participation.exam_result.grade
  }

  if (participation.state !== 'booked') {
    return 'Status onbekend';
  }

  if (participation.no_show) {
    return 'No show';
  }

  if (participation.result_state === 'failed') {
    return 'Geannuleerd';
  }

  // Exam date in future / result yet to be received.
  return null;
}

export const examOfParticipationIsPassed = (participation) =>
  participation &&
  participation.exam_result &&
  participation.exam_result.grade > 5;