import React from 'react'
import { compose, withHandlers } from 'recompose'
import { withAccessToken } from 'containers/Authentication'
import { withOrganisation } from 'containers/Organisation'

import LoginForm from 'components/LoginForm'

const defaultCopy = "Welkom op het dynamisch dashboard van Lindenhaeghe. Log hier in om de voortgang van je studenten te volgen."

export const Login = ({ onSubmit, organisation }) =>
  <>
    <h1>Inloggen</h1>
    <p>{organisation && organisation.theme && organisation.theme.copy
      ? organisation.theme.copy
      : defaultCopy}
    </p>
    <br />
    <LoginForm onSubmit={onSubmit} />
  </>

export default compose(
  withOrganisation,
  withAccessToken,
  withHandlers({
    onSubmit: ({ setAccessToken }) => ({ loginUser }) =>
      setAccessToken(loginUser.accessToken)
  })
)(Login)
