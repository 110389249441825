/**
 * @generated SignedSource<<0c911dd85ee4e327f07684fc5a908516>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organisationId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "organisationId",
        "variableName": "organisationId"
      }
    ],
    "concreteType": "Organisation",
    "kind": "LinkedField",
    "name": "activateOrganisation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ActionsActivateOrganisationMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ActionsActivateOrganisationMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5615623515b3d0aa2b485569a44b1206",
    "id": null,
    "metadata": {},
    "name": "ActionsActivateOrganisationMutation",
    "operationKind": "mutation",
    "text": "mutation ActionsActivateOrganisationMutation(\n  $organisationId: ID!\n) {\n  activateOrganisation(organisationId: $organisationId) {\n    id\n  }\n}\n"
  }
};
})();

node.hash = "4803f9c075d262b8944baf65469ba8b4";

module.exports = node;
