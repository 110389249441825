import { handleAction } from 'redux-actions'

const reducer = handleAction(
  'SET_ROW',
  (state, { payload: { table, key, row } }) => ({
    ...state,
    [table]: {
      ...(state[table] || {}),
      [key]: row
    }
  }),
  {}
)

export default reducer
