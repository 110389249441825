import styled from 'styled-components'

export const Left = styled.div`text-align: left`
export const Right = styled.div`text-align: right`
export const Center = styled.div`text-align: center`
export const Justify = styled.div`text-align: justify`

export const Align = {
  Left, Right, Center, Justify
}
export default Align
