import React from 'react'
import moment from 'moment'
import { SimpleTable } from 'hoffelijk-react-components'

import {
  getBestAttempt,
  getCategory,
  parseGrade,
} from 'utilities/attemptManipulation'

const hasCategory = category => (student, allowed_tries, max_score) => {
  const attempt = getBestAttempt(student.attempts, allowed_tries)
  return category === getCategory(attempt && attempt.grade, max_score)
}

export default ({ row, allowed_tries, max_score, selectedCategory, loading, error, t }) =>
  <SimpleTable
    columns={[
      {
        label: t('student_singular')[0].toUpperCase() + t('student_singular').substring(1),
        content: row => <strong>{row.last_name}, {row.first_name} {row.middle_name}</strong>,
        comparableContent: row => `${row.last_name}, ${row.first_name} ${row.middle_name} (${row.id})`,
      },
      {
        label: 'Eerste poging',
        content: row => row.attempts && row.attempts.length > 0 && row.attempts[0].grade
          ? <span>{row.attempts[0].grade} <sup>({row.attempts[0].date.format('DD-MM-YYYY')})</sup></span> : '-',
        comparableContent: row => row.attempts && row.attempts.length > 0 && row.attempts[0].grade
          ? parseGrade(row.attempts[0].grade)
          : null
      },
      {
        label: 'Beste poging',
        content: row => row.bestAttempt && row.bestAttempt.grade
          ? <span>{row.bestAttempt.grade} <sup>({row.bestAttempt.date.format('DD-MM-YYYY')})</sup></span> : '-',
        comparableContent: row => row.bestAttempt && row.bestAttempt.grade
          ? parseGrade(row.bestAttempt.grade)
          : null,
      },
      {
        label: 'In poging nr.',
        content: row => row.bestAttempt
          ? row.attempts.findIndex(attempt => attempt === row.bestAttempt) + 1
          : '-',
        comparableContent: row => row.bestAttempt
          ? row.attempts.findIndex(attempt => attempt === row.bestAttempt) + 1
          : null,
      },
      {
        label: 'Tijdsduur',
        content: row => row.bestAttempt && row.bestAttempt.duration
          ? moment.utc(row.bestAttempt.duration).format('HH:mm:ss')
          : '-',
        comparableContent: row => row.bestAttempt
          ? row.bestAttempt.duration
          : null,
      },
    ]}
    data={(selectedCategory !== null && selectedCategory !== undefined
      ? row.students.filter(student => hasCategory(selectedCategory)(student, allowed_tries, max_score))
      : row.students).map(row => ({
        ...row,
        bestAttempt: getBestAttempt(row.attempts, allowed_tries),
      }))}
    loading={loading}
    error={error}
  />
