import React from 'react'
import styled from 'styled-components'
import { unique } from 'utilities/functions'
import { compose, withProps } from 'recompose'
import { withOrganisation } from 'containers/Organisation'
import { withModal } from 'containers/Modal'

import { Paper } from 'hoffelijk-react-components'

const displayObjects = (singular, plural) => n =>
  `${n} ${n === 1 ? singular : plural}`

const countModules = access =>
  displayObjects('opleiding', 'opleidingen')(
    unique(access.map(access => access.organisation_module.id)).length)

const AccessDetailsLabel = styled.span`
  cursor: pointer;
`

const ModuleAccess = ({ name, groups, t }) =>
  <p>
    <strong>{name}: </strong>
    <br />
    {groups
      .map(group => {
        switch (group) {
          case '$$ALL': return `Alle ${t('connecting_field_group_plural')}`
          case '$$NONE': return `Geen ${t('connecting_field_group')}`
          default: return group
        }
      })
      .join(', ')}
  </p>

export default compose(
  withOrganisation,
  withModal,
  withProps(({ t, access, organisationModules }) =>  {
      return {
          modules: unique(access.map(a => a.organisation_module.id))
              .map(organisation_module_id => {
                  let module = organisationModules
                      .find(organisation_module => organisation_module.id === organisation_module_id).module;

                  return {
                      id: organisation_module_id,
                      name: module.module_study ? module.module_study.name : '-',
                      groups: access
                          .filter(a => a.organisation_module.id === organisation_module_id)
                          .map(a => a.group)
                  };
              })
         };
  }))(({ t, access, modules, popup }) =>
  <AccessDetailsLabel
    onClick={() => modules.length > 0
      ? popup(
          <Paper light>
            <Paper.Section>
              {modules.map(({ id, name, groups }) =>
                <ModuleAccess key={id} name={name} groups={groups} t={t} />
              )}
            </Paper.Section>
          </Paper>
        )
      : null}
  >
    {countModules(access)}
  </AccessDetailsLabel>
)
