import React from 'react'
import graphql from 'babel-plugin-relay/macro'
import withQuery from 'containers/withQuery'

import { Float, Paper, Table, Button, Styles } from 'hoffelijk-react-components'
import Actions from './Actions'

import { firstBy } from 'thenby'

export const OrganisationsOverview = ({ organisations, loading, error, retry }) =>
  <Paper light expanding>
    <Paper.Section>
      <Float.Left>
        <Styles.NoMargin as="h2">Overzicht organisaties</Styles.NoMargin>
      </Float.Left>
      <Float.Right>
        <Button to="/organisation/add">Organisatie toevoegen</Button>
      </Float.Right>
    </Paper.Section>
    <Table
      defaultCompare={firstBy('name')}
      dense
      columns={[
        {
          label: 'Naam',
          content: organisation => organisation.name
        },
        {
          label: 'Status',
          content: organisation => organisation.active
            ? 'Actief' : 'Inactief'
        },
        {
          label: '',
          content: organisation => <Actions refetch={retry} organisation={organisation} />,
          sortable: false,
        }
      ]}
      data={organisations}
      loading={loading}
      error={error}
    />
  </Paper>

const getQuery = () => graphql`
  query OrganisationsOverviewQuery {
    organisations {
      id
      name
      slug
      active
    }
  }
`

export default withQuery({ getQuery })(OrganisationsOverview)
