import React from 'react'
import styled, { css } from 'styled-components'
import { compose, withState, withHandlers, withProps } from 'recompose'

import { Paper, Styles, EmptyState } from 'hoffelijk-react-components'
import { Plus } from 'components/glyphs'

import empty from 'hoffelijk-react-components/assets/answer_empty_state.png'

const QuestionDial = styled.div`
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
`

const Question = styled.div`
  cursor: pointer;

  flex-grow: 1;
  padding: 14px 4px;

  font-weight: bold;
  text-align: center;

  color: ${props => props.theme.color.gray40};

  ${props => props.active && css`
    color: ${props.theme.color.text};
    background-color: ${props.theme.color.gray20};
  `}

  &:nth-child(n + 2) {
    border-left: 1px solid rgba(0, 0, 0, .1);
  }

  ${props => !props.many && css`
    &::before {
      content: 'Vraag '
    }
  `}
`

const Scrollable = styled.div`
  position: relative;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  height: calc(100vh - 11em);
`

const StyledAnswer = styled(Paper.Section)`
  position: relative;
  padding: 18px 64px ${props => props.expanded ? 14 : 0}px 29px;

  p { margin: 0; }

  transition: padding-bottom .2s ease-out;
`

const ExpandButton = styled.div`
  position: absolute;
  top: 20px;
  right: 30px;

  height: 14px;
`

const StyledPlus = styled(Plus)`
  transition: transform .2s ease-out;
  transform: rotate(${props => props.expanded ? 45 : 0}deg);
`

const Answerer = styled.span`
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: -10px;

  float: right;

  opacity: ${props => props.visible ? 1 : 0};
  ${props => !props.visible && css`height: 18px;`}

  transition: opacity .2s ease-out;
`

const Gray = styled.span`
  opacity: .5;
`

const Answer = compose(
  withState('expanded', 'setExpanded', false),
  withHandlers({
    toggleExpanded: ({ expanded, setExpanded }) => () => setExpanded(!expanded)
  })
)(({ answer, studentNames, expanded, toggleExpanded }) =>
  <StyledAnswer expanded={expanded}>
    <p>{answer || <Gray>Geen antwoord</Gray>}</p>
    <Answerer visible={expanded}>{studentNames.join(', ')}</Answerer>
    <ExpandButton onClick={toggleExpanded}>
      <StyledPlus size={14} expanded={expanded} />
    </ExpandButton>
  </StyledAnswer>)

const OpenQuestionDetails = ({ many, questions, currentQuestion, setCurrentQuestion }) =>
  <Paper light>
    <QuestionDial>
      {questions.map((question, i) =>
        <Question
          key={i}
          onClick={() => setCurrentQuestion(i)}
          active={currentQuestion === i}
          many={many}
        >
          {question.question_number || i + 1}
        </Question>
      )}
    </QuestionDial>
    <Scrollable>
      <Styles.NoVerticalPadding as={Paper.Section}>
        <h4 dangerouslySetInnerHTML={{__html: questions[currentQuestion].name}}/>
      </Styles.NoVerticalPadding>
      {questions[currentQuestion].answers.length > 0
        ? questions[currentQuestion].answers.map((answer, i) =>
            <Answer key={`${questions[currentQuestion].question_number}-${i}`} {...answer} />
          )
        : <EmptyState
            image={empty}
            title="Geen antwoorden beschikbaar"
            description="Er zijn geen antwoorden beschikbaar voor deze vraag. Zorg dat je studenten antwoord geven op deze vraag om de antwoorden te zien."
          />
      }
    </Scrollable>
  </Paper>

export default compose(
  withState('currentQuestion', 'setCurrentQuestion', 0),
  withProps(({ questions }) => ({
    many: questions.length > 6
  })),
)(OpenQuestionDetails)
