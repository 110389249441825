import React from 'react'
import graphql from 'babel-plugin-relay/macro'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { withOrganisation } from 'containers/Organisation'
import { withNotifications } from 'containers/Notifications'
import withQuery from 'containers/withQuery'

import { HeaderMod } from 'containers/Header'
import TeacherAccessManagement from 'components/organisation/TeacherAccessManagement'
import Loader from 'components/Loader'

import { unique } from 'utilities/functions'

const EditUser = ({ notify, t, organisation, history, user, groups, ...rest }) =>
  <HeaderMod
    backLink={`/${organisation.slug}/#teachers`}
    title="Rechten beheren"
  >
    <TeacherAccessManagement
      data={{ user, groups }}
      defaultValues={{
        access: unique(user.access.map(x => x.organisation_module.id))
          .map(organisation_module_id => ({
            organisation_module_id,
            groups: unique(user.access
              .filter(x => x.organisation_module.id === organisation_module_id)
              .map(x => x.group))
          }))
      }}
      onSubmit={() => {
        history.push(`/${organisation.slug}/#teachers`)
        notify({
          type: 'success',
          title: `Rechten ${t('teacher_singular')} succesvol bijgewerkt`,
        })
      }}
    />
  </HeaderMod>

const getQuery = () => graphql`
  query accessTeacherQuery($userId: ID!, $organisationId: ID!) {
    groups(organisationId: $organisationId)
    user(id: $userId) {
      id
      name
      organisation {
        organisation_modules {
          id
          module {
            module_study {
              name
            }
          }
        }
      }
      access {
        organisation_module {
          id
        }
        group
      }
    }
  }
`

const getVariables = ({ organisation, match }) => ({
  organisationId: organisation.id,
  userId: match.params.teacherId,
})

export default compose(
  withRouter,
  withOrganisation,
  withNotifications,
  withQuery({ getQuery, getVariables, loader: Loader }),
)(EditUser)
