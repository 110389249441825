import React from 'react'
import styled from 'styled-components'
import graphql from 'babel-plugin-relay/macro'

import withForm from 'containers/withForm'

import { Link } from 'react-router-dom'
import { Paper, Input, Button, Float } from 'hoffelijk-react-components'
import Recaptcha from 'components/Recaptcha'

const LoginPaper = styled(Paper)`
  margin-right: -90px;
  margin-bottom: 30px;
`

const LoginInfo = styled.div`
  padding-top: 20px;

  a {
    display: inline-block;
    margin-top: 10px;
  }
`

const LoginForm = ({ recaptcha=false, values, errors, disabled, updateValue, onSubmit }) =>
  <form>
    <LoginPaper light>
      <Paper.Section>
        <Input.Group>
          <Input
            label="Gebruikersnaam"
            type="email"
            name="email"
            beforeIcon="user"
            value={values.email}
            error={errors.email}
            onChange={v => updateValue('email', v)}
            block />
          <Input
            label="Wachtwoord"
            type="password"
            name="password"
            beforeIcon="key"
            value={values.password}
            error={errors.password}
            onChange={v => updateValue('password', v)}
            block />
        </Input.Group>
        {recaptcha
          ? <Recaptcha />
          : null}
        <LoginInfo>
          <Float.Left>
            <Link to="password-forgot">Wachtwoord vergeten?</Link>
          </Float.Left>
          <Float.Right>
            <Button type="submit" onClick={onSubmit} disabled={disabled}>Inloggen</Button>
          </Float.Right>
        </LoginInfo>
      </Paper.Section>
    </LoginPaper>
  </form>

export default withForm({
  getMutation: () => graphql`
    mutation LoginFormMutation($input: LoginUserInput!) {
      loginUser(input: $input) {
        accessToken
      }
    }
  `,
  getVariables: () => input => ({
    input
  }),
})(LoginForm)
