import React from 'react'

export default ({ width, height, fill, className }) =>
	<svg height={height} width={width} className={className} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<g className="nc-icon-wrapper" fill={fill}>
			<path id="Frame_-_24px" d="M0 0h24v24H0z" fill="none"/>
			<g id="Line_Icons">
				<path d="M17 18.999H7c-3.859 0-7-3.139-7-7 0-3.86 3.141-7 7-7h10c3.859 0 7 3.14 7 7 0 3.861-3.141 7-7 7zm-10-12c-2.757 0-5 2.243-5 5s2.243 5 5 5h10c2.757 0 5-2.243 5-5s-2.243-5-5-5H7z" fill={fill}/>
				<circle cx="7" cy="11.999" fill={fill} r="3"/>
			</g>
		</g>
	</svg>
