import React from 'react'

import { withOrganisation } from 'containers/Organisation'

import { Menu, Paper, EmptyState } from 'hoffelijk-react-components'
import { firstBy } from 'thenby'

import empty from 'hoffelijk-react-components/assets/module_empty_state.png'

const TestResultsMenu = ({ organisation, organisation_modules=[], loading }) =>
  <Menu
    title="Toetsresultaten"
    loading={loading}
    empty={!loading && organisation_modules.length === 0}
    emptyState={() =>
      <Paper.Section>
        <EmptyState
          image={empty}
          title="Geen opleidingen weer te geven"
          description="Vraag jouw organisatiebeheerder om jou weergaverechten te geven"
        />
      </Paper.Section>}
    categories={[
      {
        title: '',
        items: organisation_modules
          .map(organisation_module => ({
            icon: organisation_module.module.module_study.icon,
            label: organisation_module.module.module_study.name,
            to: `/${organisation.slug}/dashboard/results/${organisation_module.module.module_study.slug}`,
          }))
          .sort(firstBy('label'))
      }
    ]}
  />

export default withOrganisation(TestResultsMenu)
