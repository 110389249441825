import React from 'react'
import graphql from 'babel-plugin-relay/macro'

import { compose, withProps } from 'recompose'
import { withMe } from 'containers/Me'
import { withMask } from 'containers/Mask'
import { withOrganisation } from 'containers/Organisation'
import withQuery from 'containers/withQuery'

import { HeaderMod } from 'containers/Header'

import Loader from 'components/Loader'

import Dashboard from 'pages/teacher'
import Account from 'pages/account'
import NotFoundPage from 'pages/404'

import Results from 'pages/teacher/results'
import Progress from 'pages/teacher/progress'

import { Route, Switch } from 'react-router-dom'

import { isMainAdmin, isAdmin } from 'utilities/logic/authentication'
import { uniqueByKey } from 'utilities/functions'

const getUpLinks = (me, organisation) => isMainAdmin(me)
  ? [
      { label: 'Terug naar hoofdbeheer', to: '/#organisations' },
      { label: 'Terug naar organisatiebeheer', to: `/${organisation.slug}` },
    ]
  : isAdmin(me, organisation)
    ? [
        { label: 'Terug naar organisatiebeheer', to: `/${organisation.slug}` },
      ]
    : []

const TeacherRouter = ({ me, organisation, organisation_modules=[] }) =>
  <HeaderMod upLinks={getUpLinks(me, organisation)}>
    <Switch>
      {organisation_modules.map((organisation_module, i) =>
        <Route
          key={i}
          exact path={`/${organisation.slug}/dashboard/results/${organisation_module.module.module_study.slug}`}
          component={props => <Results {...props} organisation_module={organisation_module} />}
        />
      )}
      {organisation_modules.length > 1 &&
        <Route
          exact path={`/${organisation.slug}/dashboard/progress`}
          component={props => <Progress {...props} organisation_modules={organisation_modules} />}
        />
      }
      {organisation_modules.map((organisation_module, i) =>
        <Route
          key={i}
          exact path={`/${organisation.slug}/dashboard/progress/${organisation_module.module.module_study.slug}`}
          component={props => <Progress {...props} organisation_modules={[organisation_module]} />}
        />
      )}
      <Route
        exact path={`/${organisation.slug}/dashboard/account`}
        component={Account} />
      <Route
        exact path={`/${organisation.slug}/dashboard`}
        component={props => <Dashboard {...props} organisation_modules={organisation_modules} />} />
      <Route component={NotFoundPage} />
    </Switch>
  </HeaderMod>

const getQuery = () => graphql`
  query TeacherRouterQuery($userId: ID!) {
    user(id: $userId for: "TeacherRouter") {
      id
      name
      access {
        organisation_module {
          id
          module {
            module_study {
              id
              icon
              name
              slug
            }
          }
        }
      }
    }
  }
`

const getVariables = ({ me, maskId }) => ({ userId: maskId ? maskId : me.id })

export default compose(
  withMe,
  withMask,
  withOrganisation,
  withQuery({ getQuery, getVariables, loader: Loader }),
  withProps(({ loading, user }) => loading
    ? {}
    : {
        organisation_modules: uniqueByKey('id')(
          user.access.map(access => access.organisation_module)
        )
      })
)(TeacherRouter)
