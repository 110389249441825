import React from 'react'

export default ({ width, height, fill, className }) =>
	<svg height={height} width={width} className={className} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<g className="nc-icon-wrapper" fill={fill}>
			<path id="Frame_-_24px" d="M0 0h24v24H0z" fill="none"/>
			<g id="Line_Icons">
				<path d="M12 7.999c-2.757 0-5 2.243-5 5 0 2.758 2.243 5 5 5s5-2.242 5-5-2.243-5-5-5zm0 8c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3z" fill={fill}/>
				<path d="M20 5.999h-3.465L15.129 3.89a1.996 1.996 0 0 0-1.664-.891h-2.93c-.67 0-1.292.333-1.664.891L7.465 5.999H4c-1.103 0-2 .897-2 2v11c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2v-11c0-1.103-.897-2-2-2zm-16 13v-11h4.535l2-3h2.93l2 3H20l.001 11H4z" fill={fill}/>
			</g>
		</g>
	</svg>
