import React from 'react'
import styled, { css } from 'styled-components'
import { onlyUpdateForKeys } from 'recompose'

import Icon from '../Icon'

export const SortLabel = styled.span`
  ${props => props.sortable && css`cursor: pointer;`}

  display: inline-block;
  height: 1em;
  padding: 0 8px;
  margin: -.2em 14px -8px -4px;

  text-decoration: none;
  white-space: nowrap;

  background-color: ${props => props.theme.color.white};
  color: currentColor;

  ${props => props.active && css`
    color: ${props.theme.color.text};
    padding-right: 26px;
    margin-right: -4px;
  `}
`

const ActiveIcon = styled(Icon)`
  margin: -7px -18px 0 4px;
  transform: scale(${props => props.sorting});
`

export default onlyUpdateForKeys([
  'sorting'
])(({ children, sortable=true, onClick, sorting }) => (sortable || children) &&
  <SortLabel sortable={sortable} active={sorting !== 0} onClick={sortable ? onClick : () => {}}>
    <span>{children}</span>
    <ActiveIcon size={14} icon="caret" sorting={sorting} />
  </SortLabel>)
