import React from 'react'
import graphql from 'babel-plugin-relay/macro'
import { compose, withProps } from 'recompose'
import withQuery from 'containers/withQuery'
import withForm from 'containers/withForm'

import Loader from 'components/Loader'

import { Paper, Column, Align, Input, Button, Styles } from 'hoffelijk-react-components'

import GuaranteeManagement from './GuaranteeManagement'
import PlanningManagement from './PlanningManagement'
import ServiceSelection from './ServiceSelection'

const planningConnectFields = [
  { label: 'Basis', value: 'wftbasis' },
  { label: 'Zorg', value: 'wftzorg' },
  { label: 'Consumptief krediet', value: 'wftconsumptief' },
  { label: 'Schade particulier', value: 'wftschadeparticulier' },
  { label: 'Vermogen', value: 'wftvermogen' },
  { label: 'Inkomen', value: 'wftinkomen' },
  { label: 'Schade zakelijk', value: 'wftschadezakelijk' },
  { label: 'Hypothecair', value: 'wfthypothecair' },
  { label: 'Pensioen', value: 'wftpensioen' },
  { label: '', value: null }
];

const ModuleStudyManagement = ({ values, modules, studies, recipes, services, errors, onSubmit, disabled, updateValue }) =>
  <form>
    <Paper light>
      <Paper.Section>
        <Input.Group>
          <Column size={5} nopadding>
            <Input.Image
              label="Module icoon"
              info="Max 100kb"
              defaultValue={values.icon_url}
              error={errors.icon}
              onChange={v => updateValue('icon', v)}
              block
            />
          </Column>
          <Column size={7} nopadding>
            <Input
              required
              label="Weergavenaam"
              value={values.name}
              error={errors.name}
              onChange={v => updateValue('name', v)}
              block
            />
          </Column>
        </Input.Group>
      </Paper.Section>
      <Paper.Section>
        <Styles.NoMarginTop as="h4">Koppelingen</Styles.NoMarginTop>
        <Input.Group>
          <Input
            type="select"
            required
            isClearable
            label="Module"
            value={values.module_id}
            error={errors.module_id}
            options={modules.map(({ name, id }) => ({
              label: name,
              value: id,
            }))}
            onChange={v => updateValue('module_id', v)}
            block
          />
          <Input
            type="select"
            required
            isClearable
            label="Opleiding"
            value={values.study_id}
            error={errors.study_id}
            options={studies.map(({ name, id }) => ({
              label: name,
              value: id,
            }))}
            onChange={v => {
              updateValue('study_id', v)
              updateValue('visible_recipes', [])
            }}
            block
          />
          <Input
            type="select"
            multi
            label="Standaard zichtbare toetsmatrijzen"
            info="Laat leeg om alle toetsmatrijzen standaard te weergeven"
            value={values.visible_recipes}
            error={errors.visible_recipes}
            isDisabled={recipes.length === 0}
            isClearable
            options={recipes.map(({ name, id, code }) => ({
              label: `${name} (${code})`,
              value: id,
            }))}
            onChange={v => updateValue('visible_recipes', v)}
            block
          />
        </Input.Group>
      </Paper.Section>
      <Paper.Section>
        <Styles.NoMarginTop as="h4">Slagingsgarantie</Styles.NoMarginTop>
        <Input
          type="checkbox"
          checked={values.has_guarantee}
          error={errors.has_guarantee}
          onChange={v => updateValue('has_guarantee', v)}
          label="Slagingsgarantie is van toepassing op deze module"
          block
        />
        {values.has_guarantee &&
        <>
          <br />
          <GuaranteeManagement
            values={values.guarantee ? values.guarantee : {}}
            errors={errors.guarantee ? errors.guarantee : {}}
            updateValue={v => updateValue('guarantee', v)}
            recipes={recipes}
          />
        </>
        }
      </Paper.Section>
      <Paper.Section>
        <Styles.NoMarginTop as="h4">Planning</Styles.NoMarginTop>
        <PlanningManagement
          values={values.planning_recipes}
          errors={errors.planning_recipes}
          updateValue={v => updateValue('planning_recipes', v)}
          options={recipes.map(({ name, id, code }) => ({
            label: `${name} (${code})`,
            value: id,
          }))}
        />
        <br />
        <Input.Group>
          <Column size={6} nopadding>
            <Input
              type="select"
              label="Wft-module voor veldkoppeling"
              clearable
              options={planningConnectFields}
              value={values.connecting_field_planning_wft_module}
              error={errors.connecting_field_planning_wft_module}
              onChange={v => updateValue('connecting_field_planning_wft_module', v)}
              block
            />
          </Column>
        </Input.Group>
      </Paper.Section>
      <Paper.Section>
        <Styles.NoMarginTop as="h4">Examendiensten</Styles.NoMarginTop>
        <ServiceSelection
          values={values.services}
          errors={errors.services}
          updateValue={v => updateValue('services', v)}
          options={services.map(({ name, id }) => ({
            label: name,
            value: id,
          }))}
        />
      </Paper.Section>
    </Paper>
    <Align.Center>
      <Button under onClick={onSubmit} disabled={disabled}>Wijzigingen opslaan</Button>
    </Align.Center>
  </form>

const getQuery = () => graphql`
  query ModuleStudyManagementQuery {
    modules {
      id
      name
    }
    studies {
      id
      name
      recipes {
        id
        name
        code
      }
    }
    services {
        id
        name
    }
  }
`

const getMutation = ({ moduleStudyId }) => moduleStudyId
  ? graphql`
      mutation ModuleStudyManagementUpdateMutation($moduleStudyId: ID!, $input: ModuleStudyInput!) {
        updateModuleStudy(moduleStudyId: $moduleStudyId, input: $input) {
          id
        }
      }
    `
  : graphql`
      mutation ModuleStudyManagementCreateMutation($input: ModuleStudyInput!) {
        createModuleStudy(input: $input) {
          id
        }
      }
    `

const getVariables = ({ moduleStudyId }) => ({ icon_url, has_guarantee, ...input }) => {
  const vars = {
    moduleStudyId,
    input,
  };

  if (!has_guarantee) {
    vars.input.guarantee = null;
  }

  vars.input.services = vars.input.services ? vars.input.services.map(service => service.id) : [];

  return vars;
};

export default compose(
  withQuery({ getQuery, loader: Loader }),
  withForm({ getMutation, getVariables }),
  withProps(({ studies, values, defaultValues }) => ({
    recipes: values.study_id
      ? studies.find(study => study.id === values.study_id).recipes
      : [],
  }))
)(ModuleStudyManagement)
