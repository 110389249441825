import React, { Fragment } from 'react'
import styled from 'styled-components'
import graphql from 'babel-plugin-relay/macro'

import { compose, withState, withHandlers } from 'recompose'
import withQuery from 'containers/withQuery'
import withMutation from 'containers/withMutation'
import { withMe } from 'containers/Me'
import { withModal } from 'containers/Modal'
import { withOrganisation } from 'containers/Organisation'
import { withNotifications } from 'containers/Notifications'
import { HeaderMod } from 'containers/Header'

import TeachersOverview from 'components/organisation/TeachersOverview'
import ConfirmModal from 'components/ConfirmModal'

import { download } from 'utilities/functions'
import { isMainAdmin } from 'utilities/logic/authentication'
import { parse } from 'json2csv'
import moment from 'moment'
import Papa from 'papaparse'

const UploadLabel = styled.label`
  cursor: pointer;

  display: inline-block;
  margin: -13px -48px -13px -18px;
  padding: 13px 48px 13px 18px;

  input {
    position: absolute;
    visibility: hidden;
  }
`

const NON_MODULE_FIELDS = ['voornaam', 'tussenvoegsel', 'achternaam', 'email', 'status']

const fromCSV = (csv, { onCompleted, organisationModules, notify }) => {
  const transform = ({ voornaam, tussenvoegsel, achternaam, email, status, ...modules }) => ({
    first_name: voornaam,
    middle_name: tussenvoegsel || null,
    last_name: achternaam,
    email: email,
    active: status === 'Actief',
    access: Object.keys(modules)
      .map(module => modules[module]
        ? modules[module].split(/; |;/)
            .map(group => ({
                organisation_module_id: organisationModules
                  .find(organisation_module =>
                    organisation_module.module.module_study.name === module
                  ).id,
                group,
              })
            )
        : []
      )
      .reduce((acc, xs) => [...acc, ...xs], [])
  })

  Papa.parse(csv, {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    complete: res => {
      const unknownModuleStudyNames = res.meta.fields
        .filter(field => !NON_MODULE_FIELDS.includes(field))
        .filter(moduleStudyName => !organisationModules
          .find(organisation_module => organisation_module.module.module_study.name === moduleStudyName)
        )

      const data = unknownModuleStudyNames.length > 0
        ? null
        : res.data.map(transform)

      const errors = unknownModuleStudyNames.length > 0
        ? unknownModuleStudyNames
            .map(moduleStudyName => ({
              code: 'UnknownModule',
              message: `Onbekende opleiding: "${moduleStudyName}" is niet gedefinieerd voor deze organisatie`,
              row: null,
              type: 'HeaderMismatch'
            }))
        : [
          ...res.errors,
          ...data
            .filter(row => row.first_name === null)
            .map((_, i) => ({
              code: 'InvalidField',
              message: 'Leeg veld: Voornaam mag niet leeg zijn',
              row: i,
              type: 'FieldMismatch',
            })),
          ...data
            .filter(row => row.last_name === null)
            .map((_, i) => ({
              code: 'InvalidField',
              message: 'Leeg veld: Achternaam mag niet leeg zijn',
              row: i,
              type: 'FieldMismatch',
            })),
          ...data
            .filter(row => row.email === null)
            .map((_, i) => ({
              code: 'InvalidField',
              message: 'Leeg veld: Email mag niet leeg zijn',
              row: i,
              type: 'FieldMismatch',
            })),
        ]

      if (errors.length > 0) {
        notify({
          type: 'error',
          title: 'Fouten gevonden in bestand',
          content: errors.map((error, i) =>
            <Fragment key={i}>
              {error.row !== null && <strong>Rij {error.row + 1}: </strong>}
              {error.message}
              <br />
            </Fragment>
          ),
        })
      }

      onCompleted(data, errors)
    }
  })
}

const toCSV = (teachers, organisationModules) => {
  const csvData = teachers
    .map(teacher => ({
      voornaam: teacher.first_name,
      tussenvoegsel: teacher.middle_name,
      achternaam: teacher.last_name,
      email: teacher.email,
      status: teacher.active ? 'Actief' : 'Inactief',
      ...organisationModules.reduce((acc, organisation_module) => ({
        ...acc,
        [organisation_module.module.module_study.name]: teacher.access
          .filter(a => a.organisation_module.id === organisation_module.id)
          .map(a => a.group)
          .map(group => group ? group : '$$NONE')
          .join('; ')
      }), {}),
    }))

  return parse(csvData, {
    fields: Object.keys(csvData[0]),
    delimiter: ';',
  })
}

const OrganisationPage = ({
  t, me,
  users, organisationModules, loading, error, retry,
  handleOnTeachersImport
}) =>
  <HeaderMod
    title="Organisatiebeheer"
    actions={[
      {
        label: `Exporteer ${t('teacher_plural')}`,
        icon: 'download',
        disabled: !users || users.length === 0,
        onClick: () => download(
          `${t('teacher_plural')}_${moment().format('YYYY-MM-DD')}.csv`,
          toCSV(users, organisationModules)
        )
      },
      ...(isMainAdmin(me)
        ? [{
            label: <UploadLabel>
              Importeer {t('teacher_plural')}
              <input
                type="file"
                onClick={event => {
                  event.target.value = null
                }}
                onInput={handleOnTeachersImport}
                accept="text/csv,.csv"
              />
            </UploadLabel>,
            icon: 'upload',
            disabled: !organisationModules || organisationModules.length === 0,
          }]
        : []
      )
    ]}
  >
    <TeachersOverview
      loading={loading}
      error={error}
      teachers={users}
      organisationModules={organisationModules}
      retry={retry}
    />
  </HeaderMod>

const getQuery = () => graphql`
  query organisationTeachersQuery($organisationId: ID!) {
    users(organisationId: $organisationId, role: "teacher") {
      id
      name
      first_name
      middle_name
      last_name
      email
      login_count
      last_login_at
      active
      activation_sent_at
      roles
      organisation {
        id
        slug
      }
      access {
        organisation_module {
          id
        }
        group
      }
    }
    organisationModules(organisationId: $organisationId) {
      id
      module {
        module_study {
          name
        }
      }
    }
  }
`

const getMutation = () => graphql`
  mutation organisationTeachersMutation($organisationId: ID!, $input: ImportTeachersInput!) {
    importTeachers(organisationId: $organisationId, input: $input) {
      skipped
    }
  }
`

const getQueryVariables = ({ organisation }) => ({
  organisationId: organisation.id
})

const getMutationVariables = ({ organisation, importData }) => ({
  organisationId: organisation.id,
  input: { teachers: importData },
})

export default compose(
  withMe,
  withModal,
  withOrganisation,
  withNotifications,
  withState('importData', 'setImportData', null),
  withQuery({ getQuery, getVariables: getQueryVariables }),
  withMutation({
    getMutation,
    getVariables: getMutationVariables,
    onCompleted: ({ t, retry, notify }) => ({ importTeachers }) => {
      if (importTeachers.skipped.length === 0) {
        notify({
          type: 'success',
          title: `${t('teacher_plural', ['capitalize'])} succesvol geïmporteerd`
        })
      } else {
        notify({
          type: 'error',
          title: `Fout bij het importeren`,
          content: (
            <>
              De {t('teacher_plural')} met de volgende e-mail adressen zijn niet geïmporteerd, omdat er gebruikers met dezelfde e-mail adressen bestaan in andere omgevingen of met rollen anders dan {t('teacher_singular')}:
              <ul>
                {importTeachers.skipped.map((email, i) =>
                  <li key={i}>{email}</li>
                )}
              </ul>
            </>
          )
        })
      }
      retry()
    },
  }),
  withHandlers({
    handleOnTeachersImport: ({ t, popup, notify, organisationModules, setImportData, commitMutation }) => event => {
      if (event.target.files.length === 1) {
        const onCompleted = (data, errors) => {
          if (errors.length === 0) {
            popup(<ConfirmModal
              message={`Weet je zeker dat je de ${t('teacher_plural')} wilt importeren? Deze actie overschrijft alle bestaande ${t('teacher_plural')}.`}
              buttonMessage="Importeer"
              onSubmit={e => {
                setImportData(data, () => {
                  commitMutation(e)
                })
              }}
            />)
          }
        }

        fromCSV(event.target.files[0], { organisationModules, notify, onCompleted })
      }
    }
  })
)(OrganisationPage)
