import styled, { css } from 'styled-components'

export default styled.div`
  max-width: ${props => props.theme.column.gap* 2 + 1110}px;
  padding-left: ${props => props.theme.column.gap}px;
  padding-right: ${props => props.theme.column.gap}px;
  margin: auto;

  ${props => props.medium && css`
    max-width: ${props => props.theme.column.gap* 2 + 730}px;
  `}
`
