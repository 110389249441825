import PropTypes from 'prop-types'
import { compose, withState, withHandlers } from 'recompose'
import { withEnvironment } from 'containers/Authentication'
import { withNotifications } from 'containers/Notifications'
import { commitMutation } from 'react-relay'
import deepmerge from 'deepmerge'

const toObject = (key, value) => key
  .split('.')
  .reverse()
  .reduce(
    (o, k) => ({ [k]: o }),
    value
  )

const withForm = ({
  getMutation=(() => null),
  getVariables=(() => x => x),
  getUploadables=(() => () => ({}))
}) => compose(
  withEnvironment,
  withNotifications,
  withState('values', 'setValues', ({ defaultValues={} }) => defaultValues),
  withState('errors', 'setErrors', {}),
  withState('disabled', 'setDisabled', false),
  withHandlers({
    updateValue: ({ setValues }) => (key, updateFn) => {
      setValues(values => ({
        ...values,
        [key]:
          typeof updateFn === 'function' ? updateFn(values[key]) : updateFn
      }))
    },
    onSubmit: props => event => {
      event.preventDefault()

      const { environment, values, onSubmit, setErrors, setDisabled, notify, data={} } = props

      setDisabled(true)

      commitMutation(environment, {
        mutation: getMutation(data),
        variables: getVariables(data)(values),
        uploadables: {}, // getUploadables(props)(values),
        onCompleted: (results, errors) => {
          if (errors) {
            errors.forEach(error => {
              if (typeof error.extensions == 'object' && error.extensions.validation !== undefined) {
                setErrors(Object.keys(error.extensions.validation).reduce((err, key) =>
                  deepmerge(err, toObject(key, error.extensions.validation[key]).input),
                  {}
                ))
              } else {
                notify({
                  type: 'error',
                  title: 'Er is iets mis gegaan',
                  content: error.message,
                })
              }
            })
          } else {
            onSubmit(results)
          }
          setDisabled(false)
        },
        onError: err => {
          notify({
            type: 'error',
            title: 'Er is iets mis gegaan',
            content: err.message
          })
        },
      })
    }
  })
)

withForm.propTypes = {
  data: PropTypes.object,
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
}

export default withForm
