import React from 'react'
import graphql from 'babel-plugin-relay/macro'
import { compose, withProps } from 'recompose'

import naturalSort from 'es6-natural-sort'

import { withOrganisation } from 'containers/Organisation'
import withFilters from 'containers/withFilters'
import withSearch from 'containers/withSearch'
import withQuery from 'containers/withQuery'
import { withMe } from 'containers/Me'
import { withMask } from 'containers/Mask'
import { withModal } from 'containers/Modal'
import { HeaderMod } from 'containers/Header'
import { parseTheme } from 'containers/OrganisationTheme'

import Loader from 'components/Loader'
import Filters from 'components/Filters'
import ResultsOverview from 'components/teacher/results'
import ResultsManual from 'components/teacher/manual/ResultsManual'

import { firstBy } from 'thenby'
import { unique, download } from 'utilities/functions'
import {
  getAllowedTries,
  getMeanGrade,
  getBestAttempt,
  getMeanDuration,
  getMeanDurationNormalized,
  getCategories,
} from 'utilities/attemptManipulation'
import { CATEGORIES } from 'utilities/constants'
import moment from 'moment'
import { parse } from 'json2csv'
import { transformRowData } from '../../components/teacher/results/Row';

const recipesToCSV = (data, guarantee) => {
  const csvData = data
    .filter(row => row.students)
    .map(row => {
      const allowedTries = getAllowedTries(guarantee, row.id);
      const studentCount = row.students.length;
      const studentCountByCategory = getCategories(allowedTries, row.students)
      const participatedCount = studentCountByCategory[CATEGORIES.FAILED]
                              + studentCountByCategory[CATEGORIES.PASSED]
                              + studentCountByCategory[CATEGORIES.EXCELLENT]

      const passedAndExcellentCount = studentCountByCategory[CATEGORIES.PASSED]
                                    + studentCountByCategory[CATEGORIES.EXCELLENT]

      return {
        categorie: row.belongingCategory,
        naam: row.name,
        gemiddeld_cijfer: Math.round(10 * getMeanGrade(row.students, allowedTries)) / 10,
        gemiddelde_tijd: getMeanDuration(row.students, allowedTries)
          ? moment.utc(getMeanDuration(row.students, allowedTries)).format('HH:mm:ss')
          : null,
        gemiddelde_tijd_genormaliseerd: getMeanDurationNormalized(row.students, allowedTries)
          ? moment.utc(getMeanDurationNormalized(row.students, allowedTries)).format('HH:mm:ss')
          : null,
        percentage_gezakt: Math.round(studentCountByCategory[CATEGORIES.FAILED] / participatedCount * 100) + '%',
        percentage_geslaagd: Math.round(passedAndExcellentCount / participatedCount * 100) + '%',
        percentage_niet_deelgenomen: Math.round(studentCountByCategory[CATEGORIES.NOT_PARTICIPATED] / studentCount * 100) + '%',
        percentage_deelgenomen: Math.round(participatedCount / studentCount * 100) + '%',
        aantal_gezakt: studentCountByCategory[CATEGORIES.FAILED],
        aantal_geslaagd: passedAndExcellentCount,
        aantal_niet_deelgenomen: studentCountByCategory[CATEGORIES.NOT_PARTICIPATED],
        aantal_deelgenomen: participatedCount,
      }
    })

  return parse(csvData, {
    fields: Object.keys(csvData[0]),
    delimiter: ';',
  })
}

const studentsToCSV = (data, guarantee, organisationModule, t, customFields) => {
  // Cross-browser alternative for Array.flat:
  //     https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/flat#Alternative
  const flatSingle = arr => [].concat(...arr);

  const csvData = flatSingle(
    data
      .filter(row => row.students)
      .map(row => row.students.map(student => {
        const bestAttempt = getBestAttempt(student.attempts, getAllowedTries(guarantee, row.id)) || {};

        return {
          naam: student.name,
          deelgenomen: bestAttempt.date ? 'ja' : 'nee',
          ...customFields.reduce(
            (acc, cur) => {
              acc[t(cur)] = student[cur];

              return acc;
            },
            {}
          ),
          pe_omgeving: student.org_name,
          opleiding: organisationModule.module.module_study.name,
          toetsmatrijs: row.name,
          datum_afronding: bestAttempt.date ? bestAttempt.date.format('DD-MM-YYYY HH:mm') : '',
          score: bestAttempt.grade,
        }
      }))
  );

  return parse(csvData, {
    fields: Object.keys(csvData[0]),
    delimiter: ';',
  })
}

const isDownloadButtonEnabled = (data) => {
  return data.length > 0;
}

const ResultsPage = ({
  theme, popup, close, organisation, me, mask,
  organisationModule,
  data, loading, error, query, setQuery, t, ...rest
}) =>
  <HeaderMod
    title="Toetsresultaten"
    backLink={`/${organisation.slug}/dashboard`}
    actions={[
      {
        label: 'Download per toetsmatrijs',
        icon: 'download',
        disabled: !isDownloadButtonEnabled(data),
        onClick: () => download(
          `toetsresultaten_toetsmatrijs_${organisationModule.module.module_study.name}.csv`,
          recipesToCSV(data, organisationModule.module.module_study.guarantee)
        ),
      },
      {
        label: `Download per ${t('student_singular')}`,
        icon: 'download',
        disabled: !isDownloadButtonEnabled(data),
        onClick: () => download(
          `toetsresultaten_${t('student_singular')}_${organisationModule.module.module_study.name}.csv`,
          studentsToCSV(
            data,
            organisationModule.module.module_study.guarantee,
            organisationModule,
            t,
            customFields(organisation)
          )
        ),
      },
      {
        label: 'Handleiding',
        icon: 'question-mark',
        onClick: () => popup(<ResultsManual t={t} />, theme),
      },
    ]}>
    <Filters
      low
      {...rest}
    />
    <ResultsOverview
      data={data}
      loading={loading}
      error={error}
      query={query}
      setQuery={setQuery}
      teacher={mask ? mask : me}
      popup={popup}
      close={close}
      organisation_module={organisationModule}
      guarantee={organisationModule.module.module_study.guarantee}
    />
  </HeaderMod>

const getQuery = () => graphql`
  query resultsQuery($organisationModuleId: ID! $teacherId: ID!) {
    organisationModule(
      id: $organisationModuleId
    ) {
      id
      visible_recipes {
        id
      }
      module {
        id
        module_study {
          id
          icon
          name
          slug
          study {
            id
          }
          guarantee {
            allowed_fails
            duration

            first_recipe {
              id
            }
            last_recipe {
              id
            }
            counting_recipes {
              id
              allowed_tries
            }
          }
        }
      }
    }

    recipes(
      organisationModuleId: $organisationModuleId
    ) {
      id
      name
      order
      category
      category_order
      open_questions {
        id
        name
        question_number
      }
      sections {
        id
        name
      }
    }

    students(
      teacherId: $teacherId
      organisationModuleId: $organisationModuleId
    ) {
      id
      name
      first_name
      middle_name
      last_name
      invoice_contact
      code
      code_invoice
      code_debtor
      code_contract
      code_project
      groupname
      org_name
      candidate {
        id
      }
    }

    results(
      organisationModuleId: $organisationModuleId
      teacherId: $teacherId
    ) {
      id
      grade
      score
      max_score
      open_answers {
        answer
        open_question {
          id
        }
      }
      section_results {
        section {
          id
        }
        grade
      }
      started_at
      finished_at
      candidate {
        id
      }
      recipe {
        id
      }
    }
  }
`

const stringCompare = (a, b) =>
  (a.toLowerCase() < b.toLowerCase())
    ? -1
    : (a.toLowerCase() > b.toLowerCase())
      ? 1 : 0

const customFields = organisation => organisation.vocabulary
  ? Object.keys(organisation.vocabulary)
      .reduce((acc, key) => organisation.vocabulary[key] === true
        ? [...acc, key.replace('show_', '')]
        : acc, [])
  : []

const getVariables = ({ me, mask, organisation_module }) => ({
  teacherId: mask ? mask.id : me.id,
  organisationModuleId: organisation_module.id,
})

export default compose(
  withMe,
  withMask,
  withOrganisation,
  withModal,
  withProps(({ organisation }) => ({
    theme: parseTheme(organisation.theme || {}),
  })),
  withQuery({ getQuery, getVariables, loader: Loader }),
  withProps(({ recipes, students, results }) => ({
    data: recipes
      .map(recipe => ({
        ...recipe,
        sections: recipe.sections.map(section => ({
          ...section,
          students: students.map(student => ({
            ...student,
            attempts: []
          }))
        })),
        open_questions: recipe.open_questions
          ? recipe.open_questions
              .map(open_question => ({
                ...open_question,
                answers: []
              }))
          : [],
        students: students.map(student => ({
          ...student,
          attempts: []
        })),
        results: results.filter(result => result.recipe.id === recipe.id)
      }))
      .map(transformRowData)
  })),
  withProps(({ data }) => {
    const categories = data
      .map(({ category, ...rest }) => ({
        category: category || 'Overig',
        ...rest
      }))
      .reduce((categories, { category, ...rest }) => ({
        ...categories,
        [category]: [
          ...(categories[category] || []),
          { belongingCategory: category, ...rest }
        ],
      }), {})

    return {
      data: Object.keys(categories)
        .map(category => [
          { category, belongingCategory: category, category_order: categories[category][0].category_order },
          ...categories[category]
        ])
        .reduce((xs, ys) => [...xs, ...ys], [])
        .sort(
          firstBy('category_order')
            .thenBy('belongingCategory', {cmp: naturalSort})
            .thenBy('order')
            .thenBy('id'))
    }
  }),
  withProps(({ students, data, organisation, t }) => ({
    filters: [
      ...customFields(organisation)
        .map(field => ({
          label: t(field, ['capitalize']),
          type: 'select',
          options: unique(students
            .map(student => student[field])
            .map(option => option ? option : `Geen ${t(field)}`))
            .sort(stringCompare),
          filterFn: options => data => {
            if (options.length === 0) { return data }

            const filteredStudents = students
              .filter(student => options.includes(student[field]))
            const filteredStudentIds = filteredStudents.map(student => student.id)
            const filteredStudentNames = filteredStudents.map(student => student.name)

            return data.map(row => row.category
              ? row
              : {
                  ...row,
                  students: row.students
                    .filter(student => filteredStudentIds.includes(student.id)),
                  sections: row.sections
                    .map(section => ({
                      ...section,
                      students: section.students
                        .filter(student => filteredStudentIds.includes(student.id)),
                    })),
                  open_questions: row.open_questions
                    .map(open_question => ({
                      ...open_question,
                      answers: [
                        ...open_question.answers
                          .map(a => ({
                            ...a,
                            studentNames: a.studentNames
                              .filter(name => filteredStudentNames.includes(name))
                          }))
                          .filter(a => a.studentNames.length > 0)
                      ]
                    })),
                }
            )
          }
        })),
      {
        label: 'Categorie',
        type: 'select',
        options: unique(data
          .map(row => row.category)
          .filter(category => !!category))
          .sort(stringCompare),
        fragment: row => row.category || row.belongingCategory
      },
      {
        label: 'Toets',
        type: 'select',
        options: unique(data
          .map(row => row.name)
          .filter(name => !!name))
          .sort(stringCompare),
        fragment: row => row.name,
      },
      {
        label: 'Toetsdatum',
        type: 'daterange',
        min: moment().subtract(2, 'year'),
        max: moment(),
        filterFn: ({ from, to }) => data => data.map(row => row.students && (from || to)
          ? {
              ...row,
              students: row.students
                .filter(student => {
                  // Set from date to start of day, and to date to end of day,
                  // so that any attempts made that day are displayed.
                  if (from) {
                      from.startOf('day');
                  }
                  if (to) {
                      to.endOf('day');
                  }

                  const firstDate = student.attempts && student.attempts.length > 0
                    ? student.attempts.sort((a, b) => a.try - b.try)[0].date
                    : null

                  const bestDate = student.attempts && student.attempts.length > 0
                    ? getBestAttempt(student.attempts, 100).date
                    : null

                  return (
                    (
                      firstDate &&
                      !(from && firstDate.isBefore(from)) &&
                      !(to && firstDate.isAfter(to))
                    ) ||
                    (
                      bestDate &&
                      !(from && bestDate.isBefore(from)) &&
                      !(to && bestDate.isAfter(to))
                    )
                  )
                }),
            }
          : row)
      },
    ]
  })),
  withFilters,
  withSearch([
    row => row.name,
    row => row.belongingCategory,
  ]),
)(ResultsPage)
