import styled from 'styled-components'

export default styled.span`
  position: relative;

  display: inline-block;

  width: ${props => props.size}px;
  height: ${props => .9 * props.size}px;

  &::before {
    content: '';

    position: absolute;
    left: 0; top: 0;
    right: 0; bottom: 0;

    width: ${props => .6 * props.size}px;
    height: ${props => .9 * props.size}px;
    margin: auto;
    border-right: ${props => .2 * props.size}px solid;
    border-bottom: ${props => .2 * props.size}px solid;

    transform: rotate(30deg);
  }
`
