/**
 * @generated SignedSource<<cb82bb71fb5eaa32cc3385e7ba0f1c06>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ModuleStudy",
    "kind": "LinkedField",
    "name": "moduleStudies",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "icon",
        "storageKey": null
      },
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Guarantee",
        "kind": "LinkedField",
        "name": "guarantee",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Module",
        "kind": "LinkedField",
        "name": "module",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Study",
        "kind": "LinkedField",
        "name": "study",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ModulesOverviewQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ModulesOverviewQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "9110f0aa365f4b2dba38f6eb22e75164",
    "id": null,
    "metadata": {},
    "name": "ModulesOverviewQuery",
    "operationKind": "query",
    "text": "query ModulesOverviewQuery {\n  moduleStudies {\n    id\n    icon\n    name\n    guarantee {\n      id\n    }\n    module {\n      id\n      name\n    }\n    study {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

node.hash = "7da084a6ad107577896ff6ae293777f2";

module.exports = node;
