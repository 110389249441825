import React from 'react'
import graphql from 'babel-plugin-relay/macro'
import styled from 'styled-components'

import { compose, withHandlers } from 'recompose'
import withForm from 'containers/withForm'
import { withOrganisation } from 'containers/Organisation'

import { Paper, Input, Button, Align, Styles, Float } from 'hoffelijk-react-components'

const ModuleStudyName = styled.h4`
  line-height: 40px;
  margin: 0;
`

const ClearFix = styled.div`
  clear: both;
  height: 30px;
`

const TeacherAccessManagement = ({ data, values, errors, onSubmit, disabled, setAccess, t }) =>
  <form>
    <Paper light>
      <Paper.Section>
        <Styles.NoMargin as="h2">Weergaverechten van {data.user.name}</Styles.NoMargin>
      </Paper.Section>
      {data.user.organisation.organisation_modules.filter(organisation_module => organisation_module.module.module_study !== null).map(organisation_module =>
        <Paper.Section key={organisation_module.id}>
          <Float.Left>
            <ModuleStudyName>
              {organisation_module.module.module_study.name}
            </ModuleStudyName>
          </Float.Left>
          <Float.Right>
            <Button
              onClick={event => {
                event.preventDefault()
                setAccess(organisation_module.id, data.groups)}
              }
            >
              Selecteer alle {t('connecting_field_group_plural')}
            </Button>
          </Float.Right>
          <ClearFix />
          <Input
            type="select"
            label={t('connecting_field_group_plural', ['capitalize'])}
            value={values.access
              .find(x => x.organisation_module_id === organisation_module.id)
                ? values.access
                  .find(x => x.organisation_module_id === organisation_module.id).groups
                : []
            }
            options={[
              {
                label: `Alle ${t('connecting_field_group_plural')} (ook in de toekomst)`,
                value: '$$ALL'
              },
              ...data.groups
                .map(group => ({
                  label: group ? group : `Geen ${t('connecting_field_group')}`,
                  value: group ? group : '$$NONE',
                }))
            ]}
            onChange={groups =>
              setAccess(organisation_module.id, groups.includes('$$ALL')
                ? ['$$ALL']
                : groups)}
            multi
            block
          />
        </Paper.Section>
      )}
    </Paper>
    <Align.Center>
      <Button under onClick={onSubmit} disabled={disabled}>Wijzigingen opslaan</Button>
    </Align.Center>
  </form>

const getMutation = () => graphql`
  mutation TeacherAccessManagementMutation($userId: ID!, $input: UserAccessInput!) {
    updateUserAccess(userId: $userId, input: $input) {
      id
    }
  }
`

export default compose(
  withOrganisation,
  withForm({
    getMutation,
    getVariables: ({ user }) => input => ({
      userId: user.id,
      input,
    }),
  }),
  withHandlers({
    setAccess: ({ values, updateValue }) => (organisation_module_id, groups) => updateValue('access',
      values.access.find(x => x.organisation_module_id === organisation_module_id)
        ? values.access.map(value => value.organisation_module_id === organisation_module_id
          ? { ...value, groups } : value)
        : [...values.access, { organisation_module_id, groups }]
    )
  }),
)(TeacherAccessManagement)
