import React from 'react'

import OrganisationsOverview from 'components/admin/OrganisationsOverview'
import ModulesOverview from 'components/admin/ModulesOverview'
import AdminsOverview from 'components/admin/AdminsOverview'

import { withMe } from 'containers/Me'
import { isSuperAdmin } from 'utilities/logic/authentication'

const IndexPage = ({ me }) =>
  <>
    <OrganisationsOverview />
    <ModulesOverview />
    {isSuperAdmin(me) &&
      <>
        <AdminsOverview />
      </>}
  </>

export default withMe(IndexPage)
