import React from 'react'

import { compose, onlyUpdateForKeys, withProps } from 'recompose'

import Filter from './Filter'
import Container from './Container'
import FilterContainer from './FilterContainer'

import SelectionFilter, { Option } from './SelectionFilter'
import DateFilter from './DateFilter'

const isSelected = filterState =>
  filterState && (filterState.length > 0 || !!filterState.from || !!filterState.to)

const Filters = compose(
  onlyUpdateForKeys(['filters', 'filterState', 'down', 'low', 'expanded']),
  withProps(({ filters }) => ({
    filters: filters.filter(x => !x.options || x.options.length > 1)
  }))
)(({
  filters, filterState={},
  toggleOption=(() => () => {}),
  setRange=(() => () => {}),
  down, low,
  expanded, toggleExpanded,
  clearFilters,
}) =>
  <Container
    selected={Object.values(filterState).some(isSelected)}
    down={down}
    low={low}
    expanded={expanded}
    toggleExpanded={toggleExpanded}
    clearFilters={clearFilters}
  >
    {filters.length > 0 && filters
      .map(({ label, type, options, min, max }, i, xs) =>
        <Filter
          key={i}
          label={label}
          selected={isSelected(filterState[label])}
          type={type}
          state={filterState[label]}
        >
        {type === 'daterange'
          ? <DateFilter
              label={label}
              dateState={filterState[label]}
              onChange={v => setRange(label)(v)}
              last={i === xs.length - 1}
              min={min}
              max={max}
            />
          : <SelectionFilter
              options={options}
              optionState={filterState[label]}
              onOptionClick={v => toggleOption(label)(v)}
              last={i === xs.length - 1}
            />}
      </Filter>
    )}
  </Container>)

Filters.Filter = Filter
Filters.Container = Container
Filters.OptionContainer = FilterContainer
Filters.Option = Option

export {
  Filter,
  Container,
  FilterContainer as OptionContainer,
  Option,
}

export default Filters
