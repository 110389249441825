/**
 * @generated SignedSource<<614944fb9b1bfb9abcad142fd68a632f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "studentId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      },
      {
        "kind": "Variable",
        "name": "studentId",
        "variableName": "studentId"
      }
    ],
    "concreteType": "StudentNote",
    "kind": "LinkedField",
    "name": "addNotes",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CommentDialogMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CommentDialogMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "8c0c641b00787d409095b410d1145d77",
    "id": null,
    "metadata": {},
    "name": "CommentDialogMutation",
    "operationKind": "mutation",
    "text": "mutation CommentDialogMutation(\n  $studentId: ID!\n  $input: AddNotesInput!\n) {\n  addNotes(studentId: $studentId, input: $input) {\n    id\n  }\n}\n"
  }
};
})();

node.hash = "834619865ec4784fc6640b59863c66fb";

module.exports = node;
