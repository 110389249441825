import { takeLatest } from 'redux-saga/effects'
import { LOCATION_CHANGE } from 'connected-react-router'

const getOffset = el => {
  if (!el) { return { x: 0, y: 0 } }

  const { x, y } = getOffset(el.offsetParent)
  return { x: x + el.offsetLeft, y: y + el.offsetTop }
}

const scrollToTop = ({ payload }) => {
  if (payload.hash) {
    const el = document.querySelector(payload.hash)
    const { x, y } = getOffset(el)
    window.scrollTo(x, y - 203)
  } else {
    window.scrollTo(0, 0)
  }
}

export function* saga() {
  yield takeLatest(LOCATION_CHANGE, scrollToTop)
}

export default saga
