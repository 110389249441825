import React from 'react'
import styled from 'styled-components'
import graphql from 'babel-plugin-relay/macro'

import withForm from 'containers/withForm'

import { Paper, Input, Button, Float } from 'hoffelijk-react-components'

const LoginPaper = styled(Paper)`
  margin-right: -90px;
  margin-bottom: 30px;
`

const LoginInfo = styled.div`
  padding-top: 20px;

  a {
    display: inline-block;
    margin-top: 10px;
  }
`

const PasswordResetForm = ({ passwordReset, recaptcha=false, values, errors, updateValue, disabled, onSubmit }) =>
  <form>
    <LoginPaper light>
      <Paper.Section>
        <h4>Stel een nieuw wachtwoord in</h4>
        {passwordReset
          ? passwordReset.expired
            ? <p>Dit reset token is verlopen</p>
            : <>
                <Input.Group>
                  <Input
                    label="Wachtwoord"
                    type="password"
                    name="password"
                    value={values.password}
                    error={errors.password}
                    onChange={v => updateValue('password', v)}
                    block
                  />
                  <Input
                    label="Herhaal wachtwoord"
                    type="password"
                    name="confirm_password"
                    value={values.confirm_password}
                    error={errors.confirm_password}
                    onChange={v => updateValue('confirm_password', v)}
                    block
                  />
                </Input.Group>
                <LoginInfo>
                  <Float.Right>
                    <Button type="submit" onClick={onSubmit} disabled={disabled}>Stel in</Button>
                  </Float.Right>
                </LoginInfo>
              </>
            : <p>Dit reset token bestaat niet</p>}
      </Paper.Section>
    </LoginPaper>
  </form>

export default withForm({
  getMutation: () => graphql`
    mutation PasswordResetFormMutation($input: PasswordResetInput!) {
      passwordReset(input: $input) {
        accessToken
      }
    }
  `,
  getVariables: ({ token }) => input => ({
    input: {
      ...input,
      token,
    }
  }),
})(PasswordResetForm)
