import React from 'react'
import styled from 'styled-components'
import { compose, withProps, withState, onlyUpdateForKeys } from 'recompose'

import FilterContainer from './FilterContainer'
import Input from '../Input'
import { Label } from '../Input/Input'

const Gray = styled.div`
  font-weight: bold;
  padding: 16px;

  color: ${props => props.theme.color.gray40};
`

export const Option = styled.div`
  cursor: pointer;
  padding: 16px 16px 16px 48px;

  &:nth-child(n + 2) { border-top: 1px solid ${props => props.theme.color.gray20}; }

  font-weight: bold;

  position: relative;

  &:hover { background-color: ${props => props.theme.color.gray10}; }

  &::before {
    content: "${props => props.selected ? '✓' : ''}";

    display: inline-block;
    width: 18px;
    height: 18px;
    padding-left: 1.5px;
    margin-left: -32px;
    margin-right: 14px;
    border: 2px solid ${props => props.theme.color.gray40};
    border-radius: 4px;

    font-size: 12px;
    line-height: 15px;
  }
`

const SearchContainer = styled.div`
  border-bottom: 1px solid ${props => props.theme.color.gray20};

  ${Label} { border: none; }
`

const Inner = styled.div`
  min-height: 30px;
  max-height: calc(100vh - ${props => 400 + (props.hasSearch ? 50 : 0) - (props.down ? 60 : 0)}px);
  &:not(:hover) {
    max-height: inherit;
  }
  overflow-y: auto;
`

const enhance = compose(
  onlyUpdateForKeys([
    'options', 'optionState', 'last', 'down',
  ]),
  withState('query', 'setQuery', null),
  withProps(({ query, options }) => {
    const formattedOptions = options.length > 0 && typeof options[0] === 'string'
      ? options.map(option => ({ match: option, label: option }))
      : options

    return {
      filteredOptions: formattedOptions
        .filter(option => query === null || option.match.toLowerCase().includes(query))
    }
  })
)

export default enhance(({
  down, last,
  options, filteredOptions, onOptionClick, optionState=[],
  query, setQuery
}) =>
  <FilterContainer light last={last}>
    {options.length > 10 &&
      <SearchContainer>
        <Input
          type="search"
          label="Zoek optie"
          block
          value={query}
          onChange={v => setQuery(v ? v.toLowerCase() : null)}
        />
      </SearchContainer>}
    <Inner down={down} hasSearch={options.length > 10}>
      {filteredOptions.length > 0
        ? filteredOptions.map(({ match, label }) =>
            <Option
              key={match}
              selected={optionState.includes(match)}
              onClick={onOptionClick(match)}
            >
              {label}
            </Option>
          )
        : <Gray>Geen opties</Gray>
      }
    </Inner>
  </FilterContainer>
)
