import React from 'react'
import { compose } from 'recompose'
import styled from 'styled-components'

import { withModal } from 'containers/Modal'
import { Plus, Check, Cross } from 'components/glyphs'
import ProgressIllustration from './ProgressIllustration'
import AttemptDetails from './AttemptDetails'
import ExamResults from './ExamResults'
import { SimpleTable, Icon } from 'hoffelijk-react-components'

import { getAllowedTries, parseGrade, getBestAttempt, getCategory } from 'utilities/attemptManipulation'
import { CATEGORIES } from 'utilities/constants'
import { firstBy } from 'thenby'
import naturalSort from "es6-natural-sort";

const ClickablePlus = styled(Plus)`cursor: pointer;`

const SuccessText = styled.span`color: ${props => props.theme.color.success}`
const ErrorText = styled.span`color: ${props => props.theme.color.error}`

const getProgressMessage = ({ status, days }) => {
  switch (status) {
    case 0: return 'Geen schema'
    case 1: return <><ErrorText>{Math.abs(days)} dagen</ErrorText> achter op schema</>
    case 2: return 'Op schema'
    case 3: return <><SuccessText>{Math.abs(days)} dagen</SuccessText> voor op schema</>
    case 4: return 'Streefdatum gepasseerd'
    case 5: return 'Afgerond'
    default: return 'Onbekend'
  }
}

const RowDetails = ({ row, loading, error, popup }) =>
  <>
    {row.progress.status !== null &&
      <>
        <strong>{getProgressMessage(row.progress)} {row.start_date
          ? `(gestart op ${row.start_date.format('DD-MM-YYYY')})`
          : ''}</strong>
        <ProgressIllustration row={row} />
      </>}
    {(row.exam_participations.length > 0) &&
      <>
        <ExamResults participations={row.exam_participations} />
        <br />
      </>}
    <SimpleTable
      defaultCompare={
        firstBy('category_order')
          .thenBy('category', {cmp: naturalSort})
          .thenBy('order')
          .thenBy('id')
      }
      wideFirstColumn={true}
      columns={[
        {
          label: 'Toetsnaam',
          content: recipe => recipe.name,
        },
        {
          label: 'Resultaat',
          content: recipe => recipe.bestAttempt && recipe.bestAttempt.grade
            ? recipe.bestAttempt.grade : '-',
          comparableContent: recipe => recipe.bestAttempt
            ? parseGrade(recipe.bestAttempt.grade) : null,
        },
        {
          label: 'Datum',
          content: recipe => recipe.bestAttempt
            ? recipe.bestAttempt.date.format('DD-MM-YYYY') : '-'
        },
        {
          label: 'Pogingen',
          content: recipe => recipe.attempts.length > 0
            ? recipe.attempts.length
            : '-',
          comparableContent: recipe => recipe.attempts.length,
          align: 'center',
        },
        {
          label: 'Garantie',
          content: recipe => recipe.has_guarantee
            ? recipe.attempts.length > 0
              ? [CATEGORIES.PASSED, CATEGORIES.EXCELLENT]
                  .includes(getCategory(recipe.bestAttempt.grade, recipe.bestAttempt.max_score))
                    ? <Check size={14} />
                    : <Cross size={14} />
              : <Icon icon="hourglass" size={14} />
            : null,
          align: 'center',
        },
        {
          label: '',
          content: recipe => recipe.attempts.length > 1 &&
            <ClickablePlus
              size={14}
              onClick={() => popup(
                <AttemptDetails
                  moduleName={recipe.name}
                  allowed_tries={recipe.allowed_tries}
                  has_guarantee={recipe.has_guarantee}
                  attempts={recipe.attempts.map((x, i) => ({ ...x, attempt: i }))}
                />
              )}
            />,
          sortable: false
        },
      ]}
      data={row.recipes
        .map(recipe => ({
          ...recipe,
          has_guarantee: row.guarantee && row.guarantee.counting_recipes
            .find(counting_recipe => counting_recipe.id === recipe.id),
          allowed_tries: getAllowedTries(row.guarantee, recipe.id)
        }))
        .map(recipe => ({
          ...recipe,
          bestAttempt: getBestAttempt(
            recipe.attempts,
            recipe.allowed_tries
          ),
          key: recipe.id
        }))
      }
      loading={loading}
      error={error}
    />
  </>

export default compose(
  withModal,
)(RowDetails)
