/**
 * @generated SignedSource<<b857318740b45663aefe7bd9f7dae912>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organisationModuleId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      },
      {
        "kind": "Variable",
        "name": "organisationModuleId",
        "variableName": "organisationModuleId"
      }
    ],
    "concreteType": "OrganisationModule",
    "kind": "LinkedField",
    "name": "updateOrganisationModule",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganisationModuleManagementUpdateMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "OrganisationModuleManagementUpdateMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "ed9e2cba89e33a5b67d4c8bade96aa60",
    "id": null,
    "metadata": {},
    "name": "OrganisationModuleManagementUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation OrganisationModuleManagementUpdateMutation(\n  $organisationModuleId: ID!\n  $input: OrganisationModuleInput!\n) {\n  updateOrganisationModule(organisationModuleId: $organisationModuleId, input: $input) {\n    id\n  }\n}\n"
  }
};
})();

node.hash = "f233a9e34e235b5a521813f383df41b7";

module.exports = node;
